import { useState, useEffect } from "react";
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';
import NoteAdress from './NoteAdress.js';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  Typography,
  Button,
  Box,
  Checkbox,
  TextField,
  FormControlLabel,
} from '@material-ui/core';

function NoteWrite(props) {

  console.log(props);

  const [RECEIVE_USER_ID, setRECEIVE_USER_ID] = useState("");
  const [NTT_CN, setNTT_CN] = useState("");

  const getNote = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
      body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID'), NTT_ID: props.NTT_ID })
    };
    fetch(home_url + '/api/BRD110010SVC/get', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data != null) {
          setNTT_CN(data.NTT_CN);
          setRECEIVE_USER_ID(data.RECEIVE_USER_ID);
        }
      });
  }

  useEffect(() => {
    if (props.noteMode === 'updateNote') getNote();
    if (props.noteMode === 'writeNoteMyself') setRECEIVE_USER_ID(localStorage.getItem('USER_ID'));
    if (props.Receiver != null) setRECEIVE_USER_ID(props.Receiver);
  }, []);

  const sendNote = () => {
    if (RECEIVE_USER_ID === "") {
      alert("받는사람을 입력해주세요.");
      return false;
    }
    if (!window.confirm("쪽지를 보내시겠습니까?"))
      return false;
    let arr = RECEIVE_USER_ID.split(',');


    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
      body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID'), NTT_CN: NTT_CN, RECEIVE_USER_ID: arr, REF_NO: props.REF_NO })
    };
    fetch(home_url + '/api/BRD110010SVC/save', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.DATA !== 1) {
          alert(data.MSG);
          return false;
        }
        props.setREF_NO();

        if (RECEIVE_USER_ID === localStorage.getItem("USER_ID")) props.setNoteMode('getListMyself');
        else props.setNoteMode('sendList');

      })
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "800px",
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      padding: "32px 32px 16px",
      zIndex: "5000"
    },
  }));

  const classes = useStyles();
  const [Adress, setAdress] = useState(false);

  const AdressOpen = () => {
    setAdress(true)
  }

  const AdressClose = () => {
    setAdress(false);
  }

  return (
    <div>
      <Typography className="listTable_tt">
        작성하기
      </Typography>
      <Card>
        <table className="srchTable">
          <tr>
            <td colSpan="3">
              <table className="NoteTable">
                <colgroup style={{ display: props.noteMode === 'writeNoteMyself' ? 'none' : '' }}>
                  <col style={{ width: "90%" }}></col>
                  <col style={{ width: "10%" }}></col>
                </colgroup>
                <tr>
                  <td>
                    <TextField className="bbsInputSize"
                      fullWidth
                      name="RECEIVE_USER_ID"
                      type="string"
                      placeholder="받는사람: ( , )를 이용해 여러 명 입력 가능합니다. ex) abc,def,ghi"
                      value={RECEIVE_USER_ID}
                      onChange={(event) => setRECEIVE_USER_ID(event.target.value)}
                      disabled={props.noteMode === 'writeNoteMyself' || props.Receiver !== undefined ? true : false}
                    />
                  </td>
                  <td style={{ display: props.noteMode === 'writeNoteMyself' ? 'none' : '', paddingLeft: "5px" }}>
                    <Button className="adresPop_OpenBtn" onClick={() => AdressOpen()}>주소록</Button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              <textarea
                className="NoteWrite_txtSection"
                value={NTT_CN}
                onChange={(event) => { setNTT_CN(event.target.value) }}
              />
            </td>
          </tr>
        </table>
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button onClick={() => sendNote()} className="ButtonStyle02" variant="contained">보내기</Button>
      </Box>
      <Modal
        className={classes.modal}
        open={Adress}
        onClose={() => AdressClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Adress}>
          {/* <NoteAdress/> */}
          <div className={classes.paper} style={{ textAlign: "center" }}>
            <NoteAdress setAdress={setAdress} setRECEIVE_USER_ID={setRECEIVE_USER_ID} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
export default NoteWrite;