import { filter } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';

// material
import {
  Card,
  Box,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';

import { brdbd001Actions } from "../../../../slice/brdbd001Slice";
import { TotalboardListHead, TotalboardListToolbar, TotalboardMoreMenu } from './';

const TABLE_HEAD = [
  { id: 'No', label: 'No.', alignRight: false },
  { id: 'BBS_NAME', label: '게시판명', alignRight: false },
  // { id: 'BBS_INTRCN', label: '게시판설명', alignRight: false },
  { id: 'TMPLAT_ID', label: '게시판타입', alignRight: false },
  { id: 'ADD_DATE', label: '등록일자', alignRight: false },
  { id: 'WRITE_AUTH', label: '글쓰기 권한', alignRight: false },
  { id: 'USE_AT', label: '사용여부', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('query : ', query, array);
  if (query) {
    return filter(array, (_user) => _user.BBS_NAME.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Totalboard() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('ADD_DATE');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(brdbd001Actions.getBrdbd001());
  }, [dispatch]);

  const { brdbd001, isLoading, isSuccess, error } = useSelector((state) => ({
    brdbd001: state.brdbd001Reducers.brdbd001,
    isLoading: state.brdbd001Reducers.isLoading,
    isSuccess: state.brdbd001Reducers.isSuccess,
    error: state.brdbd001Reducers.error,
  }));

  const BRDLIST = [...Array(0)].map((_, index) => ({}));
  // console.log('brdbd001 : ', brdbd001, BRDLIST);

  for (var n in brdbd001) {
    BRDLIST.push(brdbd001[n]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = BRDLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);

  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleBoardRowClick = (bbs_data) => {
    history.push({ pathname: '/app/boardupdate/' + bbs_data['BBS_ID'], state: { 'bbs_id': bbs_data['BBS_ID'] } });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRegisterPage = (event, newPage) => {
    history.push('/app/boardregister');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - BRDLIST.length) : 0;

  const filteredTotalboards = applySortFilter(BRDLIST, getComparator(order, orderBy), filterName);

  const isTotalboardNotFound = filteredTotalboards.length === 0;

  const handleOnClick = useCallback(() => history.push('/app/boardregister'), [history]);

  // 게시글보러가기
  // const handleBoardRowClick = (bbs_id) => {
  //   const path = `/app/boardregister/${bbs_id}`;
  //   history.push(path);
  // };


  return (
    <div>
      <Page title="Totalboard | Minimal-UI">
        {/* <Container> */}
        <Typography className="listTable_tt">
          통합 게시판관리
        </Typography>
        <Card>
          {/* <Scrollbar> */}
          <TableContainer >
            <Table className="listTable">
              <TotalboardListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={BRDLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              // onBoardRowClick={handleBoardRowClick}
              />
              <TableBody>
                {filteredTotalboards
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const { NO, BBS_ID, BBS_NAME, BBS_INTRCN, BBS_TY_CODE, TMPLAT_ID, REPLY_POSBL_AT, FILE_ATCH_POSBL_AT, ATCH_POSBL_FILE_NUMBER, ATCH_POSBL_FILE_SIZE, ADD_DATE, USE_AT, WRITE_AUTH } = row;
                    const isItemSelected = selected.indexOf(BBS_NAME) !== -1;

                    return (
                      <TableRow
                        hover
                        key={BBS_ID}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="center" scope="row"> {index + page * rowsPerPage + 1} </TableCell>
                        <TableCell scope="row" padding="none" onClick={() => handleBoardRowClick({ BBS_ID })}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap style={{ cursor: 'pointer' }}>
                              {BBS_NAME}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {/* <TableCell align="left">&nbsp;{BBS_INTRCN}</TableCell> */}
                        <TableCell align="left">&nbsp;{TMPLAT_ID}</TableCell>
                        <TableCell align="left">&nbsp;{ADD_DATE}</TableCell>
                        <TableCell align="center">
                          {WRITE_AUTH === 'U' && '일반 사용자'}
                          {WRITE_AUTH === 'M' && '기업 관리자'}
                          {WRITE_AUTH === 'A' && '전체 관리자'}
                        </TableCell>
                        <TableCell align="center">
                          {USE_AT === 'Y' && '사용'}
                          {USE_AT === 'N' && '미사용'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isTotalboardNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* </Scrollbar> */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={BRDLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="페이지 당 게시판 수 : "
          />
          <TotalboardListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button type="primary" className="ButtonStyle02"
              color="primary"
              variant="contained"
              onClick={handleRegisterPage}>
              게시판생성
            </Button>
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </div>
  );
}
