import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function monve(){alert('asdfasdfasdfasdfasd');}

function PLT010101(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          개요
          </Typography>
          {/* <Divider/> */}
          <div className="common_totalWrap">
          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            사업목적
          </div>
          <div className="common_txt">
            - 스마트 물류플랫폼 구축·운영을 통해 산업단지 SOC 디지털화 촉진<br/>
            - 산업단지 물류 최적화 및 입주기업 물류비용 절감효과 제고
          </div>

          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            총 사업기간
          </div>
          <div className="common_txt">
            - 2020년 9월 ~ 2022년 12월
          </div>

          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            총 예산
          </div>
          <div className="common_txt">
            - 3년간 총 105억(정부지원금 50억원, 컨소시엄 투자 55억)
          </div>

          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            추진체계
          </div>
          <div className="common_img">
            <img src={require("../../../images/organization_map.jpg").default} className="" />
          </div>
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT010101;