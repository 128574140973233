import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import  {logout} from '../PortalMain/Sections/PortalSession.js';

import {
  Typography
} from '@material-ui/core';
import cookies from 'react-cookies';

const PLT000101_13 = (props) => {

  const history = useHistory();
 
  const textMsg1 = useRef();
  const textMsg2 = useRef();

  const [nowPw , setNowPw] = useState('');
  const [newPw , setNewPw] = useState('');
  const [checkNewPw , setChekcNewPw] = useState('');
  const [msg1 , setMsg1] = useState('');
  const [msg2 , setMsg2] = useState('');
 
  
  
 
  const handleSubmit = (event) => {
    event.preventDefault();

    if(nowPw == '' || newPw == '' || checkNewPw == ''){
      alert("비밀번호를 모두 입력하세요.");
      return;
    }
    
    let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    if (!regExp.test(newPw)) {
        alert("비밀번호는 특수문자를 반드시 포함한 8~16글자 입니다.");
        return false;
    }


    if( newPw != checkNewPw){
      alert("입력한 새 비밀번호가 서로 일치하지 않습니다.");
      return false;
    }

    if( nowPw == checkNewPw){
      alert("새 암호가 기존 암호와 동일합니다.");
      return false;
    }
  

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': cookies.load('X-CSRF-TOKEN') },
      body: JSON.stringify({ C_PW: nowPw, N_PW_C: checkNewPw , PORTAL_CHANGE_PW : 'Y' })
    };
    fetch(home_url + '/api/SMS020020SVC/changepw', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) { if (data.TYPE == -1) {
        alert("현재 비밀번호가 다릅니다.")
      } else if(data.TYPE == 1){
          alert("비밀번호 변경이 완료되었습니다.");
        //  logout();
          history.push("/");
      }
  });


  }
  const checkPw = (param ,type) => { 
    if(newPw == ''){
      if(checkNewPw != ''){
        setMsg2("새 비밀번호를 먼저 입력해 주세요.")
       
        textMsg2.current.style.color = 'red'
      }
    }else {
      let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
        if (!regExp.test(newPw)) {
          setMsg1("비밀번호는 특수문자를 반드시 포함한 8~16글자 입니다.");
          textMsg1.current.style.color = 'red'
       
        }else{
          setMsg1("사용 가능한 비밀번호입니다.");
        
          textMsg1.current.style.color = 'blue'
        }
        if(checkNewPw != '' && newPw != checkNewPw){
          setMsg2("입력한 새 비밀번호가 서로 일치하지 않습니다.");
         
          textMsg2.current.style.color = 'red'
        }else if(checkNewPw != '' && newPw == checkNewPw){
          setMsg2("입력한 비밀번호가 일치합니다.");
        
          textMsg2.current.style.color = 'blue'
        }
    }
  
  }

   const reset = () => {
    setNowPw('');
    setNewPw('');
    setChekcNewPw('');
    setMsg1('');
    setMsg2('');
  }
    
 const savePw = (event , passwordType) =>{
    if(passwordType =='nowPw')
      setNowPw(event.target.value);
    else if(passwordType =='newPw')
      setNewPw(event.target.value);
    else
      setChekcNewPw(event.target.value); 

 }

 
          
  return (
    <>
       <form onSubmit = {handleSubmit}>
        <div className ="pwChange">
          <Typography variant="h4">  비밀번호 변경 </Typography>
          <Typography style = {{fontSize : '13px' ,color : '#a9a9a9', marginTop :'20px'}}> 스마트물류풀랫폼에서는 개인정보를 보호하기 위해 안전한 비밀번호만 사용할 수 있습니다.</Typography>               
          <div className ='changePwSection'>
            <ul>
              <li><span className = 'nowPw'>현재 비밀번호</span>
                  <input className ='pwInput2' 
                        name = 'now_password' 
                        type = 'password' 
                        onChange = {(event) => savePw(event ,"nowPw")}
                        value = {nowPw}
                          /></li>
              <li><span className = 'newPw'>새 비밀번호</span>
                  <input className ='pwInput2' 
                        name = 'new_password' 
                        type = 'password'  
                        onChange = {(event) => savePw(event ,"newPw")}
                        value = {newPw}
                        onBlur ={(event) => checkPw(event, "1")}  
                        /><br />
                        <p className = 'passwordMsg' ref={textMsg1} style = {{fontSize : '13px' ,marginTop :'7px' }}>{msg1}</p></li>
              <li><span className = 'checkNewPw'>새 비밀번호 확인</span>
                  <input className ='pwInput2' 
                        name = 'check_new_password' 
                        type = 'password'  
                        onChange = {(event) => savePw(event ,"checkNewPw")}
                        value = {checkNewPw}
                        onBlur ={(event) => checkPw(event, "2")}        
                        /><br />
                        <p  className = 'passwordMsg' ref={textMsg2} style = {{fontSize : '13px' ,marginTop :'7px'}}>{msg2}</p></li>
            </ul>
          </div>
          <br />
          <div style = {{color : '#a9a9a9' ,marginBottom : '20px'}}>
              유의 사항
            <ul>
              <li>8~16자 영문 대무자,소문자,숫자,특수문자를 조합하여 주세요.</li>
            </ul>
          </div>
        <button type ="button" className = 'resetPwButton' onClick= {reset}>다시입력</button> &nbsp;&nbsp;
        <button type ="submit"  className = 'changePwButton'>확인</button>
        </div>
      </form> 

    </>
  );
}
export default PLT000101_13;