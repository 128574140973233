import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import TextList from "../JobList";
import { brdbd003Actions } from "../../../../slice/brdbd003Slice";
import PortalSession from "./PortalSession";
import cookies from "react-cookies"
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/styles";

import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

import $ from 'jquery'
import ReserveEvent from "./ReserveEvent";
window.$ = $;


function PortalRight() {

  var items = new Array();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(0);

  const rightBannerOpen = () => {
      $('.bannerPop_logo, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05').css('display','none');
      $('.bannerPop, .bannerPop_Img, .coffeeBanner_Img, .bannerPop_ImgWrap, .coffee_banner_button1, .coffee_banner_button2').css('display','block');
      $('.bannerPop_ImgMain, .bannerPop_menuWrap').css('display', 'none');
      $('body').css('overflow','hidden'); 
      var popBgPosition = document.documentElement.scrollTop;
      var popBgHeight = window.scrollHeight;
      $('.bannerPop_ImgWrap, .bannerPop').css('top',popBgPosition); 
      $('.bannerPop').css('height',popBgHeight); 
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "800px",
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      padding: "32px 32px 16px",
      zIndex: "5000"
    },
  }));
  const classes = useStyles();
  const [Reserve, setReserve] = useState(false);
  const [RECEIVE_USER_ID, setRECEIVE_USER_ID] = useState();
  const [Adress, setAdress] = useState(false);

  const ReserveOpen = () => {
    setReserve(true)
  }
  const AdressClose = () => {
    setAdress(false);
  }

  const states = [
    { value: 'Y', label: '사용' },
    { value: 'N', label: '미사용' }
  ];

  const stpoint = [
    { value: '0', label: '선택' },
    { value: '출발지1', label: '출발지1' },
    { value: '출발지2', label: '출발지2' },
    { value: '출발지3', label: '출발지3' },
    { value: '출발지4', label: '출발지4' },
    { value: '출발지5', label: '출발지5' },
    { value: '출발지6', label: '출발지6' },
    { value: '출발지7', label: '출발지7' }
  ];

  const edpoint = [
    { value: '0', label: '선택' },
    { value: '도착지1', label: '도착지1' },
    { value: '도착지2', label: '도착지2' },
    { value: '도착지3', label: '도착지3' },
    { value: '도착지4', label: '도착지4' },
    { value: '도착지5', label: '도착지5' },
    { value: '도착지6', label: '도착지6' },
    { value: '도착지7', label: '도착지7' }
  ];

  const carType = [
    { value: '0', label: '선택' },
    { value: '탑차', label: '탑차' },
    { value: '윙바디', label: '윙바디' },
    { value: '트레일러', label: '트레일러' },
    { value: '탱크로리', label: '탱크로리' },
    { value: '덤프트럭', label: '덤프트럭' },
  ];

  const containerType = [
    { value: '0', label: '선택' },
    { value: '컨테이너타입1', label: '컨테이너타입1' },
    { value: '컨테이너타입2', label: '컨테이너타입2' },
    { value: '컨테이너타입3', label: '컨테이너타입3' },
    { value: '컨테이너타입4', label: '컨테이너타입4' },
    { value: '컨테이너타입5', label: '컨테이너타입5' },
  ];

  const cargoType = [
    { value: '0', label: '선택' },
    { value: '화물종류1', label: '화물종류1' },
    { value: '화물종류2', label: '화물종류2' },
    { value: '화물종류3', label: '화물종류3' },
    { value: '화물종류4', label: '화물종류4' },
    { value: '화물종류5', label: '화물종류5' },
  ];

  const onSubmitBrdbd003 = (event) => {

    event.preventDefault();
    const ESTIMATE_TYPE = event.target.ESTIMATE_TYPE.value;
    const ST_POINT = event.target.ST_POINT.value;
    const ED_POINT = event.target.ED_POINT.value;
    const CARGO_TYPE = event.target.CARGO_TYPE.value;
    const NAME = event.target.NAME.value;
    const CELL_PHONE = event.target.CELL_PHONE.value;
    const EMAIL = event.target.EMAIL.value;
    let CAR_TYPE = '';
    let CONTAINER_TYPE = '';
    let CBM_TYPE = '';
    let WEIGTH = '';

    if (ST_POINT === "" || ST_POINT === null || ST_POINT === undefined || ST_POINT === '0') {
      alert("출발지를 선택해 주세요.");
      return false;
    }

    if (ED_POINT === "" || ED_POINT === null || ED_POINT === undefined || ED_POINT === '0') {
      alert("도착지를 선택해 주세요.");
      return false;
    }

    if (ESTIMATE_TYPE === "운송") { //운송
      CAR_TYPE = event.target.CAR_TYPE.value;

      if (CAR_TYPE === "" || CAR_TYPE === null || CAR_TYPE === undefined || CAR_TYPE === '0') {
        alert("차량종류를 선택해 주세요.");
        return false;
      }
    }

    if (ESTIMATE_TYPE === "해상") { //해상
      CONTAINER_TYPE = event.target.CONTAINER_TYPE.value;

      if (CONTAINER_TYPE === "" || CONTAINER_TYPE === null || CONTAINER_TYPE === undefined || CONTAINER_TYPE === '0') {
        alert("컨테이너를 선택해 주세요.");
        return false;
      }
    }

    if (ESTIMATE_TYPE === "항공") { //항공
      CBM_TYPE = event.target.CBM_TYPE.value;
      WEIGTH = event.target.WEIGTH.value;

      if (CBM_TYPE === "" || CBM_TYPE === null || CBM_TYPE === undefined || CBM_TYPE === '0') {
        alert("CBM 종류를 선택해 주세요.");
        return false;
      }

      if (WEIGTH === "" || WEIGTH === null || WEIGTH === undefined) {
        alert("Weigth를 입력해 주세요.");
        return false;
      }
    }

    if (CARGO_TYPE === "" || CARGO_TYPE === null || CARGO_TYPE === undefined || CARGO_TYPE === '0') {
      alert("화물종류를 선택해 주세요.");
      return false;
    }

    if (NAME === "" || NAME === null || NAME === undefined) {
      alert("이름을 입력해 주세요.");
      return false;
    }

    if (CELL_PHONE === "" || CELL_PHONE === null || CELL_PHONE === undefined) {
      alert("휴대전화를 입력해 주세요.");
      return false;
    }

    if (EMAIL === "" || EMAIL === null || EMAIL === undefined) {
      alert("이메일을 입력해 주세요.");
      return false;
    }

    const AGREE_YN = $('input:checkbox[name="AGREE_YN"]').is(":checked");

    if (!AGREE_YN) {
      alert("개인정보수집 및 이용동의를 동의해 주세요.");
      return false;
    }

    //제목
    const NTT_SJ_MAKE = `[${ESTIMATE_TYPE}] ${ST_POINT} -> ${ED_POINT}`

    //내용
    let NTT_CN_MAKE = '';
    if (ESTIMATE_TYPE === "운송") {
      NTT_CN_MAKE =
        `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">출발지:${ST_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">도착지:${ED_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">차량종류:${CAR_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">화물종류:${CARGO_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이름:${NAME}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">휴대전화:${CELL_PHONE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이메일:${EMAIL}</p>`
    } else if (ESTIMATE_TYPE === "해상") {
      NTT_CN_MAKE =
        `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">출발지:${ST_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">도착지:${ED_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">컨테이너종류:${CONTAINER_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">화물종류:${CARGO_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이름:${NAME}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">휴대전화:${CELL_PHONE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이메일:${EMAIL}</p>`
    } else if (ESTIMATE_TYPE === "항공") {
      NTT_CN_MAKE =
        `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">출발지:${ST_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">도착지:${ED_POINT}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">CBM 종류:${CBM_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">Weigth:${WEIGTH} KG</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">화물종류:${CARGO_TYPE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이름:${NAME}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">휴대전화:${CELL_PHONE}</p>`
        + `<p style="font-family: 굴림; font-size: 12pt; line-height: 1.2; margin-top: 0px; margin-bottom: 0px;">이메일:${EMAIL}</p>`
    }

    const requestOption = {
      'BBS_ID': '20210721030707000078'
      , 'NTT_SJ': NTT_SJ_MAKE
      , 'NTT_CN': NTT_CN_MAKE
    };

    //견적문의 저장
    if (window.confirm("견적문의를 하시겠습니까?"))
      dispatch(brdbd003Actions.registerBrdbd003(requestOption));
    else return;

  }


  /* Tabs 추가를 위한 소스코드 시작 */
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
  }

  const InputProps = (label) => {
    if (localStorage.getItem(label)) {
      return (
        { 'value': localStorage.getItem(label), 'disabled': true }
      )
    }
  }

  /* Tabs 추가를 위한 소스코드 끝 */
  return (
    <>
      <div className="right_wrap">
        {location.pathname.split('/')[2] !== 'FUL' && <PortalSession />}
        <div className="join_Link_section">
          <button className="join_Link_btn01" onClick={() => {
            if (localStorage.getItem("USER_AUTH") !== 'M') {
              alert("기업 관리자만 가입요청이 가능합니다.");
              return false;
            } else {
              window.open('/app/POPUP/PLT000101_04', '', 'width=620px,height=670px,location=no,status=no,scrollbars=yes');
            }
          }} >얼라이언스 가입하기</button>
          <button className="join_Link_btn02" onClick={() => window.open('https://info.myslp.kr')}>스마트물류정보시스템 바로가기</button>
        </div>
        <div className="join_Link_section">
          <MuiAccordion className="accordionWrap">
            <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordiontt_display" >
              <Typography style={{ fontFamily: "Noto Sans KR", fontWeight: "bold", color: "#fff" }}>견적문의 해보세요</Typography>
            </MuiAccordionSummary>
            <Divider />
            <MuiAccordionDetails>
              <Typography component={'span'}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="scrollable"
                    // scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    {/* 운송,해상,항공 탭을 스크롤 없이 출력하기위해 minWidth 추가 */}
                    <Tab label="운송" {...a11yProps(0)} className="PriceQna_tab" style={{ 'minWidth': '80px' }} />
                    <Tab label="해상" {...a11yProps(1)} className="PriceQna_tab" style={{ 'minWidth': '80px' }} />
                    <Tab label="항공" {...a11yProps(2)} className="PriceQna_tab" style={{ 'minWidth': '80px' }} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} className="PriceQnaWrap">
                  <form onSubmit={onSubmitBrdbd003}>
                    <input type="hidden" name="ESTIMATE_TYPE" value="운송" />
                    <table className="srchTable">
                      <colgroup>
                        <col style={{ width: "40%" }} />
                        <col style={{ width: "60%" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>출발지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ST_POINT"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>도착지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ED_POINT"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>차량종류</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CAR_TYPE"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>화물종류</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CARGO_TYPE"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이름
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="NAME"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('USER_NAME_LOC')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            휴대전화
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CELL_PHONE"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('TEL_NO')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이메일
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="EMAIL"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('EMAIL')}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <label>
                      <input type="checkbox" name='AGREE_YN' value='Y' className="PriceQnaChkBox" />
                      개인정보수집 및 이용동의
                    </label>
                    <a onClick={() => window.open('/app/POPUP/PoliciesPopup', '', 'top=100, left=1000, width=650px,height=850px,location=no,status=no,scrollbars=yes')}
                      style={{ "cursor": "pointer" }}>(내용보기)</a>


                    <Button type="primary"
                      fullWidth
                      className="ButtonStyle02"
                      variant="contained">
                      견적신청
                    </Button>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={1} className="PriceQnaWrap">
                  <form onSubmit={onSubmitBrdbd003}>
                    <input type="hidden" name="ESTIMATE_TYPE" value="해상" />
                    <table className="srchTable">
                      <colgroup>
                        <col style={{ width: "45%" }} />
                        <col style={{ width: "55%" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>출발지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ST_POINT"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>도착지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ED_POINT"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>컨테이너종류</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CONTAINER_TYPE"
                              type="string"
                              required
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>화물종류</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CARGO_TYPE"
                              type="string"
                              required
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이름
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="NAME"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('USER_NAME_LOC')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            휴대전화
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CELL_PHONE"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('TEL_NO')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이메일
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="EMAIL"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('EMAIL')}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <label>
                      <input type="checkbox" name='AGREE_YN' value='Y' className="PriceQnaChkBox" />
                      개인정보수집 및 이용동의
                    </label>
                    <a onClick={() => window.open('/app/POPUP/PoliciesPopup', '', 'top=100, left=1000, width=650px,height=850px,location=no,status=no,scrollbars=yes')}
                      style={{ "cursor": "pointer" }}>(내용보기)</a>
                    <Button type="primary"
                      // color="primary"
                      fullWidth
                      className="ButtonStyle02"
                      variant="contained">
                      견적신청
                    </Button>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={2} className="PriceQnaWrap">
                  <form onSubmit={onSubmitBrdbd003}>
                    <input type="hidden" name="ESTIMATE_TYPE" value="항공" />
                    <table className="srchTable">
                      <colgroup>
                        <col style={{ width: "40%" }} />
                        <col style={{ width: "60%" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>출발지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ST_POINT"
                              type="string"
                              required
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>도착지</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="ED_POINT"
                              type="string"
                              required
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>CBM</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CBM_TYPE"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>Weight</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="WEIGTH"
                              required
                              type="number"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>화물종류</th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CARGO_TYPE"
                              required
                              type="string"
                              variant="outlined"
                            >
                            </TextField>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이름
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="NAME"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('USER_NAME_LOC')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            휴대전화
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="CELL_PHONE"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('TEL_NO')}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>
                            이메일
                          </th>
                          <td colSpan="2">
                            <TextField className="bbsInputSize"
                              fullWidth
                              name="EMAIL"
                              type="string"
                              variant="outlined"
                              inputProps={InputProps('EMAIL')}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <label>
                      <input type="checkbox" name='AGREE_YN' className="PriceQnaChkBox" />
                      개인정보수집 및 이용동의
                    </label>
                    <a onClick={() => window.open('/app/POPUP/PoliciesPopup', '', 'top=100, left=1000, width=650px,height=850px,location=no,status=no,scrollbars=yes')}
                      style={{ "cursor": "pointer" }}>(내용보기)</a>
                    <Button type="primary"
                      // color="primary"
                      fullWidth
                      className="ButtonStyle02"
                      variant="contained">
                      견적신청
                    </Button>
                  </form>
                </TabPanel>
              </Typography>
            </MuiAccordionDetails>
          </MuiAccordion>
        </div>
        <div className="mainright_banner" onClick={() => rightBannerOpen()}>
          <img src={require("../../../../images/joinCoffeeCouponBanner.png").default} className="coffeeBanner" style={{ 'height': '133px', 'cursor': 'pointer' }}
          />
        </div>
        <div className="mainright_banner">
          {/* <img src={require("../../../../images/camera.png").default} className="" 
           onClick={() => ReserveOpen()}
          /> */}
          <img src={require("../../../../images/reserveEvent.png").default} onClick={() => ReserveOpen()} style={{ 'width': '310px', 'height': '133px', 'cursor': 'pointer' }} />
          <Modal
            className={classes.modal}
            open={Reserve}
            // onClose={()=>AdressClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >

            <Fade in={Reserve}>
              {/* <NoteAdress/> */}
              <div className={classes.paper} style={{ textAlign: "center", width: "55%" }}>
                <ReserveEvent setReserve={setReserve} USER_ID={localStorage.getItem('USER_ID')} EVENT_NO='EVT2200001' />
              </div>
            </Fade>

          </Modal>
        </div>
        <div className="mainright_banner">
          <div className="slp_reference_tt">
            반월시화산업단지 스마트물류플랫폼<br />
            입주기업 구축 사례
          </div>
          <ul className="slp_reference_listWrap">
            <TextList bbsId='20210714090712000060' />
          </ul>
        </div>
        <div className="plane_schedule">
          <div className="plane_schedule_tt">
            수요실태조사통계
          </div>
          {/* <Sales /> */}

          <img src={require("../../../../images/statisticsright_banner01.png").default} className=""
            onClick={() => window.open('/app/POPUP/PLT000101_01', '', 'top=100, left=1000, width=1030px,height=630px,location=no,status=no,scrollbars=yes')}
            style={{ "cursor": "pointer" }} />

        </div>
        <div className="Link_box">
          <a href="https://www.kicox.or.kr/index.do" target="blank">
            <img src={require("../../../../images/link_logo01.png").default} className="" />
          </a>
        </div>
        <div className="Link_box">
          <a href="https://www.kicox.or.kr/kfactory/" target="blank">
            <img src={require("../../../../images/link_logo02.png").default} className="" />
          </a>
        </div>
      </div>
    </>
  );
}
export default PortalRight;