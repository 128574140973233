import React, { useCallback, useRef, useState, useEffect, forwardRef } from "react";
import {
  Card,
  TextField,
  Typography,
  Radio
} from '@material-ui/core';
import cookies from "react-cookies"
import { home_url } from "../../../../js/globalProperties";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
registerLocale("ko", ko);

const states = [
  // {
  //   value: 'C',
  //   label: '선택'
  // },
  {
    value: 'Y',
    label: '사용'
  },
  {
    value: 'N',
    label: '미사용'
  }
];

const bbs_type_cd = [
  {
    value: '1',
    label: '게시판형 1'
  },
  {
    value: '2',
    label: '게시판형 2'
  },
  {
    value: '3',
    label: '갤러리형 1'
  },
  {
    value: '4',
    label: '갤러리형 2'
  }
];

const tmplat_cd = [
  {
    value: '1',
    label: '블루'
  },
  {
    value: '2',
    label: '레드'
  },
  {
    value: '3',
    label: '그린'
  },
  {
    value: '4',
    label: '그레이'
  }
];

function RegisterOrEdit(props) {
  console.log("props : ", props);
  const inputEmptyArray = new Array(props.BBS.ATCH_POSBL_FILE_NUMBER);
  const inputEmptyMap = [];
  for (var i = 0; i < inputEmptyArray.length; i++) {
    inputEmptyMap.push({ "name": 'FILE' + (i + 1) });
  }

  const [CATEGORY, setCATEGORY] = useState([{ CAT_NAME: "" }]);

  const getCATS = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({ 'BBS_ID': props.BBS_ID, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD140010SVC/get', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.DATA) {
          data.DATA.push({ CAT_NAME: "" });
          // setCATEGORY((prevState) => ([...prevState, data.DATA]));
          setCATEGORY(data.DATA);
          console.log("cats : ", data.DATA);
        }
      })
  }

  var filesCont = 0;

  if (props.FILES != undefined) {
    filesCont = props.FILES.length;
  }

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const selectDate = (name, date) => {
    if (props.BBS.TMPLAT_ID !== "2") return false;
    else {
      return (
        <>
          <DatePicker
            locale="ko"
            selected={date}
            dateFormat={props.ALLDAY_YN === 'Y' ? "yyyy년 MM월 dd일" : "yyyy년 MM월 dd일 a hh시 mm분"}
            onChange={(dates) => {
              props.handleRegisterTimeChange(name, dates.toISOString());
            }}
            withPortal
            showTimeSelect={props.ALLDAY_YN === 'Y' ? false : true}
            style={{ "padding": "0px" }}
            customInput={<CustomDatePicker />}
          />
        </>
      )
    }
  }

  useEffect(() => {
    getCATS();
  }, []);

  return (
    <div>
      <form onSubmit={() => props.handleSubmit}
        encType="multipart/form-data"
        {...props}>
        <Typography className="listTable_tt">
          {props.BBS.BBS_NAME} - 작성하기
        </Typography>
        <Card style={{ width: "100%" }}>
          <table className="srchTable">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th>제목</th>
                <td colSpan="3">
                  <TextField className="bbsInputSize"
                    fullWidth
                    name="NTT_SJ"
                    onChange={(event) => props.handleRegisterChange(event)}
                    type="string"
                    required
                    value={props.NTT_SJ}
                    variant="outlined"
                  />
                </td>
              </tr>
              <tr style={{ display: props.BBS.TMPLAT_ID !== "2" ? 'none' : '' }}>
                <th>
                  시작
                </th>
                <td>
                  <div className="tradeDateInput tradeDatePicBg">{selectDate("START_TIME", Date.parse(props.START_TIME))}</div>
                </td>
                <th>
                  종료
                </th>
                <td>
                  <div className="tradeDateInput tradeDatePicBg">{selectDate("END_TIME", Date.parse(props.END_TIME))}</div>
                </td>
              </tr>
              <tr style={{ display: props.BBS.TMPLAT_ID !== "2" ? 'none' : '' }}>
                <th>
                  시간선택
                </th>
                <td colSpan="3">
                  하루종일
                  <Radio
                    checked={props.ALLDAY_YN === 'Y'}
                    onChange={(event) => props.handleRegisterChange(event)}
                    value="Y"
                    name="ALLDAY_YN"
                    inputProps={{ 'aria-label': 'A' }}
                  />
                  시간
                  <Radio
                    checked={props.ALLDAY_YN === 'N'}
                    onChange={(event) => props.handleRegisterChange(event)}
                    value="N"
                    name="ALLDAY_YN"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                </td>
              </tr>
              <tr style={{ display: CATEGORY.length === 1 ? 'none' : '' }}>
                <th>카테고리</th>
                <td colSpan="3">
                  <TextField className="bbsInputSize"
                    fullWidth
                    name="CAT_NAME"
                    onChange={(event) => props.handleRegisterChange(event)}
                    select
                    SelectProps={{ native: true }}
                    value={props.CAT_NAME}
                    variant="outlined"
                  >
                    {CATEGORY && CATEGORY.map((row) => (
                      <option
                        key={row.CAT_NAME}
                        value={row.CAT_NAME}
                      >
                        {row.CAT_NAME}
                      </option>
                    ))}
                  </TextField>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <props.DEXT5
                    onLoaded={() => props.NTT_CN && window.DEXT5 && window.DEXT5.SetBodyValue(props.NTT_CN)}
                  />
                </td>
              </tr>
              <tr style={{ display: props.BBS.TMPLAT_ID === "2" ? 'none' : '' }}>
                <th>
                  비밀번호
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    // helperText="비공개가 필요할 경우 비밀번호를 입력해주세요"
                    // label="비밀번호"
                    name="PASSWORD"
                    onChange={(event) => props.handleRegisterChange(event)}
                    type="string"
                    required
                    value={props.PASSWORD}
                    variant="outlined"
                  />
                </td>
                <th>
                  비밀글 여부
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    // label="비밀글 여부"
                    name="SECRET_AT"
                    onChange={(event) => props.handleRegisterChange(event)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={props.SECRET_AT}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </td>
              </tr>
              <tr style={{ display: props.REPLY || localStorage.getItem('USER_AUTH') !== 'A' || props.BBS.TMPLAT_ID === "2" ? 'none' : '' }}>
                <th>
                  공지사항 여부
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    // label="공지사항여부"
                    name="NOTICE_AT"
                    onChange={(event) => props.handleRegisterChange(event)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={props.NOTICE_AT}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </td>
                <th>
                  제목 진하게 표시 여부
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    // label="제목 진하게 표시 여부"
                    name="SJ_BOLD_AT"
                    onChange={(event) => props.handleRegisterChange(event)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={props.SJ_BOLD_AT}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </td>
              </tr>
              <tr style={{ display: props.REPLY || localStorage.getItem('USER_AUTH') !== 'A' || props.BBS.TMPLAT_ID === "2" ? 'none' : '' }}>
                <th>
                  답변허용
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    name="REPLAY_AT"
                    onChange={(event) => props.handleRegisterChange(event)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={props.REPLAY_AT}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </td>
                <th>
                  댓글허용
                </th>
                <td>
                  <TextField className="bbsInputSize"
                    fullWidth
                    name="COMMENT_AT"
                    onChange={(event) => props.handleRegisterChange(event)}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={props.COMMENT_AT}
                    variant="outlined"
                  >
                    {states.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </td>
              </tr>
              <tr style={{ display: props.BBS.ATCH_POSBL_FILE_NUMBER === 0 || props.BBS.TMPLAT_ID === "2" ? 'none' : '' }}>
                <th>
                  첨부파일
                </th>
                <td colSpan="3">
                  {
                    props.FILES != undefined && (
                      props.FILES.map((row, index) => {
                        const { FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN } = row;
                        return <Typography variant="subtitle2" noWrap>
                          <table border='0'>
                            <tr>
                              <td>
                                {ORIGNL_FILE_NM}
                              </td>
                              <td>
                                <div
                                  onClick={() => props.handleRemoveFile({ 'BBS_ID': props.BBS_ID, 'NTT_ID': props.NTT_ID, 'ATCH_FILE_ID': ATCH_FILE_ID, 'FILE_SN': FILE_SN })}
                                  style={{ color: 'white', lineHeight: '5px', backgroundColor: 'red', fontSize: '15px', padding: '5px', cursor: 'pointer' }}
                                >
                                  x
                                </div>
                              </td>
                            </tr>
                          </table>
                        </Typography>;
                      })
                    )
                  }
                  {
                    // props.FILES == undefined && (
                    inputEmptyMap.map((row, index) => {
                      const { name } = row;

                      if (filesCont <= index) {
                        return <input accept="*" name={name} type="file" onChange={(event) => props.handleRegisterChange(event)} />;
                      }

                    })
                    // )
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
        <TextField name="ADD_USER_ID"
          onChange={() => props.handleRegisterChange}
          type="string"
          value={localStorage.getItem('USER_ID')}
          style={{ display: 'none' }} />
        <TextField name="ADD_USER_NAME"
          onChange={() => props.handleRegisterChange}
          type="string"
          value={localStorage.getItem('user_name')}
          style={{ display: 'none' }} />

      </form>
    </div>
  );
};
export default RegisterOrEdit;
