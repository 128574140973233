import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          BP기업설명
          </Typography>
          <Divider/>
          수기관리되는 발주정보를 시스템을 통해관리함으로써 진행상태 및 다음 단계 업무의 연결성 확보를 통해 업무 진척율 및 발주량을 관리
          <video autoPlay muted loop src={require("../../../images/samplevideo.mp4").default} style={{"width":"1000px", "height":"520px"}}/>
        </Card>
      </Page>
    </>
  );
}

export default PLT000101_01;