import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { brdbd001Actions } from "../../../slice/brdbd001Slice";
import RegisterOrEdit from "./RegisterBoard/RegisterOrEdit";

import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import { home_url } from '../../../js/globalProperties';


function RegisterPage(props) {
  // console.log('새로 불러지나?');
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  // console.log('props : ', props.match.params.bbs_id);
  var items = new Array();
  const onGoTotalBoard = useCallback(() => history.push('/app/totalboard'), [history]);
  const goBack = () => { history.goBack(); };
  const goHome = () => { history.push('/'); };
  const [value, setValue] = useState([]);

  const callApi = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'BBS_ID': props.match.params.bbs_id, 'OPEN_API_YN': 'Y' })
    };

    // fetch('http://localhost:8081/api/BRD010010SVC/selectBbs', requestOptions)
    fetch(home_url + '/api/BRD010010SVC/selectBbs', requestOptions)

      .then(function (response) { return response.json(); })
      .then(function (data) {
        items = data['DATA'];
        // console.log('items.keys', items==undefined?"":items[0].keys());

        // 소스 자동화 필요함 
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_ID", "value": items[0] == undefined ? "" : items[0].BBS_ID }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_NAME", "value": items[0] == undefined ? "" : items[0].BBS_NAME }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_INTRCN", "value": items[0] == undefined ? "" : items[0].BBS_INTRCN }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_TY_CODE", "value": items[0] == undefined ? "" : items[0].BBS_TY_CODE }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "REPLY_POSBL_AT", "value": items[0] == undefined ? "" : items[0].REPLY_POSBL_AT }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "FILE_ATCH_POSBL_AT", "value": items[0] == undefined ? "" : items[0].FILE_ATCH_POSBL_AT }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "ATCH_POSBL_FILE_NUMBER", "value": items[0] == undefined ? "" : items[0].ATCH_POSBL_FILE_NUMBER }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "ATCH_POSBL_FILE_SIZE", "value": items[0] == undefined ? "" : items[0].ATCH_POSBL_FILE_SIZE }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "TMPLAT_ID", "value": items[0] == undefined ? "" : items[0].TMPLAT_ID }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "USE_AT", "value": items[0] == undefined ? "" : items[0].USE_AT }));
        dispatch(brdbd001Actions.changeRegisterInput({ "name": "WRITE_AUTH", "value": items[0] == undefined ? "" : items[0].WRITE_AUTH }));

        // console.log('데이터 조회결과 : ', items);
      });
  }

  useEffect(() => {
    //컴포넌트가 마운트 되었을 때, 실행되는 영역
    // dispatch(brdbd001Actions.getBrdbd001(props.match.params.bbs_id));
    // const unblock = history.block('목록 페이지로 이동하시겠습니까?');
    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock();
    };
  }, [history]);


  const { BBS_ID, BBS_NAME, BBS_INTRCN, BBS_TY_CODE, REPLY_POSBL_AT, FILE_ATCH_POSBL_AT, ATCH_POSBL_FILE_NUMBER, ATCH_POSBL_FILE_SIZE, TMPLAT_ID, USE_AT, WRITE_AUTH } = useSelector(
    (state) => ({
      BBS_ID: state.brdbd001Reducers.BBS_ID,
      BBS_NAME: state.brdbd001Reducers.BBS_NAME,
      BBS_INTRCN: state.brdbd001Reducers.BBS_INTRCN,
      BBS_TY_CODE: state.brdbd001Reducers.BBS_TY_CODE,
      REPLY_POSBL_AT: state.brdbd001Reducers.REPLY_POSBL_AT,
      FILE_ATCH_POSBL_AT: state.brdbd001Reducers.FILE_ATCH_POSBL_AT,
      ATCH_POSBL_FILE_NUMBER: state.brdbd001Reducers.ATCH_POSBL_FILE_NUMBER,
      ATCH_POSBL_FILE_SIZE: state.brdbd001Reducers.ATCH_POSBL_FILE_SIZE,
      TMPLAT_ID: state.brdbd001Reducers.TMPLAT_ID,
      USE_AT: state.brdbd001Reducers.USE_AT,
      WRITE_AUTH: state.brdbd001Reducers.WRITE_AUTH,
    }),
    shallowEqual
  );

  const [IsForUpdate, setIsForUpdate] = useState(false);

  useEffect(() => {
    callApi();
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get("isForEdit") === "true") {
      dispatch(brdbd001Actions.fetchBrdbd001(props.match.params.brdbd001Id));
      setIsForUpdate(true);
    }
  }, [BBS_ID]);

  const onSubmitBrdbd001 = (event) => {
    event.preventDefault();
    // console.log('BBS_NAME ', BBS_ID, BBS_NAME, BBS_INTRCN, BBS_TY_CODE, REPLY_POSBL_AT, FILE_ATCH_POSBL_AT, ATCH_POSBL_FILE_NUMBER, ATCH_POSBL_FILE_SIZE, TMPLAT_ID, USE_AT);
    if (BBS_NAME === "" || BBS_NAME === null || BBS_NAME === undefined) {
      alert("제목을 작성하십시오.");
      return false;
    }

    if (BBS_INTRCN === "" || BBS_INTRCN === null || BBS_INTRCN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    const brdbd001 = {
      BBS_ID: BBS_ID,
      BBS_NAME: BBS_NAME,
      BBS_INTRCN: BBS_INTRCN,
      BBS_TY_CODE: BBS_TY_CODE,
      REPLY_POSBL_AT: REPLY_POSBL_AT,
      FILE_ATCH_POSBL_AT: FILE_ATCH_POSBL_AT,
      ATCH_POSBL_FILE_NUMBER: ATCH_POSBL_FILE_NUMBER,
      ATCH_POSBL_FILE_SIZE: ATCH_POSBL_FILE_SIZE,
      TMPLAT_ID: TMPLAT_ID,
      USE_AT: USE_AT,
      WRITE_AUTH: WRITE_AUTH
    };
    console.log("brdbd001 : ", brdbd001);
    dispatch(brdbd001Actions.updateBrdbd001(brdbd001));
  };

  const onRegisterChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    // console.log('onRegisterChange ', name, value);
    dispatch(brdbd001Actions.changeRegisterInput({ name: name, value: value }));
  };
  return (
    <>
      <RegisterOrEdit
        BBS_ID={BBS_ID}
        BBS_NAME={BBS_NAME}
        BBS_INTRCN={BBS_INTRCN}
        BBS_TY_CODE={BBS_TY_CODE}
        REPLY_POSBL_AT={REPLY_POSBL_AT}
        FILE_ATCH_POSBL_AT={FILE_ATCH_POSBL_AT}
        ATCH_POSBL_FILE_NUMBER={ATCH_POSBL_FILE_NUMBER}
        ATCH_POSBL_FILE_SIZE={ATCH_POSBL_FILE_SIZE}
        TMPLAT_ID={TMPLAT_ID}
        USE_AT={USE_AT}
        WRITE_AUTH={WRITE_AUTH}
        handleRegisterChange={onRegisterChange}
        handleSubmit={onSubmitBrdbd001}
        handleTotalBoard={onGoTotalBoard}
        handleGoBack={goBack}

        updateRequest={IsForUpdate}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button onClick={goBack}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          이전페이지
        </Button>
        &nbsp;&nbsp;
        <Button onClick={onGoTotalBoard}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          목록으로
        </Button>
        &nbsp;&nbsp;
        <Button 
          onClick={() => history.push('/app/board/'+BBS_ID)}
          type="primary"
          className="ButtonStyle02"
          variant="contained">
          게시판으로 이동
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={onSubmitBrdbd001}
          // color="primary"
          className="ButtonStyle02"
          variant="contained">
          저장
        </Button>

      </Box>
    </>
  );
}

export default RegisterPage;