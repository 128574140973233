import { createSlice } from "@reduxjs/toolkit";

export const brdbd009Slice = createSlice({
  name: "brdbd009",
  initialState: {
    NTT_ID : '',
    BBS_ID : '',
    ANSWER_NO : '',
    WRTER_ID : '',
    WRTER_NM : '',
    ANSWER : '',
    USE_AT : '',
    PASSWORD : '',
    REMARKS : '',
    STATUS : '',
    TIME_ZONE : '',
    ADD_DATE : '',
    ADD_USER_ID : '',
    ADD_USER_NAME : '',
    CHANGE_DATE : '',
    CHANGE_USER_ID : '',
    CHANGE_USER_NAME : '',
    ANSWER_LC : '',
    PARNTSCTT_NO :'',
  },
  reducers: {
    registerBrdbd009: (state, { payload: id }) => {
    },
    getBrdbd009: (state, { payload: id }) => {
    },
    getBrdbd009Async: (state, { payload: brdbd009 }) => {
      return {
        ...state,
        brdbd009: brdbd009,
        isSuccess: true,
        isLoading: false,
      };
    },
    getBrdbd009FailedAsync: (state, { payload: error }) => {
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    },
    fetchBrdbd009: (state, { payload: brdbd009 }) => {
    },
    updateBrdbd009: (state, { payload: id }) => {
    },
    deleteBrdbd009: (state, { payload: id }) => {
    },
    changeRegisterInput: (state, { payload }) => {
      // console.log('payload.name : ' + payload.name);
        switch (payload.name) { 
        case "NTT_ID" : return { ...state, NTT_ID:payload.value,};
        case "BBS_ID" : return { ...state, BBS_ID:payload.value,};
        case "ANSWER_NO " : return { ...state, ANSWER_NO :payload.value,};
        case "WRTER_ID " : return { ...state, WRTER_ID :payload.value,};
        case "WRTER_NM " : return { ...state, WRTER_NM :payload.value,};
        case "ANSWER " : return { ...state, ANSWER :payload.value,};
        case "USE_AT " : return { ...state, USE_AT :payload.value,};
        case "PASSWORD " : return { ...state, PASSWORD :payload.value,};
        case "REMARKS " : return { ...state, REMARKS :payload.value,};
        case "STATUS " : return { ...state, STATUS :payload.value,};
        case "TIME_ZONE " : return { ...state, TIME_ZONE :payload.value,};
        case "ADD_DATE" : return { ...state, ADD_DATE:payload.value,};
        case "ADD_USER_ID" : return { ...state, ADD_USER_ID:payload.value,};
        case "ADD_USER_NAME" : return { ...state, ADD_USER_NAME:payload.value,};
        case "CHANGE_DATE" : return { ...state, CHANGE_DATE:payload.value,};
        case "CHANGE_USER_ID" : return { ...state, CHANGE_USER_ID:payload.value,};
        case "CHANGE_USER_NAME" : return { ...state, CHANGE_USER_NAME:payload.value,};
        default:
          break;
      }
    },
  },
});

export const brdbd009Reducers = brdbd009Slice.reducer;
export const brdbd009Actions  = brdbd009Slice.actions;
