import { delay,put } from "redux-saga/effects";
import Axios from "axios";
import { loginActions } from "../slice/loginSlice";
import { home_url } from "../js/globalProperties";
import cookies from "react-cookies"

export function* getUserInfoAsync(action) {
	console.log("X-CSRF-TOKEN ( Init_TOKEN ): ",localStorage.getItem("X-CSRF-TOKEN"));
	cookies.remove('X-CSRF-TOKEN');
	cookies.remove('JSESSIONID');
	cookies.remove('remember-me');
	console.log("X-CSRF-TOKEN ( REMOVE_TOKEN ): ",localStorage.getItem("X-CSRF-TOKEN"));

	try{
		//데이터확인(ID,PW,OPEN_API_YN)
		const data = action.payload;

		//USER검색(ID,PW,OPEN_API_YN)
		const responseLogin = yield Axios.post(home_url+"/api/slpportal/login", data);
		const users = responseLogin.data;

		//USER검색 결과가 1이면 검색 성공
		if(users.TYPE === 0) {
			alert(users.MSG);
		} else if (users.TYPE === -1) {
			alert(users.MSG);
		} else if (users.TYPE === 1){
			const loginJspOption = {
				method: "GET",
				headers: { "Content-Type": "application/json"}
			}
			fetch(home_url+"/login.jsp",loginJspOption)
				// .then(function(response){return response.json();})
				.then(function(){
					const csrfToken = localStorage.getItem("X-CSRF-TOKEN"); 
					console.log("X-CSRF-TOKEN ( LOGIN.JSP_TOKEN ): ",csrfToken);
					let details = {
						"_spring_security_remember_me":true,
						"_csrf": csrfToken,
						"USER_ID": data.USER_ID,
						"PW": data.PW
					};
					let formBody = [];
					for (let property in details) {
						let encodedKey = encodeURIComponent(property);
						let encodedValue = encodeURIComponent(details[property]);
						formBody.push(encodedKey + "=" + encodedValue);
					}
					formBody = formBody.join("&");
					const requestOptions = { 
						method: "POST",
						headers: { "Content-Type": "application/x-www-form-urlencoded","Accept":"*/*"},
						body: formBody
					  };
					  fetch(home_url+"/slp_sec", requestOptions)
						.then(function (response) {return response.json();})
				})
			//------------------------------------------------------------------------------------------------
			yield delay(2000);
			yield put(loginActions.getUserInfoAsync(users.DATA[0]));
		} else {
			alert("관리자에게 문의하세요.");
		}
 
	}catch (e) {
		yield put(loginActions.getLoginFail(e.message));
	}
}