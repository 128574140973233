import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Label from '../Label';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale } = product;

  return (
    <Card>
      {/* 사진표시영 */}
      <Box sx={{ pt: '100%', position: 'relative' }} className="glyThumnail">
        <ProductImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 0 }} className="thumbnail_ttWrap">
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          {status && (
          <Label className="thumbnail_newIcon"
            // variant="filled"
            // color={(status === 'sale' && 'error') || 'info'}
            // sx={{
            //   zIndex: 9,
            //   top: 16,
            //   right: 16,
            //   position: 'absolute',
            //   textTransform: 'uppercase'
            // }}
          >
            N
          </Label>
        )}
          <Typography variant="subtitle2" noWrap className="thumbnail_name">
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1" className="thumbnail_date">
            <Typography
              component="span"
              variant="body1"
              // sx={{
              //   color: 'text.disabled',
              //   textDecoration: 'line-through'
              // }}
            >
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
            2021-07-14 15:29:35
            {/* {fCurrency(price)} */}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
