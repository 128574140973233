import { useState, useEffect, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"
// material
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Divider,
  Pagination,
  TextField,
  TablePagination,
} from '@material-ui/core';

// components
import Page from '../CommonBoard/Page';
import TradeList from './TradeList';
import TradeToolbar from './TradeToolbar'
import { brdbd003Actions } from "../../../slice/brdbd003Slice";



//
// import PRODUCTS from '../data/products';

// ----------------------------------------------------------------------
function TradeMain(props) {
  const history = useHistory();

  const dispatch = useDispatch();


  const [SEARCHMODE, setSEARCHMODE] = useState('all');
  const [KEYWORD_SEARCH, setKEYWORD_SEARCH] = useState();
  const [CATEGORY, setCATEGORY] = useState();
  const [FROMDATE, setFROMDATE] = useState(new Date('2021-01-01')); // 최초날짜
  const [ENDDATE, setENDDATE] = useState(new Date()); // 최종날짜

  const [CAT_NAME, setCAT_NAME] = useState('');

  const [ITEMS, setITEMS] = useState([]);



  function getFormatDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth());
    month = month >= 10 ? month : '0' + month;
    var day = date.getDate();
    day = day >= 10 ? day : '0' + day;
    return year + '' + month + '' + day;
  }

  const APIS = {
    all: '/api/MBP080010SVC/getList',
    my: '/api/MBP060010SVC/getList',
    wish: '/api/MBP100010SVC/getList'
  }

  const PARAM = {
    all: {
      "DEAL_TYPE": "SEL",
      "CATEGORY": CAT_NAME,
      "FROM_DATE": getFormatDate(FROMDATE),
      "TO_DATE": getFormatDate(ENDDATE),
      "KEYWORD_SEARCH": KEYWORD_SEARCH,
      "S_FUNC_CODE": "DL",
      "OPEN_API_YN": "Y"
    },
    my: {
      "MBP060010F1": {
        "ADD_USER_ID": localStorage.getItem("USER_ID"),
        "CATEGORY": CAT_NAME,
        "FROM_DATE": getFormatDate(FROMDATE),
        "TO_DATE": getFormatDate(ENDDATE),
        "KEYWORD_SEARCH": KEYWORD_SEARCH,
        "S_FUNC_CODE": "DL"
      }
    },
    wish: {
      "MBP100010F1": {
        "USER_ID": localStorage.getItem("USER_ID"),
        "S_FUNC_CODE": "DL"
      }
    }
  }
  const getList = () => {
    if (KEYWORD_SEARCH === '') setKEYWORD_SEARCH(null);
    try {
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify(PARAM[SEARCHMODE]),
      };
      fetch(home_url + APIS[SEARCHMODE], requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.MBP080010G1) setITEMS(data.MBP080010G1);
          if (data.MBP060010G1) setITEMS(data.MBP060010G1);
          if (data.MBP100010G1) setITEMS(data.MBP100010G1);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const [WISHLIST, setWISHLIST] = useState([]);

  const getWishList = () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify(PARAM.wish),
      };
      fetch(home_url + '/api/MBP100010SVC/getList', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.MBP100010G1) {
            let wishList = [];
            for (let i in data.MBP100010G1) {
              wishList.push(data.MBP100010G1[i].DEAL_NO);
            }
            setWISHLIST(wishList);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getList();
    getWishList();
  }, [SEARCHMODE]);


  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Typography className="listTable_tt">
        중고거래
      </Typography>
      <Divider />
      <TradeToolbar
        className=""
        FROMDATE={FROMDATE}
        setFROMDATE={setFROMDATE}
        ENDDATE={ENDDATE}
        setENDDATE={setENDDATE}
        CAT_NAME={CAT_NAME}
        setCAT_NAME={setCAT_NAME}
        getList={getList}
        setCATEGORY={setCATEGORY}
        KEYWORD_SEARCH={KEYWORD_SEARCH}
        setKEYWORD_SEARCH={setKEYWORD_SEARCH}
      />
      <div className=" position_rel">
        <div className="quickbbs_tab_wrap tradeBottomMargin">
          <span onClick={() => setSEARCHMODE('all')} className={SEARCHMODE && SEARCHMODE === 'all' ? "quickbbs_tab_select Tradetab01" : "Tradetab02"}>
            전체 상품
          </span>
          <span onClick={() => setSEARCHMODE('my')} className={SEARCHMODE && SEARCHMODE === 'my' ? "quickbbs_tab_select Tradetab01" : "Tradetab02"}>
            내가 올린 상품
          </span>
          <span onClick={() => setSEARCHMODE('wish')} className={SEARCHMODE && SEARCHMODE === 'wish' ? "quickbbs_tab_select Tradetab01" : "Tradetab02"}>
            찜한 상품
          </span>
          {/* <span className="tradeDoneChkbox"><input type="checkbox" name="" id="labelfor" value="" className="login_etc_autologinInput" /><label htmlFor="labelfor">거래 완료 안보기</label></span> */}
        </div>
        <div className="quickbbs_thumbnail_wrap TradetabContents01">
          <TradeList SEARCHMODE={SEARCHMODE} FROMDATE={FROMDATE} ENDDATE={ENDDATE} ITEMS={ITEMS} WISHLIST={WISHLIST} />
        </div>
      </div>
      {/* </Container> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          type="primary"
          className="ButtonStyle02"
          color="primary"
          variant="contained"
          onClick={() => { history.push('/app/trade/register') }}
        >
          판매
        </Button>
      </Box>
    </Page>
  );
}


export default TradeMain;