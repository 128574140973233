import React, { useEffect } from "react";
import { home_url } from '../../../js/globalProperties';
function PLT000101_05(props) {

  let sessionData;

  const getSessionData = () => {
    return new Promise(function(resolve, reject){
      let httpRequest = new XMLHttpRequest();
      httpRequest.withCredentials = true;
      httpRequest.open("POST", home_url + "/api/SLP000000SVC/getSessionData", true);
      httpRequest.setRequestHeader("AJAX", true);
      httpRequest.setRequestHeader("X-CSRF-TOKEN",
        (() => {
          if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf('X-CSRF-TOKEN=');
            if (c_start != -1) {
              c_start = c_start + 13;
              let c_end = document.cookie.indexOf(";", c_start);
              if (c_end == -1) {
                c_end = document.cookie.length;
              }
              return unescape(document.cookie.substring(c_start, c_end));
            }
          }
          return "";
        })());
      httpRequest.send();
      httpRequest.onload = () => {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
          resolve(JSON.parse(httpRequest.response));
        } else {
          reject({
            status: this.status,
            statusText: httpRequest.statusText
          });
        }
      };
    })
  }

  const setPostMessageDigitaltwin = () => {
    document.getElementById('digitalTwin').contentWindow.postMessage(sessionData, 'https://dtwin.myslp.kr');
  }

  useEffect(() => {
    (async() => {
      sessionData = await getSessionData();
      window.addEventListener('message', setPostMessageDigitaltwin);
    })()

    return () => {
      window.removeEventListener('message', setPostMessageDigitaltwin);
    }

  }, []);

  return (
    <>
      <iframe frameBorder="0" src="https://dtwin.myslp.kr" style={{ 'width': '100%', 'height': window.innerHeight }} id="digitalTwin" />
    </>
  );
}
export default PLT000101_05;