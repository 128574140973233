import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';
import SalesTotalPrice from './PLT020501/SalesTotalPrice';
import PurchaseTotalPrice from './PLT020501/PurchaseTotalPrice';
import LatestOrders from './PLT020501/LatestOrders';
import WeekSales from './PLT020501/WeekSales';
import TrafficByDevice from './PLT020501/TrafficByDevice';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"

function PLT020501(props) {
  console.log(props.COMPANY_CODE);
  console.log("props : ", props)
  const history = useHistory();
  var items = new Array();
  // var   sales = {};
  // var   purchase = {};
  const [BBS, setBBS] = useState([]);
  const [SALES, setSALES] = useState([]); // 매입총계
  const [PURCHASE, setPURCHASE] = useState([]); // 매입총계
  const [MAIN, setMAIN] = useState([]);
  const [MODULE, setMODULE] = useState([]);
  const reducer = (accumulator, curr) => accumulator + curr;

  const callApi = () => {
    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({ "COMPANY_CODE": props.COMPANY_CODE, "DATE": props.DATE, "DATE_TYPE": props.DATE_TYPE, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BMS010010SVC/getBillingInfo', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        items = data['DATA'];

        setBBS(items.CUSTOMER);
        setMAIN(items.MAIN);
        setMODULE(items.MODULE);

        let salesArr = [];
        let purchaseArr =[];
        for (let i in items.MODULE) {
          let sales = new Object();
          let purchase = new Object();

          sales.S_FUNC_NAME = items.MODULE[i].S_FUNC_NAME;
          sales.RATIO = items.MODULE[i].AR_RATIO;
          sales.COUNT = items.MODULE[i].AR_COUNT;
          sales.AMT = items.MODULE[i].AR_AMT;
          salesArr.push(sales);

          purchase.S_FUNC_NAME = items.MODULE[i].S_FUNC_NAME;
          purchase.RATIO = items.MODULE[i].AP_RATIO;
          purchase.COUNT = items.MODULE[i].AP_COUNT;
          purchase.AMT = items.MODULE[i].AP_AMT;
          purchaseArr.push(purchase);
        }
        setSALES(salesArr);
        setPURCHASE(purchaseArr);
      })
  }
  useEffect(() => {
    callApi();
  }, [history]);

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card className="overflow_control">
          <Typography className="listTable_tt">
            정산현황 대시보드
          </Typography>
          <Divider />
          <Box
            sx={{
              backgroundColor: '#eeeeee',
              minHeight: '100%',
              py: 3
            }}
          >
            <Container maxWidth={false}>
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <SalesTotalPrice
                    MAIN={MAIN} />
                </Grid>
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={12}
                >
                  <PurchaseTotalPrice
                    MAIN={MAIN} />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <WeekSales
                    DATA={SALES}
                    TITLE='모듈별 매출 현황'
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <WeekSales
                    DATA={PURCHASE}
                    TITLE='모듈별 매입 현황'
                  />
                  {/* <TrafficByDevice 
                    MODULE={MODULE}
                    sx={{ height: '100%' }} /> */}
                </Grid>
                <Grid
                  item
                  lg={8}
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LatestOrders
                    BBS={BBS} />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Card>
      </Page>
    </>
  );
}

export default PLT020501;