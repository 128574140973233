import React from "react";
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Router } from "react-router";
import { BrowserRouter } from 'react-router-dom';
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "./css/common.css"
import "./js/common.js"
import store from "./store";
import history from "./utils/history";

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();