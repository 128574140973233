import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script';

export class DEXT5Editor extends Component {    
    constructor (props) {
        super(props);

        this._editor = null;
        this._destroyed = false;
    }

    componentDidMount() {        
        this._initEditor();
    }

    componentDidUpdate(prevProps) {
        const { props, _editor } = this;

        if (!_editor) {
            return;
        }

        if (prevProps.data !== props.data) {
            DEXT5Editor.DEXT5.SetBodyValue(props.data);    
        }
    }

    componentWillUnmount() {
        this._destroyEditor();
    }

    render() {
        return <div id="editorHolder"></div>;
    }

    // DEXT5 Event Re-defined
    onEditorLoadedEvent() {
        console.log("DEXT5 Editor Loaded Completed !!");
    }

    _initEditor() {        
        const { config } = this.props;

        this._getDEXT5EditorNamespace().then(DEXT5 => {            
            if (this._destroyed) {
                return;
            }

            // �ʿ信 ���� �����߰�            
            window.DEXT5.config.EditorHolder = "editorHolder";
            if (typeof (config.Width) !== "undefined") { window.DEXT5.config.Width = config.Width }
            if (typeof (config.Height) !== "undefined") { window.DEXT5.config.Height = config.Height }
            if (typeof (config.Lang) !== "undefined") { window.DEXT5.config.Lang = config.Lang }

            // Event Define
            window.DEXT5.config.Event = {
                LoadedEvent: this.onEditorLoadedEvent
            };

            this._editor = new window.Dext5editor( config.Id );
        }).catch(console.error);
    }

    _destroyEditor() {        
        if (this._editor) {
            //window.DEXT5.destroy();
        }

        this._editor = null;
        this._destroyed = true;
    }

    _getDEXT5EditorNamespace() {        
        let promise;

        if ('DEXT5' in window) {
            return Promise.resolve(window.DEXT5);
        } else if (!promise) {
            promise = new Promise((scriptResolve, scriptReject) => {
                loadScript(DEXT5Editor.editorUrl, err => {
                    if (err) {
                        scriptReject(err);
                    } else {
                        scriptResolve(window.DEXT5);
                        promise = undefined;
                    }
                });
            });
        }

        return promise;
    }
}

DEXT5Editor.propTypes = {
    data: PropTypes.string,
    config: PropTypes.object
};

DEXT5Editor.defaultProps = {
    data: '',
    config: {
        Id: "editor1",
        Width: "100%",
        Height: "500px",
        Lang: "ko-kr"
    }
};

DEXT5Editor.editorUrl = "/dext5editor/js/dext5editor.js";
