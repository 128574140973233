import React, { useEffect, useState, useCallback, forwardRef, useRef } from "react";
import moment from "moment";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";
import { home_url } from "../../../js/globalProperties";
import PLT020501 from "../CommonDashBoard/PLT020501.js"
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";

import { makeStyles } from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import cookies from "react-cookies"
registerLocale("ko", ko);


function PortalMainDashOnSession() {
  var items = new Array();
  const [DATA, setDATA] = useState([]);
  const [POPUPDATA, setPOPUPDATA] = useState([]);
  const [pickDate, setPickDate] = useState(new Date());
  const [LIST, setLIST] = useState([]);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      padding: "32px 32px 16px",
      zIndex: "3000"
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  // const [startDate, setStartDate] = useState(new Date()); //기간선택 시작날짜
  // const [endDate, setEndDate] = useState(new Date()); //기간선택 마지막날짜

  const [datePickerDate, setDatePickerDate] = useState(new Date()); // 날짜선택

  const transDateFormat = (date) => {
    let year = date.getFullYear();
    let month = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    let day = date.getDate();
    day = day >= 10 ? day : "0" + day;
    setPickDate(String(year) + String(month) + String(day));
  }

  let APIS = {
    "orderInfo": "/api/OMS020010SVC/getOrderInfo",
    "transInfo": "/api/TRS010040SVC/getTransInfo",
    "forwardingInfo": "/api/FRS010010SVC/getForwardingInfo",
    "warehouseInfo": "/api/WHS020010SVC/getWarehouseInfo",
    "billingInfo": "/api/BMS010010SVC/getBillingInfo"
  };

  let DATE_TYPE = {
    "today": "D",
    "thisWeek": "W",
    "thisMonth": "M",
  }

  let popupAPIS = {
    "orderInfo": "/api/OMS020010SVC/getOrderDetailPopup",
    "transInfo": "/api/TRS010040SVC/getTransDetailPopup",
    "forwardingInfo": "/api/FRS010010SVC/getForwardingDetailPopup",
  }

  //POPUP---------------------------------------------------------------------------------------
  const ROWPOPUP = async (key) => {
    let result = null;
    let apiSubject = subject;
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({ "KEY": key, "COMPANY_CODE": localStorage.getItem("COMPANY_CODE") })
      };
      await fetch(home_url + popupAPIS[apiSubject], requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          result = data.DATA;
          setPOPUPDATA(result);
        });
    } catch (error) {
      console.log(error);
    }

    handleOpen();
  }
  //----------------------------------------------------------------------------------------------

  const getList = (subjectIdx, termIdx, pickedDate) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({ "DATE": pickedDate, "COMPANY_CODE": localStorage.getItem("COMPANY_CODE"), "DATE_TYPE": DATE_TYPE[termIdx] })
      };
      fetch(home_url + APIS[subjectIdx], requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          items = data["DATA"];
          if (items) {
            setDATA(items);
            if (subjectIdx !== 4)
              setLIST(items.LIST);
          }
        });
    } catch (error) {
      console.log("error : ", error);
    }
  }

  useEffect(() => {
    let subjectIdx = "orderInfo";
    let termIdx = "today";
    let date = new Date();
    transDateFormat(date);

    let year = date.getFullYear();
    let month = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    let day = date.getDate();
    day = day >= 10 ? day : "0" + day;

    let pickedDate = String(year) + String(month) + String(day);

    getList(subjectIdx, termIdx, pickedDate);
  }, []);

  const filter = (status) => {
    if (status === '전체') {
      setLIST(DATA.LIST);
    }

    if (status === '완료') {
      console.log(DATA.LIST);
      let list = [];
      for (let i in DATA.LIST) {
        if (DATA.LIST[i].STATUS === '완료' || DATA.LIST[i].ORDER_STATUS === '완료') {
          list.push(DATA.LIST[i]);
        }
      }
      setLIST(list);
    }

    if (status === '미완료') {
      let list = [];
      for (let i in DATA.LIST) {
        if (DATA.LIST[i].STATUS !== '완료' && DATA.LIST[i].ORDER_STATUS !== '완료') {
          list.push(DATA.LIST[i]);
        }
      }
      setLIST(list);
    }
  }

  const MainDash = () => {
    //발주현황
    if (subject === "orderInfo") {
      return (
        <>
          <div className="logistics_dash_rightWrap" style={{ width: "700px" }}>
            <table
              className="logistics_dash_loginTable02">
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    상태
                  </th>
                  <th>
                    발주처
                  </th>
                  <th>
                    출발지
                  </th>
                  <th>

                  </th>
                  <th>
                    도착지
                  </th>
                  <th>
                    완료일자
                  </th>
                </tr>
                {LIST && LIST.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => ROWPOPUP(row.KEY)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <button className="logistics_Link_btn01" style={{ display: row.ORDER_STATUS === '완료' ? '' : 'none' }}>
                          {row.ORDER_STATUS}
                        </button>
                        <button className="logistics_Link_btn02" style={{ display: row.ORDER_STATUS === '진행 중' ? '' : 'none' }}>
                          {row.ORDER_STATUS}
                        </button>
                        <button className="logistics_Link_btn03" style={{ display: row.ORDER_STATUS === '등록' ? '' : 'none' }}>
                          {row.ORDER_STATUS}
                        </button>
                      </td>
                      <td>
                        {row.CUSTOMER_NAME}
                      </td>
                      <td>
                        {row.DEPART_NAME === '' ? "-" : row.DEPART_NAME}
                      </td>
                      <td>
                        ▶
                      </td>
                      <td>
                        {row.ARRIVAL_NAME === '' ? "-" : row.ARRIVAL_NAME}
                      </td>
                      <td>
                        {row.ORDER_CONFIRM_DATE === '' ? "-" : row.ORDER_CONFIRM_DATE}
                      </td>
                    </tr>
                  )
                })}
                {LIST == 0 && (
                  <tr>
                    <td colSpan="6">조회된 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper} style={{ textAlign: "center" }}>
                <div style={{ fontSize: "3em", textAlign: "center" }}>발주정보</div><br />
                <table className="srchTable" style={{ minWidth: "500px", textAlign: "center" }}>
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>발주번호</th>
                      <td>{POPUPDATA ? POPUPDATA.ORDER_NO : "-"}</td>
                    </tr>
                    <tr>
                      <th>진행정보</th>
                      <td>{POPUPDATA ? POPUPDATA.ORDER_STATUS : "-"}</td>
                    </tr>
                    <tr>
                      <th>거래처명</th>
                      <td>{POPUPDATA ? POPUPDATA.CUSTOMER_NAME : "-"}</td>
                    </tr>
                    <tr>
                      <th>발주일시</th>
                      <td>{POPUPDATA ? POPUPDATA.ORDER_DATE : "-"}</td>
                    </tr>
                    <tr>
                      <th>납품요청일시</th>
                      <td>{POPUPDATA ? POPUPDATA.DELIVERY_TIME : "-"}</td>
                    </tr>
                    <tr>
                      <th>배송처주소</th>
                      <td>{POPUPDATA ? POPUPDATA.DELIVERY_ADDR : "-"}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <Button onClick={handleClose}>닫기</Button>
              </div>
            </Fade>
          </Modal>
        </>
      )
    }

    //운송현황
    if (subject === "transInfo") {
      return (
        <>
          <div className="logistics_dash_rightWrap" style={{ width: "450px" }}>
            <table
              className="logistics_dash_loginTable">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "27.5%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "27.5%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    상태
                  </th>
                  <th>
                    출발지
                  </th>
                  <th>

                  </th>
                  <th>
                    도착지
                  </th>
                  <th>
                    차량톤수
                  </th>
                </tr>
                {LIST && LIST.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => ROWPOPUP(row.KEY)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <button className="logistics_Link_btn01" style={{ display: row.STATUS === '완료' ? '' : 'none' }}>
                          {row.STATUS}
                        </button>
                        <button className="logistics_Link_btn02" style={{ display: row.STATUS === '진행 중' ? '' : 'none' }}>
                          {row.STATUS}
                        </button>
                        <button className="logistics_Link_btn03" style={{ display: row.STATUS === '등록' || row.STATUS === '요청' ? '' : 'none' }}>
                          {row.STATUS}
                        </button>
                      </td>
                      <td>
                        {row.DEPART_TIME}<br />{row.DEPART_NAME}
                      </td>
                      <td>
                        ▶
                      </td>
                      <td>
                        {row.ARRIVAL_TIME}<br />{row.ARRIVAL_NAME}
                      </td>
                      <td>
                        {row.CAR_TON}
                      </td>
                    </tr>
                  )
                })}
                {LIST == 0 && (
                  <tr>
                    <td colSpan="5">조회된 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper} style={{ textAlign: "center" }}>
                <div style={{ fontSize: "3em", textAlign: "center" }}>운송정보</div><br />
                <table className="srchTable" style={{ minWidth: "500px" }}>
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>운송요청번호</th>
                      <td>{POPUPDATA ? POPUPDATA.TRANS_REQ_NO : "-"}</td>
                    </tr>
                    <tr>
                      <th>진행정보</th>
                      <td>{POPUPDATA ? POPUPDATA.STATUS : "-"}</td>
                    </tr>
                    <tr>
                      <th>거래처명</th>
                      <td>{POPUPDATA ? POPUPDATA.CUSTOMER_NAME : "-"}</td>
                    </tr>
                    <tr>
                      <th>출발지상차요청일시</th>
                      <td>{POPUPDATA ? POPUPDATA.DEPART_REQ_TIME : "-"}</td>
                    </tr>
                    <tr>
                      <th>출발지주소</th>
                      <td>{POPUPDATA ? POPUPDATA.DEPART_ADDR : "-"}</td>
                    </tr>
                    <tr>
                      <th>도착지하차요청일시</th>
                      <td>{POPUPDATA ? POPUPDATA.ARRIVAL_UNLOAD_REQ_TIME : "-"}</td>
                    </tr>
                    <tr>
                      <th>도착지주소</th>
                      <td>{POPUPDATA ? POPUPDATA.ARRIVAL_ADDR : "-"}</td>
                    </tr>
                    <tr>
                      <th>차량종류</th>
                      <td>{POPUPDATA ? POPUPDATA.CAR_TYPE : "-"}</td>
                    </tr>
                    <tr>
                      <th>차량톤수</th>
                      <td>{POPUPDATA ? POPUPDATA.CAR_TON : "-"}</td>
                    </tr>
                    <tr>
                      <th>중량</th>
                      <td>{POPUPDATA ? POPUPDATA.WEIGHT : "-"}</td>
                    </tr>
                    <tr>
                      <th>차량번호</th>
                      <td>{POPUPDATA ? POPUPDATA.CAR_NO : "-"}</td>
                    </tr>
                    <tr>
                      <th>운전기사정보</th>
                      <td>{POPUPDATA ? POPUPDATA.DRIVER_INFO : "-"}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <Button onClick={handleClose}>닫기</Button>
              </div>
            </Fade>
          </Modal>
        </>
      )
    }
    //포워딩현황
    if (subject === "forwardingInfo") {
      return (
        <>
          <div className="logistics_dash_rightWrap" style={{ width: "700px" }}>
            <table
              className="logistics_dash_loginTable03">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    출발지
                  </th>
                  <th>

                  </th>
                  <th>
                    도착지
                  </th>
                  <th>
                    포장수량
                  </th>
                  <th>
                    HBL No
                  </th>
                </tr>
                {LIST && LIST.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => ROWPOPUP(row.KEY)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        {row.DEPART_NAME}
                      </td>
                      <td>
                        ▶
                      </td>
                      <td>
                        {row.ARRIVAL_NAME}
                      </td>
                      <td>
                        {row.PKG_AMT}
                      </td>
                      <td>
                        {row.HBL_NO}
                      </td>
                    </tr>
                  )
                })}
                {LIST == 0 && (
                  <tr>
                    <td colSpan="5">조회된 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper} style={{ textAlign: "center" }}>
                <div style={{ fontSize: "3em", textAlign: "center" }}>포워딩정보</div><br />
                <table className="srchTable" style={{ minWidth: "500px" }}>
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "65%" }} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>요청번호</th>
                      <td>{POPUPDATA ? POPUPDATA.IF_NO : "-"}</td>
                    </tr>
                    <tr>
                      <th>진행정보</th>
                      <td>{POPUPDATA ? POPUPDATA.STATUS : "-"}</td>
                    </tr>
                    <tr>
                      <th>수취인</th>
                      <td>{POPUPDATA ? POPUPDATA.CONSIGNEE_NAME : "-"}</td>
                    </tr>
                    <tr>
                      <th>항공/해상</th>
                      <td>{POPUPDATA ? POPUPDATA.TYPE : "-"}</td>
                    </tr>
                    <tr>
                      <th>출발지</th>
                      <td>{POPUPDATA ? POPUPDATA.ARRIVAL : "-"}</td>
                    </tr>
                    <tr>
                      <th>도착지</th>
                      <td>{POPUPDATA ? POPUPDATA.DEPART : "-"}</td>
                    </tr>
                    <tr>
                      <th>포장수량</th>
                      <td>{POPUPDATA ? POPUPDATA.PKG_AMT : "-"}</td>
                    </tr>
                    <tr>
                      <th>무게중량</th>
                      <td>{POPUPDATA ? POPUPDATA.WEIGHT : "-"}</td>
                    </tr>
                    <tr>
                      <th>HBL_NO</th>
                      <td>{POPUPDATA ? POPUPDATA.HBL_NO : "-"}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <Button onClick={handleClose}>닫기</Button>
              </div>
            </Fade>
          </Modal>
        </>
      )
    }
    //창고현황
    if (subject === "warehouseInfo") {
      return (
        <>
          <div className="logistics_dash_rightWrap" style={{ width: "450px" }}>
            <table
              className="logistics_dash_loginTable04">
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    창고명
                  </th>
                  <th>
                    입고요청
                  </th>
                  <th>
                    입고완료
                  </th>
                  <th>
                    출고요청
                  </th>
                  <th>
                    출고완료
                  </th>
                </tr>
                {DATA.LIST && DATA.LIST.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <button className="logistics_Link_btn01">
                          {row.WAREHOUSE_NAME}
                        </button>
                      </td>
                      <td>
                        {row.GR_REQ_COUNT}
                      </td>
                      <td>
                        {row.GR_COMPLETECOUNT}
                      </td>
                      <td>
                        {row.GI_REQ_COUNT}
                      </td>
                      <td>
                        {row.GI_COMPLETECOUNT}
                      </td>
                    </tr>
                  )
                })}
                {DATA.LIST == 0 && (
                  <tr>
                    <td colSpan="5">조회된 내역이 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )
    }

  }

  const TabDetail = () => {
    return (
      <>
        {/* 상단 대시보드 */}
        <div className="">
          <div className="logistics_dash_div" style={{ cursor: 'pointer' }} onClick={() => filter('전체')}>
            <div className="logistics_dash_tt">전체</div>
            <div className="logistics_dash_data">{DATA.MAIN && DATA.MAIN.ALL_COUNT}<span> 건</span></div>
          </div>
          <div className="logistics_dash_div lgsDashDiv_margin" style={{ cursor: 'pointer' }} onClick={() => filter('완료')}>
            <div className="logistics_dash_tt">완료</div>
            <div className="logistics_dash_data">{DATA.MAIN && DATA.MAIN.COMPLETE_COUNT}<span> 건</span></div>
          </div>
          <div className="logistics_dash_div lgsDashDiv_margin" style={{ cursor: 'pointer' }} onClick={() => filter('미완료')}>
            <div className="logistics_dash_tt">미완료</div>
            <div className="logistics_dash_data">{DATA.MAIN && DATA.MAIN.INCOMPLETE_COUNT}<span> 건</span></div>
          </div>
        </div>


        {/* 하단 대시보드 */}
        <div className="logistics_dash_TotalWrap">

          {/* 좌측 대시보드 */}
          <div className="logistics_dash_leftWrap" style={{ display: subject === "orderInfo" || subject === "forwardingInfo" ? "none" : "" }}>
            <div className="logistics_dash_loginWrap">
              <img src={require("../../../images/main_logisticsIcon01.jpg").default} className="" />
              <div className="logistics_dash_logintt">
                {subject === "transInfo" && "운송 요청"}
                {subject === "warehouseInfo" && "입고 요청"}
              </div>
              <div className="logistics_dash_logindata">
                {subject === "transInfo" && DATA.DETAIL && DATA.DETAIL.REQ_COUNT}
                {subject === "warehouseInfo" && DATA.DETAIL && DATA.DETAIL.GR_REQ_COUNT}
                <span> 건</span>
              </div>
            </div>
            <div className="logistics_dash_loginWrap">
              <img src={require("../../../images/main_logisticsIcon02.jpg").default} className="" />
              <div className="logistics_dash_logintt">
                {subject === "transInfo" && "운송 중"}
                {subject === "warehouseInfo" && "입고 완료"}
              </div>
              <div className="logistics_dash_logindata">
                {subject === "transInfo" && DATA.DETAIL && DATA.DETAIL.ONGOING_COUNT}
                {subject === "warehouseInfo" && DATA.DETAIL && DATA.DETAIL.GR_COMPLETECOUNT}
                <span> 건</span>
              </div>
            </div>
            <div className="logistics_dash_loginWrap">
              <img src={require("../../../images/main_logisticsIcon03.jpg").default} className="" />
              <div className="logistics_dash_logintt">
                {subject === "transInfo" && "운송 완료"}
                {subject === "warehouseInfo" && "출고 요청"}
              </div>
              <div className="logistics_dash_logindata">
                {subject === "transInfo" && DATA.DETAIL && DATA.DETAIL.COMPLETE_COUNT}
                {subject === "warehouseInfo" && DATA.DETAIL && DATA.DETAIL.GI_REQ_COUNT}
                <span> 건</span>
              </div>
            </div>
            <div className="logistics_dash_loginWrap">
              <img src={require("../../../images/main_logisticsIcon04.jpg").default} className="" />
              <div className="logistics_dash_logintt">
                {subject === "transInfo" && "운송 취소"}
                {subject === "warehouseInfo" && "출고 완료"}
              </div>
              <div className="logistics_dash_logindata">
                {subject === "transInfo" && DATA.DETAIL && DATA.DETAIL.CANCEL_COUNT}
                {subject === "warehouseInfo" && DATA.DETAIL && DATA.DETAIL.GI_COMPLETECOUNT}
                <span> 건</span>
              </div>
            </div>
            <div className="logistics_dash_loginWrap">
              <img src={require("../../../images/main_logisticsIcon05.jpg").default} className="" />
              <div className="logistics_dash_logintt">
                {subject === "transInfo" && "전체"}
                {subject === "warehouseInfo" && "전체"}
              </div>
              <div className="logistics_dash_logindata">
                {subject === "transInfo" && DATA.DETAIL && DATA.DETAIL.ALL_COUNT}
                {subject === "warehouseInfo" && DATA.DETAIL && DATA.DETAIL.ALL_COUNT}
                <span> 건</span>
              </div>
            </div>
          </div>
          {/* 메인 대시보드 */}
          <MainDash />
        </div>
      </>
    )
  }
  //TabPanel----------------------------------------------------------------------------
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  //-----------------------------------------------------------------------------------
  const [subject, setSubject] = useState("orderInfo");
  const [term, setTerm] = useState("today");

  const subjectChange = (event, newValue) => {
    event.preventDefault();
    setSubject(newValue);
    setTerm("today");
    if (subject != 4) {
      let subjectIdx = newValue;
      let termIdx = "today";
      let pickedDate = pickDate;
      getList(subjectIdx, termIdx, pickedDate);
    }
  };

  const termChange = (event, newValue) => {
    event.preventDefault();
    setTerm(newValue);
    if (subject != 4) {
      let subjectIdx = subject;
      let termIdx = newValue;
      let pickedDate = pickDate;
      getList(subjectIdx, termIdx, pickedDate);
    }
  };

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  // 기간선택 컴퍼넌트
  // const selectTerm = () =>{
  //   return(
  //     <>
  //       <DatePicker
  //         locale="ko"
  //         selected={startDate}
  //         onChange={changeTerm}
  //         startDate={startDate}
  //         endDate={endDate}
  //         dateFormat="yyyyMMdd"
  //         selectsRange
  //         withPortal
  //         customInput={<CustomDatePicker/>}
  //       />
  //     </>
  //   )
  // }

  // const changeTerm = (dates) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  const selectDate = () => {
    return (
      <>
        <DatePicker
          locale="ko"
          selected={datePickerDate}
          onChange={changeDate}
          dateFormat="yyyy년 MM월 dd일"
          withPortal
          style={{ "padding": "0px" }}
          customInput={<CustomDatePicker />}
        />
      </>
    )
  }

  const changeDate = (dates) => {
    const date = dates;
    setDatePickerDate(date);
    transDateFormat(date);
    setTerm("today")
    if (subject != 4) {
      let subjectIdx = subject;
      let termIdx = term;
      let pickedDate = pickDate;
      getList(subjectIdx, termIdx, pickedDate);
    }
  };

  return (
    <>
      <div className="logistics_data_wrap02" id="logisticsData_login_on" style={{ "padding": "0px" }}>
        <Typography component={"span"}>

          <Tabs className="tab_shape_control"
            onChange={subjectChange}
            value={subject}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example">
            <Tab label="발주현황" {...a11yProps("orderInfo")} value="orderInfo" />
            <Tab label="운송현황" {...a11yProps("transInfo")} value="transInfo" />
            <Tab label="포워딩현황" {...a11yProps("forwardingInfo")} value="forwardingInfo" />
            <Tab label="창고현황" {...a11yProps("warehouseInfo")} value="warehouseInfo" />
            <Tab label="정산현황" {...a11yProps("billingInfo")} value="billingInfo" />
          </Tabs>

          <Tabs className="tab_shape_control"
            onChange={termChange}
            value={term}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable auto tabs example"
            style={{ "float": "left" }}>
            {/* <Tab label={selectDate()} {...a11yProps("selectedDate")} value="selectedDate"/> */}
            <Tab label="오늘" {...a11yProps("today")} value="today" />
            <Tab label="이번 주" {...a11yProps("thisWeek")} value="thisWeek" />
            <Tab label="이번 달" {...a11yProps("thisMonth")} value="thisMonth" />
          </Tabs>
          <Tabs className="tab_shape_control02" value="0">
            <Tab label={selectDate()} value="0" />
          </Tabs>
          <Button className="ButtonStyle02 btn_position_control" style={{ "float": "right", "marginRight": "20px" }} onClick={() => window.open("https://info.myslp.kr/")}>정보시스템에서 확인하기</Button>

          <TabPanel className="logistics_dash_positon" value={subject} index={"orderInfo"}> <TabDetail /> </TabPanel>
          <TabPanel className="logistics_dash_positon" value={subject} index={"transInfo"}> <TabDetail /> </TabPanel>
          <TabPanel className="logistics_dash_positon" value={subject} index={"forwardingInfo"}> <TabDetail /> </TabPanel>
          <TabPanel className="logistics_dash_positon" value={subject} index={"warehouseInfo"}> <TabDetail /> </TabPanel>
          <TabPanel className="logistics_dash_positon02" value={subject} index={"billingInfo"}>
            < PLT020501
              DATE={pickDate}
              COMPANY_CODE={localStorage.getItem('COMPANY_CODE')}
              DATE_TYPE={DATE_TYPE[term]} />
          </TabPanel>
        </Typography>
      </div>
    </>
  )
}

export default PortalMainDashOnSession;