import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

import SalesTotalPrice    from './PLT020301/SalesTotalPrice';
import PurchaseTotalPrice from './PLT020301/PurchaseTotalPrice';

import LatestOrders       from './PLT020301/LatestOrders';
import WeekSales          from './PLT020301/WeekSales';
import TrafficByDevice    from './PLT020301/TrafficByDevice';
import { home_url } from '../../../js/globalProperties';

function PLT020301(props) {
  const history = useHistory();
  var   items = new Array();
  var   sales = {};
  var   purchase = {};
  const [BBS, setBBS] = useState([]);
  const [SALES, setSALES] = useState([]); // 매입총계
  const [PURCHASE, setPURCHASE] = useState([]); // 매입총계
  const reducer = (accumulator, curr) => accumulator + curr;

  const callApi = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'BBS_ID':props.match.params.bbs_id, 'OPEN_API_YN':'Y'})
    };
    console.log('BBS_ID: ', props.match.params.bbs_id);
    await fetch(home_url +'/api/BRD010010SVC/selectBbs', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
      items = data['DATA'];
      console.log('데이터 조회결과 : ', items); 
      var salesSum = 0;
      var salesList = [];
      var salesDaysList = [];
      var purchaseSum = 0;
      var purchaseList = [];
      var purchaseDaysList = [];
      const BBSLIST = [...Array(0)].map((_, index) => ({}));

      for(var itm in items){
        if(itm < 10){ //임시로 건수 제한 
          salesSum = salesSum + items[itm]['ATCH_POSBL_FILE_SIZE'];
          salesList.push(items[itm]['ATCH_POSBL_FILE_SIZE']);
          salesDaysList.push(moment(items[itm]['CHANGE_DATE']).format('YYYY-MM-DD'));
          purchaseSum = purchaseSum + items[itm]['ATCH_POSBL_FILE_SIZE'];
          purchaseList.push(items[itm]['ATCH_POSBL_FILE_SIZE']);
          purchaseDaysList.push(moment(items[itm]['CHANGE_DATE']).format('YYYY-MM-DD'));
        }
        BBSLIST.push(items[itm]);
      }
      setBBS(BBSLIST);

      console.log('BBSLIST : ', BBSLIST);
      console.log('매입내역 : ', salesSum, items.length, salesSum / items.length, (salesSum/(salesSum+purchaseSum))*100, salesList);
      console.log('매출내역 : ', purchaseSum, items.length, purchaseSum / items.length, (purchaseSum/(salesSum+purchaseSum))*100, purchaseList );

      setSALES({'sum'  : salesSum
              , 'cnt'  : items.length
              , 'avg'  : salesSum / items.length
              , 'per'  : (salesSum/(salesSum+purchaseSum))*100
              , 'list' : salesList
              , 'dayslist' : salesDaysList
      });

      setPURCHASE({'sum'  : purchaseSum
                 , 'cnt'  : items.length
                 , 'avg'  : purchaseSum / items.length
                 , 'per'  : (purchaseSum/(salesSum+purchaseSum))*100
                 , 'list' : purchaseList
                 , 'dayslist' : purchaseDaysList
      })
      
    });
  }

  useEffect(() => {
      callApi();
    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock(); 
    };
  }, [history]);

  return (
  <>
    <Page title="Totalboard | Minimal-UI">
      <Card>
        <Typography className="listTable_tt">
          운송현황 대시보드
        </Typography>
        <Divider/>
          <Box
          sx={{
            backgroundColor: '#eeeeee',
            minHeight: '100%',
            py: 3
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                <SalesTotalPrice
                    SALES={SALES}
                    PURCHASE={PURCHASE}/>
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                <PurchaseTotalPrice
                    SALES={SALES}
                    PURCHASE={PURCHASE}/>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xl={8}
                xs={12}
              >
                <WeekSales
                    SALES={SALES}
                    PURCHASE={PURCHASE} />
              </Grid>
              <Grid
                item
                lg={2}
                md={4}
                xl={4}
                xs={12}
              >
                <TrafficByDevice 
                    SALES={SALES}
                    PURCHASE={PURCHASE}
                    sx={{ height: '100%' }} />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={12}
                xs={12}
              >
                <LatestOrders 
                BBS={BBS}/>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Card>
    </Page>
  </>
  );
}
export default PLT020301;
