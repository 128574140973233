import { useEffect, useState } from 'react';
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';
import { filter } from 'lodash';
import moment from 'moment';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Button
} from '@material-ui/core';
// components
import SearchNotFound from '../CommonBoard/SearchNotFound';
import { CommonMoreMenu, CommonToolbar, CommonHead } from '../CommonBoard/BoardList/';


// ----------------------------------------------------------------------
const TABLE_HEAD = [
    { id: 'No', label: 'No.', alignRight: false },
    { id: 'USER_NAME_LOC', label: '이름', alignRight: false },
    { id: 'ADD_DATE', label: '차단일자', alignRight: false },
    { id: '', label: '허용여부', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.NTT_SJ.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function NoteList(props) {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('ADD_DATE',);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [List, setList] = useState([]);

    const getList = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({"USER_ID":localStorage.getItem("USER_ID"), "TYPE":'I'})
        };
        
        let requestUrl = '/api/BRD130010SVC/getList';

        fetch(home_url + requestUrl, requestOptions)
            .then((response) => { return response.json(); })
            .then((data) => { 
                let items = new Array();
                items = data.DATA;
                if(items!=null) setList(items); 
            });
    }

    useEffect(() => {
        getList();
    }, [props]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = List.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

    const filteredTotalboards = applySortFilter(List, getComparator(order, orderBy), filterName);

    const isTotalboardNotFound = filteredTotalboards.length === 0;

    const today = moment().format('YYYY-MM-DD');

    const cancleIgnore = (name, ntt_id) => {
        if (!window.confirm(name+"님으로부터 수신을 허용하시겠습니까?"))
            return false;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ "NTT_ID": ntt_id })
        };

        fetch(home_url + '/api/BRD130010SVC/delete', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.DATA !== 1) {
                    alert(data.MSG);
                    return false;
                }
                getList();
            })
    }

    return (
        <div>
            <Card>
                <Typography className="listTable_tt">
                    차단목록
                </Typography>
                {/* <Scrollbar> */}
                <TableContainer >
                    <Table className="listTable NoteTable_head">
                        <CommonHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={List.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {List&&filteredTotalboards
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = selected.indexOf(row.NTT_ID) !== -1;
                                    return (
                                        <>
                                            <TableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell scope="row" align="center"> {index + page * rowsPerPage + 1} </TableCell>
                                                <TableCell scope="row" padding="none" style={{ textAlign:"center" }}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap style={{textAlign:"center"}}>
                                                        {row.USER_NAME_LOC}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center" style={{ display : props.noteMode === 'sendList' ? 'none' : ''}}>{row.ADD_DATE}</TableCell>
                                                <TableCell align="center">
                                                    {/* {today === row.ADD_DATE.substring(0, 10) ? row.ADD_DATE.substring(10, 16) : row.ADD_DATE.substring(0, 10)} */}
                                                    <Button className="NoteAllow_Btn" variant="contained"
                                                        onClick = {() => {cancleIgnore(row.USER_NAME_LOC, row.NTT_ID)}}
                                                    >
                                                        수신허용
                                                    </Button>
                                                    
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isTotalboardNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell className="NoteNoSrch_td" align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {/* </Scrollbar> */}

                <TablePagination
                    rowsPerPageOptions={[15, 30, 45]}
                    component="div"
                    count={List.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
            {/* </Container> */}
        </div>
    );
}

export default NoteList;