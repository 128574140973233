import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import TradeLabel from './TradeLabel';

const TradeCard = (props) => {
  const [IMAGE, setIMAGE] = useState();

  const getURL = (link) => {
    let company_code = link.split('/')[6];
    let file_mgt_code = link.split('/')[8];

    fetch(home_url + "/api/apicall/getDownload/" + company_code + "/DL/" + file_mgt_code)
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => { setIMAGE(url) })
  }

  useEffect(() => {
    if (props.ITEM.PHOTO_URL) getURL(props.ITEM.PHOTO_URL);
  }, [props])

  const history = useHistory();
  const handleBoardRowClick = (item, wishList) => {
    history.push({ pathname: '/app/trade/' + item.DEAL_TYPE + '/' + item.COMPANY_CODE + '/' + item.DEAL_NO, state: { item: item, wishList: wishList } });
  };
  return (
    <>
      <Card onClick={() => { handleBoardRowClick(props.ITEM, props.WISHLIST) }} style={{ cursor: 'pointer' }}>
        <Box sx={{ pt: '100%', position: 'relative' }} className="tradeThumnail">
          <img alt="" src={props.ITEM.PHOTO_URL !== '' ? IMAGE : require("../../../images/no_image.png").default} className="tradeThumnail_Img" />
          <img alt="찜하기" src={require("../../../images/heart_off.png").default} className="tradeHeart_Img" style={{ display: props.WISHLIST.includes(props.ITEM.DEAL_NO) ? 'none' : '' }} />
          <img alt="찜하기" src={require("../../../images/heart_on.png").default} className="tradeHeart_Img" style={{ display: props.WISHLIST.includes(props.ITEM.DEAL_NO) ? '' : 'none' }} />
        </Box>
        <Stack spacing={2} sx={{ p: 0 }} className="thumbnail_ttWrap">
          <TradeLabel className="thumbnail_newIcon_f" style={{ display: props.ITEM.FINISH_YN === "F" ? '' : 'none' }}>
            완료
          </TradeLabel>
          <TradeLabel className="thumbnail_newIcon_ing" style={{ display: props.ITEM.FINISH_YN !== "F" ? '' : 'none' }}>
            거래중
          </TradeLabel>
          <Typography
            variant="subtitle2"
            className="thumbnail_name">
            {props.ITEM.TITLE}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
            <TradeLabel className="tradePrice">
              {props.ITEM.AMOUNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 원
            </TradeLabel>

            <Typography variant="subtitle1" className="thumbnail_date tradeSubmitDate">
              {props.ITEM.ADD_DATE === "오늘" && (
                <TradeLabel className="thumbnail_newIcon02">
                  N
                </TradeLabel>
              )}
              {props.ITEM.ADD_DATE}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
export default TradeCard;
