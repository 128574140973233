//메인 서브 메뉴 open 컨트롤
import "react-jqueryui";
import "react-jqueryui-datepicker";

import $ from 'jquery'
window.$ = $;

function menuClickAfter(){
		$('#submenu01, #submenu02, #submenu03, #submenu04, #submenu05, #submenu06, #submenu07, #submenu08, #submenu09, #submenu10, #submenu11, #submenu12, #submenu13, .main_submenu_wrap').css( 'display','none');
	}

$(function() {
	// console.log('화면 새로고침이 되는지?');
	$('#menu01').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu01').css( 'display','block');
	});
	$('#menu02').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu02').css( 'display','block');
	});
	$('#menu03').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu03').css( 'display','block');
	});
	$('#menu04').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu04').css( 'display','block');
	});
	$('#menu05').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu05').css( 'display','block');
	});
	$('#menu06').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu06').css( 'display','block');
	});
	$('#menu07').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu07').css( 'display','block');
	});
	$('#menu08').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu08').css( 'display','block');
	});
	$('#menu09').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu09').css( 'display','block');
	});
	$('#menu10').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu10').css( 'display','block');
	});
	$('#menu11').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu11').css( 'display','block');
	});
	$('#menu12').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu12').css( 'display','block');
	});
	$('#menu13').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('#submenu13').css( 'display','block');
	});
	$('.menuMainAll02').click(function(event) {
		menuClickAfter();
		event.stopPropagation();
		$('.main_submenu_wrap').css( 'display','block');
	});
	$('.M_right_menu').click(function() {
		$('#leftmenu_wrap').css( 'display','block');

		var bodyhei = document.body.scrollHeight;
		$('.leftmenu_background').css( 'display','block');
		$('.leftmenu_background').css( 'height', bodyhei);
	});
	$('.leftmenu_close').click(function() {
		$('#leftmenu_wrap').css( 'display','none');
		$('.leftmenu_background').css( 'display','none');
	});
});

//캐러셀css 컨트롤
// $(function() {
// 	$('div[class*="makeStyles-root"]').css({'position':'relative','height':'500px','border':'1px solid #eee'}); 
// 	$('div[class*="makeStyles-itemWrapper"]').css({'height':'500px','line-height':'470px'}); 
// 	$('div[class*="makeStyles-indicators"]').css({'position':'absolute','bottom':'0px'}); 
// 	$('div[class*="makeStyles-item"]').css({'max-width':'770px','max-height':'450px'}); 
// });

//메인 서브 메뉴 close 컨트롤
$(document).click(function(e,i,t){
		menuClickAfter();
		// $( '.userLoginPop' ).hide();
});

$(function() {
	$( '.userLoginOnOff' ).click( function(event) {
	$( '.userLoginPop' ).toggle();
	// event.stopPropagation();
	});
});

//좌측 상위 메뉴 컨트롤
$(function() {
	$('.leftmenu_single').click(function() {
		$('.leftmenu_single').css( 'background','#fff');
		$('.leftmenu_single a').css( 'color','#000');	
		$('.leftmenu_single > .fa-caret-right').css( 'color','#00b0ec');
		$('.submenu_wrap').hide();
		$(this).css( 'background','#003b85');
		$(this).find('.colorChange').css( 'color','#fff');
		//$(this).find('.fa-caret-right').css( 'color','#fff');
		$(this).next().css('display', 'block');
	});
});

//좌측 서브 메뉴 컨트롤
$(function() {
	$('.submenu').click(function() {
		$('.submenu').css('font-weight','normal');
		$(this).css( 'font-weight','bold');
	});
});

//조회 테이블형 DIV 컨트롤
$(function() {
	//옵션 전체보기
	$('.srch_div_close').css('display', 'none')
	$('.srch_div_open').click(function() {
		$('.srch_div_open').css('display', 'none');
		$('.srch_div_close').css('display', 'block');		
		$('.srch_div_one02').css('display', 'block');				
	});	
	$('.srch_div_close').click(function() {
		$('.srch_div_open').css('display', 'block');
		$('.srch_div_close').css('display', 'none');		
		$('.srch_div_one02').css('display', 'none');				
	});	
	
	//펼치기
	$('.srch_minus_wrap').css('display', 'none')
	$('#srch_plus_wrap').click(function() {
		$('#srch_div_oneChk_Wrap').css('height', 'auto');
		$('#srch_plus_wrap').css('display', 'none');
		$('#srch_minus_wrap').css('display', 'block');		
	});	
	$('#srch_minus_wrap').click(function() {
		$('#srch_div_oneChk_Wrap').css('height', 18);
		$('#srch_plus_wrap').css('display', 'block');
		$('#srch_minus_wrap').css('display', 'none');		
	});	
	
	$('.oneSrch_close').click(function() {
		$(this).empty();
		return false;
	});	
		
});


//상품 리스트, 액자형 컨트롤
$(function() {
	$('#product_list_open i').css( 'color','#a6a6a6');
	$('#product_list_open').click(function(){
		$('#product_list_view').css( 'display','block');
		$('#product_frame_view').css( 'display','none');
		$('#product_list_open i').css( 'color','#a6a6a6');
		$('#product_frame_open i').css( 'color','#e1e1e1');		
	});

	$('#product_frame_open').click(function(){
		$('#product_frame_view').css( 'display','block');
		$('#product_list_view').css( 'display','none');
		$('#product_list_open i').css( 'color','#e1e1e1f');
		$('#product_frame_open i').css( 'color','#a6a6a6');						
	});
});

//상품비교 플롯팅 오픈 컨트롤 - 임시
$(function() {
$(".srch_chekbox_aline").change(function(){
		$('.compare_choice_wrap').css( 'display','block');
    });
	$('.compare_choice_close').click(function() { 
		$('.compare_choice_wrap').css('display','none');
	});	
});	

//기기 비교 컨트롤
$(function() {
	
	$('#compare_head').css('border-right','0px');		

	var bodytr01 = parseInt($('#compare_body tr:eq(0)').height());	
	var bodytr02 = parseInt($('#compare_body tr:eq(1)').height());	
	var bodytr03 = parseInt($('#compare_body tr:eq(2)').height());	
	var bodytr04 = parseInt($('#compare_body tr:eq(3)').height());	
	var bodytr05 = parseInt($('#compare_body tr:eq(4)').height());	
	var bodytr06 = parseInt($('#compare_body tr:eq(5)').height());	
	var bodytr07 = parseInt($('#compare_body tr:eq(6)').height());		

	$('#compare_head tr:eq(0)').css('height', bodytr01);
	$('#compare_head tr:eq(1)').css('height', bodytr02 );
	$('#compare_head tr:eq(2)').css('height', bodytr03 );
	$('#compare_head tr:eq(3)').css('height', bodytr04 );
	$('#compare_head tr:eq(4)').css('height', bodytr05 );
	$('#compare_head tr:eq(5)').css('height', bodytr06 );
	$('#compare_head tr:eq(6)').css('height', bodytr07 );
	
	var tdSize = $("#compare_body tr:eq(0)").children().tdSize;
	$('#compare_body').css('width', tdSize * 321);
});

//상세페이지 컨트롤
$(function() {
	$('#detail_img01').show();
	$('#tum01').hover(function() {
		$('#detail_img02').hide();
		$('#detail_img03').hide();
		$('#detail_img01').show();
	});
	$('#tum02').hover(function() {
		$('#detail_img01').hide();
		$('#detail_img03').hide();
		$('#detail_img02').show();
	});
	$('#tum03').hover(function() {
		$('#detail_img01').hide();
		$('#detail_img02').hide();
		$('#detail_img03').show();
	});
});

//탭 컨트롤
$( function() {
	//움직이는 메뉴 left 클릭 함수
	$(function() {
		$('.menu_arrow_L').click(function() {
			var broWid = window.innerWidth;
			var wi = $('#menu_box').width();
			
			$('#menu_box_in').stop().animate({'left':'0px'}, 1000,function(){
			$('#menu_box_in').css('left',-wi +'px').find('.menuRoll:last').prependTo('#menu_box_in');
			});
		});
	});
	//움직이는 메뉴 right 클릭 함수
	$(function() {
		$('.menu_arrow_L').click(function() {
			var wi = $('#menu_box').width();
			$('#menu_box_in').stop().animate({'left':'0px'}, 1000,function(){
			$('#menu_box_in').css('left',-wi +'px').find('.menuRoll:last').prependTo('#menu_box_in');
			});
		});
	});
	$(function() {
		$('.menu_arrow_R').click(function() {
			var wi = $('#menu_box').width();
			$('#menu_box_in').css('left',wi +'px').find('.menuRoll:last').prependTo('#menu_box_in');
			$('#menu_box_in').css('left','0px').find('.menuRoll:first').prependTo('#menu_box_in');
			$('#menu_box_in').stop().animate({'left':-wi+'px'}, 1000,function(){
			$('#menu_box_in').css('left',-wi +'px').find('.menuRoll:last').appendTo('#menu_box_in');
			});
		});
	});

	//움직이는 메뉴 초기 설정 함수
	$(function(){
		var wi = $('#menu_box').width();
		$('#menu_box_in').css('left','-'+wi +'px').find('.menuRoll:last').prependTo('#menu_box_in');
	});

	// //메인 서브 메뉴 open 컨트롤
	// $(function() {
	// 	$('.menuMain').click(function() {
	// 		$('.main_submenu_wrap').css( 'display','block');
	// 	});
	// });

	$('.tabContents01').css('display','inline-block');	
	$('.tabContents02, .tabContents03').css('display','none');
	$('.tab01').click(function() { 
		$('.tabContents02, .tabContents03').css('display','none');
		$('.tabContents01').css('display','inline-block');		
		$(".tab01").addClass("quickbbs_tab_select");
		$(".tab02, .tab03").removeClass("quickbbs_tab_select");
	});
	$('.tab02').click(function() {
		$('.tabContents01, .tabContents03').css('display','none');
		$('.tabContents02').css('display','inline-block');
		$(".tab02").addClass("quickbbs_tab_select");
		$(".tab01, .tab03").removeClass("quickbbs_tab_select");				
	});
	$('.tab03').click(function() {
		$('.tabContents01, .tabContents02').css('display','none');
		$('.tabContents03').css('display','inline-block');		
		$(".tab03").addClass("quickbbs_tab_select");
		$(".tab01, .tab02").removeClass("quickbbs_tab_select");		
	});

	$('.tabContents04').css('display','inline-block');	
	$('.tabContents05, .tabContents06').css('display','none');
	$('.tab04').click(function() { 
		$('.tabContents05, .tabContents06').css('display','none');
		$('.tabContents04').css('display','inline-block');		
		$(".tab04").addClass("quickbbs_tab_select");
		$(".tab05, .tab06").removeClass("quickbbs_tab_select");
	});
	$('.tab05').click(function() {
		$('.tabContents04, .tabContents06').css('display','none');
		$('.tabContents05').css('display','inline-block');
		$(".tab05").addClass("quickbbs_tab_select");
		$(".tab04, .tab06").removeClass("quickbbs_tab_select");				
	});
	$('.tab06').click(function() {
		$('.tabContents04, .tabContents05').css('display','none');
		$('.tabContents06').css('display','inline-block');		
		$(".tab06").addClass("quickbbs_tab_select");
		$(".tab04, .tab05").removeClass("quickbbs_tab_select");		
	});
	
	$('.tabContents07').css('display','inline-block');	
	$('.tabContents08, .tabContents09').css('display','none');
	$('.tab07').click(function() { 
		$('.tabContents08, .tabContents09').css('display','none');
		$('.tabContents07').css('display','inline-block');		
		$(".tab07").addClass("quickbbs_tab_select");
		$(".tab08, .tab09").removeClass("quickbbs_tab_select");
	});
	$('.tab08').click(function() {
		$('.tabContents07, .tabContents09').css('display','none');
		$('.tabContents08').css('display','inline-block');
		$(".tab08").addClass("quickbbs_tab_select");
		$(".tab07, .tab09").removeClass("quickbbs_tab_select");				
	});
	$('.tab09').click(function() {
		$('.tabContents07, .tabContents08').css('display','none');
		$('.tabContents09').css('display','inline-block');		
		$(".tab09").addClass("quickbbs_tab_select");
		$(".tab07, .tab08").removeClass("quickbbs_tab_select");		
	});

	$('.tabContents10').css('display','block');	
	$('.tabContents11, .tabContents12').css('display','none');
	$('.tab10').click(function() { 
		$('.tabContents11, .tabContents12').css('display','none');
		$('.tabContents10').css('display','block');		
		$(".tab10").addClass("quickbbs_tab_select");
		$(".tab11, .tab12").removeClass("quickbbs_tab_select");
	});
	$('.tab11').click(function() {
		$('.tabContents10, .tabContents12').css('display','none');
		$('.tabContents11').css('display','block');
		$(".tab11").addClass("quickbbs_tab_select");
		$(".tab10, .tab12").removeClass("quickbbs_tab_select");				
	});
	$('.tab12').click(function() {
		$('.tabContents10, .tabContents11').css('display','none');
		$('.tabContents12').css('display','block');		
		$(".tab12").addClass("quickbbs_tab_select");
		$(".tab10, .tab11").removeClass("quickbbs_tab_select");		
	});

	$('.TradetabContents01, tradeDoneChkbox').css('display','inline-block');	
	$('.TradetabContents02, .TradetabContents03, .TradetabContents04').css('display','none');
	// $('.Tradetab01').click(function() { 
	// 	$('.TradetabContents02, .TradetabContents03, .TradetabContents04').css('display','none');
	// 	$('.TradetabContents01, .tradeDoneChkbox').css('display','inline-block');		
	// 	$(".Tradetab01").addClass("quickbbs_tab_select");
	// 	$(".Tradetab02, .Tradetab03, .Tradetab04").removeClass("quickbbs_tab_select");
	// });
	// $('.Tradetab02').click(function() {
	// 	$('.TradetabContents01, .TradetabContents03, .TradetabContents04').css('display','none');
	// 	$('.TradetabContents02, .tradeDoneChkbox').css('display','inline-block');
	// 	$(".Tradetab02").addClass("quickbbs_tab_select");
	// 	$(".Tradetab01, .Tradetab03, .Tradetab04").removeClass("quickbbs_tab_select");				
	// });
	// $('.Tradetab03').click(function() {
	// 	$('.TradetabContents01, .TradetabContents02, .TradetabContents04').css('display','none');
	// 	$('.TradetabContents03, .tradeDoneChkbox').css('display','inline-block');		
	// 	$(".Tradetab03").addClass("quickbbs_tab_select");
	// 	$(".Tradetab01, .Tradetab02, .Tradetab04").removeClass("quickbbs_tab_select");		
	// });
	// $('.Tradetab04').click(function() {
	// 	$('.TradetabContents01, .TradetabContents02, .TradetabContents03, .tradeDoneChkbox').css('display','none');
	// 	$('.TradetabContents04').css('display','inline-block');		
	// 	$(".Tradetab04").addClass("quickbbs_tab_select");
	// 	$(".Tradetab01, .Tradetab02, .Tradetab03").removeClass("quickbbs_tab_select");		
	// });
 });

 $(function() {
	 			// $('.slides').click(function() {
				// 	$('.bannerPop, .bannerPop_Img, .bannerPop_ImgMain, .bannerPop_ImgWrap, .bannerPop_menuWrap').css('display','block'); 
				// 	$('body').css('overflow','hidden'); 
				// });
				// $('.bannerPop_Xbtn').click(function() {
				// 	$('.bannerPop, .bannerPop_Img, .bannerPop_ImgMain, .bannerPop_ImgWrap, .bannerPop_logo, .coffeeBanner_Img, .coffee_banner_button1, .coffee_banner_button2').css('display','none');
				// 	$('body').css('overflow','auto'); 
				// });
				// $('.coffeeBanner').click(function() {
				// 	$('.bannerPop, .bannerPop_Img, .coffeeBanner_Img, .bannerPop_ImgWrap, .coffee_banner_button1, .coffee_banner_button2').css('display','block');
				// 	$('.bannerPop_ImgMain, .bannerPop_menuWrap').css('display', 'none');
				// 	$('body').css('overflow','hidden'); 
				// });
				$('.bannerPop_logo, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05, .coffeeBanner_Img, .coffee_banner_button1, .coffee_banner_button2').css('display','none');
				$('.bannerPop_logo').click(function() {
					$('.bannerPop_logo, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05').css('display','none');
					$('.bannerPop_mainlogo, .bannerPop_ImgMain').css('display','block');
					$('.bannerPop_menu01, .bannerPop_menu02, .bannerPop_menu03, .bannerPop_menu04, .bannerPop_menu05').css('background','none');		
				});
				$('.bannerPop_menu01').click(function() {
					$('.bannerPop_mainlogo,.bannerPop_ImgMain, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05').css('display','none');
					$('.bannerPop_logo, .bannerPop_Img01').css('display','block');
					$('.bannerPop_menu02, .bannerPop_menu03, .bannerPop_menu04, .bannerPop_menu05').css('background','none');
					$('.bannerPop_menu01').css('background','#edecec');		
				});
				$('.bannerPop_menu02').click(function() {
					$('.bannerPop_mainlogo, .bannerPop_ImgMain, .bannerPop_Img01, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05').css('display','none');
					$('.bannerPop_logo, .bannerPop_Img02').css('display','block');
					$('.bannerPop_menu01, .bannerPop_menu03, .bannerPop_menu04, .bannerPop_menu05').css('background','none');
					$('.bannerPop_menu02').css('background','#edecec');		
				});
				$('.bannerPop_menu03').click(function() {
					$('.bannerPop_mainlogo, .bannerPop_ImgMain, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img04, .bannerPop_Img05').css('display','none');
					$('.bannerPop_logo, .bannerPop_Img03').css('display','block');
					$('.bannerPop_menu01, .bannerPop_menu02, .bannerPop_menu04, .bannerPop_menu05').css('background','none');
					$('.bannerPop_menu03').css('background','#edecec');			
				});
				$('.bannerPop_menu04').click(function() {
					$('.bannerPop_mainlogo, .bannerPop_ImgMain, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img05').css('display','none');
					$('.bannerPop_logo, .bannerPop_Img04').css('display','block');
					$('.bannerPop_menu01, .bannerPop_menu02, .bannerPop_menu03, .bannerPop_menu05').css('background','none');
					$('.bannerPop_menu04').css('background','#edecec');			
				});
				$('.bannerPop_menu05').click(function() {
					$('.bannerPop_mainlogo, .bannerPop_ImgMain, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04').css('display','none');
					$('.bannerPop_logo, .bannerPop_Img05').css('display','block');
					$('.bannerPop_menu01, .bannerPop_menu02, .bannerPop_menu03, .bannerPop_menu04').css('background','none');
					$('.bannerPop_menu05').css('background','#edecec');		
				});
			});


$(function() {
	$('.btnOverCtrl01').mouseover(function() {
			$('.btnOverView01').css('opacity','0.2');
	});
	$('.btnOverCtrl02').mouseover(function() {
		$('.btnOverView02').css('opacity','0.2');
	});	
	$('.btnOverCtrl03').mouseover(function() {
		$('.btnOverView03').css('opacity','0.2');
	});
	$('.btnOverCtrl04').mouseover(function() {
		$('.btnOverView04').css('opacity','0.2');
	});
	$('.btnOverCtrl05').mouseover(function() {
		$('.btnOverView05').css('opacity','0.2');
	});
	$('.btnOverCtrl06').mouseover(function() {
		$('.btnOverView06').css('opacity','0.2');
	});
	$('.btnOverCtrl01').mouseout(function() {
		$('.btnOverView01').css('opacity','0.5');
	});	
	$('.btnOverCtrl02').mouseout(function() {
		$('.btnOverView02').css('opacity','0.5');
	});	
	$('.btnOverCtrl03').mouseout(function() {
		$('.btnOverView03').css('opacity','0.5');
	});	
	$('.btnOverCtrl04').mouseout(function() {
		$('.btnOverView04').css('opacity','0.5');
	});	
	$('.btnOverCtrl05').mouseout(function() {
		$('.btnOverView05').css('opacity','0.5');
	});	
	$('.btnOverCtrl06').mouseout(function() {
		$('.btnOverView06').css('opacity','0.5');
	});	
});

$(document).ready(function () {
	$(document).on('click', '.choiceGroup1>ul>li', function () {
	  $(this).appendTo('.choiceGroup2>ul');
	  sortLi('.choiceGroup2');
	});
	$(document).on('click', '.choiceGroup2>ul>li', function () {
	  $(this).appendTo('.choiceGroup1>ul');
	  sortLi('.choiceGroup1');
	});

	function sortLi(e) {
	  $(e + '>ul>li').sort(function (a, b) {
		
		return $(a).data('sid') - $(b).data('sid');
	  }).appendTo(e + '>ul');
	}
  });

$(function() {
	$('.adresSection02, .adresSection03').hide();
	$('.adresMenu li a.adresSectionOpen01').css( 'font-weight','bold');
	$('.adresSectionOpen01').click(function() { 
		$('.adresMenu li a.adresSectionOpen01').css( 'font-weight','bold');
		$('.adresMenu li a.adresSectionOpen02, .adresMenu li a.adresSectionOpen03').css( 'font-weight','normal');
		$('.adresSection01').show();
		$('.adresSection02, .adresSection03').hide();
		return false;
	});
	$('.adresSectionOpen02').click(function() { 
		$('.adresMenu li a.adresSectionOpen02').css( 'font-weight','bold');
		$('.adresMenu li a.adresSectionOpen01, .adresMenu li a.adresSectionOpen03').css( 'font-weight','normal');
		$('.adresSection02').show();
		$('.adresSection01, .adresSection03').hide();
		return false;
	});
	$('.adresSectionOpen03').click(function() { 
		$('.adresMenu li a.adresSectionOpen03').css( 'font-weight','bold');
		$('.adresMenu li a.adresSectionOpen01, .adresMenu li a.adresSectionOpen02').css( 'font-weight','normal');
		$('.adresSection03').show();
		$('.adresSection01, .adresSection02').hide();
		return false;
	});

	$('.adresDBlebtn01').click(function() { 
		$('.adresDBlebtn02, .adresDBlebtn03').val("");
	});
	$('.adresDBlebtn02').click(function() { 
		$('.adresDBlebtn01, .adresDBlebtn03').val("");
	});
	$('.adresDBlebtn03').click(function() { 
		$('.adresDBlebtn01, .adresDBlebtn02').val("");
	});
	$(document).mouseup(function (e){
		var clickOX01 = $(".adresSelectWrap01");
		if(clickOX01.has(e.target).length === 0){
			$('.adresDBlebtn01, .adresDBlebtn02, .adresDBlebtn03').val("");
		}
	  });

	$(".adresbtn").click(function(){
		var side =  $(this).prop('id');
		var division01 = $(".adresDBlebtn01 option:selected").val();
		var division02 = $(".adresDBlebtn02 option:selected").val();
		var division03 = $(".adresDBlebtn03 option:selected").val();
		var division04 = $(".adresDBlebtn04 option:selected").val();
		if(division01 == undefined && division02 == undefined && division03 == undefined && division04 == undefined){

		}else {
			move(side);	
		}
    });
 });
 $(function() {
	$(".adresDBlebtn01, .adresDBlebtn02, .adresDBlebtn03, .adresDBlebtn04").dblclick(function(){
		var side =  $(this).prop('id');
		move(side);	
		
	});
});

function move(side){
	var temp1 = new Array();
	var temp2 = new Array();
	var tempa = new Array();
	var tempb = new Array();
	var current1 = 0;
	var current2 = 0;
	var y=0;
	var attribute1;
	var attribute2;
	var division01 = $(".adresDBlebtn01 option:selected").val();
	var division02 = $(".adresDBlebtn02 option:selected").val();
	var division03 = $(".adresDBlebtn03 option:selected").val();
	
	if (side == "right")	{ 
		if (division01 > 0)	{ 
			attribute1 = document.rep.category_name01; 
		}else if(division02 > 0) {
			attribute1 = document.rep.category_name02; 
		}else if(division03 > 0){
			attribute1 = document.rep.category_name03; 
		}
		attribute2 = document.rep.category_selected;

		for (var i = 0; i < attribute2.length; i++)	{  
			y=current1++
			temp1[y] = attribute2.options[i].value;
			tempa[y] = attribute2.options[i].text;
		}
	
		for (var i = 0; i < attribute1.length; i++)	{   
			if ( attribute1.options[i].selected )		{  
				y=current1++
				temp1[y] = attribute1.options[i].value;
				tempa[y] = attribute1.options[i].text;
			}
			else		{  
				y=current2++
				temp2[y] = attribute1.options[i].value; 
				tempb[y] = attribute1.options[i].text;
			}
		}
		
		for (var i = 0; i < temp1.length; i++)	{  
			attribute2.options[i] = new Option();
			attribute2.options[i].value = temp1[i];
			attribute2.options[i].text =  tempa[i];
		}
	}
	else	{  
		$(".adresDBlebtn04 option:selected").remove();
	}
}

//데이터픽커 컨트롤
// $( function() {
//     $( '.datepicker01' ).datepicker({dateFormat:'dd-mm-yy', showOn:'both'});
//     $( '.datepicker02' ).datepicker({dateFormat:'dd-mm-yy', showOn:'both'});	
//   });

//데이터픽커 컨트롤
// $( function() {
//     $( '.datepicker01' ).datepicker({dateFormat:'dd-mm-yy', showOn:'both', buttonText: '<i class="fa fa-calendar"></i>'});
//     $( '.datepicker02' ).datepicker({dateFormat:'dd-mm-yy', showOn:'both', buttonText: '<i class="fa fa-calendar"></i>'});	
//   });
  
//리스트 테이블 짝수 줄 회색 및 리스트 전체 마지막 td 우측 보더 삭제 컨트롤
// $(function() {
	// $('.listTable tr td:last-child').css('border-right','0px');
	// $('.listTable tr th:last-child').css('border-right','0px');
	// $('.listTable tbody tr:nth-child(2n)').css('background-color','#fcfcfc');
// });

