import { useState, useEffect, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"
import TradeComment from "./TradeComment"
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Divider,
  Pagination,
  TextField,
  TablePagination,
  Card, Paper

} from '@material-ui/core';

const TradeDetail = (props) => {
  const history = useHistory();
  const [ITEM, setITEM] = useState();
  const [PHOTO, setPHOTO] = useState([]);
  const [FAVOR, setFAVOR] = useState(false);
  const [NOTE, setNOTE] = useState('');

  const location = useLocation();

  const DEAL_TYPE = location.pathname.split('/')[3];
  const COMPANY_CODE = location.pathname.split('/')[4];
  const DEAL_NO = location.pathname.split('/')[5];

  const getItem = () => {
    try {
      let requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({
          "P_COMPANY_CODE": COMPANY_CODE,
          "DEAL_TYPE": DEAL_TYPE,
          "DEAL_NO": DEAL_NO,
          "S_FUNC_CODE": "DL",
          "REF_TYPE": "JOB",
          "OPEN_API_YN": "Y"
        }),
      };
      fetch(home_url + "/api/MBP040010SVC/get", requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.MBP040010G1) setITEM(data.MBP040010G1[0]);
          requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") }
          }
          setPHOTO([]);
          for (let i in data.MBP040010G1[1].ptData) {
            fetch(home_url + "/api/apicall/getDownload/" + data.MBP040010G1[1].ptData[i].COMPANY_CODE + "/DL/" + data.MBP040010G1[1].ptData[i].FILE_MGT_CODE)
              .then(response => response.blob())
              .then(blob => URL.createObjectURL(blob))
              .then(url => { setPHOTO(prevState => [...prevState, url]) })
          }
        })
    } catch (error) {
      console.log(error);
    }
  }

  const handleFavor = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({
        "MBP040010F1": {
          "DEAL_TYPE": DEAL_TYPE,
          "DEAL_NO": DEAL_NO,
          "USER_ID": localStorage.getItem("USER_ID")
        }
      })
    };
    fetch(home_url + '/api/MBP040010SVC/setFavor', requestOptions)
      .then(function (response) { return response.json(); })
      .then(() => InitFavor())
  }

  const InitFavor = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({
        "MBP100010F1": {
          "USER_ID": localStorage.getItem("USER_ID"),
          "S_FUNC_CODE": "DL"
        }
      })
    };
    fetch(home_url + '/api/MBP100010SVC/getList', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        setFAVOR(false);
        for (let i in data.MBP100010G1) {
          if (data.MBP100010G1[i].DEAL_NO === DEAL_NO)
            setFAVOR(true);
          break;
        }
      })
  }

  const FinishTrade = () => {
    if (window.confirm("판매완료 하시겠습니까?")) {
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({
          "MBP040010F1": {
            "DEAL_TYPE": DEAL_TYPE,
            "DEAL_NO": DEAL_NO,
            "FINISH_YN": "F",
            "UPDATE_USER_ID": localStorage.getItem("USER_ID")
          }
        })
      };
      fetch(home_url + '/api/MBP040010SVC/finish', requestOptions)
        .then(function (response) { return response.json(); })
        .then((data) => { alert(data.MSG); getItem(); })
    }
  }

  const [REQUEST, setREQUEST] = useState(false);

  const RequestOpen = () => {
    setREQUEST(true)
  }

  const ReqeustClose = () => {
    setREQUEST(false);
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "500px",
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      padding: "15px",
      zIndex: "5000"
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    getItem();
    InitFavor();
  }, []);

  const MODEL_STATUS = {
    H: "상",
    M: "중",
    L: "하"
  }

  return (
    <>
      <Typography className="listTable_tt">
        중고장비 판매
      </Typography>

      <table className="srchTable">
        <tr className="tradeDetailCarouselWrap">
          <td>
            <Carousel className="carouselControl">
              {PHOTO && PHOTO.map((row, index) => (
                <img key={index} alt="" src={row} className="carouselImg" ></img>
              ))}
            </Carousel>

          </td>
        </tr>
        <tr className="tradeDetailTT">
          <td>
            {ITEM && ITEM.TITLE}
            <img
              alt="찜하기"
              src={require("../../../images/heart_off.png").default}
              className="tradeHeart_Img02"
              style={{ display: FAVOR ? 'none' : '', cursor: 'pointer' }}
              onClick={() => { handleFavor() }}
            />
            <img
              alt="찜하기"
              src={require("../../../images/heart_on.png").default}
              className="tradeHeart_Img02"
              style={{ display: FAVOR ? '' : 'none', cursor: 'pointer' }}
              onClick={() => { handleFavor() }}
            />
            <span className="tradeDetailDataWrap">
              {ITEM && ITEM.CATEGORY_LOC}&nbsp;·&nbsp;2021-11-05
            </span>
            <span className="tradeDetailAmountWrap">
              {ITEM && ITEM.AMOUNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
            </span>
            <span className="tradeDetailDataWrap02" dangerouslySetInnerHTML={{ __html: ITEM && ITEM.EQ_DESC }} />
            <span className="tradeDetailDataWrap">
              상태 : {ITEM && MODEL_STATUS[ITEM.MODEL_STATUS]}&nbsp;·&nbsp;
              구매년도 : {ITEM && ITEM.MODEL_YEAR}&nbsp;·&nbsp;
              상품명 : {ITEM && ITEM.MODEL_NAME}&nbsp;·&nbsp;
              장소 : {ITEM && ITEM.LOCATION}
            </span>
          </td>
        </tr>
      </table>
      <TradeComment
        REF_NO={DEAL_NO}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}>
        &nbsp;&nbsp;
        <Button
          onClick={() => history.push('/app/trade/')}
          className="ButtonStyle03"
          variant="contained">
          목록으로
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          className="ButtonStyle02"
          style={{ display: ITEM && ITEM.ADD_USER_ID === localStorage.getItem("USER_ID") ? '' : 'none' }}
          variant="contained"
          onClick={() => { history.push({ pathname: '/app/trade/update/' + ITEM.DEAL_NO, state: ITEM }) }}
        >
          수정
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          className={ITEM && ITEM.FINISH_YN === 'F' ? "ButtonStyle05" : "ButtonStyle02"}
          style={{ display: ITEM && ITEM.ADD_USER_ID === localStorage.getItem("USER_ID") ? '' : 'none' }}
          disabled={ITEM && ITEM.FINISH_YN === 'F' ? true : false}
          variant="contained"
          onClick={() => { FinishTrade() }}
        >
          판매완료
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          className={ITEM && ITEM.FINISH_YN === 'F' ? "ButtonStyle03" : "ButtonStyle04"}
          style={{ display: ITEM && ITEM.ADD_USER_ID === localStorage.getItem("USER_ID") ? 'none' : '' }}
          disabled={ITEM && ITEM.FINISH_YN === 'F' ? true : false}
          onClick={() => RequestOpen()}
          variant="contained">
          거래요청
        </Button>
      </Box>

      <Modal
        className={classes.modal}
        open={REQUEST}
        onClose={() => ReqeustClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={REQUEST}>
          <div className={classes.paper} style={{ textAlign: "center" }}>
            <Typography className="listTable_tt">
              거래 연결
            </Typography>
            <Card>
              <table className="srchTable">
                <tr className="tradeDetailTT">
                  <td>
                    {ITEM && ITEM.TITLE}
                    <span className="tradeDetailAmountWrap">
                      {ITEM && ITEM.AMOUNT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                    </span>
                    <span className="tradeDetailDataWrap">
                      {ITEM && ITEM.TEL_NO}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <textarea
                      className="NoteWrite_txtSection"
                      placeholder="거래에 관한 쪽지 내용을 작성해주세요"
                      onChange={(e) => { setNOTE(e.target.value) }}
                    />
                  </td>
                </tr>
              </table>
            </Card>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                className="ButtonStyle02"
                variant="contained"
                onClick={() => {
                  if (!window.confirm("쪽지를 보내시겠습니까?"))
                    return false;

                  const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
                    body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID'), NTT_CN: NOTE, RECEIVE_USER_ID: [ITEM && ITEM.ADD_USER_ID], REF_NO: ITEM && ITEM.DEAL_NO })
                  };
                  fetch(home_url + '/api/BRD110010SVC/save', requestOptions)
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                      if (data.DATA !== 1) {
                        alert(data.MSG);
                        return false;
                      }
                      ReqeustClose();
                    })
                }}>
                쪽지 보내기
              </Button>
            </Box>
            <Button className="ButtonStyle02" variant="contained" onClick={() => ReqeustClose()}>닫기</Button>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default TradeDetail;