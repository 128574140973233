import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { mockImgLogo } from '../../../../utils/mockImages';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import GetAppIcon from '@material-ui/icons/GetApp';

ProductCard.propTypes = {
  product: PropTypes.object.isRequired
};

export default function ProductCard({ product, ...rest }){

  const history = useHistory();
  const [files, setFiles] = useState([]);
  
  const getRandom = (min, max) => Math.floor(Math.random() * (max - min) + min);

  const {NTT_ID,BBS_ID, NTT_SJ } = product;

  // const callApi = () => {
  //   const requestFileOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({'ATCH_FILE_ID':ATCH_FILE_ID})
  //   };

  //   if(ATCH_FILE_ID != undefined && ATCH_FILE_ID != ""){
  //     fetch(home_url+'/api/BRD070010SVC/getSelectFileList', requestFileOptions)
  //     .then(function (response) { return response.json(); })
  //     .then(function (data) {
  //       var arry = [];

  //       resultFiles = data['DATA'];

  //       for(var n in resultFiles){
  //         arry.push(resultFiles[n]);
  //       }

  //       setFiles(arry);
  //     });
  //   }
  // }

  const handleBoardRowClick = (bbs_data) => {
    // alert('bbs_data : ', bbs_data, bbs_data['NTT_ID'],  {'bbs_id': { 'ntt_id': '19', 'bbs_id': bbs_data['BBS_ID'] } } );
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'] + '/' + bbs_data['NTT_ID'], state: bbs_data});
  };

  return (
    <Card  className="glybbs2_Wrap"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      {...rest}
    >
      <CardContent className="glybbs2_ImgWrap">
          <Avatar
            alt="Product"
            src={mockImgLogo(getRandom(1,25))}
            variant="square"
            className="glybbs2_Img"
          />
        <Typography className="glybbs2_name"
          align="center"
          color="textPrimary"
          onClick={() => handleBoardRowClick({NTT_ID, BBS_ID })}
          // gutterBottom
          // variant="h5"
        >
          {NTT_SJ}
        </Typography>
        {/* <Typography className="glybbs2_date"
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {product.NTT_CN}
        </Typography> */}
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box >
        <Grid 
          container
          // spacing={2}
          // sx={{ justifyContent: 'space-between' }}
          className="glybbs2_dadanWrap"
        >
          {/* <Grid style={{background:"#ff0000"}}
            item
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          > */}
            {/* <AccessTimeIcon color="action" /> */}
            <div className="glybbs2_dadan glybbs2_dadan_tt">
            <Typography className="glybbs2_dadan_ttFont"
              color="textSecondary"
              display="inline"
              // sx={{ pl: 1 }}
              // variant="body2"
            >
            신규등록업체
            </Typography>
            </div>
          {/* </Grid> */}
          <div  className="glybbs2_dadan">
          <Grid
            item
            // sx={{
            //   alignItems: 'center',
            //   display: 'flex'
            // }}
          >
            {/* <GetAppIcon color="action" /> */}
            <Typography
              color="textSecondary"
              display="inline"
              // sx={{ pl: 1 }}
              variant="body2"
            >
              시화공단
            </Typography>
          </Grid>
          </div>
        </Grid>
      </Box>
    </Card>
  );
}

