import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import {
    Box,
    Button,
    Checkbox,
} from '@material-ui/core';
import {
    TermsOfservice,
    PrivacyPolicy,
    LocationInformation,
    PromotionInformation
} from './PortalTermsText'
import { home_url } from '../../../js/globalProperties';

const PortalTerms = (props) => {
    console.log(props);
    const history = useHistory();
    const [isAgreeAll, setIsAgreeAll] = useState(false);
    const [isAgreeTermsOfservice, setIsAgreeTermsOfservice] = useState(false); //이용약관
    const [isAgreePrivacyPolicy, setIsAgreePrivacyPolicy] = useState(false); //개인정보 처리약관
    const [isAgreeLocationInformation, setIsAgreeLocationInformation] = useState(false); //위치정보 이용약관 동의
    const [isAgreePromotionInformation, setIsAgreePromotionInformation] = useState(false); //프로모션 정보 수신동의

    const moveRegister = () => {
        history.push({
            pathname: '/app/FUL/Register',
            state: {
                TERMS_USE: isAgreeTermsOfservice ? 'Y' : 'N',
                TERMS_PERSONAL: isAgreePrivacyPolicy ? 'Y' : 'N',
                TERMS_LOCATION: isAgreeLocationInformation ? 'Y' : 'N',
                TERMS_PROMOTION: isAgreePromotionInformation ? 'Y' : 'N',
            }
        })
    }
    const moveLogin = (props) => {
        const postTermOption = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                OPEN_API_YN: "Y",
                USER_ID: props.location.state.id,
                TERMS_USE: isAgreeTermsOfservice ? 'Y' : 'N',
                TERMS_PERSONAL: isAgreePrivacyPolicy ? 'Y' : 'N',
                TERMS_LOCATION: isAgreeLocationInformation ? 'Y' : 'N',
                TERMS_PROMOTION: isAgreePromotionInformation ? 'Y' : 'N',
            })
        };
        fetch(home_url + "/api/slpportal/postTerms", postTermOption)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                alert(data.MSG);
                if (data.TYPE === 1) {
                    let tempParam = { 'ID': props.location.state.id, 'PW': props.location.state.pw }
                    localStorage.setItem('tempLogin', JSON.stringify(tempParam));
                    history.push('/');
                } else return false;
            })
    }
    return (
        <>
            <div className="agreeTotal_Wrap">
                <Checkbox
                    checked={isAgreeAll}
                    onChange={(event) => {
                        setIsAgreeAll(event.target.checked);
                        setIsAgreeTermsOfservice(event.target.checked);
                        setIsAgreePrivacyPolicy(event.target.checked);
                        setIsAgreeLocationInformation(event.target.checked);
                        setIsAgreePromotionInformation(event.target.checked);
                    }}
                />
                <span className="agreeTt_Wrap03">
                    스마트물류플랫폼 이용약관, 개인정보 수집 및 이용, 위치정보 이용약관(선택), 프로모션 정보 수신(선택)에 모두 동의합니다.<br />
                </span>

                <Checkbox
                    checked={isAgreeTermsOfservice}
                    onChange={(event) => {
                        setIsAgreeTermsOfservice(event.target.checked);
                    }}
                />
                <span className="agreeTt_Wrap">
                    [이용약관]<br />
                </span>
                <Box>
                    <TermsOfservice />
                </Box>
                <Checkbox
                    checked={isAgreePrivacyPolicy}
                    onChange={(event) => {
                        setIsAgreePrivacyPolicy(event.target.checked);
                    }}
                />
                <span className="agreeTt_Wrap">
                    [개인정보 처리약관]<br />
                </span>
                <Box>
                    <PrivacyPolicy />
                </Box>
                <Checkbox
                    checked={isAgreeLocationInformation}
                    onChange={(event) => {
                        setIsAgreeLocationInformation(event.target.checked);
                    }}
                />
                <span className="agreeTt_Wrap">
                    [위치정보 이용약관 동의] (선택)<br />
                </span>
                <Box>
                    <LocationInformation />
                </Box>
                <Checkbox
                    checked={isAgreePromotionInformation}
                    onChange={(event) => {
                        setIsAgreePromotionInformation(event.target.checked);
                    }}
                />
                <span className="agreeTt_Wrap">
                    [프로모션 정보 수신동의] (선택)
                </span>
                <Box>
                    <PromotionInformation />
                </Box>
                <Box className="agreeBtn_Wrap">
                    <Button
                        onClick={() => { history.push('/'); }}
                        variant="contained"
                        className="ButtonStyle03"
                    >
                        취소
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        type="primary"
                        onClick={() => { if (props.location.state !== undefined) moveLogin(props); else moveRegister(); }}
                        className="ButtonStyle02"
                        style={{ display: isAgreeTermsOfservice && isAgreePrivacyPolicy ? "" : "none" }}
                    >다음</Button>
                    <Button
                        type="primary"
                        className="ButtonStyleDisabled"
                        style={{ display: isAgreeTermsOfservice && isAgreePrivacyPolicy ? "none" : "" }}
                        disabled
                    >다음</Button>
                </Box>
            </div>
        </>
    )
}

export default PortalTerms;