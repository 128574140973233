import { createSlice } from "@reduxjs/toolkit";

export const brdbd001Slice = createSlice({
  name: "brdbd001",
  initialState: {
    BBS_ID: '',
    BBS_NAME: '',
    BBS_INTRCN: '',
    BBS_TY_CODE: '1',
    REPLY_POSBL_AT: '',
    FILE_ATCH_POSBL_AT: 'Y',
    ATCH_POSBL_FILE_NUMBER: '1',
    ATCH_POSBL_FILE_SIZE: '0',
    TMPLAT_ID: '1',
    USE_AT: 'Y',
    WRITE_AUTH: '',
  },
  reducers: {
    registerBrdbd001: (state, { payload: brdbd001 }) => {
      console.log("게시글 등록 액션 호출 -- registerBrdbd001"); // saga에서 감시용
    },
    getBrdbd001: (state, { payload: id }) => {
      console.log("게시글 조회 액션 호출 -- getBrdbd001"); // saga에서 감시용
    },
    getBrdbd001Async: (state, { payload: brdbd001 }) => {
      console.log("saga에서 조회결과 데이터 리턴함 -- getBrdbd001Async");
      return {
        ...state,
        brdbd001: brdbd001,
        isSuccess: true,
        isLoading: false,
      };
    },
    getBrdbd001FailedAsync: (state, { payload: error }) => {
      console.log("saga에서 put 액션 호출 -- getBrdbd001FailedAsync");
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    },
    fetchBrdbd001: (state, { payload: id }) => {
      console.log("게시글 조회 액션 호출 -- fetchBrdbd001"); // saga에서 감시용
    },
    updateBrdbd001: (state, { payload: brdbd001 }) => {
      console.log("게시글 수정 액션 호출 -- updateBrdbd001"); // saga에서 감시용
    },
    deleteBrdbd001: (state, { payload: id }) => {
      console.log("게시글 삭제 액션 호출 -- deleteBrdbd001"); // saga 에서 감시용
    },
    changeRegisterInput: (state, { payload }) => {
      // console.log('payload.name : ' + payload.name);
      switch (payload.name) {
        case "BBS_ID":
          return {
            ...state,
            BBS_ID: payload.value,
          };
        case "BBS_NAME":
          return {
            ...state,
            BBS_NAME: payload.value,
          };

        case "BBS_INTRCN":
          return {
            ...state,
            BBS_INTRCN: payload.value,
          };
        case "BBS_TY_CODE":
          return {
            ...state,
            BBS_TY_CODE: payload.value,
          };
        case "REPLY_POSBL_AT":
          return {
            ...state,
            REPLY_POSBL_AT: payload.value,
          };
        case "FILE_ATCH_POSBL_AT":
          return {
            ...state,
            FILE_ATCH_POSBL_AT: payload.value,
          };
        case "ATCH_POSBL_FILE_NUMBER":
          return {
            ...state,
            ATCH_POSBL_FILE_NUMBER: payload.value,
          };
        case "ATCH_POSBL_FILE_SIZE":
          return {
            ...state,
            ATCH_POSBL_FILE_SIZE: payload.value,
          };
        case "TMPLAT_ID":
          return {
            ...state,
            TMPLAT_ID: payload.value,
          };
        case "USE_AT":
          return {
            ...state,
            USE_AT: payload.value,
          };
        case "WRITE_AUTH":
          return {
            ...state,
            WRITE_AUTH: payload.value,
          };

        default:
          break;
      }
    },
  },
});

export const brdbd001Reducers = brdbd001Slice.reducer;
export const brdbd001Actions = brdbd001Slice.actions;
