import NoteLeft from "./NoteLeft";
import NoteWrite from "./NoteWrite";
import NoteList from "./NoteList";
import NoteDetail from "./NoteDetail";
import NoteIgnoreList from "./NoteIgnoreList";
import NoteTradeList from "./NoteTradeList";
import { useState } from 'react';

function NoteMain() {
    const [noteMode, setNoteMode] = useState('getList');
    const [Note, setNote] = useState();
    const [Back, setBack] = useState();
    const [Receiver, setReceiver] = useState();
    const [REF_NO, setREF_NO] = useState();
    return (
        <>
            <div className="NoteSection_L">
                <NoteLeft noteMode={noteMode} setNoteMode={setNoteMode} />
            </div>
            <div className="NoteSection_R">
                {noteMode === 'writeNote' && <NoteWrite noteMode={noteMode} Receiver={Receiver} setNoteMode={setNoteMode} setREF_NO={setREF_NO} REF_NO={REF_NO} />} {/* 쪽지쓰기 */}
                {noteMode === 'writeNoteMyself' && <NoteWrite noteMode={noteMode} setNoteMode={setNoteMode} setREF_NO={setREF_NO} REF_NO={REF_NO} />} {/* 내게쓰기 */}
                {noteMode === 'updateNote' && <NoteWrite noteMode={noteMode} />} {/* 쪽지수정 */}
                {noteMode === 'getList' && <NoteList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 받은쪽지함 */}
                {noteMode === 'getListMyself' && <NoteList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 내게쓴쪽지함 */}
                {noteMode === 'sendList' && <NoteList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 보낸쪽지함 */}
                {noteMode === 'getSpamList' && <NoteList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 스팸쪽지함 */}
                {noteMode === 'noteBox' && <NoteList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 쪽지보관함 */}
                {noteMode === 'readNote' && <NoteDetail noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} Note={Note} Back={Back} setReceiver={setReceiver} setREF_NO={setREF_NO} />} {/* 쪽지보기 */}
                {noteMode === 'getIgnoreList' && <NoteIgnoreList noteMode={noteMode} />} {/* 차단목록 */}
                {noteMode === 'tradeGetList' && <NoteTradeList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 중고거래 수신 */}
                {noteMode === 'tradeSendList' && <NoteTradeList noteMode={noteMode} setBack={setBack} setNoteMode={setNoteMode} setNote={setNote} />} {/* 중고거래 발신 */}
            </div>
        </>
    )
}
export default NoteMain;