import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import {
  Typography
} from '@material-ui/core';
import cookies from 'react-cookies';
const PLT000101_12 = (props) => {

  const history = useHistory();
  

  const handleSubmit = (event) => {
   
   event.preventDefault();
  
  if( event.target.elements.password.value == ''){
    alert("비밀번호를 입력해주세요");
    return false;
  }

    const option = {
      url : home_url + '/api/slpportal/login', 
      method: 'POST', 
      headers:{ 
        'Content-type': 'application/json', 
        'Accept': 'application/json' 
        
      },
      data:{
        USER_ID: event.target.elements.user_id.value, 
        PW: event.target.elements.password.value,
        OPEN_API_YN: 'Y'
      } 
    }
      axios(option)
        .then(response => { 
          if(response.data.TYPE == -1){
              alert(response.data.MSG)
          }else if(response.data.TYPE == 1){
            history.push('/app/FUL/PLT000101_13');
          } 
        })

  }


    
 

          
  return (
    <>
    <form onSubmit = {handleSubmit}>
      <div className ="pwCertification">
        <Typography variant="h4">  비밀번호 재확인 </Typography>
        <Typography style = {{fontSize : '13px' ,color : '#a9a9a9', marginTop :'20px'}}> {localStorage.getItem("USER_NAME_LOC")}님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 한 번 입력해주기 바랍니다. </Typography>               
        <div className ='certificationSection'>
          <ul>
            <li><span className = 'pwSpan1'>아이디</span>
                <input className ='pwInput1' 
                       name = 'user_id' 
                       value={localStorage.getItem("USER_ID")} readOnly/> </li>
            <li><span className = 'pwSpan2'>비밀번호</span>
                <input className ='pwInput2' 
                       name = 'password' 
                       type = 'password'  
                       placeholder ='비밀번호를 입력해주세요' /></li>
          </ul>
        </div>
        <br />
      <button type ="submit"  className = 'changePwButton'>확인</button>
      </div>
    </form>
    </>
  );
}
export default PLT000101_12;