import PropTypes from 'prop-types';
// material
import {
  Grid,
  TablePagination
} from '@material-ui/core';
import TradeCard from './TradeCard';
import { useState, useEffect } from 'react';
import CommonToolbar from './TradeBottomToolbar';
import Page from './../CommonBoard/Page';
import { filter } from 'lodash';

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array.length === 0) return false;
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.NTT_CN.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TradeList = (props) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('ADD_DATE',);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [List, setList] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredTotalboards = applySortFilter(List, getComparator(order, orderBy), filterName);

  useEffect(() => {
    setList(props.ITEMS);
  }, [props])

  return (
    <>
      <Page>
        <Grid container spacing={3}>
          {List && List.length > 0 && filteredTotalboards
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <>
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <TradeCard ITEM={row} WISHLIST={props.WISHLIST} />
                  </Grid>
                </>
              )
            })}
          {List && List.length == 0 && (
            <>
              <Grid item xs={12} sm={12} md={12} class="MainThumNosrch">
                <span style={{}}>조회된 내역이 없습니다.</span>
              </Grid>
            </>
          )}
        </Grid>
        <div style={{ display: props.LOCATION === 'MAIN' ? 'none' : '' }}>
          <br /><hr /><br />
          <TablePagination
            rowsPerPageOptions={[20, 40, 60]}
            component="div"
            count={List.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="페이지 당 게시물 수 : "
          />
          <CommonToolbar
            numSelected={selected.length}
          />
        </div>
      </Page>
    </>
  );
}
export default TradeList;
