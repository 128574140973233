import { forwardRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";

import cookies from "react-cookies"
import { home_url } from "../../../js/globalProperties";
registerLocale("ko", ko);

const TradeToolbar = (props) => {
  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const selectDate = (date, setDate) => {
    return (
      <>
        <DatePicker
          locale="ko"
          selected={date}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(dates) => { setDate(dates) }}
          withPortal
          style={{ "padding": "0px" }}
          customInput={<CustomDatePicker />}
        />
      </>
    )
  }

  const [CATS, setCATS] = useState([]);

  const getCATS = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({ 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/MBP040010SVC/getCode', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.MBP040010G1) setCATS(data.MBP040010G1);
      })
  }

  useEffect(() => {
    getCATS();
  }, []);

  return (
    <Card>
      <CardContent className="tableSrchInput_Wrap">
        <table className="srchTable tradeTableTopLine_X">
          <colgroup>
            <col style={{ width: "14%" }}></col>
            <col style={{ width: "2%" }}></col>
            <col style={{ width: "14%" }}></col>
            <col style={{ width: "15%" }}></col>
            <col style={{ width: "35%" }}></col>
            <col style={{ width: "10%" }}></col>
            <col style={{ width: "10%" }}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                {/* <input type="text" name="" id="" className="tradeDateInput" placeholder="From_Date" onClick={() =>{selectDate()}}/> */}
                <div className="tradeDateInput tradeDatePicBg">{selectDate(props.FROMDATE, props.setFROMDATE)}</div>
              </th>
              <th>
                ~
              </th>
              <th>
                {/* <input type="text" name="" id="" className="tradeDateInput " placeholder="To_Date" /> */}
                <div className="tradeDateInput tradeDatePicBg">{selectDate(props.ENDDATE, props.setENDDATE)}</div>
              </th>
              <th>
                {/* <input type="text" name="" id="" className="tradeDateInput" placeholder="Category"/> */}
                <select
                  className="tradeDateInput"
                  value={props.CAT_NAME}
                  onChange={(e) => props.setCAT_NAME(e.target.value)}
                >
                  <option value="">전체</option>
                  {CATS && CATS.map((row) => (
                    <option
                      key={row.ENG_VALUE}
                      value={row.ENG_VALUE}
                    >
                      {row.LOC_VALUE}
                    </option>
                  ))}
                </select>
              </th>
              <th>
                <input
                  type="text"
                  name=""
                  id=""
                  value={props.KEYWORD_SEARCH}
                  className="tradeDateInput"
                  placeholder="검색어를 입력해주세요."
                  onChange={(e) => props.setKEYWORD_SEARCH(e.target.value)}
                  onKeyPress={
                    (e) => {
                      if (e.key === 'Enter') {
                        { props.getList() }
                      }
                    }}
                />
              </th>
              <th>
                <Button
                  className="NoteAllow_bluBtn"
                  variant="contained"
                  onClick={() => { props.getList() }}
                >
                  조회
                </Button>
              </th>
              <th>
                <Button
                  className="NoteAllow_Btn"
                  variant="contained"
                  onClick={() => {
                    props.setKEYWORD_SEARCH('');
                    props.setFROMDATE(new Date('2021-01-01'));
                    props.setENDDATE(new Date());
                  }}>
                  초기화
                </Button>
              </th>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  )
};

export default TradeToolbar;
