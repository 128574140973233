import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
	name: "login",
	initialState: {
		user_id: '',
		user_name_loc: '',
		user_name_eng: '', 
		email: '',
		tel_no: '',
		mobile_no: '',
	},
	reducers: {
		getUserInfo: (state, { payload: id }) => {
			console.log("userinfo action"); // saga에서 감시용
		},
		getUserInfoAsync: (state, {payload: login}) => {
			console.log("saga에서 조회결과 데이터 리턴함 -- getUserInfoAsync");
			return {
				...state,
				login: login,
				isSuccess: true,
				isLoading: false,
			};
		},
		getLoginFail: (state, {payload: error}) => {
			console.log("login fail ", error);
			return {
				...state,
				isLoading: false,
				error: error,
			};
		}
	}
});

export const loginReducers = loginSlice.reducer;
export const loginActions = loginSlice.actions;

