import React from "react";
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { loginActions } from "../../../../slice/loginSlice";
import { home_url } from '../../../../js/globalProperties';
import GoogleLogin from '../SnsLogin/GoogleLogin';
import $ from 'jquery'
import PortalSession from "./PortalSession";
window.$ = $;

function PortalHead() {
  const history = useHistory();
  const location = useLocation();

  const goBoard = (bbs_data) => {
    console.log('bbs_data: ', bbs_data);
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'], state: bbs_data });
  };

  const goPage = (bbs_data) => {
    console.log('bbs_data: ', bbs_data);
    history.push({ pathname: '/app/' + bbs_data["PGE_ID"] });
  };

  const mainPage = () => {
    history.push('/');
  }
  return (
    <div>
      <div className="total_wrap">
        <div className="header_wrap">
          <div className="header_forposition">
            <div className="header">
              <a className="header_logo" onClick={mainPage} style={{ cursor: "pointer" }}><img src={require("../../../../images/slp_logo.png").default} className="" /></a>
              <div className="right_menu userLoginOnOff" style={{ display: location.pathname.startsWith("/app/FUL/") ? '' : 'none' }}>
                <img src={require("../../../../images/user_icon.png").default} className="" />
                <span className="user_name headerUser_nm" name='user_name' id=''>{localStorage.getItem('USER_NAME_LOC') ? localStorage.getItem('USER_NAME_LOC') : null}</span>
                <span className="headerUser_nim login_on"></span>
              </div>
              <div className="userLoginPop">
                {location.pathname.split('/')[2] === 'FUL' && <PortalSession />}
              </div>

            </div>
          </div>
        </div>

        <div className="menu_wrap" id="menu_wrap">
          <div className="menu">
            <div className="menuMain02" id="menu01">플랫폼</div>
            <div className="menuMain02" id="menu02">첨단장비</div>
            <div className="menuMain02" id="menu03">빅데이터</div>
            <div className="menuMain02" id="menu04">디지털트윈</div>
            <div className="menuMain02" id="menu05">뉴스</div>
            <div className="menuMain02" id="menu06">고객센터</div>
            <div className="menuMainAll02">전체보기</div>


          </div>
        </div>
        <div className="main_submenu_center" id="main_submenu_center">
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu01">
              <a onClick={() => goPage({ PGE_ID: "PLT010101" })} className="submenu"><span>-</span> 개요  </a>
              <a onClick={() => goPage({ PGE_ID: "PLT010201" })} className="submenu"><span>-</span> 목표,비전 </a>
              <a onClick={() => goPage({ PGE_ID: "PLT010301" })} className="submenu"><span>-</span> 사업내용 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090712000060" })} className="submenu"><span>-</span> 구축사례 </a>
              <a onClick={() => goPage({ PGE_ID: "PLT010501" })} className="submenu"><span>-</span> 물류협의체 </a>
              <a onClick={() => goPage({ PGE_ID: "PLT010601" })} className="submenu"><span>-</span> 특허 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu02">
              <a onClick={() => goPage({ PGE_ID: "PLT030101" })} className="submenu"><span>-</span> 실증센터 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210721060754000082" })} className="submenu"><span>-</span> 컨설팅 문의 </a>
              <a onClick={() => goPage({ PGE_ID: "PLT030301" })} className="submenu"><span>-</span> AGV </a>
              <a onClick={() => goPage({ PGE_ID: "PLT030401" })} className="submenu"><span>-</span> 멀티셔틀 </a>
              <a onClick={() => goPage({ PGE_ID: "PLT030501" })} className="submenu"><span>-</span> 스마트팩토리 솔루션 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu03">
              <a onClick={() => goPage({ PGE_ID: "PLT020101" })} className="submenu"><span>-</span> 통합대시보드 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu04">
              <a onClick={() => { localStorage.getItem('LoginType') ? goPage({ PGE_ID: "FUL/PLT000101_05" }) : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 디지털트윈 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu05">
              <a onClick={() => goBoard({ BBS_ID: "20210721060703000080" })} className="submenu"><span>-</span> 물류,산업 뉴스 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210721060722000081" })} className="submenu"><span>-</span> 산단소식 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090723000069" })} className="submenu"><span>-</span> 일정게시판 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu06">
              <a onClick={() => goBoard({ BBS_ID: "20210714090736000056" })} className="submenu"><span>-</span> 공지사항 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090715000057" })} className="submenu"><span>-</span> 자주하는 질문 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090706000058" })} className="submenu"><span>-</span> 질문게시판 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210721030707000078" })} className="submenu"><span>-</span> 견적문의 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu07">
              <a onClick={() => goBoard({ BBS_ID: "20210715020701000075" })} className="submenu"><span>-</span> 중고거래(구매/판매)  </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu08">

            </div>
          </div>

          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu09">
              <a onClick={() => goBoard({ BBS_ID: "20210714090756000066" })} className="submenu"><span>-</span> 구인/구직 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu10">
              <a onClick={() => goBoard({ BBS_ID: "20210729050704000101" })} className="submenu"><span>-</span> 동호회 소개 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210729050735000102" })} className="submenu"><span>-</span> 맛집 소개 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210729050745000103" })} className="submenu"><span>-</span> 숙소 소개 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210729050745000103" })} className="submenu"><span>-</span> 반월시화 대나무 숲 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu11">
              <a onClick={() => goBoard({ BBS_ID: "20210708100737000048" })} className="submenu"><span>-</span> 회사소개 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210715110704000074" })} className="submenu"><span>-</span> 제품소개 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu12">
              <a to="/app/totalboard" className="submenu"><span>-</span> 통합게시판  </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090728000063" })} className="submenu"><span>-</span> 관리자 기능 </a>
              <a onClick={() => goBoard({ BBS_ID: "20210714090723000064" })} className="submenu"><span>-</span> 권한설정 </a>
            </div>
          </div>
          <div className="menuMain02 submenu_Oneloca02">
            <div className="submenu_Onewrap" id="submenu13">
            </div>
          </div>


          {/* 전체보기   */}
          <div className="menuMainAll submenu_Oneloca01"></div>
          <div className="main_submenu_wrap">
            <table>
              <tbody>
                <tr>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">플랫폼</span>
                    <a onClick={() => goPage({ PGE_ID: "PLT010101" })} className="submenu"><span>-</span> 개요  </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT010201" })} className="submenu"><span>-</span> 목표,비전 </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT010301" })} className="submenu"><span>-</span> 사업내용 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090712000060" })} className="submenu"><span>-</span> 구축사례 </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT010501" })} className="submenu"><span>-</span> 물류협의체 </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT010601" })} className="submenu"><span>-</span> 특허 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">물류관리</span>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 발주관리 </a>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 운송관리 </a>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 창고관리 </a>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 포워딩관리 </a>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 정산관리 </a>
                    <a onClick={() => { localStorage.getItem('LoginType') ? window.open("https://info.myslp.kr/") : alert("로그인 후 이용 가능합니다.") }} className="submenu"><span>-</span> 발주관리 </a>
                    <a onClick={() => goPage({ PGE_ID: "FUL/PLT000101_11" })} className="submenu"><span>-</span> IOT관제 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">첨단장비</span>
                    <a onClick={() => goPage({ PGE_ID: "PLT030101" })} className="submenu"><span>-</span> 실증센터 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210721060754000082" })} className="submenu"><span>-</span> 컨설팅 문의 </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT030301" })} className="submenu"><span>-</span> AGV </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT030401" })} className="submenu"><span>-</span> 멀티셔틀 </a>
                    <a onClick={() => goPage({ PGE_ID: "PLT030501" })} className="submenu"><span>-</span> 스마트팩토리 솔루션 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">빅데이터</span>
                    <a onClick={() => goPage({ PGE_ID: "PLT020101" })} className="submenu"><span>-</span> 통합대시보드 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">디지털트윈</span>
                    <a onClick={() => goPage({ PGE_ID: "FUL/PLT000101_05" })} className="submenu"><span>-</span> 디지털트윈 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">뉴스</span>
                    <a onClick={() => goBoard({ BBS_ID: "20210721060703000080" })} className="submenu"><span>-</span> 물류,산업 뉴스 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210721060722000081" })} className="submenu"><span>-</span> 산단소식 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090723000069" })} className="submenu"><span>-</span> 일정게시판 </a>
                  </td>
                  <td className="main_submenu_single02 main_submenu_R_X">
                    <span className="main_submenuTotalTT">고객센터</span>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090736000056" })} className="submenu"><span>-</span> 공지사항 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090715000057" })} className="submenu"><span>-</span> 자주하는 질문 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090706000058" })} className="submenu"><span>-</span> 질문게시판 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210721030707000078" })} className="submenu"><span>-</span> 견적문의 </a>
                  </td>
                </tr>
                <tr>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">중고거래</span>
                    <a onClick={() => history.push("/app/trade")} className="submenu"><span>-</span> 중고거래  </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">구인구직</span>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090756000066" })} className="submenu"><span>-</span> 구인 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210820010819000108" })} className="submenu"><span>-</span> 구직 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">커뮤니티</span>
                    <a onClick={() => goBoard({ BBS_ID: "20210729050704000101" })} className="submenu"><span>-</span> 동호회 소개 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210729050735000102" })} className="submenu"><span>-</span> 맛집 소개 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210729050745000103" })} className="submenu"><span>-</span> 숙소 소개 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210714090704000071" })} className="submenu"><span>-</span> 반월시화 대나무 숲 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">소개</span>
                    <a onClick={() => goBoard({ BBS_ID: "20210708100737000048" })} className="submenu"><span>-</span> 회사소개 </a>
                    <a onClick={() => goBoard({ BBS_ID: "20210715110704000074" })} className="submenu"><span>-</span> 제품소개 </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">게시판</span>
                    <a onClick={() => history.push("/app/totalboard")} className="submenu"><span>-</span> 통합게시판  </a>
                  </td>
                  <td className="main_submenu_single02">
                    <span className="main_submenuTotalTT">기타</span>
                    <a onClick={() => window.open('https://data.myslp.kr/index.html')} className="submenu"><span>-</span> 오픈 API </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </div>
  );
}
export default PortalHead;