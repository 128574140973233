import { React, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from '@fullcalendar/list';
import { home_url } from './../../../../js/globalProperties';
import { Typography, Button, Box } from '@material-ui/core';

// import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.css'; // needs additional webpack config!
// import bootstrapPlugin from '@fullcalendar/bootstrap';

const CommonCalander = (props) => {
    const [list, setList] = useState([]);
    console.log("props : ", props);
    const BBS = props.BBS;

    const getList = () => {
        let arr = [];
        const requestOption = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'BBS_ID': props.BBS.BBS_ID, 'OPEN_API_YN': 'Y' })
        };
        fetch(home_url + '/api/BRD030010SVC/getSelectBbsList', requestOption)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                console.log("list : ", data.DATA);
                for (let i in data.DATA) {
                    arr.push({
                        start: data.DATA[i].START_TIME,
                        end: data.DATA[i].END_TIME,
                        title: data.DATA[i].NTT_SJ,
                        allDay: data.DATA[i].ALLDAY_YN === 'Y' ? true : false,
                        color: '#f38a30',
                        id: data.DATA[i].NTT_ID,
                    })
                }
            })
            .then(function () {
                setList(arr);
            });
    }

    const history = useHistory();

    const handleRegisterPage = (bbs_data) => {
        if (localStorage.getItem('USER_AUTH') === 'A') {
            console.log("bbs_data : ", bbs_data);
            history.push({ pathname: '/app/board/register', state: bbs_data });
        } else return false;
    };

    const renderEventContent = (eventInfo) => {
        console.log("eventInfo : ", eventInfo)
        return (
            <>
                <div
                    onClick={() => history.push({ pathname: '/app/board/' + props.BBS.BBS_ID + '/' + eventInfo.event.id })}
                    style={{ marginLeft: '1px', marginRight: '1px', cursor: 'pointer' }}
                >
                    <b style={{ marginRight: eventInfo.timeText !== '' && '2px' }}>{eventInfo.timeText}</b>
                    <span>{eventInfo.event.title}</span>
                </div>
            </>
        )
    }
    useEffect(() => {
        getList();
    }, [props.BBS])
    return (
        <>
            <Typography className="listTable_tt">
                {props.BBS.BBS_NAME}
            </Typography>
            <hr />
            <br />
            <FullCalendar
                locale='ko'
                headerToolbar={{
                    start: 'prev', // will normally be on the left. if RTL, will be on the right
                    center: 'title',
                    end: 'next' // will normally be on the right. if RTL, will be on the left
                }}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                eventContent={renderEventContent}
                // dateClick={() => handleRegisterPage({ BBS })}
                events={list}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                    hour12: false
                }}
            />
            <Typography className="listTable_tt">
                이번주 일정
            </Typography>
            <hr />
            <br />

            <FullCalendar
                locale='ko'
                headerToolbar={{
                    start: '', // will normally be on the left. if RTL, will be on the right
                    center: '',
                    end: '' // will normally be on the right. if RTL, will be on the left
                }}
                plugins={[listPlugin, interactionPlugin]}
                initialView="listWeek"
                eventContent={renderEventContent}
                events={list}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                    hour12: false
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}
            >
                <Button
                    type="primary"
                    className="ButtonStyle02"
                    color="primary"
                    variant="contained"
                    onClick={() => { handleRegisterPage({ BBS }) }}>
                    일정등록
                </Button>
            </Box>
        </>
    )
}
export default CommonCalander;