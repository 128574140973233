import { useEffect, useState } from 'react';
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';
import { Button, Box } from '@material-ui/core';


function NoteDetail(props) {
    const ignoreOrSpam = (p) => {
        if (p === "S") {
            if (!window.confirm(props.Note.ADD_USER_NAME + "님을 스팸으로 등록하시겠습니까?"))
                return false;
        }
        if (p === "I") {
            if (!window.confirm(props.Note.ADD_USER_NAME + "님을 차단하시겠습니까?"))
                return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID'), 'TARGET_USER_ID': props.Note.ADD_USER_ID, "TYPE": p })
        };
        fetch(home_url + '/api/BRD130010SVC/save', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.DATA !== 1) {
                    alert(data.MSG);
                    return false;
                }
                props.setNoteMode('getList');
            })
    }

    const cancleSpam = () => {
        if (!window.confirm("스팸등록을 취소하시겠습니까?"))
            return false;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'ADD_USER_ID': localStorage.getItem('USER_ID'), 'TARGET_USER_ID': props.Note.ADD_USER_ID, "TYPE": "S" })
        };
        fetch(home_url + '/api/BRD130010SVC/get', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (!data.DATA) {
                    alert(data.MSG);
                    return false;
                } else {
                    let ntt_id = data.DATA[0].NTT_ID;
                    requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
                        body: JSON.stringify({ "NTT_ID": ntt_id })
                    };

                    fetch(home_url + '/api/BRD130010SVC/delete', requestOptions)
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                            if (data.DATA !== 1) {
                                alert(data.MSG);
                                return false;
                            }
                            props.setNoteMode('getSpamList');
                        })
                }
            })
    }

    const deleteNote = () => {
        if (!window.confirm("삭제하시겠습니까?")) return false;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'NTT_ID': props.Note.NTT_ID })
        };
        fetch(home_url + '/api/BRD120010SVC/delete', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.DATA !== 1) {
                    alert(data.MSG);
                    return false;
                }
                props.setNoteMode('getList');
            })
    }

    const updateReadYN = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'NTT_ID': props.Note.NTT_ID, 'READ_YN': 'Y' })
        };
        fetch(home_url + '/api/BRD120010SVC/update', requestOptions)
            .then(function (response) { return response.json(); })
    }

    const storeNote = (status) => {
        if (!window.confirm("보관함으로 이동시키겠습니까?")) return false;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'NTT_ID': props.Note.NTT_ID, 'USE_AT': status })
        };
        fetch(home_url + '/api/BRD120010SVC/update', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.DATA !== 1) {
                    alert(data.MSG);
                    return false;
                }
                props.setNoteMode('getList');
            })
    }

    useEffect(() => {
        updateReadYN();
    }, [props]);

    return (
        <>
            <div style={{ width: "100%" }}>
                <table className="srchTable">
                    <colgroup>
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "30%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "30%" }} />
                    </colgroup>
                    <tr>
                        <th>작성일</th>
                        <td colSpan="4">{props.Note.ADD_DATE}</td>
                    </tr>
                    <tr style={{ display: props.Note.ADD_USER_ID === localStorage.getItem("USER_ID") ? 'none' : '' }}>
                        <th>보낸사람</th>
                        <td colSpan="4">
                            {props.Note.ADD_USER_NAME} ({props.Note.ADD_USER_ID})
                        </td>
                    </tr>
                    <tr style={{ display: props.Back === 'sendList' ? '' : 'none' }}>
                        <th>받는사람</th>
                        <td colSpan="4">
                            {props.Note.USER_NAME_LOC}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" minRows="30" className="NoteDetail_txtSection">
                            <div className="editor-preview">
                                <div style={{ 'fontSize': '15px', padding: '20px' }} dangerouslySetInnerHTML={{ __html: props.Note.NTT_CN }}></div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}
            >
                <Button className="ButtonStyle02 NoteBtn_margin" variant="contained" onClick={() => { props.setNoteMode(props.Back) }}>
                    목록
                </Button>
                <Button className="ButtonStyle02 NoteBtn_margin" variant="contained" onClick={() => {
                    props.setNoteMode('writeNote');
                    props.setBack(props.NoteMode);
                    props.setReceiver(props.Note.ADD_USER_ID);
                    props.setREF_NO(props.Note.REF_NO);
                }}
                    style={{ display: props.Note.ADD_USER_ID === localStorage.getItem('USER_ID') ? 'none' : '' }}>
                    답장
                </Button>
                <Button className="ButtonStyle02 NoteBtn_margin" variant="contained"
                    onClick={() => { storeNote('S') }}
                    style={{ display: props.Note.ADD_USER_ID === localStorage.getItem('USER_ID') || props.Back === 'noteBox' ? 'none' : '' }}>
                    보관
                </Button>
                <Button className="ButtonStyle02 NoteBtn_margin" variant="contained"
                    onClick={() => { storeNote('Y') }}
                    style={{ display: props.Back === "noteBox" ? '' : 'none'  }}>
                    보관해제
                </Button>
                <Button className="ButtonStyle03 NoteBtn_margin" variant="contained"
                    onClick={() => { ignoreOrSpam('I') }}
                    style={{ display: props.Note.ADD_USER_ID === localStorage.getItem('USER_ID') ? 'none' : '' }}>
                    차단
                </Button>
                <Button className="ButtonStyle03 NoteBtn_margin" variant="contained"
                    onClick={() => { ignoreOrSpam('S') }}
                    style={{ display: props.Note.ADD_USER_ID === localStorage.getItem('USER_ID') || props.Back === "getSpamList"? 'none' : '' }}>
                    스팸
                </Button>
                <Button className="ButtonStyle03 NoteBtn_margin" variant="contained" onClick={() => { deleteNote() }}>삭제</Button>
                <Button className="ButtonStyle03 NoteBtn_margin" variant="contained"
                    onClick={() => { cancleSpam() }}
                    style={{ display: props.Back === "getSpamList" ? '' : 'none' }}
                >
                    스팸해제
                </Button>
            </Box>
        </>
    )
}
export default NoteDetail;