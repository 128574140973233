import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

function PortalDetail(props) {
  return (
    <div>
      테스트 PortalDetail
    </div>
  );
}

export default PortalDetail;
