import React, { useCallback } from "react";
// import { Button, Input } from "antd";
import { Link, } from "react-router-dom";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@material-ui/core';

const states = [
  {
    value: 'Y',
    label: '사용'
  },
  {
    value: 'N',
    label: '미사용'
  }
];

const bbs_type_cd = [
  {
    value: '1',
    label: '게시판형 1'
  },
  {
    value: '2',
    label: '게시판형 2'
  },
  {
    value: '3',
    label: '갤러리형 1'
  },
  {
    value: '4',
    label: '갤러리형 2'
  }
];

const tmplat_cd = [
  {
    value: '1',
    label: '블루'
  },
  {
    value: '2',
    label: '레드'
  },
  {
    value: '3',
    label: '그린'
  },
  {
    value: '4',
    label: '그레이'
  }
];

function DetailOrEdit(props) {
  return ( 
    <>
      <form onSubmit={props.handleSubmit}
        // autoComplete="off"
        // noValidate
        encType="multipart/form-data"
        {...props}>
        <Typography className="listTable_tt">
        {/* {props.BBS==undefined?"게시물":props.BBS.BBS_NAME} - 상세보기 */}
                      입주기업
        </Typography>
        <Divider/>
          <div className="corp_name">
            (주)동양봉제기계
          </div>
          <div className="corp_ttWrap">
             <span className="corp_ttIcon"></span>         
                              회사개요
          </div>
          <div className="corp_summaryWrap">
            <div className="corp_summaryImg">
              <img src={require("../../../../images/junggoproduct.jpg").default} className="" />
            </div>
            <div className="corp_summaryTabWrap">  
            <table className="srchTable01">
              <colgroup>
                <col style={{width:"28%"}} />
                <col style={{width:"72%"}} />
              </colgroup>  
              <tr>
                <th>공장명</th>
                <td>
                  (주)동양봉제기계
                </td>
              </tr>
              <tr>
                <th>업종명</th>
                <td>
                                         기타섬유, 의복 및 가죽 가공 기계 제조업
                </td>
              </tr>
              <tr>                
                <th>업종코드</th>
                <td>
                  29269
                </td>
              </tr>
              <tr>                
                <th>주소</th>
                <td>
                                      경기도 포천시 금강로 2927(내촌면) 1층
                </td>
              </tr>
              <tr>                
                <th>연락처</th>
                <td>
                  031-531-3579
                </td>
              </tr>
              <tr>                
                <th>홈페이지</th>
                <td>
                  www.lastar.co.kr
                </td>
              </tr>
              <tr>                
                <th>건축면적</th>
                <td>
                  1,089미터제곱
                </td>
              </tr>
              <tr>                
                <th>용지면적</th>
                <td>
                  3,051미터제곱
                </td>
              </tr>
              <tr>                
                <th>종업원수</th>
                <td>
                  8명
                </td>
              </tr>
              <tr>                
                <th>기업구분</th>
                <td>
                                       소기업
                </td>
              </tr>
              <tr>                
                <th>기본정보 수정일</th>
                <td>
                  2017.07.01
                </td>
              </tr>
            </table>
            </div>
          </div>
          <div className="corp_ttWrap">
             <span className="corp_ttIcon"></span>         
                              회사연혁
          </div>
          <div className="corp_historyWrap">
            <span className="corp_historyTT">2021</span>
            <table className="corp_historyTable">
              <tr> 
                <td> 
                  <b>02</b> 이범택배산업 인수 
                </td>
              </tr>
              <tr>
                <td>
                  <b>01</b> 박성곤 대표이사 취임
                </td>
              </tr>
              <tr>
                <td>
                  <b>01</b> 2021년 고용노동부 청연 친화 강소기업 선정
                </td>
              </tr>
            </table>
          </div>
          <div className="corp_historyWrap">
            <span className="corp_historyTT">2020</span>
            <table className="corp_historyTable">
              <tr> 
                <td> 
                  <b>11</b> 경기시화산업단지 스마트물류플랫폼 구축 및 운영사업 사업자 선정 
                </td>
              </tr>
              <tr>
                <td>
                  <b>07</b> 대한믹국 일자리 으뜸기업선정(2년 연속)
                </td>
              </tr>
              <tr>
                <td>
                  <b>04</b> 고객사 전용 물류시스템 오픈
                </td>
              </tr>
              <tr>
                <td>
                  <b>03</b> 국제 화물 포워딩 연합 WCA가입
                </td>
              </tr>
            </table>
          </div>
          <div className="corp_historyWrap">
            <span className="corp_historyTT">2019</span>
            <table className="corp_historyTable">
              <tr> 
                <td> 
                  <b>12</b> 안산산단 특허보세창고 설립<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;일자리 창출 정부 포상<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수원상공회의소 기업대상(인재육성부문) 수상<br/> 
                </td>
              </tr>
              <tr>
                <td>
                  <b>10</b> ISO 13485 인증
                </td>
              </tr>
              <tr>
                <td>
                  <b>07</b> 대한민국 일자리 으뜸기업선정
                </td>
              </tr>
              <tr>
                <td>
                  <b>03</b> 사업관리 우수업체 선정
                </td>
              </tr>
              <tr>
                <td>
                  <b>02</b> AEO 진흥협회 우수회원사 포상 수상
                </td>
              </tr>
            </table>
          </div>
          <div className="corp_ttWrap">
             <span className="corp_ttIcon"></span>         
                              제품소개
          </div>
          <div className="corp_productWrap">  
            <table className="srchTable">
              <colgroup>
                <col style={{width:"20%"}} />
                <col style={{width:"80%"}} />
              </colgroup>  
              <tr>
                <th>생산품명</th>
                <td>
                  GF 표준형 단조휠(SSW-Q1R)  f8f9fb
                </td>
              </tr>
              <tr>
                <th>규격</th>
                <td>
                  GF 400, 450, 500, 550 등
                </td>
              </tr>
              <tr>
                <th>생산가능수준</th>
                <td>
                  주문휠 전문제작 표준휠 다량보유
                </td>
              </tr>
            </table>
          </div>
          <div className="corp_productImgWrap">
            <img src={require("../../../../images/junggoproduct01.jpg").default} className="" />
            <img src={require("../../../../images/junggoproduct01.jpg").default} className="" />
            <span className="corp_productTxt">단조휘, 주강휠, 소형휠, 방폭/크린룸 휠 제작</span>
          </div>
          <div className="corp_ttWrap">
             <span className="corp_ttIcon"></span>         
                              Contact
          </div>
          <div>
            <table >
              <colgroup>
                <col style={{width:"8%"}} />
                <col style={{width:"23%"}} />
                <col style={{width:"8%"}} />
                <col style={{width:"23%"}} />
                <col style={{width:"8%"}} />
                <col style={{width:"30%"}} />
              </colgroup>  
              <tr>
                <td className="corp_contactIcon">
                  <img src={require("../../../../images/contact_img01.jpg").default} className="" />
                </td>
                <td className="corp_contactTxt">
                  <b>대표전화<br/></b>
                  031-531-3579
                </td>
                <td className="corp_contactIcon">
                  <img src={require("../../../../images/contact_img02.jpg").default} className="" />
                </td>
                <td className="corp_contactTxt">
                  <b>E-mail<br/></b>
                  master@lastar.co.kr
                </td>
                <td className="corp_contactIcon">
                  <img src={require("../../../../images/contact_img03.jpg").default} className="" />
                </td>
                <td className="corp_contactTxt">
                  <b>주소<br/></b>
                                        경기도 포천시 금강로 2927 (내촌면) 1층
                </td>
              </tr>
            </table>
          </div>
      </form>
    </>
  );
}

export default DetailOrEdit;
