import {
    Card,
    Box,
    Button,
    Checkbox,
    TextField,
    Typography,
    Divider,
} from '@material-ui/core';
import { home_url } from '../../../js/globalProperties';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router';

const PortalFindSession = () => {
    const history = useHistory();
    const [ID,setID] = useState('');
    const [EMAIL,setEMAIL] = useState('');

    const findPW = (id,email) => {
        if(id === ''){
            alert('아이디를 확인해주세요.');
            return false;
        }

        if(email === ''){
            alert('이메일을 확인해주세요.');
            return false;
        }

        try {
          let requestOptions = { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "OPEN_API_YN":"Y",
                "USER_ID":id,
                "EMAIL":email
            })
          };
          fetch(home_url+'/api/slpportal/findPassword ', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
              alert(data.MSG);
              if(data.TYPE === 1) 
                history.push('/');
              else 
                return false;
          });
        } catch(error) {
          console.log(error);
        }
    }

    useEffect(()=>{
        if(localStorage.getItem("USER_ID") !== null){
            alert("로그아웃 이후에 이용해주세요!");
            history.push('/');
        }
    })

    return (
        <>
            <Typography className="listTable_tt">
                비밀번호 찾기
            </Typography>

            <div className="common_totalWrap06">
                <div className="pwSrch_Wrap">
                    <span className="agreeTt_Wrap02">아이디</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        // name="USER_ID"
                        type="string"
                        required
                        onChange = {(e) => setID(e.target.value)}
                        onKeyPress={
                            (e) => {
                                if (e.key === 'Enter') {
                                    findPW(ID,EMAIL);
                                }
                            }}
                    />
                    <span className="pwSrchTt_Wrap04">※ 가입시 입력하신 아이디를 입력해주세요.</span>

                    <span className="agreeTt_Wrap02">이메일</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        // name="PW"
                        type="string"
                        required
                        onChange = {(e) => setEMAIL(e.target.value)}
                        onKeyPress={
                            (e) => {
                                if (e.key === 'Enter') {
                                    findPW(ID,EMAIL);
                                }
                            }}
                    />
                    <span className="pwSrchTt_Wrap04">※ 가입시 입력하신 이메일을 입력해주세요.</span>
                </div>
            
            <Box className="agreeBtn_Wrap">
                <Button
                    variant="contained"
                    className="ButtonStyle03"
                    onClick={() => { history.push('/');}}
                >
                    취소
                </Button>
                &nbsp;&nbsp;
                <Button
                    type="primary"
                    className="ButtonStyle02"
                    onClick={() => findPW(ID,EMAIL)}
                >
                    비밀번호 찾기
                </Button>
            </Box>
            </div>
        </>
    )
}

export default PortalFindSession;