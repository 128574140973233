import { Button } from '@material-ui/core';
import { isNumeral } from 'numeral';
import { useEffect, useState } from "react";
import cookies from 'react-cookies';
import { home_url } from '../../../../js/globalProperties';



function ReserveEvent(props) {
    const [Event,setEvent] = useState([]);
    const [Loc,setLoc] = useState([]);
    const [Date,setDate] = useState([]);
    const [Time,setTime] = useState([]);
    const [AttendInfo,setAttendInfo] = useState();
    const [With,setWith] = useState(0);
    const [Event_no,setEvent_no] = useState("");
    const [Period,setPeriod] = useState("");
    const [Branch_code,setBranch_code] = useState("");



   
    const getEvent = () =>{
        if(props.USER_ID == null){
            alert("로그인을 먼저 해주세요.");
            props.setReserve(false);
        }else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
                body: JSON.stringify({ EVENT_NO: props.EVENT_NO})
              };
              fetch(home_url + '/api/BRD110010SVC/getEventList', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) { if (data != null) {
                    setEvent(data.DATA);
                    if(props.EVENT_NO != ''){
                        getLoc(props.EVENT_NO);
                        setEvent_no(props.EVENT_NO);
                    }
                } });     
        }

    }
    const getLoc = (param) => {
        if(Event_no != ''){
            // if(Event_no != param){
            //     if(!window.confirm("이벤트를 변경하면 설정이 초기화됩니다.")) {
            //             return false;
            //     }else{
                    setDate([]);
                    setTime([]);
                    setAttendInfo();  
                    setBranch_code('');
                    setPeriod([]);
                // }
            // }
           
        }
      
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ EVENT: param })
          };
          fetch(home_url + '/api/BRD110010SVC/getLoc', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) { if (data != null) {
                setLoc(data.DATA);
            } 
            setEvent_no(param);
           });



    }

    const getDate = (event_no ,branch_code) => {
        if(Branch_code != ''){
            // if(Branch_code != branch_code){
            //     if(!window.confirm("장소를 변경하면 설정이 초기화됩니다.")) {
            //             return false;
            //     }else{
                    setTime([]);
                    setAttendInfo();  
                    setPeriod([]);
            //     }
            // }
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ EVENT_NO: event_no , BRANCH_CODE : branch_code })
          };
          fetch(home_url + '/api/BRD110010SVC/getDate', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) { if (data != null) {
                setDate(data.DATA);
            } 
            setBranch_code(branch_code);
           });
    }

    const getTime = (event_no , period ,branch_code) =>{
        if(Period != ''){
            // if(Period != period){
            //     if(!window.confirm("날짜를 변경하면 설정이 초기화됩니다.")) {
            //             return false;
            //     }else{
                    setAttendInfo();  
            //     }
            // }
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ EVENT_NO : event_no , PERIOD: period , BRANCH_CODE : branch_code})
          };
          fetch(home_url + '/api/BRD110010SVC/getTime', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) { if (data != null) {
                setTime(data.DATA);
            } 
            setPeriod(period);
        });
    }

    const getAttendInfo = (event_no , branch_code ,seq_no , available_person) =>{
        if(available_person == 0){
            if(!window.confirm("예약이 만료되어 예약이 불가합니다.")) {
                    return false;
            }
        }else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
                body: JSON.stringify({ EVENT_NO : event_no , BRANCH_CODE: branch_code , SEQ_NO : seq_no})
              };
              fetch(home_url + '/api/BRD110010SVC/getAttendInfo', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) { if (data != null) {
                    setAttendInfo(data.DATA[0]);
                } });

        }
    }


    const sendNote = (event_no ,branch_code ,seq_no , event_name , branch_name , event_date ,start_time) => {
        if(!window.confirm("예약을 하시겠습니까?")) 
          return false;

        // if(isNaN(With)){
        //    if(!window.confirm("숫자만 입력해주세요."))
        //    return false;
        // }
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
        //   body: JSON.stringify({ EVENT_NO: event_no , BRANCH_CODE : branch_code , WITH : With  ,SEQ_NO : seq_no})
        body: JSON.stringify({ EVENT_NO: event_no , BRANCH_CODE : branch_code ,SEQ_NO : seq_no , EVENT_NAME : event_name , BRANCH_NAME : branch_name , EVENT_DATE : event_date , START_TIME : start_time})
        };
        fetch(home_url + '/api/BRD110010SVC/saveReserve', requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            if(data.DATA !== 1){
                alert(data.MSG); 
                if(data.DESC !=2){
                    props.setReserve(false);         
                }
                return false;
            }
            
          })
      }
    

    // const onChange = (e) => {
    //     let num  = e.target.value;
    //     let regex = /[^0-9]/g;
    //     var result = num.replace(regex, "");
    //     setWith(result);

        
    // }
    

    useEffect(() => {
        getEvent();
    }, []);

    return(
        <>
            <div className="eventSectionWrap">
            <div className="adresTTWrap">행사 예약</div>
            <div className="eventMenuWrap">
                <div className="adresSection01" >
                <div className="eventSectionTT">이벤트명</div>
                <div className="eventSection">
                    <ul className="eventMenu">
                    {Event&&Event.map((row,index)=>{
                        return (
                        <>
                        <li  key={index}   onClick={()=>{getLoc(row.EVENT_NO)}} value={row.EVENT_NO}>{row.EVENT_NAME} </li> 
                        </>
                        )
                    })}

                </ul>
                </div>
            </div>
            </div>
            <div className="eventSelectWrap01">
            <div className="adresSection01">
               <div className="eventSectionTT">장소</div>
              <div className="eventSection">
              <ul 
                    name="category_name01" 
                    multiple size="50000" 
                    className="attendInfo" 
                    id="right" 
              >
                {Loc&&Loc.map((row,index)=>{
                    return (
                    <>
                        <li key={index}  onClick={()=>{getDate(row.EVENT_NO , row.BRANCH_CODE)}} value={row.BRANCH_CODE}>{row.BRANCH_NAME}</li>
                    </>
                    ) 
                })}
              </ul>
              </div>
            </div>

             </div>
            <div className="eventSelectWrap02">
            <div className="adresSection02">
                <div className="eventSectionTT">날짜</div>
                <div className="eventSection">
              <ul 
                    name="category_name01" 
                    multiple size="50000" 
                    className="attendInfo" 
                    id="right" 
              >
               {Date&&Date.map((row,index)=>{
                    return (
                    <>
                        <li key={index} onClick={()=>{getTime(row.EVENT_NO ,row.PERIOD , row.BRANCH_CODE)}} value={row.PERIOD}>{row.PERIOD}</li>
                    </>
                    )
                })}
              </ul>
              </div>
                
            </div>
            </div>

             <div className="eventSelectWrap03">
            <div className="adresSection03">
                <div className="eventSectionTT">시간</div>
                <div className="eventSection">
              <ul 
                    name="category_name01" 
                    multiple size="50000" 
                    className="attendInfo" 
                    id="right" 
              >
               {Time&&Time.map((row,index)=>{
                    return (
                    <>
                        <li key={index} onClick={()=>{getAttendInfo(row.EVENT_NO ,row.BRANCH_CODE , row.SEQ_NO , row.AVAILABLE_PERSON)}} value={row.SEQ_NO}>{row.START_TIME}~{row.END_TIME} ({row.AVAILABLE_PERSON})</li>
                    </>
                    )
                })}
              </ul>
              </div>
                
            </div>
            </div>
            

            <div className="eventSelectWrap04">
            <div className="adresSection04">
            <div className="eventSectionTT">참가자 정보</div>
      
              
                <div className="eventSection2">
              <ul 
                    name="category_name01" 
                    multiple size="50000" 
                    className="attendInfo" 
                    
              >
            {AttendInfo && <>     
                <li>이벤트명 :<span>{AttendInfo.EVENT_NAME}  </span></li>
                <li>장소 :<span>{AttendInfo.BRANCH_NAME}  </span></li>
                <li>주소 : <span>{AttendInfo.ADDR_LOC}  </span></li>
                <li>날짜 : <span>{AttendInfo.EVENT_DATE} </span></li>
                <li>일시 : <span>{AttendInfo.START_TIME}~{AttendInfo.END_TIME}  </span></li>
                <li>이름 : <span>{localStorage.getItem('USER_NAME_LOC')} </span></li>
                {/* <li>동석자 인원 : <input type="number" placeholder="0" min="0"  onChange = {onChange} ></input></li>   */}
                <div className="EvnetButton" >
                    <Button className="ButtonStyle02"  onClick={()=>{sendNote(AttendInfo.EVENT_NO , AttendInfo.BRANCH_CODE , AttendInfo.SEQ_NO ,AttendInfo.EVENT_NAME, AttendInfo.BRANCH_NAME ,AttendInfo.EVENT_DATE, AttendInfo.START_TIME)}} >예약</Button>
                </div>
            </>
            }

              </ul>
            </div>
            </div>
            </div>

            </div>
            <div className="adresBtnWrap">
            <span className="adresBtnSection">

            <Button 
                className="ButtonStyle03 adresBtn_Lmargin"
                variant="contained"
                onClick={()=>props.setReserve(false)}>
                취소
            </Button>
            </span>
            </div>
            {/* 쪽지 끝 */}   
        </>
    )
}
export default ReserveEvent;