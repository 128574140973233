import { useEffect, useState } from 'react';
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';
import { filter } from 'lodash';
import moment from 'moment';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Checkbox
} from '@material-ui/core';
// components
import SearchNotFound from '../CommonBoard/SearchNotFound';
import { CommonMoreMenu, CommonToolbar, CommonHead } from '../CommonBoard/BoardList/';
import TradeLabel from '../PortalTrade/TradeLabel'


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'No', label: 'No.', alignRight: false },
    { id: 'NTT_CN', label: '내용', alignRight: false },
    { id: 'USER_NAME_LOC', label: '거래상대', alignRight: false },
    { id: 'ADD_DATE', label: '마지막 전송시간', alignRight: false },
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.NTT_SJ.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function NoteTradeList(props) {

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('ADD_DATE',);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [List, setList] = useState([]);
    const [PHOTOLIST, setPHOTOLIST] = useState();

    const Parameters = {
        tradeGetList: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': 'getList' },
        tradeSendList: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': 'sendList' },
    }

    const getList = () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify(Parameters[props.noteMode])
        };
        let requestUrl = '';
        if (props.noteMode === 'tradeGetList') requestUrl = '/api/BRD120010SVC/getTrade';
        if (props.noteMode === 'tradeSendList') requestUrl = '/api/BRD110010SVC/getTrade';

        fetch(home_url + requestUrl, requestOptions)
            .then((response) => { return response.json(); })
            .then((data) => {
                let items = data.DATA;
                if (items != null) setList(items);

                let photoList = new Object();

                for (let i in items) {
                    if (photoList[items[i].REF_NO] == null) {
                        let company_code = items[i].COMPANY_CODE;
                        let file_mgt_code = items[i].FILE_MGT_CODE;

                        fetch(home_url + "/api/apicall/getDownload/" + company_code + "/DL/" + file_mgt_code)
                            .then(response => response.blob())
                            .then(blob => URL.createObjectURL(blob))
                            .then(url => { photoList[items[i].REF_NO] = url })
                            .then(() => { setPHOTOLIST(photoList) })
                    }
                }

                requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
                    body: JSON.stringify(Parameters[props.noteMode])
                };
            });
    }

    useEffect(() => {
        getList();
    }, [props]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = List.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

    const filteredTotalboards = applySortFilter(List, getComparator(order, orderBy), filterName);

    const isTotalboardNotFound = filteredTotalboards.length === 0;

    const today = moment().format('YYYY-MM-DD');

    return (
        <div>
            <Card>
                <Typography className="listTable_tt">
                    중고거래 쪽지함
                </Typography>
                {/* <Scrollbar> */}
                <TableContainer >
                    <Table className="listTable NoteTable_head">
                        <CommonHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={List.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {List && filteredTotalboards
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = selected.indexOf(row.NTT_ID) !== -1;
                                    console.log(row)
                                    return (
                                        <>
                                            <TableRow
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell scope="row" align="center"> {index + page * rowsPerPage + 1} </TableCell>
                                                <TableCell scope="row" padding="none" className={row.READ_YN === 'Y' || props.noteMode === 'tradeSendList' ? "NoteTable_tdWidth" : "NoteTable_Nosee"}>
                                                    <Stack direction="row" alignItems="center" spacing={2} className="NoteTradeListTd">
                                                        <Typography
                                                            variant="subtitle2" noWrap
                                                            onClick={() => {
                                                                props.setBack(props.noteMode);
                                                                props.setNoteMode('readNote');
                                                                props.setNote(row);
                                                            }}
                                                        >
                                                            <span className="NoteTradeListImgWrap">
                                                                {/* <img alt="" src={require("../../../images/carouselTest01.png").default} className=""/>     */}
                                                                <img alt="" src={PHOTOLIST && PHOTOLIST[row.REF_NO]} className="" />
                                                            </span>
                                                            <span className="NoteTradeListTTWrap">
                                                                &nbsp;{row.NTT_CN}
                                                            <TradeLabel className="thumbnail_newIcon_f" style={{ display: row.FINISH_YN === "F" ? '' : 'none' }}>
                                                                완료
                                                            </TradeLabel>
                                                            <TradeLabel className="thumbnail_newIcon_ing" style={{ display: row.FINISH_YN !== "F" ? '' : 'none' }}>
                                                                거래중
                                                            </TradeLabel>
                                                            </span>
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.USER_NAME_LOC.split(',')[0].split(' ')[0]}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.ADD_DATE}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isTotalboardNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {/* </Scrollbar> */}

                <TablePagination
                    rowsPerPageOptions={[15, 30, 45]}
                    component="div"
                    count={List.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
            {/* </Container> */}
        </div>
    );
}

export default NoteTradeList;