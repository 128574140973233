import PropTypes from 'prop-types';
// material
import { Grid } from '@material-ui/core';
import PortalCard from './PortalCard';
import React, { useEffect, useState, useCallback } from "react";
import { home_url } from '../../../js/globalProperties';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  bbsId: PropTypes.string
};

export default function ProductList({ bbsId, catName, ...other }) {

  const [cardData, setCardData] = useState([]);
  var items = new Array();

  const callApi = () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'BBS_ID': bbsId, 'ROWNUM': '4', "OPEN_API_YN": "Y", 'CAT_NAME': catName })
      };

      fetch(home_url + '/api/BRD030010SVC/getSelectBbsList', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          items = data['DATA'];
          //items = data; 
          // console.log("portallist : ", items);
          setCardData(items); // and use this.props.dispatch I get `props` of undefined 
        });

    } catch (error) {
      console.log(error);
    }

  }


  useEffect(() => {
    callApi();
  }, [catName]);

  //console.log("list item : " , cardData)

  return (
    <Grid container spacing={3} {...other}>
      {cardData && cardData.map((cardData, index) => (
        <Grid key={index} item xs={3}>
          <PortalCard boardList={cardData} />
        </Grid>
      ))}

      {cardData && cardData.length === 0 && (
        <Grid item xs={3} class="MainThumNosrch">
          <span style={{}}>조회된 내역이 없습니다.</span>
        </Grid>
      )}
    </Grid>
  );
}
