import PropTypes from 'prop-types';
// material
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { home_url } from '../../../js/globalProperties';

// ----------------------------------------------------------------------

JobList.propTypes = {
  bbsId: PropTypes.string
};

export default function JobList({ bbsId, ...other }) {
  const history = useHistory();
  const [jobData, setJobData] = useState([]);
  const [bbs,setBbs] = useState();
  var items = [];

  const callApi = async () => {
    try {
      const requestOptionsBRD1 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'BBS_ID': bbsId, 'OPEN_API_YN': 'Y' })
      };
      fetch(home_url + '/api/BRD010010SVC/selectBbs', requestOptionsBRD1)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          let items = data['DATA'];
          setBbs(items);
        });

      const requestOptionsBRD3 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'BBS_ID': bbsId, 'ROWNUM': 5, "OPEN_API_YN": "Y" })
      };

      fetch(home_url + '/api/BRD030010SVC/getSelectBbsList', requestOptionsBRD3)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          let items = data['DATA'];
          setJobData(items);
        });

    } catch (error) {
      console.log(error);
    }

  }

  const handleBoardRowClick = (NTT_ID, BBS_ID) => {
    history.push({ pathname: '/app/board/' + BBS_ID + '/' + NTT_ID, state: { 'NTT_ID': NTT_ID, 'BBS_ID': BBS_ID } });
  };

  useEffect(() => {
    callApi();
  }, []);

  const addDate = (date) => {
    const AddDate = date.substring(5, 10);
    return AddDate;
  };

  return (
    jobData && jobData.map((jobData, index) => (
      <li key={index}>
        <span className="quickbbs_list_new">N</span>
        {
          bbsId !== '20210714090712000060'
          && <span className="quickbbs_list_txt">
            <a onClick={() => handleBoardRowClick(jobData.NTT_ID, jobData.BBS_ID, bbs)}>{jobData.NTT_SJ}</a>
          </span>
        }
        {
          bbsId == '20210714090712000060'
          && <span className="quickbbs_list_txt02">
            <a onClick={() => handleBoardRowClick(jobData.NTT_ID, jobData.BBS_ID, bbs)}>{jobData.NTT_SJ}</a>
          </span>
        }
        {
          bbsId !== '20210714090712000060'
          && <span className="quickbbs_list_data"> {addDate(jobData.ADD_DATE)} </span>
        }
      </li>
    ))
  );
}
