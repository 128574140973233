import React, { useCallback, useState, useEffect } from "react";
import history from '../../../utils/history'
import Page from './../PLT/Page';
import { home_url } from '../../../js/globalProperties';
import {
    Card,
    Box,
    Button,
    Checkbox,
    TextField,
    Typography,
    Divider
} from '@material-ui/core';
import cookies from "react-cookies"

const PortalRegister = (props) => {
    console.log(props);

    useEffect(() => {
        if (props.location.state === undefined) {
            alert("잘못된 접근입니다.");
            history.push("/app/FUL/Terms");
            history.go(0);
        } else {
            setUser((prevState) => ({
                ...prevState,
                TERMS_USE: props.location.state.TERMS_USE,
                TERMS_PERSONAL: props.location.state.TERMS_PERSONAL,
                TERMS_LOCATION: props.location.state.TERMS_LOCATION,
                TERMS_PROMOTION: props.location.state.TERMS_PROMOTION
            }));
            console.log(user);
        }
    }, [props]);

    const [user, setUser] = useState({
        USER_ID: "",
        PW: "",
        PWC: "",
        USER_NAME_LOC: "",
        USER_NAME_ENG: "",
        TEL_NO: "",
        MOBILE_NO: "",
        EMAIL: "",
        FAX_NO: "",
        IRS_NO: "",
        TERMS_USE: "",
        TERMS_PERSONAL: "",
        TERMS_LOCATION: "",
        TERMS_PROMOTION: "",
        USER_AUTH: "U",
        NATIONALITY: "KR",
        USER_LANG: "KO",
        USE_YN: "Y",
        OPEN_API_YN: "Y",
        SALES_USER_NAME : ""
    });
    const [IRSoption, setIRSoption] = useState(false);
    const [isAvailable_USER_ID, setIsAvailable_USER_ID] = useState(false);
    const [isAvailable_EMAIL, setIsAvailable_EMAIL] = useState(false);
    const [isAvailable_IRS_NO, setIsAvailable_IRS_NO] = useState(false);

    const onSubmit = () => {
        if (user.USER_ID === "" || user.USER_ID === null || user.USER_ID === undefined) {
            alert("아이디를 입력해 주세요.");
            return false;
        }


        if (user.PW === "" || user.PW === null || user.PW === undefined) {
            alert("비밀번호를 입력해 주세요.");
            return false;
        }


        if (user.USER_NAME_LOC === "" || user.USER_NAME_LOC === null || user.USER_NAME_LOC === undefined) {
            alert("한글명을 입력해 주세요.");
            return false;
        }

        /* let reg = /^[가-힣]{2,4}$/;
        if (!reg.test(user.USER_NAME_LOC)) {
            alert("한글 이름을 확인해주세요!");
            return false;
        } */

        /*reg = /^[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/;
        if(!reg.test(USER_NAME_ENG)){
          alert("영문 이름을 확인해주세요!");
          return false;
        }*/

        if (!isAvailable_USER_ID) {
            alert("아이디를 확인해주세요.");
            return false;
        }

        if (!isAvailable_EMAIL) {
            alert("이메일을 확인해주세요.");
            return false;
        }

        //비밀번호 정책
        let regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
        if (!regExp.test(user.PW)) {
            alert("비밀번호는 특수문자를 반드시 포함한 8~16글자 입니다.")
            // alert("비밀번호는 8 ~ 16자 영문, 숫자, 특수문자를 사용해주세요.");
            return false;
        }

        if (user.PW != user.PWC) {
            alert("비밀번호를 확인해주세요.")
            return false;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };
        if (!window.confirm("회원가입을 진행하시겠습니까?")) return false;

        fetch(home_url + '/api/slpportal/joinUser', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                alert(data.MSG);
                if (data.TYPE !== 1) return false;
                //회원가입 완료 후 기업등록 요청
                // 2021.11.22 회원가입시에 IRS_NO에 null 이나 빈 문자열이 아닌 값이 넘어오면 기업등록 요청도 함께 처리된다.
                // if(data.TYPE === 1) if(IRS_NO != '' && isAvailable_IRS_NO) registIRS(USER_ID,IRS_NO);
            })
            .then(function () {
                history.push('/');
                history.go(0);
            })
            .catch((error) => alert('회원가입에 실패했습니다'));
    };

    //기업등록 요청
    const registIRS = (USER_ID, IRS_NO) => {

        //기가입 여부 확인
        let alreadyRegisted = false;

        try {
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                body: JSON.stringify({ 'USER_ID': USER_ID, 'OPEN_API_YN': 'Y' })
            };
            fetch(home_url + '/api/SMS020010SVC/getUserCompany', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                    console.log("기업등록 승인 여부 확인 : ", data['MSG']);
                    if (data['DATA'].length == 1) alreadyRegisted = true;

                    if (alreadyRegisted) joinCompanyAfterReset(USER_ID);
                    else joinCompany(USER_ID, IRS_NO);
                });
        } catch (error) {
            console.log(error);
        }

        //기가입자일 경우 기업정보 초기화
        const joinCompanyAfterReset = (USER_ID, IRS_NO) => {
            try {
                let requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                    body: JSON.stringify({ 'USER_ID': USER_ID, 'OPEN_API_YN': 'Y' })
                };
                fetch(home_url + '/api/SMS020010SVC/deleteUserCompany', requestOptions)
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                        console.log("기업정보 삭제 : ", data['MSG']);
                        joinCompany(USER_ID, IRS_NO);
                    });
            } catch (error) {
                console.log(error);
            }
        }

        //기업등록 요청
        const joinCompany = (USER_ID, IRS_NO) => {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                    body: JSON.stringify({ 'USER_ID': USER_ID, 'IRS_NO': IRS_NO, 'OPEN_API_YN': 'Y' })
                };
                fetch(home_url + '/api/SMS020010SVC/joinCompany', requestOptions)
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                        console.log('기업등록 요청 : ', data['MSG']);
                    });
            } catch (error) {
                console.log(error);
            }
        }

    }


    //아이디 중복확인 ------------------------------------------------------------
    const checkID = () => {
        let regExp = /^[a-z]+[a-z0-9]{5,19}$/g;
        if (!regExp.test(user.USER_ID)) {
            alert("아이디는 영문자 또는 숫자 6~20자 입니다.");
            return false;
        }
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'USER_ID': user.USER_ID, 'OPEN_API_YN': 'Y' })
            };
            fetch(home_url + '/api/slpportal/joinCheckId', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                    if (!data) alert("이미 등록된 아이디입니다.");
                    else {
                        alert(user.USER_ID + "은(는) 사용가능한 아이디입니다.");
                        setIsAvailable_USER_ID(true);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    //---------------------------------------------------------------------------
    //이메일 중복확인 ------------------------------------------------------------
    const checkEMAIL = () => {
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        if (!regExp.test(user.EMAIL)) {
            alert("이메일을 확인해주세요!")
            return false;
        }
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'EMAIL': user.EMAIL, 'OPEN_API_YN': 'Y' })
            };
            fetch(home_url + '/api/slpportal/joinCheckEmail', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                    if (!data) alert("이미 사용중인 이메일입니다!");
                    else {
                        alert(user.EMAIL + "은(는) 사용가능한 이메일입니다.");
                        setIsAvailable_EMAIL(true);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    //---------------------------------------------------------------------------
    //사업자등록번호 등록확인 ------------------------------------------------------------
    const checkIRSNO = () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'IRS_NO': user.IRS_NO, 'OPEN_API_YN': 'Y' })
            };
            fetch(home_url + '/api/slpportal/checkIrsNo', requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                    if (data.TYPE !== 1) {
                        alert(data.MSG);
                        return false;
                    } else {
                        alert("요청가능한 사업자등록번호입니다.");
                        setIsAvailable_IRS_NO(true);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    //---------------------------------------------------------------------------

    return (
        <>
            <Page style={{ 'padding': '20px' }}>
                <div className="agreeTotal_Wrap">
                    <Typography className="listTable_tt">
                        회원가입
                    </Typography>
                    <Divider />
                    <br />
                    <span className="agreeTt_Wrap02">아이디 *</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="USER_ID"
                        type="string"
                        value={user.USER_ID}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, USER_ID: e.target.value }));
                            setIsAvailable_USER_ID(false);
                        }}
                        required
                    />
                    <Button onClick={checkID} disabled={isAvailable_USER_ID}>아이디 중복체크</Button><br />

                    <span className="agreeTt_Wrap02">비밀번호 *</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="PW"
                        type="password"
                        value={user.PW}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, PW: e.target.value }));
                        }}
                        required
                    />

                    <span className="agreeTt_Wrap02">비밀번호 확인 *</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="PWC"
                        type="password"
                        value={user.PWC}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, PWC: e.target.value }));
                        }}
                        required
                    />

                    <span className="agreeTt_Wrap02">이름 (한글) *</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="USER_NAME_LOC"
                        type="string"
                        value={user.USER_NAME_LOC}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, USER_NAME_LOC: e.target.value }));
                        }}
                        required
                    />

                    <span className="agreeTt_Wrap02">이름 (영문)</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="USER_NAME_ENG"
                        type="string"
                        value={user.USER_NAME_ENG}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, USER_NAME_ENG: e.target.value }));
                        }}
                    />

                    <span className="agreeTt_Wrap02">전화번호</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="TEL_NO"
                        type="string"
                        value={user.TEL_NO}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, TEL_NO: e.target.value }));
                        }}
                    />

                    <span className="agreeTt_Wrap02">휴대전화</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="MOBILE_NO"
                        type="string"
                        value={user.MOBILE_NO}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, MOBILE_NO: e.target.value }));
                        }}
                    />

                    <span className="agreeTt_Wrap02">이메일 *</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="EMAIL"
                        type="string"
                        value={user.EMAIL}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, EMAIL: e.target.value }));
                            setIsAvailable_EMAIL(false);
                        }}
                        helperText="비밀번호 분실시 이메일을 이용해 찾을 수 있습니다."
                    />
                    <Button onClick={checkEMAIL} disabled={isAvailable_EMAIL}>이메일 중복체크</Button><br />

                    <span className="agreeTt_Wrap02">팩스번호</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="FAX_NO"
                        type="string"
                        value={user.FAX_NO}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, FAX_NO: e.target.value }));
                        }}
                    />
                    <span className="agreeTt_Wrap02">추천 직원</span>
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="SALES_USER_NAME"
                        type="string"
                        value={user.SALES_USER_NAME}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, SALES_USER_NAME: e.target.value }));
                        }}
                    />

                    <br /><br /><br />
                    <Divider />
                    <Typography className="listTable_tt">
                        기업등록 요청 (선택)
                        <Checkbox
                            checked={IRSoption}
                            onChange={(e) => {
                                setIRSoption(e.target.checked);
                                if (!e.target.checked) {
                                    setIsAvailable_IRS_NO(false);
                                    setUser((prevState) => ({ ...prevState, PWC: e.target.value }));
                                }
                            }}
                        />
                    </Typography>
                    <br />
                    <span className="agreeTt_Wrap02">사업자등록번호</span>
                    <TextField className="bbsInputSizeDisabled"
                        fullWidth
                        name="IRS_NO"
                        type="string"
                        disabled
                        style={{ display: !IRSoption ? "" : "none" }}
                    />
                    <TextField className="bbsInputSize"
                        fullWidth
                        name="IRS_NO"
                        type="string"
                        style={{ display: !IRSoption ? "none" : "" }}
                        value={user.IRS_NO}
                        onChange={(e) => {
                            setUser((prevState) => ({ ...prevState, IRS_NO: e.target.value }));
                            setIsAvailable_IRS_NO(false);
                        }}
                    />
                    <Button onClick={checkIRSNO} disabled style={{ display: !IRSoption ? "" : "none" }}>사업자등록번호 중복체크</Button>
                    <Button onClick={checkIRSNO} disabled={isAvailable_IRS_NO} style={{ display: !IRSoption ? "none" : "" }}>사업자등록번호 중복체크</Button>
                    <Box className="agreeBtn_Wrap">
                        <Button
                            variant="contained"
                            className="ButtonStyle03"
                            onClick={() => { history.push('/'); history.go(0) }}
                        >
                            취소
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            type="primary"
                            className="ButtonStyle02"
                            onClick={() => onSubmit()}
                        >
                            가입하기
                        </Button>
                    </Box>
                </div>
            </Page>
        </>
    )

}
export default PortalRegister;
