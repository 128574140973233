import React, { Component } from 'react'
//import { Button, Image } from 'semantic-ui-react'
import { GoogleLogin } from 'react-google-login';
import { home_url } from './../../../../js/globalProperties';
import cookies from "react-cookies"
import $ from 'jquery'
window.$ = $;

export default class GoogleLoginButton extends Component {
    render() {
        return (
            <GoogleLogin
                clientId={'438178864435-93itmmo3i2ee3roh5n6llru64k1cjkc9.apps.googleusercontent.com'}
                render={renderProps => (
                    <span onClick={renderProps.onClick} className="socialLogin_google">
                        <img src={require("../../../../images/google_logo.png").default} />
                    </span>
                )}
                onSuccess={(res) => {
                    console.log("google res : ", res.profileObj);
                    const loginOption = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            "OPEN_API_YN": "Y",
                            "TYPE": "google",
                            "VALUE": {
                                "id": res.profileObj.googleId,
                                "name": res.profileObj.name,
                                "email": res.profileObj.email
                            }
                        })
                    };
                    fetch(home_url + "/api/slpportal/socialLogin", loginOption)
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                            this.props.login(data.DATA.ID, data.DATA.PW);
                        })
                }
                }
                onFailure={(err) => {
                    console.log(err);
                }}
            >
            </GoogleLogin >
        );
    }
}