import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { brdbd003Actions } from "../../../../slice/brdbd003Slice";
import {
  Box,
  Card,
  Button,
  Typography,
  Container,
  Grid,
  Pagination,
  Divider,
  dividerClasses
} from '@material-ui/core';
import ProductListToolbar from '../product2/ProductListToolbar';
import ProductCard from '../product2/ProductCard';

function PortalBoard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [BBS_ID, setBbsId] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const AUTH = ['U', 'D', 'M', 'A'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRegisterPage = (bbs_data) => {
    console.log('작성화면이동 : ', bbs_data);
    history.push({ pathname: '/app/board/register', state: bbs_data });
  };

  useEffect(() => {
    dispatch(brdbd003Actions.getBrdbd003({ 'BBS_ID': props.BBS.BBS_ID, 'OPEN_API_YN':'Y' }));
  }, [BBS_ID,props.BBS]);

  const { brdbd003, isLoading, isSuccess, error } = useSelector((state) => ({
    brdbd003: state.brdbd003Reducers.brdbd003,
    isLoading: state.brdbd003Reducers.isLoading,
    isSuccess: state.brdbd003Reducers.isSuccess,
    error: state.brdbd003Reducers.error,
  }));

  const BRDLIST = [...Array(0)].map((_, index) => ({}));
  // console.log('brdbd003 : ', brdbd003, BRDLIST);

  const products = [];
  //console.log('props : ', props);
  const BBS = props.BBS;
  
  for (var n in brdbd003) {
    products.push(brdbd003[n]);
  }
  return (
    <div>
          {/* <Container maxWidth={false}> */}
              <Typography className="listTable_tt">
                {props.BBS.BBS_NAME}
              </Typography>
              <Divider/><br/>
              <Grid
                container
                spacing={3}
              >
                {products.map((product) => (
                  <Grid
                    item
                    key={product.bbs_id}
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <ProductCard product={product} />
                  </Grid>
                ))}
                {products.length < 1 && (
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                 등록된 내역이 없습니다.
                 </Grid>
                )}
              </Grid>
              <div className=" position_rel">
              <ProductListToolbar />
              <Pagination  className="glyPagePosition"
                pageSize={page}
                itemsCount={products.length}
                currentPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
              </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
          <Button type="primary" className="ButtonStyle02"
            color="primary"
            variant="contained"
            style={{ display: AUTH.indexOf(localStorage.getItem('USER_AUTH')) >= AUTH.indexOf(props.BBS.WRITE_AUTH) ? '' : 'none' }}
            onClick={() => handleRegisterPage({BBS})}>
            글쓰기
            </Button>
          </Box>
          {/* </Container> */}
    </div>
  );
}
export default PortalBoard;