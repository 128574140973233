import { useState, useEffect, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"
import { DEXT5Editor } from "../../DEXT5Editor";
import {
    Box,
    Button,
    Container,
    Stack,
    Typography,
    Divider,
    Pagination,
    TextField,
    TablePagination,
    Input
} from '@material-ui/core';

const TradeUpdate = (props) => {
    const history = useHistory();
    const [FILE, setFILE] = useState([]);
    const [PREVIEW, setPREVIEW] = useState([]);
    const [CATS, setCATS] = useState([]);
    const [ORIGIN, setORIGIN] = useState([]);

    const getPHOTO = () => {
        try {
            let requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                body: JSON.stringify({
                    "P_COMPANY_CODE": props.location.state.COMPANY_CODE,
                    "DEAL_TYPE": props.location.state.DEAL_TYPE,
                    "DEAL_NO": props.location.state.DEAL_NO,
                    "S_FUNC_CODE": "DL",
                    "REF_TYPE": "JOB",
                    "OPEN_API_YN": "Y"
                }),
            };
            fetch(home_url + "/api/MBP040010SVC/get", requestOptions)
                .then(function (response) { return response.json(); })
                .then(function (data) {
                    requestOptions = {
                        method: 'POST',
                        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") }
                    }
                    for (let i in data.MBP040010G1[1].ptData) {
                        fetch(home_url + "/api/apicall/getDownload/" + data.MBP040010G1[1].ptData[i].COMPANY_CODE + "/DL/" + data.MBP040010G1[1].ptData[i].FILE_MGT_CODE)
                            .then(response => response.blob())
                            .then(blob => {
                                URL.createObjectURL(blob);
                                blobToFile(blob, data.MBP040010G1[1].ptData[i].FILE_NAME);
                                makeOrigin(data.MBP040010G1[1].ptData[i].FILE_MGT_CODE, data.MBP040010G1[1].ptData[i].FILE_NAME, data.MBP040010G1[1].ptData[i].YYMMDD);
                            })
                            .then(() => previewHandler())
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }

    const makeOrigin = (FILE_MGT_CODE, FILE_NAME, YYMMDD) => {
        let arr = ORIGIN;
        let origin = {
            "S_FUNC_CODE": "DL",
            "FILE_MGT_CODE": FILE_MGT_CODE,
            "REF_NO": props.location.state.DEAL_NO,
            "REF_TYPE": "JOB",
            "FILE_NAME": FILE_NAME,
            "YYMMDD": YYMMDD
        }
        arr.push(origin);
        setORIGIN(arr);
        console.log("ORIGIN : ", ORIGIN);
    }

    const blobToFile = (blob, fileName) => {
        let file = new File([blob], fileName);
        let files = FILE;

        files.push(file);
        setFILE(files);
    }

    const fileHandler = (e) => {
        let files = e.target.files;
        let file = FILE;
        let isNotImage = false;
        for (let [key, value] of Object.entries(files)) {
            if (isNaN(parseInt(key))) break;
            let t = value.type.split('/').pop().toLowerCase();
            if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
                isNotImage = true;
                break;
            }
            if (isNotImage) alert("이미지 파일만 첨부 가능합니다.");
            file.push(value);
        }
        setFILE(file);
        previewHandler();
    }

    const previewHandler = () => {
        let preview = [];
        for (let i in FILE) {
            preview.push(window.URL.createObjectURL(FILE[i]));
        }
        setPREVIEW(preview);
    }

    const deleteFileHandler = (f) => {
        let file = FILE;
        let preview = PREVIEW;

        for (let i in preview) {
            if (preview[i] === f) {
                file.splice(i, 1);
                break;
            }
        }
        setFILE(file);
        previewHandler();
    }

    const getCATS = () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
            body: JSON.stringify({ 'OPEN_API_YN': 'Y' })
        };
        fetch(home_url + '/api/MBP040010SVC/getCode', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.MBP040010G1) setCATS(data.MBP040010G1);
            })
    }

    const [MBP040010F1, setMBP040010F1] = useState({
        "DEAL_NO": props.location.state.DEAL_NO,
        "DEAL_TYPE": props.location.state.DEAL_TYPE,
        "TITLE": props.location.state.TITLE,
        "MODEL_STATUS": props.location.state.MODEL_STATUS,
        "CATEGORY": props.location.state.CATEGORY,
        "MODEL_NAME": props.location.state.MODEL_NAME,
        "MODEL_YEAR": props.location.state.MODEL_YEAR,
        "MODEL_NO": props.location.state.MODEL_NO,
        "LOCATION": props.location.state.LOCATION,
        "AMOUNT": String(props.location.state.AMOUNT),
        "EQ_DESC": props.location.state.EQ_DESC,
        "UPDATE_USER_ID": localStorage.getItem("USER_ID")
    })

    const save = () => {
        let temp = MBP040010F1;
        temp.EQ_DESC = window.DEXT5.GetBodyValue();
        setMBP040010F1(temp);
        if (MBP040010F1.TITLE === "") { alert("제목을 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_STATUS === "") { alert("상품 상태을 입력해주세요!"); return false; }
        if (MBP040010F1.CATEGORY === "") { alert("카테고리를 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_NAME === "") { alert("상품명을 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_YEAR === "") { alert("구매년도 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_NO === "") { alert("상품번호를 입력해주세요!"); return false; }
        if (MBP040010F1.LOCATION === "") { alert("장소를 입력해주세요!"); return false; }
        if (MBP040010F1.AMOUNT === "") { alert("가격을 입력해주세요!"); return false; }
        if (MBP040010F1.EQ_DESC === "") { alert("상품설명을 입력해주세요!"); return false; }

        let param = MBP040010F1;
        console.log(MBP040010F1);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
            body: JSON.stringify({ "MBP040010F1": param })
        };
        fetch(home_url + '/api/MBP040010SVC/save', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.TYPE != 1) {
                    alert(data.MSG);
                    return false;
                }
                for (let i in ORIGIN) {
                    requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                        body: JSON.stringify(ORIGIN[i])
                    };
                    fetch(home_url + '/api/file/delete', requestOptions)
                }

                if (FILE.length === 0) alert(FILE.length);
                let formData = new FormData();
                formData.append("S_FUNC_CODE", "DL");
                formData.append("REF_NO", data.MBP040010F1.DEAL_NO);
                formData.append("REF_TYPE", "JOB");
                formData.append("JOB_NO", "N");
                formData.append("PUBLIC_YN", "N");
                formData.append("REAMRKS", null);
                formData.append("file", null);

                requestOptions = {
                    method: 'POST',
                    headers: { "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                    body: formData
                };

                for (let i in FILE) {
                    formData.set('file', FILE[i]);
                    fetch(home_url + '/api/file/upload', requestOptions)
                        .then(function (response) { return response.json(); })
                }
            })
            .then(function () {
                alert('저장되었습니다!');
                history.push('/app/trade/' + props.location.state.DEAL_TYPE + '/' + props.location.state.COMPANY_CODE + '/' + props.location.state.DEAL_NO);
            });
    }

    useEffect(() => {
        getPHOTO();
        getCATS();
    }, []);


    window.DEXT5 && window.DEXT5.SetBodyValue(props.location.state.EQ_DESC, 'editor1');

    return (
        <>
            <Typography className="listTable_tt">
                중고장비 판매
                {/* <img alt="찜하기" src={require("../../../images/heart_off.png").default} className="tradeHeart_Img02" /> */}
                {/* <img alt="찜하기" src={require("../../../images/heart_on.png").default} className="tradeHeart_Img02"/> */}
            </Typography>
            <table className="srchTable">
                <colgroup>

                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "30%" }}></col>
                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "30%" }}></col>
                </colgroup>
                <tr>
                    <th>
                        제목
                    </th>
                    <td colSpan="3">
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.TITLE}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "TITLE": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="tradeWriteImgWrapTh">
                        <span className="tradeWriteImgSpan">
                            <img alt="" src={require("../../../images/camera.png").default} className="tradeCameraImg" />
                            <label htmlFor="fileHandler">
                                <input
                                    accept="image/*"
                                    id="fileHandler"
                                    style={{ display: 'none' }}
                                    multiple
                                    type="file"
                                    onChange={(e) => fileHandler(e)} />
                                <Button className="NoteAllow_bluBtn tradeBluBtn" variant="contained" component="span">
                                    사진첨부
                                </Button>
                            </label>
                        </span>
                    </th>
                    <td className="tradeWriteImgWrap" colSpan="3">
                        {PREVIEW.map((row, index) => {
                            return (
                                <span key={index}>
                                    <img src={row} />
                                    <span onClick={() => { deleteFileHandler(row) }}>X</span>
                                </span>
                            )
                        })}
                    </td>
                </tr>
                <tr>
                    <th>카테고리</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            style={{ textAlign: 'center' }}
                            value={MBP040010F1.CATEGORY}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "CATEGORY": e.target.value }))}
                        >
                            <option value="">카테고리</option>
                            {CATS && CATS.map((row) => (
                                <option
                                    key={row.ENG_VALUE}
                                    value={row.ENG_VALUE}
                                >
                                    {row.LOC_VALUE}
                                </option>
                            ))}
                        </TextField>
                    </td>
                    <th>상태</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            value={MBP040010F1.MODEL_STATUS}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_STATUS": e.target.value }))}
                        >
                            <option value="">상태</option>
                            <option value="H">상</option>
                            <option value="M">중</option>
                            <option value="L">하</option>
                        </TextField>
                    </td>
                </tr>
                <tr>
                    <th>상품번호</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.MODEL_NO}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_NO": e.target.value }))}
                        />
                    </td>
                    <th>상품명</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.MODEL_NAME}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_NAME": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th>구매년도</th>
                    {/* <td><div className="tradeDateInput tradeDatePicBg">2021년 01월 01일</div></td> */}
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="String"
                            placeholder=""
                            value={MBP040010F1.MODEL_YEAR}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_YEAR": e.target.value }))}
                        />
                    </td>
                    <th>가격</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="String"
                            placeholder=""
                            value={MBP040010F1.AMOUNT}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "AMOUNT": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th>장소</th>
                    <td colSpan="3">
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.LOCATION}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "LOCATION": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="4">
                        <DEXT5Editor />

                    </td>
                </tr>
            </table>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}>
                &nbsp;&nbsp;
                <Button
                    onClick={() => history.goBack()}
                    className="ButtonStyle03"
                    variant="contained">
                    취소
                </Button>
                &nbsp;&nbsp;
                <Button
                    type="primary"
                    className="ButtonStyle02"
                    variant="contained"
                    onClick={() => save()}
                >
                    저장
                </Button>
            </Box>
        </>
    )
}
export default TradeUpdate;