import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { brdbd001Actions } from "../../../slice/brdbd001Slice";
import RegisterOrEdit from "./RegisterBoard/RegisterOrEdit";

import { Input } from "antd";
import {
  Box,
  Button,
} from '@material-ui/core';

function RegisterPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const [brddata, setBrdbr001] = useState([]); 
  var items = new Array();
  const onGoTotalBoard = useCallback(() => history.push('/app/totalboard'), [history]);
  const goBack = () => { history.goBack(); };

  useEffect(() => {
    //컴포넌트가 마운트 되었을 때, 실행되는 영역
    // dispatch(brdbd001Actions.getBrdbd001(props.match.params.bbs_id));
    // const unblock = history.block('목록 페이지로 이동하시겠습니까?');
      dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_ID", "value": ""}));
      dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_NAME", "value": "" }));
      dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_INTRCN", "value":"" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "BBS_TY_CODE", "value": "" }));
      dispatch(brdbd001Actions.changeRegisterInput({ "name": "REPLY_POSBL_AT", "value": "" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "FILE_ATCH_POSBL_AT", "value": "" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "ATCH_POSBL_FILE_NUMBER", "value": "" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "ATCH_POSBL_FILE_SIZE", "value": "" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "TMPLAT_ID", "value":"" }));
      // dispatch(brdbd001Actions.changeRegisterInput({ "name": "USE_AT", "value": "" }));

    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock(); 
    };
  }, [history]);
  
  const { BBS_ID, BBS_NAME, BBS_INTRCN, BBS_TY_CODE, REPLY_POSBL_AT, FILE_ATCH_POSBL_AT, ATCH_POSBL_FILE_NUMBER, ATCH_POSBL_FILE_SIZE, TMPLAT_ID, USE_AT, WRITE_AUTH } = useSelector(
    (state) => ({
      BBS_ID : state.brdbd001Reducers.BBS_ID,
      BBS_NAME : state.brdbd001Reducers.BBS_NAME,
      BBS_INTRCN : state.brdbd001Reducers.BBS_INTRCN,
      BBS_TY_CODE : state.brdbd001Reducers.BBS_TY_CODE,
      REPLY_POSBL_AT : state.brdbd001Reducers.REPLY_POSBL_AT,
      FILE_ATCH_POSBL_AT : state.brdbd001Reducers.FILE_ATCH_POSBL_AT,
      ATCH_POSBL_FILE_NUMBER : state.brdbd001Reducers.ATCH_POSBL_FILE_NUMBER,
      ATCH_POSBL_FILE_SIZE : state.brdbd001Reducers.ATCH_POSBL_FILE_SIZE,
      TMPLAT_ID : state.brdbd001Reducers.TMPLAT_ID,
      USE_AT : state.brdbd001Reducers.USE_AT,
      WRITE_AUTH : state.brdbd001Reducers.WRITE_AUTH
    }),
    shallowEqual
  );

  console.log('items: ', brddata, brddata[0]==undefined?"":brddata[0].BBS_NAME);
  const [IsForUpdate, setIsForUpdate] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get("isForEdit") === "true") {
      dispatch(brdbd001Actions.fetchBrdbd001(props.match.params.brdbd001Id));
      setIsForUpdate(true);
    }
  }, [BBS_ID]);

  const onRegisterChange = (event) => {
    const { name, value } = event.target;
    console.log('onRegisterChange ', name, value);
    dispatch(brdbd001Actions.changeRegisterInput({ name: name, value: value }));
  };

  const onSubmitBrdbd001 = (event) => {
    // event.preventDefault();
    console.log('BBS_NAME ', BBS_ID, BBS_NAME, BBS_INTRCN, BBS_TY_CODE, REPLY_POSBL_AT, FILE_ATCH_POSBL_AT, ATCH_POSBL_FILE_NUMBER, ATCH_POSBL_FILE_SIZE, TMPLAT_ID, USE_AT);
    if (BBS_NAME === "" || BBS_NAME === null || BBS_NAME === undefined) {
      alert("제목을 작성하십시오.");
      return false;
    }

    if (BBS_INTRCN === "" || BBS_INTRCN === null || BBS_INTRCN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    const brdbd001 = {
      BBS_ID: BBS_ID,
      BBS_NAME: BBS_NAME,
      BBS_INTRCN: BBS_INTRCN,
      BBS_TY_CODE: BBS_TY_CODE,
      REPLY_POSBL_AT: REPLY_POSBL_AT,
      FILE_ATCH_POSBL_AT: FILE_ATCH_POSBL_AT,
      ATCH_POSBL_FILE_NUMBER: ATCH_POSBL_FILE_NUMBER,
      ATCH_POSBL_FILE_SIZE: ATCH_POSBL_FILE_SIZE,
      TMPLAT_ID: TMPLAT_ID,
      USE_AT: USE_AT,
      WRITE_AUTH: WRITE_AUTH
    };
      dispatch(brdbd001Actions.registerBrdbd001(brdbd001));
      history.push({pathname:'/app/totalboard/'});
  };

  return (
    <>
      <RegisterOrEdit
        BBS_ID={BBS_ID}
        BBS_NAME={BBS_NAME}
        BBS_INTRCN={BBS_INTRCN}
        BBS_TY_CODE={BBS_TY_CODE}
        REPLY_POSBL_AT={REPLY_POSBL_AT}
        FILE_ATCH_POSBL_AT={FILE_ATCH_POSBL_AT}
        ATCH_POSBL_FILE_NUMBER={ATCH_POSBL_FILE_NUMBER}
        ATCH_POSBL_FILE_SIZE={ATCH_POSBL_FILE_SIZE}
        TMPLAT_ID={TMPLAT_ID}
        USE_AT={USE_AT}
        WRITE_AUTH={WRITE_AUTH}
        handleRegisterChange={onRegisterChange}
        handleSubmit={onSubmitBrdbd001}
        handleTotalBoard={onGoTotalBoard}
        handleGoBack={goBack}

        updateRequest={IsForUpdate}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        &nbsp;&nbsp;
        <Button onClick={onGoTotalBoard}  className="ButtonStyle03"
          // color="inherit"
          variant="contained"
          >
          목록으로
        </Button>
        &nbsp;&nbsp;
            <Button type="primary" onClick={onSubmitBrdbd001}  className="ButtonStyle02"
          // color="primary"
          variant="contained"
          >
          저장
        </Button>
      </Box>
    </>
  );
}

export default RegisterPage;