import React, { useEffect } from "react";
import TotalboardList from "./Totalboard/Totalboard";

function TotalboardBoard() {
  return (
    <div> 
      {/* <PortalHead/> */}
        <TotalboardList/>
      {/* <PortalFootter/> */}
    </div>
  );
}
export default TotalboardBoard;