import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT020101(props) {
  //임시적으로 링크 허용 권한 설정 ------------------------22.02.04 이민호
  const POPUP = (URL) => {
    if (localStorage.getItem("USER_AUTH") === "A"){
      window.open(URL);
    }else alert("현재 준비중입니다.");
  }
  //-------------------------------------------------------------------


  //위의 코드 주석처리후 아래 주석 해제시 기존 로직 작동 ----22.02.04 이민호
  // const POPUP = (URL) => {
  //   window.open(URL);
  // }
  //-------------------------------------------------------------------


  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Typography className="listTable_tt">
          빅데이터 시각화 대시보드
        </Typography>
        {/* #1 */}
        <Card>
          <Divider />
          <div className="position_rel">
            <div className="bigdataPage_leftWrap">
              <img src={require("../../../images/bigdata_dashBtn03.jpg").default} className="" />
              <div className="bigdataPage_absolWrap01 btnOverView03"></div>
              <div className="bigdataTxt_Wrap01">
                물류 산업단지의 운송현황 데이터를<br /> 통계적으로 분석하여<br /> 시각화한 결과를 확인할 수 있는<br /> 실시간 대시보드입니다.
              </div>
              <div className="bigdataBtn_Wrap"></div>
              {/* <Button className="bigdataBtn01" variant="" onClick={() => window.open('/app/POPUP/PLT000101_09','','width='+window.screen.width+',height=1100px,location=no,status=no,scrollbars=no')}> */}
              <Button className="bigdataBtn01" variant="" onClick={() => POPUP('http://211.216.47.60:5601/goto/130ddb08a2ce97c61d3bb6080f72d5c7', '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                {/* <Button className="bigdataBtn01 btnOverCtrl03" variant="" onClick={() => alert("현재 준비중입니다!")}> */}
                물류운송현황
              </Button>
            </div>
            <div className="bigdataPage_RightWrap">
              <img src={require("../../../images/bigdata_dashBtn02.jpg").default} className="" />
              <div className="bigdataPage_absolWrap02 btnOverView02"></div>
              <div className="bigdataTxt_Wrap02">
                산업단지에 입주된 업체의 현황과<br /> 설문조사 자료를 통계적으로 분석하여<br /> 시각화한 결과를 확인할 수 있는 <br />대시보드입니다.
              </div>
              <div className="bigdataBtn_Wrap"></div>
              {/* <Button className="bigdataBtn02" variant="" onClick={() => window.open('/app/POPUP/PLT000101_10','','width='+window.screen.width+',height=1100px,location=no,status=no,scrollbars=no')}> */}
              <Button className="bigdataBtn02" variant="" onClick={() => POPUP('http://211.216.47.60:5601/goto/96bac4fe6702e5dfb03beebed3c24597', '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                {/* <Button className="bigdataBtn02 btnOverCtrl02" variant="" onClick={() => alert("현재 준비중입니다!")}> */}
                산단업체현황
              </Button>
            </div>
          </div>
        </Card>
        {/* #2 */}
        <Card>
          <Divider />
          <div className="position_rel">
            <div className="bigdataPage_leftWrap">
              <img src={require("../../../images/bigdata_dashBtn01.jpg").default} className="" />
              <div className="bigdataPage_absolWrap01 btnOverView01"></div>
              <div className="bigdataTxt_Wrap01">
                물류산업 단지의 전체 물류비용를  <br /> 시각화한 실시간 대시보드입니다.
              </div>
              <div className="bigdataBtn_Wrap"></div>
              <Button className="bigdataBtn01 btnOverCtrl01" variant="" onClick={() => POPUP("http://211.216.47.60:5601/app/dashboards#/view/0a8fc070-5ed9-11ec-8f4d-8b8b6198726b?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2y,to:now))&_a=(description:%EB%AC%BC%EB%A5%98%EC%A0%95%EB%B3%B4%EC%B4%9D%EA%B3%84,filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:%EB%B9%85%EB%8D%B0%EC%9D%B4%ED%84%B0%EB%B6%84%EC%84%9D_%ED%99%94%EB%A9%B4-%EB%AC%BC%EB%A5%98%EC%A0%95%EB%B3%B4%EC%B4%9D%EA%B3%84,viewMode:view)", '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                물류비용총계
              </Button>
            </div>
            <div className="bigdataPage_RightWrap">
              <img src={require("../../../images/bigdata_dashBtn04.jpg").default} className="" />
              <div className="bigdataPage_absolWrap02 btnOverView04"></div>
              <div className="bigdataTxt_Wrap02">
                차량종류 및 차량별 실적에 대하여 <br /> 물류비를 세분화한 <br /> 실시간 대시보드 입니다.
              </div>
              <div className="bigdataBtn_Wrap"></div>
              <Button className="bigdataBtn02 btnOverCtrl04" variant="" onClick={() => POPUP("http://211.216.47.60:5601/app/dashboards#/view/e8b6b2e0-5f07-11ec-8f4d-8b8b6198726b?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2y,to:now))&_a=(description:%EB%AC%BC%EB%A5%98%EC%A0%95%EB%B3%B4%EC%B4%9D%EA%B3%84,filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:%EB%B9%85%EB%8D%B0%EC%9D%B4%ED%84%B0%EB%B6%84%EC%84%9D_%ED%99%94%EB%A9%B4-%EB%AC%BC%EB%A5%98%EC%A0%95%EB%B3%B4%EC%B4%9D%EA%B3%84-%EC%84%B8%EB%B6%80%EC%A0%95%EB%B3%B4,viewMode:view)", '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                물류비용총계 세부내역
              </Button>
            </div>
          </div>
        </Card>
        {/* #3 */}
        <Card>
          <Divider />
          <div className="position_rel">
            <div className="bigdataPage_leftWrap">
              <img src={require("../../../images/bigdata_dashBtn05.jpg").default} className="" />
              <div className="bigdataPage_absolWrap01 btnOverView05"></div>
              <div className="bigdataTxt_Wrap01">
                업체별로 물류비와 운송정보를  <br /> 확인할 수 있는 실시간 대시보드입니다.
              </div>
              <div className="bigdataBtn_Wrap"></div>
              <Button className="bigdataBtn01 btnOverCtrl05" variant="" onClick={() => POPUP("http://211.216.47.60:5601/app/dashboards#/view/616689f0-5ffc-11ec-8f4d-8b8b6198726b?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2y,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:%EB%B9%85%EB%8D%B0%EC%9D%B4%ED%84%B0%EB%B6%84%EC%84%9D_%ED%99%94%EB%A9%B4-%EB%AC%BC%EB%A5%98%EC%A0%95%EB%B3%B4%EC%B4%9D%EA%B3%84-%EC%97%85%EC%B2%B4%EB%B3%84%ED%98%84%ED%99%A9%EC%A0%95%EB%B3%B4,viewMode:view)", '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                업체별 물류정보통계
              </Button>
            </div>
            <div className="bigdataPage_RightWrap">
              <img src={require("../../../images/bigdata_dashBtn06.jpg").default} className="" />
              <div className="bigdataPage_absolWrap02 btnOverView06"></div>
              <div className="bigdataTxt_Wrap02">
                출발지와 도착지별 물류비를<br /> 시각적으로 분석해볼 수 있는<br /> 실시간 대시보드입니다.
              </div>
              <div className="bigdataBtn_Wrap03"></div>
              <Button className="bigdataBtn03 btnOverCtrl06" variant="" onClick={() => POPUP("http://211.216.47.60:5601/app/dashboards#/view/0b0a92e0-5fe3-11ec-8f4d-8b8b6198726b?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-2y,to:now))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:%EB%B9%85%EB%8D%B0%EC%9D%B4%ED%84%B0%EB%B6%84%EC%84%9D_%ED%99%94%EB%A9%B4-%EC%B6%9C%EB%B0%9C%EC%A7%80_%EB%8F%84%EC%B0%A9%EC%A7%80%EB%B3%84_%EC%B4%9D%EA%B3%84%ED%99%94%EB%A9%B4,viewMode:view)", '', 'width=' + window.screen.width + ',height=' + (window.screen.height) + ',location=no,status=no,scrollbars=yes')}>
                출발지-도착지별 물류비총계
              </Button>
            </div>
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT020101;