import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

  function LatestOrders(props) {
    console.log('LatestOrders : ', props.BBS);
    return (
      <Card {...props}>
        <CardHeader title="포워딩세부내역" />
        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table className="listTable">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    No.
                  </TableCell>
                  <TableCell align="center">
                    송하인명
                  </TableCell>
                  <TableCell align="center">
                    수하인명
                  </TableCell>
                  <TableCell align="center">
                    대표품명
                  </TableCell>
                  <TableCell align="center">
                    포장수량
                  </TableCell>
                  <TableCell align="center">
                    총중량
                  </TableCell>
                  <TableCell align="center">
                    요청일자
                  </TableCell>
                  <TableCell align="center">
                    진행상황
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.BBS.map((bbs, index) => (
                  <TableRow
                    hover
                    key={bbs.BBS_ID}
                  >
                    <TableCell align="center">
                      {index+1}
                    </TableCell>
                    <TableCell align="center" style={{"cursor":"pointer"}}>
                      하나로TNS
                    </TableCell>
                    <TableCell align="right">
                      하나로TNS
                    </TableCell>
                    <TableCell align="right">
                      갤럭시 폴드2
                    </TableCell>
                    <TableCell align="right">
                      47
                    </TableCell>
                    <TableCell align="right">
                      134 KG
                    </TableCell>
                    <TableCell align="center">
                      {moment(bbs.CHANGE_DATE).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                        color="primary"
                        label={bbs.STATUS}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            발주관리 화면으로 이동
          </Button>
        </Box>
      </Card>
    );
  }

export default LatestOrders;
