import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useHistory } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Label from '../Label';
import { mockImgCommon } from '../../../../utils/mockImages';
import { home_url } from '../../../../js/globalProperties';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',                                                                                                                          
  position: 'absolute'
});

CommonGalleryCard.propTypes = {
  product: PropTypes.object
};

export default function CommonGalleryCard({ product, BBS, ...other  }) {
  
  useEffect(() => {
    callApi();
  },[product]);

  const history = useHistory();
  
  const { NTT_ID, BBS_ID, NTT_SJ, name,  cover, price, colors, status, priceSale , ATCH_FILE_ID, CHANGE_DATE, ADD_DATE} = product;
  
   console.log("commongallery : ", product);
  
  const handleBoardRowClick = (bbs_data) => {
    // alert('bbs_data : ', bbs_data, bbs_data['NTT_ID'],  {'bbs_id': { 'ntt_id': '19', 'bbs_id': bbs_data['BBS_ID'] } } );
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'] + '/' + bbs_data['NTT_ID'], state: bbs_data});
  };
  
  const [files, setFiles] = useState([]);
  var resultFiles = [];

  const callApi = () => {
    const requestFileOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'ATCH_FILE_ID':ATCH_FILE_ID, 'OPEN_API_YN':'Y'})
    };

    if(ATCH_FILE_ID != undefined && ATCH_FILE_ID != ""){
      fetch(home_url+'/api/BRD070010SVC/getSelectFileList', requestFileOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        var arry = [];

        resultFiles = data['DATA'];

        for(var n in resultFiles){
          arry.push(resultFiles[n]);
        }
        console.log("gallerycard file : ",resultFiles);

        setFiles(arry);
      });
    }
  }

  const FILELIST = [...Array(0)].map((_, index) => ({}));

  for (var n in files) {
    FILELIST.push(files[n]);
  } 

  // const fileImage = () => {
    if(files.length > 0){
      return(
        <Card>
      {/* 사진표시영 */}

        <Box sx={{ pt: '100%', position: 'relative' }} className="glyThumnail">
          {
            // 아래 row별 변수를 정확하게 매칭안해주면 오류가 발생될 수 있으니, 꼭 참고 할 것.. 웹에서 원인을 찾기 어려움
            
            files.map((row, index) => {
              const {FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN} = row;
              var filepath = FILE_STRE_COURS.substring(FILE_STRE_COURS.indexOf('BBS\\'), FILE_STRE_COURS.length) ;
              filepath = filepath.replace('\\', '/');
              console.log('filepath : ', filepath);
              const filename = home_url+"/api/"+filepath+"/"+STRE_FILE_NM;
              // console.log('filename : ', filename);
              if(filename !== null && filename !== ""){
                return (
                  <ProductImgStyle alt={ORIGNL_FILE_NM} src={filename} />
                );
              } else {
                return (
                  <ProductImgStyle alt="이미지없음" src={require("../../../../images/no_image.png").default} />
                );
              }
            })
          }
        </Box>
        <Stack spacing={2} sx={{ p: 0 }} className="thumbnail_ttWrap"
          onClick={() => handleBoardRowClick({BBS, NTT_ID, BBS_ID })}
        >
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            {status && (
            <Label className="thumbnail_newIcon">
              N
            </Label>
          )}
            <Typography variant="subtitle2" noWrap className="thumbnail_name">
              {NTT_SJ}
            </Typography>
          </Link>
          <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
            <Typography variant="subtitle1" className="thumbnail_date">
              <Typography
                component="span"
                variant="body1">
              </Typography>
              
              {/* if({CHANGE_DATE} === ""){ */}
                {ADD_DATE}  
              {/* } else {
                {CHANGE_DATE}
              } */}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      );

    } else {
      return (
        <Card>

        <Box sx={{ pt: '100%', position: 'relative' }} className="glyThumnail">
          {
            <ProductImgStyle alt="이미지없음" src={require("../../../../images/no_image.png").default} />
          }
        </Box>
        <Stack spacing={2} sx={{ p: 0 }} className="thumbnail_ttWrap"
          onClick={() => handleBoardRowClick({BBS, NTT_ID, BBS_ID })}
        >
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            {status && (
            <Label className="thumbnail_newIcon">
              N
            </Label>
          )}
            <Typography variant="subtitle2" noWrap className="thumbnail_name">
              {NTT_SJ}
            </Typography>
          </Link>
          <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
            <Typography variant="subtitle1" className="thumbnail_date">
              <Typography
                component="span"
                variant="body1">
              </Typography>
              
              {/* if({CHANGE_DATE} === ""){ */}
                {ADD_DATE}  
              {/* } else {
                {CHANGE_DATE}
              } */}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    );
   }
}
