import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { brdbd003Actions } from "../../../slice/brdbd003Slice";
import DetailOrEdit from "./BoardForm/DetailOrEdit";
import BoardComment from "./BoardComment";
import GalleryOrEdit from "./BoardForm/GalleryOrEdit";
import Video from "./BoardForm/Video";
import { home_url } from '../../../js/globalProperties';
import { DEXT5Editor } from "../../DEXT5Editor";

import {
  Box,
  Button,
} from '@material-ui/core';
import { array } from "yup";

function RegisterPage(props) {
  // console.log("ATCH_POSBL_FILE_NUMBER : ", props.location.state.BBS.ATCH_POSBL_FILE_NUMBER);
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const [brddata, setBrdbr003] = useState([]);
  const [files, setFiles] = useState([]);
  const [BBS, setBBS] = useState();
  const location = useLocation();

  var items = new Array();
  var resultFiles = [];

  const onGoTotalBoard = useCallback(() => history.push('/app/commonboard'), [history]);
  const goBack = () => { history.goBack(); };
  const goHome = () => { history.push('/'); };

  const callApi = () => {
    const requestOptionsBRD1 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'BBS_ID': location.pathname.split('/')[3], 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD010010SVC/selectBbs', requestOptionsBRD1)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        let items = data['DATA'];
        setBBS(items[0]);
      });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'NTT_ID': location.pathname.split('/')[4], 'BBS_ID': location.pathname.split('/')[3], 'OPEN_API_YN': 'Y', 'READ': 'Y' })
    };
    fetch(home_url + '/api/BRD030010SVC/getSelectBbsDetailList', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        items = data['DATA'];
        console.log("items : ", items);
        if (items[0].SECRET_AT === 'Y') {
          if (localStorage.getItem("USER_AUTH") !== 'A' && items[0].ADD_USER_ID !== localStorage.getItem("USER_ID") && props.location.state.add_user_id !== localStorage.getItem("USER_ID")) {
            alert('비밀글은 작성자와 관리자만 열람할 수 있습니다.');
            history.goBack();
            return false;
          }
        }
        setBrdbr003(items);
        const requestFileOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'ATCH_FILE_ID': items[0]['ATCH_FILE_ID'], 'OPEN_API_YN': 'Y' })
        };

        if (items[0]['ATCH_FILE_ID'] != undefined && items[0]['ATCH_FILE_ID'] != "") {
          fetch(home_url + '/api/BRD070010SVC/getSelectFileList', requestFileOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
              var arry = [];
              resultFiles = data['DATA'];

              for (var n in resultFiles) {
                arry.push(resultFiles[n]);
              }

              setFiles(arry);
            });
        }

      });


  }


  const { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, BBS_TY_CODE, CAT_NAME, REPLY_AT, COMMENT_AT, START_TIME, END_TIME, ALLDAY_YN, CAL_ID } = useSelector(
    (state) => ({
      NTT_ID: brddata[0] == undefined ? "" : brddata[0].NTT_ID,
      BBS_ID: brddata[0] == undefined ? "" : brddata[0].BBS_ID,
      NTT_NO: brddata[0] == undefined ? "" : brddata[0].NTT_NO,
      NTT_SJ: brddata[0] == undefined ? "" : brddata[0].NTT_SJ,
      NTT_CN: brddata[0] == undefined ? "" : brddata[0].NTT_CN,
      ANSWER_AT: brddata[0] == undefined ? "" : brddata[0].ANSWER_AT,
      PARNTSCTT_NO: brddata[0] == undefined ? "" : brddata[0].PARNTSCTT_NO,
      ANSWER_LC: brddata[0] == undefined ? "" : brddata[0].ANSWER_LC,
      SORT_ORDR: brddata[0] == undefined ? "" : brddata[0].SORT_ORDR,
      RDCNT: brddata[0] == undefined ? "" : brddata[0].RDCNT,
      USE_AT: brddata[0] == undefined ? "" : brddata[0].USE_AT,
      NTCE_BGNDE: brddata[0] == undefined ? "" : brddata[0].NTCE_BGNDE,
      NTCE_ENDDE: brddata[0] == undefined ? "" : brddata[0].NTCE_ENDDE,
      NTCR_ID: brddata[0] == undefined ? "" : brddata[0].NTCR_ID,
      NTCR_NM: brddata[0] == undefined ? "" : brddata[0].NTCR_NM,
      PASSWORD: brddata[0] == undefined ? "" : brddata[0].PASSWORD,
      ATCH_FILE_ID: brddata[0] == undefined ? "" : brddata[0].ATCH_FILE_ID,
      NOTICE_AT: brddata[0] == undefined ? "" : brddata[0].NOTICE_AT,
      SJ_BOLD_AT: brddata[0] == undefined ? "" : brddata[0].SJ_BOLD_AT,
      SECRET_AT: brddata[0] == undefined ? "" : brddata[0].SECRET_AT,
      BLOG_ID: brddata[0] == undefined ? "" : brddata[0].BLOG_ID,
      REMARKS: brddata[0] == undefined ? "" : brddata[0].REMARKS,
      STATUS: brddata[0] == undefined ? "" : brddata[0].STATUS,
      TIME_ZONE: brddata[0] == undefined ? "" : brddata[0].TIME_ZONE,
      ADD_DATE: brddata[0] == undefined ? "" : brddata[0].ADD_DATE,
      ADD_USER_ID: brddata[0] == undefined ? "" : brddata[0].ADD_USER_ID,
      ADD_USER_NAME: brddata[0] == undefined ? "" : brddata[0].ADD_USER_NAME,
      CHANGE_DATE: brddata[0] == undefined ? "" : brddata[0].CHANGE_DATE,
      CHANGE_USER_ID: brddata[0] == undefined ? "" : brddata[0].CHANGE_USER_ID,
      CHANGE_USER_NAME: brddata[0] == undefined ? "" : brddata[0].CHANGE_USER_NAME,
      BBS_TY_CODE: brddata[0] == undefined ? "" : brddata[0].BBS_TY_CODE,
      CAT_NAME: brddata[0] == undefined ? "" : brddata[0].CAT_NAME,
      REPLY_AT: brddata[0] == undefined ? "" : brddata[0].REPLY_AT,
      COMMENT_AT: brddata[0] == undefined ? "" : brddata[0].COMMENT_AT,
      START_TIME: brddata[0] == undefined ? "" : brddata[0].START_TIME,
      END_TIME: brddata[0] == undefined ? "" : brddata[0].END_TIME,
      ALLDAY_YN: brddata[0] == undefined ? "" : brddata[0].ALLDAY_YN,
      CAL_ID: brddata[0] == undefined ? "" : brddata[0].CAL_ID,

    }),
    shallowEqual
  );


  // console.log('items: ', brddata, brddata[0]==undefined?"":brddata[0].BBS_NAME);
  const [IsForUpdate, setIsForUpdate] = useState(false);

  useEffect(() => {
    callApi();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get("isForEdit") === "true") {
      dispatch(brdbd003Actions.fetchBrdbd003(props.match.params.brdbd003Id));
      setIsForUpdate(true);
    }
  }, [location]);

  const onRegisterChange = (event) => {
    const { name, value } = event.target;
    dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: value }));
  };
  const onUpdateBrdbd003 = (bbs_data) => {
    if (bbs_data.PASSWORD !== undefined) {
      let password = prompt("게시글 비밀번호를 입력해주세요.");

      if (password !== bbs_data.PASSWORD) {
        alert("게시글 비밀번호가 틀렸습니다.");
        return false;
      }
    }

    if (bbs_data.ANSWER_LC != 0) {
      bbs_data.REPLY = true;
    }
    history.push({ pathname: '/app/board/update', state: bbs_data });
  };
  const moveCommonBoard = (bbs_data) => {
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'], state: { 'bbs_id': bbs_data['BBS_ID'] } });
  };

  const moveRegisterReply = (bbs_data) => {
    bbs_data.REPLY = true;
    history.push({ pathname: '/app/board/register', state: bbs_data });
  };

  const onDeleteBrdbd003 = (bbs_data) => {

    // 게시글에 대한 권한처리 

    if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
      if (bbs_data.ADD_USER_ID != localStorage.getItem('USER_ID')) {
        alert('직접 작성한 게시물만 삭제 가능합니다.');   // 관리자 및 직접 작성한 글만 삭제 가능함 
        return false;
      }
    }

    if (window.confirm("삭제하시겠습니까?")) {
      dispatch(brdbd003Actions.deleteBrdbd003(bbs_data));
      history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'] });
    }
    else
      return false;
  }

  const onSubmitBrdbd003 = (event) => {
    event.preventDefault();
    if (NTT_SJ === "" || NTT_SJ === null || NTT_SJ === undefined) {
      alert("제목을 작성하십시오.");
      return false;
    }

    if (NTT_CN === "" || NTT_CN === null || NTT_CN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    const brdbd003 = {
      NTT_ID: NTT_ID,
      BBS_ID: BBS_ID,
      NTT_NO: NTT_NO,
      NTT_SJ: NTT_SJ,
      NTT_CN: NTT_CN,
      ANSWER_AT: ANSWER_AT,
      PARNTSCTT_NO: PARNTSCTT_NO,
      ANSWER_LC: ANSWER_LC,
      SORT_ORDR: SORT_ORDR,
      RDCNT: RDCNT,
      USE_AT: USE_AT,
      NTCE_BGNDE: NTCE_BGNDE,
      NTCE_ENDDE: NTCE_ENDDE,
      NTCR_ID: NTCR_ID,
      NTCR_NM: NTCR_NM,
      PASSWORD: PASSWORD,
      ATCH_FILE_ID: ATCH_FILE_ID,
      NOTICE_AT: NOTICE_AT,
      SJ_BOLD_AT: SJ_BOLD_AT,
      SECRET_AT: SECRET_AT,
      BLOG_ID: BLOG_ID,
      REMARKS: REMARKS,
      STATUS: STATUS,
      TIME_ZONE: TIME_ZONE,
      ADD_DATE: ADD_DATE,
      ADD_USER_ID: ADD_USER_ID,
      ADD_USER_NAME: ADD_USER_NAME,
      CHANGE_DATE: CHANGE_DATE,
      CHANGE_USER_ID: CHANGE_USER_ID,
      CHANGE_USER_NAME: CHANGE_USER_NAME,
      CAT_NAME: CAT_NAME,
      REPLY_AT: REPLY_AT,
      COMMENT_AT: COMMENT_AT
    };

    if (IsForUpdate) {
      dispatch(brdbd003Actions.updateBrdbd003(brdbd003));
    } else {
      dispatch(brdbd003Actions.registerBrdbd003(brdbd003));
    }
  };
  const FILELIST = [...Array(0)].map((_, index) => ({}));

  for (var n in files) {
    FILELIST.push(files[n]);
  }

  //게시판 타입별 디테이 화면
  const boardDetailType = (boardType) => {

    if (boardType.BBS_TY_CODE === '1') {
      return (
        <DetailOrEdit
          BBS={BBS}
          FILES={FILELIST}
          NTT_ID={NTT_ID}
          BBS_ID={BBS_ID}
          NTT_NO={NTT_NO}
          NTT_SJ={NTT_SJ}
          NTT_CN={NTT_CN}
          ANSWER_AT={ANSWER_AT}
          PARNTSCTT_NO={PARNTSCTT_NO}
          ANSWER_LC={ANSWER_LC}
          SORT_ORDR={SORT_ORDR}
          RDCNT={RDCNT}
          USE_AT={USE_AT}
          NTCE_BGNDE={NTCE_BGNDE}
          NTCE_ENDDE={NTCE_ENDDE}
          NTCR_ID={NTCR_ID}
          NTCR_NM={NTCR_NM}
          PASSWORD={PASSWORD}
          ATCH_FILE_ID={ATCH_FILE_ID}
          NOTICE_AT={NOTICE_AT}
          SJ_BOLD_AT={SJ_BOLD_AT}
          SECRET_AT={SECRET_AT}
          BLOG_ID={BLOG_ID}
          REMARKS={REMARKS}
          STATUS={STATUS}
          TIME_ZONE={TIME_ZONE}
          ADD_DATE={ADD_DATE}
          ADD_USER_ID={ADD_USER_ID}
          ADD_USER_NAME={ADD_USER_NAME}
          CHANGE_DATE={CHANGE_DATE}
          CHANGE_USER_ID={CHANGE_USER_ID}
          CHANGE_USER_NAME={CHANGE_USER_NAME}
          CAT_NAME={CAT_NAME}
          START_TIME={START_TIME}
          END_TIME={END_TIME}
          ALLDAY_YN={ALLDAY_YN}
          CAL_ID={CAL_ID}

          handleRegisterChange={onRegisterChange}
          handleSubmit={onSubmitBrdbd003}
          handleTotalBoard={onGoTotalBoard}
          handleGoBack={goBack}
          updateRequest={IsForUpdate}
        />
      )
    } else if (boardType.BBS_TY_CODE === '2') {
      // alert("{BBS_TY_CODE} : " + boardType.BBS_TY_CODE);
      return (
        <Video
          BBS={BBS}
          FILES={FILELIST}
          NTT_ID={NTT_ID}
          BBS_ID={BBS_ID}
          NTT_NO={NTT_NO}
          NTT_SJ={NTT_SJ}
          NTT_CN={NTT_CN}
          ANSWER_AT={ANSWER_AT}
          PARNTSCTT_NO={PARNTSCTT_NO}
          ANSWER_LC={ANSWER_LC}
          SORT_ORDR={SORT_ORDR}
          RDCNT={RDCNT}
          USE_AT={USE_AT}
          NTCE_BGNDE={NTCE_BGNDE}
          NTCE_ENDDE={NTCE_ENDDE}
          NTCR_ID={NTCR_ID}
          NTCR_NM={NTCR_NM}
          PASSWORD={PASSWORD}
          ATCH_FILE_ID={ATCH_FILE_ID}
          NOTICE_AT={NOTICE_AT}
          SJ_BOLD_AT={SJ_BOLD_AT}
          SECRET_AT={SECRET_AT}
          BLOG_ID={BLOG_ID}
          REMARKS={REMARKS}
          STATUS={STATUS}
          TIME_ZONE={TIME_ZONE}
          ADD_DATE={ADD_DATE}
          ADD_USER_ID={ADD_USER_ID}
          ADD_USER_NAME={ADD_USER_NAME}
          CHANGE_DATE={CHANGE_DATE}
          CHANGE_USER_ID={CHANGE_USER_ID}
          CHANGE_USER_NAME={CHANGE_USER_NAME}

          handleRegisterChange={onRegisterChange}
          handleSubmit={onSubmitBrdbd003}
          handleTotalBoard={onGoTotalBoard}
          handleGoBack={goBack}
          updateRequest={IsForUpdate}
        />
      )
    } else if (boardType.BBS_TY_CODE === '3') {
      return (
        <GalleryOrEdit
          BBS={BBS}
          FILES={FILELIST}
          NTT_ID={NTT_ID}
          BBS_ID={BBS_ID}
          NTT_NO={NTT_NO}
          NTT_SJ={NTT_SJ}
          NTT_CN={NTT_CN}
          ANSWER_AT={ANSWER_AT}
          PARNTSCTT_NO={PARNTSCTT_NO}
          ANSWER_LC={ANSWER_LC}
          SORT_ORDR={SORT_ORDR}
          RDCNT={RDCNT}
          USE_AT={USE_AT}
          NTCE_BGNDE={NTCE_BGNDE}
          NTCE_ENDDE={NTCE_ENDDE}
          NTCR_ID={NTCR_ID}
          NTCR_NM={NTCR_NM}
          PASSWORD={PASSWORD}
          ATCH_FILE_ID={ATCH_FILE_ID}
          NOTICE_AT={NOTICE_AT}
          SJ_BOLD_AT={SJ_BOLD_AT}
          SECRET_AT={SECRET_AT}
          BLOG_ID={BLOG_ID}
          REMARKS={REMARKS}
          STATUS={STATUS}
          TIME_ZONE={TIME_ZONE}
          ADD_DATE={ADD_DATE}
          ADD_USER_ID={ADD_USER_ID}
          ADD_USER_NAME={ADD_USER_NAME}
          CHANGE_DATE={CHANGE_DATE}
          CHANGE_USER_ID={CHANGE_USER_ID}
          CHANGE_USER_NAME={CHANGE_USER_NAME}

          handleRegisterChange={onRegisterChange}
          handleSubmit={onSubmitBrdbd003}
          handleTotalBoard={onGoTotalBoard}
          handleGoBack={goBack}
          updateRequest={IsForUpdate}
        />
      )
    }

  }

  // console.log('FILELIST-- : ', FILELIST);
  return (
    <>
      {boardDetailType({ BBS_TY_CODE })}
      {COMMENT_AT === 'Y' &&
        <BoardComment
          BBS_ID={location.pathname.split('/')[3]}
          NTT_ID={location.pathname.split('/')[4]}
        />
      }
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          onClick={() => history.goBack()}
          // onClick={() => goBack}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          이전페이지
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => moveCommonBoard({ BBS_ID })}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          목록으로
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => onUpdateBrdbd003({ BBS, NTT_ID, BBS_ID, ANSWER_LC })}
          // color="primary"
          className="ButtonStyle02"
          variant="contained"
          style={{ display: NTCR_ID === localStorage.getItem('USER_ID') ? '' : 'none' }}
        >
          수정
        </Button>
        &nbsp;&nbsp;
        {/* <Button onClick={() => onDeleteBrdbd003({ BBS, NTT_ID, BBS_ID, ADD_USER_ID })} */}
        <Button onClick={() => onDeleteBrdbd003({ NTT_ID, BBS_ID, CAL_ID })}
          // color="primary"
          className="ButtonStyle02"
          variant="contained"
          style={{ display: NTCR_ID === localStorage.getItem('USER_ID') || localStorage.getItem("USER_AUTH") === 'A' ? '' : 'none' }}
        >
          삭제
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => moveRegisterReply({ BBS, NTT_ID, BBS_ID, NTT_SJ, ANSWER_LC, SORT_ORDR, ADD_USER_ID })}
          // color="primary"
          // style={{ display: BBS_TY_CODE === '1' && NTCR_ID !== localStorage.getItem('USER_ID') && BBS.REPLY_POSBL_AT !== 'N' && BBS.TMPLAT_ID !== '2' && REPLY_AT === 'Y' ? '' : 'none' }}
          style={{ display: BBS_TY_CODE === '1' && NTCR_ID !== localStorage.getItem('USER_ID') && BBS.TMPLAT_ID !== '2' && REPLY_AT === 'Y' ? '' : 'none' }}
          className="ButtonStyle02"
          variant="contained">
          답변
        </Button>
        {/* &nbsp;&nbsp;
            <Button type="primary" onClick={onSubmitBrdbd003}
              color="primary"
              variant="contained">
              등록
            </Button> */}
      </Box>
    </>
  );
}

export default RegisterPage;