import { useState, useEffect, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"
import { DEXT5Editor } from "../../DEXT5Editor";
import {
    Box,
    Button,
    Container,
    Stack,
    Typography,
    Divider,
    Pagination,
    TextField,
    TablePagination,
    Input
} from '@material-ui/core';

const TradeRegister = () => {
    const history = useHistory();
    const [FILE, setFILE] = useState([]);
    const [PREVIEW, setPREVIEW] = useState([]);
    const [CATS, setCATS] = useState([]);

    const fileHandler = (e) => {
        let files = e.target.files;
        let file = FILE;
        let isNotImage = false;
        for (let [key, value] of Object.entries(files)) {
            if (isNaN(parseInt(key))) break;
            let t = value.type.split('/').pop().toLowerCase();
            if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
                isNotImage = true;
                continue;
            }
            if (isNotImage) alert("이미지 파일만 첨부 가능합니다.");
            file.push(value);
        }
        setFILE(file);
        previewHandler();
    }

    const previewHandler = () => {
        let preview = [];
        for (let i in FILE) {
            preview.push(window.URL.createObjectURL(FILE[i]));
            console.log("preview : ", preview[i]);
        }
        setPREVIEW(preview);
    }

    const deleteFileHandler = (f) => {
        let file = FILE;
        let preview = PREVIEW;

        for (let i in preview) {
            if (preview[i] === f) {
                file.splice(i, 1);
                break;
            }
        }
        setFILE(file);
        previewHandler();
    }

    const getCATS = () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
            body: JSON.stringify({ OPEN_API_YN: 'Y' })
        };
        fetch(home_url + '/api/MBP040010SVC/getCode', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.MBP040010G1) setCATS(data.MBP040010G1);
            })
    }

    const [MBP040010F1, setMBP040010F1] = useState({
        "DEAL_NO": null,
        "DEAL_TYPE": "SEL",
        "TITLE": "",
        "MODEL_STATUS": "",
        "CATEGORY": "",
        "MODEL_NAME": "",
        "MODEL_YEAR": "",
        "MODEL_NO": "",
        "LOCATION": "",
        "AMOUNT": "",
        "EQ_DESC": "",
        "UPDATE_USER_ID": null
    })

    const save = () => {
        let temp = MBP040010F1;
        temp.EQ_DESC = window.DEXT5.GetBodyValue();
        setMBP040010F1(temp);
        if (MBP040010F1.TITLE === "") { alert("제목을 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_STATUS === "") { alert("상품 상태을 입력해주세요!"); return false; }
        if (MBP040010F1.CATEGORY === "") { alert("카테고리를 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_NAME === "") { alert("상품명을 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_YEAR === "") { alert("구매년도 입력해주세요!"); return false; }
        if (MBP040010F1.MODEL_NO === "") { alert("상품번호를 입력해주세요!"); return false; }
        if (MBP040010F1.LOCATION === "") { alert("장소를 입력해주세요!"); return false; }
        if (MBP040010F1.AMOUNT === "") { alert("가격을 입력해주세요!"); return false; }
        if (MBP040010F1.EQ_DESC === "") { alert("상품설명을 입력해주세요!"); return false; }

        let param = MBP040010F1;

        if (!window.confirm('저장하시겠습니까?')) return false;

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
            body: JSON.stringify({ "MBP040010F1": param })
        };
        fetch(home_url + '/api/MBP040010SVC/save', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data.TYPE != 1) {
                    alert(data.MSG);
                    return false;
                }
                let company_code = data.MBP040010F1.COMPANY_CODE;
                let deal_type = data.MBP040010F1.DEAL_TYPE;
                let ref_no = data.MBP040010F1.DEAL_NO;
                let formData = new FormData();
                formData.append("S_FUNC_CODE", "DL");
                formData.append("REF_NO", ref_no);
                formData.append("REF_TYPE", "JOB");
                formData.append("JOB_NO", "N");
                formData.append("PUBLIC_YN", "N");
                formData.append("REAMRKS", null);
                formData.append("file", null);

                requestOptions = {
                    method: 'POST',
                    headers: { "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                    body: formData
                };

                for (let i in FILE) {
                    formData.set('file', FILE[i]);
                    console.log(FILE[i]);
                    fetch(home_url + '/api/file/upload', requestOptions)
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                            if (data.TYPE != 1) {
                                alert(data.MSG);
                                return false;
                            }
                        })
                }
                alert('저장되었습니다!');
                history.push({ pathname: '/app/trade/' + deal_type + '/' + company_code + '/' + ref_no });
            });
    }

    useEffect(() => {
        getCATS();
    }, []);

    return (
        <>
            <Typography className="listTable_tt">
                중고장비 판매
                {/* <img alt="찜하기" src={require("../../../images/heart_off.png").default} className="tradeHeart_Img02" /> */}
                {/* <img alt="찜하기" src={require("../../../images/heart_on.png").default} className="tradeHeart_Img02"/> */}
            </Typography>
            <table className="srchTable">
                <colgroup>

                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "30%" }}></col>
                    <col style={{ width: "20%" }}></col>
                    <col style={{ width: "30%" }}></col>
                </colgroup>
                <tr>
                    <th>
                        제목
                    </th>
                    <td colSpan="3">
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.TITLE}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "TITLE": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="tradeWriteImgWrapTh">
                        <span className="tradeWriteImgSpan">
                            <img alt="" src={require("../../../images/camera.png").default} className="tradeCameraImg" />
                            <label htmlFor="fileHandler">
                                <input
                                    accept="image/*"
                                    id="fileHandler"
                                    style={{ display: 'none' }}
                                    multiple
                                    type="file"
                                    onChange={(e) => fileHandler(e)} />
                                <Button className="NoteAllow_bluBtn tradeBluBtn" variant="contained" component="span">
                                    사진첨부
                                </Button>
                            </label>
                        </span>
                    </th>
                    <td className="tradeWriteImgWrap" colSpan="3">
                        {PREVIEW.map((row, index) => {
                            return (
                                <span key={index}>
                                    <img src={row} />
                                    <span onClick={() => { deleteFileHandler(row) }}>X</span>
                                </span>
                            )
                        })}
                    </td>
                </tr>
                <tr>
                    <th>카테고리</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            style={{ textAlign: 'center' }}
                            value={MBP040010F1.CATEGORY}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "CATEGORY": e.target.value }))}
                        >
                            <option value="">카테고리</option>
                            {CATS && CATS.map((row) => (
                                <option
                                    key={row.ENG_VALUE}
                                    value={row.ENG_VALUE}
                                >
                                    {row.LOC_VALUE}
                                </option>
                            ))}
                        </TextField>
                    </td>
                    <th>상태</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            value={MBP040010F1.MODEL_STATUS}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_STATUS": e.target.value }))}
                        >
                            <option value="">상태</option>
                            <option value="H">상</option>
                            <option value="M">중</option>
                            <option value="L">하</option>
                        </TextField>
                    </td>
                </tr>
                <tr>

                    <th>상품번호</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.MODEL_NO}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_NO": e.target.value }))}
                        />
                    </td>

                    <th>상품명</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.MODEL_NAME}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_NAME": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th>구매년도</th>
                    {/* <td><div className="tradeDateInput tradeDatePicBg">2021년 01월 01일</div></td> */}
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="String"
                            placeholder=""
                            value={MBP040010F1.MODEL_YEAR}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "MODEL_YEAR": e.target.value }))}
                        />
                    </td>
                    <th>가격</th>
                    <td>
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="String"
                            placeholder=""
                            value={MBP040010F1.AMOUNT}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "AMOUNT": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <th>장소</th>
                    <td colSpan="3">
                        <TextField className="bbsInputSize"
                            fullWidth
                            name=""
                            type="string"
                            placeholder=""
                            value={MBP040010F1.LOCATION}
                            onChange={(e) => setMBP040010F1((prevState) => ({ ...prevState, "LOCATION": e.target.value }))}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="4">
                        <DEXT5Editor />
                    </td>
                </tr>
            </table>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}>
                &nbsp;&nbsp;
                <Button
                    onClick={() => history.goBack()}
                    className="ButtonStyle03"
                    variant="contained">
                    목록으로
                </Button>
                &nbsp;&nbsp;
                <Button
                    type="primary"
                    className="ButtonStyle02"
                    variant="contained"
                    onClick={() => { save(); }}
                >
                    저장
                </Button>
            </Box>
        </>
    )
}
export default TradeRegister;