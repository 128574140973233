import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010601(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          특허
          </Typography>
          {/* <Divider/> */}
          <div className="common_totalWrap" style={{position:"relative"}}>
          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            특허 등록 내용 (LoRa망을 이용한 IoT 기반 차량 관제 플랫폼)
          </div>
          <div className="common_txt">
            - 출원번호 : 10-2020-0021960
- 출원일자 : 2020-02-24<br/>
- 등록번호 : 10-2248272<br/>
- 등록일자 : 2021-05-07<br/>
- 출원인 : 주식회사 하나로티앤에스<br/>
- 발명의 목적<br/>
&nbsp;&nbsp;설치된 복수의 센서모듈을 통해 화물 주변 환경을 감지하여 화물 배송 상태와 화물 차량의<br/>
&nbsp;&nbsp;위치 정보를 종합적으로 관리할 수 있는 LoRa망을 이용한 IoT 기반 차량 관제 플랫폼을 
제공하는 것

          </div>
          <br/>
          <div className="common_img">
            <img src={require("../../../images/certificate_img.png").default} className="" />
          </div>

          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            차량관제 내용
          </div>
          <div className="common_img">
            <div className="common_imgTT02">
                              차량관제 모니터링[웹뷰 -1]
            </div>
            <div className="common_txtAlign">
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>         
                                위치추적
              </div>
              <div className="common_txt">
                - 최종위치 추적 : 배송 차량의 최종 위치를 맵에서 확인<br/>
                - 이동경로 추적 : 배송 차량의 운행 추적 경로를 맵에서 확인
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>         
                                배송정보
              </div>
              <div className="common_txt">
                - 배송차량의 거점(목적지)/경우지 출/도착 시간 정보 자동 제공<br/>
                - 배포된 Mobile App(WINS-BizTalk)으로 배송정보 전송 기능
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                기준정보 관리
              </div>
              <div className="common_txt">
                - GPS번호/차량정보 <br/>
                - 거점(목적지)관리 : 거점 좌표 등록 및 출/도착 반경 설정<br/>
                - 경유지(Fence)관리 : Fence 좌표 등록 및 출/도착 반경 설정<br/>
                - ERP의 기준정보 관리 기능연계
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                설정된 특정지역 접근/이탈시 비상 알람 기능
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                권한 설정
              </div>
              <div className="common_txt">
                - 개인 별/그룹 별/고객사별 권한 설정(메뉴, 제공기능, 데이터 조회)<br/>
              </div>
            </div>
            <br/><br/>
            <img src={require("../../../images/patent_introduce01.jpg").default} className="" />
            <br/><br/><br/>
            <div className="common_imgTT02">
                              차량관제 모니터링[웹뷰 -2]
            </div>
            <br/><br/>
            <img src={require("../../../images/patent_introduce02.jpg").default} className="" />
          </div>

          </div>
        </Card>
      </Page>
    </>  
  );
}

export default PLT010601;