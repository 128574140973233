import React, { useEffect } from "react";
import ProductList2 from "./product2/ProductList";

function PortalBoard() {
  return (
    <div> 
        <ProductList2/>
    </div>
  );
}
export default PortalBoard;