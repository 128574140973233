import { useState, useEffect, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"
// material
import {
  Card,
  Table,
  Button,
  TextField,
  Box,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
// components
// ----------------------------------------------------------------------
function BoardComment(props) {
  const [Comments, setComments] = useState([]);

  const getComment = () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({
          "MBP040010F1": {
            "REF_NO": props.REF_NO
          }
        }),
      };
      fetch(home_url + "/api/MBP040010SVC/getReply", requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.MBP040010G1) setComments(data.MBP040010G1);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getComment();
  }, []);

  //-------------------------------------------------------------------------------
  //Insert Comment
  var answerRegistText = '';

  const answerRegist = (event) => {
    event.preventDefault();
    // setAnswerRegistText(event.target.value);
    answerRegistText = event.target.value;
  }

  const insertComment = () => {
    if (answerRegistText.length > 0) {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
          body: JSON.stringify({
            "MBP040010F1": {
              "DEAL_TYPE": "SEL",
              "DEAL_NO": props.REF_NO,
              "SEQ": null,
              "REPLY_TYPE": "SEL",
              "REPLY_CONTENTS": answerRegistText,
              "REF_NO": props.REF_NO,
              "UPDATE_USER_ID": localStorage.getItem("USER_ID")
            }
          }),
        };
        fetch(home_url + "/api/MBP040010SVC/saveReply", requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            getComment();
          });
      } catch (error) {
        console.log(error);
      }
      answerRegistText = '';
    }
    else {
      alert("댓글을 작성해주세요!");
    }
  }
  //-------------------------------------------------------------------------------

  //Update Comment
  var answerUpdateText = '';
  const [updateNo, setUpdateNo] = useState(0);
  const answerUpdate = (event) => {
    event.preventDefault();
    // setAnswerUpdateText(event.target.value);
    answerUpdateText = event.target.value;
  }

  const onCommentUpdate = (answerNo, wrterId) => {
    if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
      if (wrterId != localStorage.getItem('USER_ID')) {
        alert('직접 작성한 게시물만 삭제 가능합니다.');   // 관리자 및 직접 작성한 글만 삭제 가능함 
        return false;
      }
    }
    setUpdateNo(answerNo);
  }
  const updateComment = (seq) => {
    if (!window.confirm("수정하시겠습니까?")) return false;
    if (answerUpdateText.length > 0) {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
          body: JSON.stringify({
            "MBP040010F1": {
              "DEAL_TYPE": "SEL",
              "DEAL_NO": props.REF_NO,
              "SEQ": seq,
              "REPLY_TYPE": "SEL",
              "REPLY_CONTENTS": answerUpdateText,
              "REF_NO": props.REF_NO,
              "UPDATE_USER_ID": localStorage.getItem("USER_ID")
            }
          }),
        };
        fetch(home_url + "/api/MBP040010SVC/saveReply", requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            setUpdateNo();
            getComment();
          });
      } catch (error) {
        console.log(error);
      }
    }
    else
      alert("댓글을 작성해주세요!");
    return false;
  }
  //-------------------------------------------------------------------------------

  //Delete Comment
  const deleteComment = (add_user_id, seq) => {
    // console.log('삭제 : ', data, data['WRTER_ID'], localStorage.getItem('USER_AUTH'), localStorage.getItem('USER_ID') );

    if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
      if (add_user_id != localStorage.getItem('USER_ID')) {
        alert('직접 작성한 게시물만 삭제 가능합니다.');   // 관리자 및 직접 작성한 글만 삭제 가능함 
        return false;
      }
    }
    if (window.confirm("삭제하시겠습니까?"))
      try {
        const requestOptions = {
          method: 'POST',
          headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
          body: JSON.stringify({
            "MBP040010F1": {
              "DEAL_TYPE": "SEL",
              "DEAL_NO": props.REF_NO,
              "REF_NO": props.REF_NO,
              "SEQ": seq,
              "UPDATE_USER_ID": localStorage.getItem("USER_ID")
            }
          }),
        };
        fetch(home_url + "/api/MBP040010SVC/deleteReply", requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            getComment();
          });
      } catch (error) {
        console.log(error);
      }
    else
      return false;
  }
  //-------------------------------------------------------------------------------;

  //Nested Comment Toggle
  const [openInputDiv, setOpenInputdiv] = useState(0);
  //-------------------------------------------------------------------------------;

  //Comments List
  const CommentsList = (props) => {
    return (
      <>
        <Box>
          <div className="comment_TotalWrap">
            <div className="comment_contentsWrap" style={{ width: '795px' }}>
              <div className="commenterNm">
                {props.ADD_USER_NAME}
              </div>
              <div style={{ display: updateNo !== props.SEQ ? '' : 'none' }}>{props.REPLY_CONTENTS}</div>
              <div style={{ display: updateNo === props.SEQ ? '' : 'none' }}>
                <TextField
                  fullWidth
                  onChange={answerUpdate}
                  placeholder={props.REPLY_CONTENTS}
                  onKeyPress={
                    (e) => {
                      if (e.key === 'Enter') {
                        { updateComment(props.SEQ) }
                      }
                    }} />
              </div>
              <div className="commenterDD">{props.ADD_DATE}</div>
              <div className="comment_BtnWrap">
                <Button style={{ display: updateNo !== props.SEQ && localStorage.getItem('USER_ID') === props.ADD_USER_ID ? '' : 'none' }} onClick={() => onCommentUpdate(props.SEQ, props.ADD_USER_ID)} className="commentBtn  float_R">수정</Button>
                <Button style={{ display: updateNo === props.SEQ ? '' : 'none' }} onClick={() => updateComment(props.SEQ)} className="commentBtn  float_R">완료</Button>
                <Button style={{ display: updateNo !== props.SEQ && localStorage.getItem('USER_ID') === props.ADD_USER_ID || localStorage.getItem('USER_AUTH') === 'A' ? '' : 'none' }} onClick={() => deleteComment(localStorage.getItem("USER_ID"), props.SEQ)} className="commentBtn  float_R">삭제</Button>
                <Button style={{ display: updateNo === props.SEQ ? '' : 'none' }} onClick={() => setUpdateNo(0)} className="commentBtn  float_R">취소</Button>
              </div>
            </div>
          </div>
        </Box>
      </>
    )

  }
  //Input Comment
  const InputComment = () => {
    return (
      <Box>
        <Table>
          <colgroup>
            <col style={{ width: "90%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  placeholder={localStorage.getItem('USER_ID') ? '댓글을 입력하세요.' : '로그인 후 이용 가능합니다.'}
                  disabled={localStorage.getItem('USER_ID') ? false : true}
                  type="string"
                  required
                  variant="outlined"
                  onChange={answerRegist}
                  label={localStorage.getItem("user_id")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyPress={
                    (e) => {
                      if (e.key === 'Enter') {
                        { insertComment({ "ANSWER_LC": null, "ANSWER_NO": null }) }
                      }
                    }}
                />
              </TableCell>
              <TableCell style={{ paddingLeft: "0px" }}>
                <Button className="commentBtn02" onClick={() => insertComment({ "ANSWER_LC": null, "ANSWER_NO": null })}>등록</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    )
  }
  //-------------------------------------------------------------------------------;
  return (
    <>
      <InputComment />
      {Comments && Comments.map((row, index) => {
        return (
          <>
            <CommentsList
              SEQ={String(row.SEQ)}
              ADD_DATE={row.ADD_DATE}
              ADD_USER_ID={row.ADD_USER_ID}
              ADD_USER_NAME={row.ADD_USER_NAME}
              REPLY_CONTENTS={row.REPLY_CONTENTS}
            />
          </>
        )
      })}
    </>
  )
}
export default BoardComment;
