import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { format, formatDistanceToNow } from 'date-fns';
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"

import {
  Button,
  Box,
  TextField,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('COMPANY_CODE') == undefined || localStorage.getItem('COMPANY_CODE') == '') {
      alert('기업회원으로 등록된 사용자만 얼라이언스 신청을 할 수 있습니다.');
      window.close();
    }

  }, []);
  const onSubmit = (event) => {
    // console.log('잘 호출이 되는가 ', event, event.target.BSNS_NM.value);
    event.preventDefault();
    // const BSNS_NM = event.target.BSNS_NM.value;
    // const TASK_NM = event.target.TASK_NM.value;
    // const SPIN_ITT = event.target.SPIN_ITT.value;
    // const RSPS_NM = event.target.RSPS_NM.value;
    // const ALL_BSNS_PRID = event.target.ALL_BSNS_PRID.value;
    // const NOW_BSNS_PRID = event.target.NOW_BSNS_PRID.value;
    const PTCN_ENTR = event.target.PTCN_ENTR.value;
    const RPRS_NM = event.target.RPRS_NM.value;

    // if (BSNS_NM === "" || BSNS_NM === null || BSNS_NM === undefined) {
    //   alert("사업명을 입력해 주세요.");
    //   return false;
    // }

    // if (TASK_NM === "" || TASK_NM === null || TASK_NM === undefined) {
    //   alert("과제명을 입력해 주세요.");
    //   return false;
    // }

    // if (SPIN_ITT === "" || SPIN_ITT === null || SPIN_ITT === undefined) {
    //   alert("주관기관을 입력해 주세요.");
    //   return false;
    // }

    // if (RSPS_NM === "" || RSPS_NM === null || RSPS_NM === undefined) {
    //   alert("실무책임자명을 입력해 주세요.");
    //   return false;
    // }

    // if (ALL_BSNS_PRID === "" || ALL_BSNS_PRID === null || ALL_BSNS_PRID === undefined) {
    //   alert("총 사업기간을 입력해 주세요.");
    //   return false;
    // }

    // if (NOW_BSNS_PRID === "" || NOW_BSNS_PRID === null || NOW_BSNS_PRID === undefined) {
    //   alert("당해 사업기간을 입력해 주세요.");
    //   return false;
    // }

    if (PTCN_ENTR === "" || PTCN_ENTR === null || PTCN_ENTR === undefined) {
      alert("참여업체명을 입력해 주세요.");
      return false;
    }

    if (RPRS_NM === "" || RPRS_NM === null || RPRS_NM === undefined) {
      alert("대표(담당자)명을 입력해 주세요.");
      return false;
    }

    const requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({
        "COMPANY_CODE": localStorage.getItem('COMPANY_NAME')
      })
    };
    event.preventDefault();
    fetch(home_url + '/api/MMS060010SVC/checkJoinAllliance', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.TYPE === 1) { //얼라이언스 가입 체크 성공
          fetch(home_url + '/api/MMS060010SVC/JoinAllliance', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
              if (data.TYPE === 1) { //얼라이언스 가입 성공
                alert(data.MSG);
                window.close();
              } else { //얼라이언스 가입 실패
                alert(data.MSG);
                return false;
              }
            })
        } else { // 얼라이언스 가입 체크 실패
          alert(data.MSG);
          return false;
        }
      })
      .catch((error) => alert('삭제에 실패했습니다'));
  };
  return (
    <>
      <Page style={{ 'padding': '20px' }}>
        <form onSubmit={onSubmit}>
          <Card>
            <Typography className="listTable_tt">
              스마트 물류플랫폼 얼라이언스 참여 의향서
            </Typography>
            스마트 물류 플랫폼 얼라이언스 참여 의향서를 작성하여 제출합니다.
            <Divider />
            <Card style={{ 'width': '100%' }}>
              <table className="srchTable">
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "30%" }} />
                </colgroup>
                <tr>
                  <th>사업명</th>
                  <td colspan="3">
                    스마트물류플랫폼 구축 및 운영사업
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="BSNS_NM"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                </tr>
                <tr>
                  <th>과제명</th>
                  <td colspan="3">
                    스마트물류플랫폼 구축 및 운영사업
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="TASK_NM"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                </tr>
                <tr>
                  <th>주관기관</th>
                  <td>
                    (주) 하나로티앤에스
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      name="SPIN_ITT"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                  <th>실무책임자</th>
                  <td colspan="3">
                    박진모
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      name="RSPS_NM"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                </tr>
                <tr>
                  <th>총 사업기간</th>
                  <td>
                    2020.09 ~ 2022.12
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      name="ALL_BSNS_PRID"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                  <th>당해 사업기간</th>
                  <td colspan="3">
                    2년 4개월
                    {/* <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      name="NOW_BSNS_PRID"
                      type="string"
                      required
                      variant="outlined"
                    /> */}
                  </td>
                </tr>
                <tr>
                  <th>참여내역</th>
                  <td colspan="3">
                    - 스마트물류플랫폼 얼라이언스 참여 및 협력
                    <p />
                    - 얼라이언스 업체로 과제 공동 발굴 및 연계
                    <p />
                    - 기타 본 사업의 취지에 포함되는 사항
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div>한국산업단지공단 스마트 물류플랫폼 구축사업의 취지에 따라 상기 얼라이언스 참여 의향을 확인합니다.</div>
                    <br />
                    <div style={{ 'text-align': 'center' }}>{format(new Date(), 'yyyy. MM. dd.')}</div>
                    <br />
                    {/* <br/>
                    <div style={{'text-align':'right'}}><pre>참여업체 :                  (생  략)           </pre></div>
                    <br/>
                    <div style={{'text-align':'right'}}><pre>대표(담당자) :                (생  략)     (인)  </pre></div> */}
                  </td>
                </tr>
                <tr>
                  <th>참여업체 </th>
                  <td>
                    <TextField className="bbsInputSize"
                      fullWidth
                      inputProps={{ maxLength: 20 }}
                      name="PTCN_ENTR"
                      type="string"
                      value={localStorage.getItem('COMPANY_NAME')}
                      required
                      variant="outlined"
                    />
                  </td>
                  <th>대표(담당자)</th>
                  <td>
                    <TextField className="bbsInputSize"
                      style={{ 'width': '120px' }}
                      inputProps={{ maxLength: 20 }}
                      name="RPRS_NM"
                      type="string"
                      value={localStorage.getItem('USER_NAME_LOC')}
                      required
                      variant="outlined"
                    />
                    &nbsp;&nbsp;(인)
                  </td>
                </tr>
              </table>
              <br />
            </Card>
          </Card>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
          >
            <Button
              type="primary"
              //  onClick={onSubmit}
              className="ButtonStyle02"
              variant="contained">
              등록하기
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => window.close()}
              className="ButtonStyle03"
              variant="contained">
              닫기
            </Button>
          </Box>
        </form>
      </Page>
    </>
  );
}

export default PLT000101_01;