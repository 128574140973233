import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    createdAt: '27/03/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/1.jpeg',
    title: 'XX상사',
    totalDownloads: '594'
  },
  {
    id: uuid(),
    createdAt: '31/03/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/2.jpeg',
    title: 'XX 물류',
    totalDownloads: '625'
  },
  {
    id: uuid(),
    createdAt: '03/04/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/3.jpeg',
    title: 'XXXX 산업',
    totalDownloads: '857'
  },
  {
    id: uuid(),
    createdAt: '04/04/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/4.jpeg',
    title: 'XX상사',
    totalDownloads: '406'
  },
  {
    id: uuid(),
    createdAt: '04/04/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/5.jpeg',
    title: 'XX상사',
    totalDownloads: '835'
  },
  {
    id: uuid(),
    createdAt: '04/04/2019',
    description: '안녕하세요 XXX 입니다. 우리 XXX는 XXX을 제조하는 업체로 다양한 인프라를 구축하여 운영하고 있습니다. 등등등',
    media: '/assets/mock-images/products_img2/6.jpeg',
    title: 'XX상사',
    totalDownloads: '835'
  }
];
