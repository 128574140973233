import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function Policies_Popup(props) {


  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt">
          
          </Typography> */}
          <div className="pop_totalWrap">
            <div className="pop_ttWrap">
              <span className="pop_ttIcon"></span>         
                              개인정보 수집•이용 동의서
            </div>
          <Divider/>
		  <Card style={{'width':'100%'}}>
			  <table className="srchTable">
                <colgroup>
                  <col style={{width:"100%"}} />
                </colgroup>  
			  
			  <tr>
		  		<th>
		  			스마트물류플랫폼은 「개인정보보호법」에 의거하여, 아래와 같은 내용으로 개인정보를 수집하고 있습니다.
					귀하께서는 아래 내용을 자세히 읽어 보시고, 모든 내용을 이해하신 후에 동의 여부를 결정해 주시기 바랍니다.
				</th>
			  </tr>
			  <tr>
		  		<td style={{'text-align':'left'}}>
				개인정보의 수집 및 이용 동의서<br/>
				- 이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 하기 목적 이외의 용도로는 사용되지 않습니다.<br/>
				① 개인정보 수집 항목 및 수집·이용 목적<br/>
				가) 수집 항목 (필수항목)<br/>
				- 성명(국문), 전화번호(휴대전화), 이메일 견적문의에 신청자가 제공한 정보<br/>
				나) 수집 및 이용 목적<br/>
				- 견적문의<br/>
				② 개인정보 보유 및 이용기간<br/>
				- 수집·이용 동의일로부터 개인정보의 수집·이용목적을 달성할 때까지<br/>
				③ 동의거부관리<br/>
				- 귀하께서는 본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다. 다만,<br/>
				귀하가 개인정보의 수집/이용에 동의를 거부하시는 경우에 견적문의에 제한될 수 있습니다.<br/>
				</td>
			  </tr>
			  </table>
		  </Card>
		</div>
        </Card>
      </Page>
    </>
  );
}

export default Policies_Popup;