import React from "react";
// import PortalHead from "./Sections/PortalHead";
// import PortalFootter from "./Sections/PortalFootter";
import ProductList from "./products/Products";

function PortalBoard() {
  return (
    <div> 
      {/* <PortalHead/> */}
        <ProductList/>
      {/* <PortalFootter/> */}
    </div>
  );
}
export default PortalBoard;