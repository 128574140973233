import React, { useCallback } from "react";
// import { Button, Input } from "antd";
import { Link, } from "react-router-dom";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import { home_url } from '../../../../js/globalProperties';


const states = [
  {
    value: 'Y',
    label: '사용'
  },
  {
    value: 'N',
    label: '미사용'
  }
];

const bbs_type_cd = [
  {
    value: '1',
    label: '게시판형 1'
  },
  {
    value: '2',
    label: '게시판형 2'
  },
  {
    value: '3',
    label: '갤러리형 1'
  },
  {
    value: '4',
    label: '갤러리형 2'
  }
];

const tmplat_cd = [
  {
    value: '1',
    label: '블루'
  },
  {
    value: '2',
    label: '레드'
  },
  {
    value: '3',
    label: '그린'
  },
  {
    value: '4',
    label: '그레이'
  }
];

function Video(props) {
  return (
    <>
      <form onSubmit={props.handleSubmit}
        // autoComplete="off"
        // noValidate
        encType="multipart/form-data"
        {...props}>
        <Typography className="listTable_tt">
          {props.BBS == undefined ? "게시물" : props.BBS.BBS_NAME} - 상세보기
        </Typography>
        {/* <CardHeader
            title={props}
            subheader={props.BBS.BBS_ITNRCN}
          /> */}
        <Card style={{ width: "100%" }}>
          <table className="srchTable">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tr>
              <th>제목</th>
              <td colspan="3">
                {props.NTT_SJ}
              </td>
            </tr>
            <tr>
              <td colspan="4" minRows="30">
                <div dangerouslySetInnerHTML={{ __html: props.NTT_CN }} style={{ padding: "20px" }}></div>
                {
                  // 아래 row별 변수를 정확하게 매칭안해주면 오류가 발생될 수 있으니, 꼭 참고 할 것.. 웹에서 원인을 찾기 어려움
                  props.FILES.map((row, index) => {
                    const { FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN } = row;
                    var filepath = FILE_STRE_COURS.substring(FILE_STRE_COURS.indexOf('BBS\\'), FILE_STRE_COURS.length);
                    filepath = filepath.replace('\\', '/');
                    // console.log('filepath : ', filepath);
                    const filename = home_url + "/" + filepath + "/" + STRE_FILE_NM;
                    // console.log('filename : ', filename);
                    return (
                      <div>
                        <div>파일명 : {ORIGNL_FILE_NM}</div>
                        <video controls autoPlay muted loop src={filename} style={{ "width": "100%", "height": "520px" }} />
                      </div>);
                  })
                }
              </td>
            </tr>
            <tr style={{ display: props.FILES.length === 0 ? 'none' : '' }}>
              <th>
                첨부파일
              </th>
              <td colspan="3">
                {
                  // 아래 row별 변수를 정확하게 매칭안해주면 오류가 발생될 수 있으니, 꼭 참고 할 것.. 웹에서 원인을 찾기 어려움
                  props.FILES.map((row, index) => {
                    const { FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN } = row;
                    return <Typography variant="subtitle2" noWrap>{index}. {ORIGNL_FILE_NM} </Typography>;
                  })
                }
              </td>
            </tr>
          </table>
        </Card>
      </form>
    </>
  );
}

export default Video;
