import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT030101(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          멀티셔틀
          </Typography>
          <div className="common_totalWrap03">
            <div className="common_ttWrap03">
            <span className="common_ttIcon"></span>         
                              셔틀 기반 자동 화물 입출고 시스템
            </div>
            <div className="common_txt02">
               &nbsp;셔틀 기반 자동 화물 입출고 시스템(Shuttle Based Storage and Retrieval System)은 제품을 보관하고 있는 선반(랙) 사이를 이동하여 제품의 입출고 작업을 수행하는 셔틀을 기반으로 한 입출고 자동화 시스템입니다.
이 시스템은 제품을 보관하는 선박(랙), 선반(랙)으로부터 입출고를 수행을 위해 제품을 수평이송하는 셔틀, 제품의 수직이송을 위한 리프트, 입출고 제품을 피킹 위치로 이송하는 컨베이어 그리고 피킹 위치에서의 처리 순서에 따라 셔틀이 출고한 제품의 처리 순서를 정렬하는 시퀀스 버퍼로 구성됩니다.
            </div>
            <br/>
            <div className="common_img02">
              <img src={require("../../../images/autoshuttle_img.jpg").default} className="" />
            </div>
            <div className="common_txt02">
               &nbsp;이 시스템은 보관 및 고객 주문 처리가 필요한 공장, 창고, 물류센터 등에서 활용되며 다음과 같은 이점을 얻을 수 있습니다.<br/>
              - 보관 밀도 향상에 따른 높은 공간활용도<br/>
              - 입출고 시간 단축 및 정확도 향상<br/>
              - 입출고 처리량 향상<br/>
            </div>
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT030101;