import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loginActions } from "../../../../slice/loginSlice";
import cookies from "react-cookies"
// import GoogleLogin from '../SnsLogin/GoogleLogin';
import { GoogleLogin } from 'react-google-login';
import { home_url } from '../../../../js/globalProperties';
import $ from 'jquery'
window.$ = $;


const PortalSession = () => {
    var items = new Array();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [inputId, setInputId] = useState('');
    const [inputPwd, setInputPwd] = useState('');
    const [NoteNum, setNoteNum] = useState(0);
    const [autoLogin, setAutoLogin] = useState(false);

    const [loginStatus, setLoginStatus] = useState();

    const { Kakao, naver } = window;

    $(function () {
        var userNameWidth = parseInt($('.user_nameWidCtrl').width());

        if (userNameWidth >= 180) {
            $('.userName_wrap').css('width', '180px');
            $('.userName_wrap').css('overflow', 'hidden');
            $('.userName_wrap').css('text-overflow', 'ellipsis');
            $('.userName_wrap').css('white-space', 'nowrap');
        } else if (userNameWidth < 180) {
            $('.userName_wrap').css('width', '100%');
            $('.userName_wrap').css('overflow', 'initial');
            $('.userName_wrap').css('text-overflow', 'initial');
            $('.userName_wrap').css('white-space', 'initial');
        }
    });

    $(function () {
        var userCorpWidth = parseInt($('.user_corpWidCtrl').width());

        if (userCorpWidth >= 200) {
            $('.user_corp').css('width', '200px');
            $('.user_corp').css('overflow', 'hidden');
            $('.user_corp').css('text-overflow', 'ellipsis');
            $('.user_corp').css('white-space', 'nowrap');
        } else if (userCorpWidth < 200) {
            $('.user_corp').css('width', '100%');
            $('.user_corp').css('overflow', 'initial');
            $('.user_corp').css('text-overflow', 'initial');
            $('.user_corp').css('white-space', 'initial');
        }
    });

    const handleInputId = (e) => {
        setInputId(e.target.value)
    }

    const handleInputPwd = (e) => {
        setInputPwd(e.target.value)
    }
    
    /*
    * SSO 설정 
    * 로그인 후  SSO 세션 생성 220405 slchoi
    */
    // const goToSSO = (redirectUrl) => {
    // 	if(redirectUrl === ''){
    // 		redirectUrl  = '/';
    // 	}
    // 	window.location = home_url + "/exsignon/sso/sso_assert.jsp?RelayState="+redirectUrl;
    // 	return false;
    // }
    // /*
    // * SSO 설정 
    // * 로그아웃 후 SSO 세션 삭제
    // * 포탈의 경우 로그인 처리가 프론트에서 이루어지기 때문에 프론트에 함수 추가 
    // * 220405 slchoi
    // */
    // const logoutSSO = (redirectUrl) => {
    // 	if(redirectUrl === ''){
    // 		redirectUrl  = '/';
    // 	}
    // 	window.location = home_url + "/exsignon/sso/logout.jsp?RelayState="+redirectUrl;
    // 	return false;
    // }

    //로그인 컴퍼넌트 ------------------------------------------------------------------------------------------------
    const login = (id, pw, refresh) => {
        if (id === '') {
            alert("아이디를 입력하세요.");
        } else if (pw === '') {
            alert("비밀번호를 입력하세요.");
        } else {
            try {
                const loginOption = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ 'OPEN_API_YN': 'Y', 'USER_ID': id, 'PW': pw })
                };
                fetch(home_url + "/api/slpportal/login", loginOption)
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                        let logindata = data;
                        if (data.TYPE === 0) {
                            alert(data.MSG);
                        } else if (data.TYPE === -1) {
                            alert(data.MSG);
                        } else if (data.TYPE === 1) {
                            const termCheckOption = {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({ 'OPEN_API_YN': 'Y', 'USER_ID': id })
                            };
                            fetch(home_url + '/api/slpportal/checkTerms', termCheckOption)
                                .then(function (response) { return response.json(); })
                                .then(function (data) {
                                    //약관동의가 안되어있을경우 약관페이지로 이동시킴
                                    if (data.DATA.result !== 'true') {
                                        history.push({ pathname: '/app/FUL/Terms', state: { id: id, pw: pw } })
                                        return false;
                                    } else { //약관동의가 되어있으면 로그인 진행
                                        const loginJspOption = {
                                            method: "GET",
                                            headers: { "Content-Type": "application/json"}
                                        }
                                        fetch(home_url + "/login.jsp", loginJspOption)
                                            .then(function () {
                                                const csrfToken = cookies.load("X-CSRF-TOKEN");
                                                let details = {
                                                    "_spring_security_remember_me": true,
                                                    "_csrf": csrfToken,
                                                    "USER_ID": id,
                                                    "PW": pw
                                                };
                                                let formBody = [];
                                                for (let property in details) {
                                                    let encodedKey = encodeURIComponent(property);
                                                    let encodedValue = encodeURIComponent(details[property]);
                                                    formBody.push(encodedKey + "=" + encodedValue);
                                                }
                                                formBody = formBody.join("&");
                                                const requestOptions = {
                                                    method: "POST",
                                                    headers: { "Content-Type": "application/x-www-form-urlencoded", "Accept": "*/*"},
                                                    body: formBody
                                                };
                                                fetch(home_url + "/slp_sec", requestOptions)
                                                    .then(function () {
                                                        logindata.DATA[0]["X-CSRF-TOKEN"] = cookies.load("X-CSRF-TOKEN"); //세션연장을 위해 기존 쿠키방식에서 localStorage로 전환
                                                        setSession(logindata.DATA[0]);
                                                    }) //Front-End 세션삽입
                                                    .then(function () {
                                                        //자동로그인 체크시 세션 저장
                                                        if (autoLogin) {
                                                            let autoParam = { 'ID': id, 'PW': pw }
                                                            localStorage.setItem('autoLogin', JSON.stringify(autoParam));
                                                        }
                                                        localStorage.removeItem('tempLogin');
                                                        setLoginStatus(true);
                                                    })
                                                    .then(function () {
                                                        if(logindata.DATA[0].EMAIL == ''){
                                                            alert("이메일을 등록해주세요.")
                                                            // if(process.env.REACT_APP_SSO_MODE === 'pro'){
                                                            //     //SSO 설정 적용 코드
                                                            //     goToSSO('/app/FUL/MyPage');
                                                            // }else{
                                                                return history.push({ pathname: '/app/FUL/MyPage'})
                                                            }
                                                        // }
                                                        
                                                        // if(process.env.REACT_APP_SSO_MODE === 'pro'){
	                                                    //     //SSO 설정 적용 코드
	                                                    //     goToSSO('');
                                                        // }else{
	                                                        /* 메인화면에서 로그인시에 화면 새로고침.*/
	                                                        if (refresh === true) {
	                                                            history.go(0);
	                                                        }
                                                        // }  
                                                        
                                                        
                                                        //SSO 설정 전 기존코드
                                                        
                                                    })
                                                   
                                                    
                                            })
                                    } 
                                });
                        } else {
                            alert("관리자에게 문의하세요.");
                        }
                    })
            } catch (e) {
                console.log(e)
            }
        }
    }
    //--------------------------------------------------------------------------------------------------------------

    //카카오 로그인
    const kakaoLogin = () => {
        Kakao.Auth.login({
            success: function (authObj) {
                const loginOption = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        "OPEN_API_YN": "Y",
                        "TYPE": "kakao",
                        "VALUE": {
                            "access_token": authObj.access_token,
                            "token_type": authObj.token_type,
                            "refresh_token": authObj.refresh_token,
                            "expires_in": authObj.expires_in,
                            "scope": authObj.scope,
                            "refresh_token_expires_in": authObj.refresh_token_expires_in
                        }
                    })
                };
                fetch(home_url + "/api/slpportal/socialLogin", loginOption)
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                        login(data.DATA.ID, data.DATA.PW, true);
                    })
            },
            fail: function (err) {
                alert(JSON.stringify(err))
            },
        })
    }
    //--------------------------------------------------------------------------------------------------------------

    //세션삽입-------------------------------------------------------------------------------------------------------
    const setSession = (login) => {
        localStorage.setItem('LoginType', "BASIC");
        localStorage.setItem('token', login);
        localStorage.setItem('COMPANY_CODE', login.COMPANY_CODE);
        localStorage.setItem('COMPANY_NAME', login.COMPANY_NAME);
        localStorage.setItem('SOCIAL_CODE', login.SOCIAL_CODE);
        localStorage.setItem('USER_NAME_LOC', login.USER_NAME_LOC);
        localStorage.setItem('USER_AUTH', login.USER_AUTH);
        localStorage.setItem('USER_ID', login.USER_ID);
        localStorage.setItem('IRS_NO', login.IRS_NO);
        localStorage.setItem('MOBILE_NO', login.MOBILE_NO);
        localStorage.setItem('FAX_NO', login.FAX_NO);
        localStorage.setItem('X-CSRF-TOKEN', login['X-CSRF-TOKEN']);
    }
    //--------------------------------------------------------------------------------------------------------------

    const naverLogin = new naver.LoginWithNaverId({
        callbackUrl: 'https://myslp.kr/',
        clientId: 'O4puAiMCrr1XVFriLQPi',
        isPopup: false,
        loginButton: { color: "green", type: 1, height: 34 },
    });

    const getNaverToken = () => {
        if (!location.hash) return;
        const token = location.hash.split('=')[1].split('&')[0];
        localStorage.setItem('token', token);
        UserProfile();
    };

    const UserProfile = () => {
        const userToken = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'OPEN_API_YN': 'Y', 'TYPE': 'naver', 'VALUE': { 'access_token': userToken } })
        };

        fetch(home_url + '/api/slpportal/socialLogin', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                login(data.DATA.ID, data.DATA.PW, true);
                history.push('/');
            });
    };

    //로그이웃-------------------------------------------------------------------------------------------------------
    const logout = () => {
        localStorage.removeItem("USER_NAME_LOC");
        localStorage.removeItem("USER_AUTH");
        localStorage.removeItem("USER_ID");
        localStorage.removeItem("COMPANY_NAME");
        localStorage.removeItem("SOCIAL_CODE");
        localStorage.removeItem("LoginType");
        localStorage.removeItem("token");
        localStorage.removeItem("COMPANY_CODE");
        localStorage.removeItem('EMAIL');
        localStorage.removeItem('IRS_NO');
        localStorage.removeItem('MOBILE_NO');
        localStorage.removeItem('FAX_NO');
        localStorage.removeItem('TEL_NO');
        localStorage.removeItem('autoLogin');
        localStorage.removeItem('X-CSRF-TOKEN');
        setLoginStatus(false);
        try {
            let requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") }
            };
            fetch(home_url + "/j_spring_security_logout", requestOptions)
                .then(function (response) { console.log(response); })
        } catch (error) {
            console.log("error : ", error);
        }


        //SSO 설정 적용 코드
        // if(process.env.REACT_APP_SSO_MODE === 'pro'){
        //     logoutSSO('/');
        // }else{
            history.push('/');
            history.go(0);
        // }
    }

    const getNoteNum = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': 'getList', 'READ_YN': 'N' })
        };
        fetch(home_url + '/api/BRD120010SVC/getCount', requestOptions)
            .then((response) => { return response.json(); })
            .then((data) => {
                console.log(data);
                if (data.STATUS !== 403) {
                    let noteCnt = data.DATA.NOTE_CNT;
                    let tradeCnt = data.DATA.TRADENOTE_CNT;
                    setNoteNum(noteCnt + tradeCnt);
                } else {
                    //자동로그인 등록시 백엔드 세션이 끊겼을 경우에 재로그인 요청.
                    if (localStorage.getItem('autoLogin') !== null) {
                        login(JSON.parse(localStorage.getItem('autoLogin')).ID, JSON.parse(localStorage.getItem('autoLogin')).PW, false);
                    } else {//자동로그인 등록이 되어있지 않았을 경우 세션을 끊음.
                        logout();
                    }
                }
            });
    }

    useEffect(() => {
        //약관동의체크 후 로그인
        if (localStorage.getItem('tempLogin') !== null) {
            login(JSON.parse(localStorage.getItem('tempLogin')).ID, JSON.parse(localStorage.getItem('tempLogin')).PW, true);
        }

        //로그인이 되었을 시에 URL이 변경될 때마다 쪽지갯수 리로드 및 백엔드 세션 유효 검증
        if (localStorage.getItem("LoginType") !== null) getNoteNum();

        //자동로그인
        // if (localStorage.getItem('LoginType') === null && localStorage.getItem('autoLogin') !== null) {
        //     login(JSON.parse(localStorage.getItem('autoLogin')).ID, JSON.parse(localStorage.getItem('autoLogin')).PW, true);
        // }

        //새창 접속시 세션이 있을 경우 로그인
        if (localStorage.getItem('LoginType') !== null) {
            setLoginStatus(true);
        };

        naverLogin.init();
        getNaverToken();
    }, [location])

    return (
        <>
            {/* 로그아웃 상태 ------------------------------------------------------------------------------------------------- */}
            <div className="loginSection_wrap login_off" id='' style={{ display: !loginStatus ? '' : 'none' }}>
                <div className="loginSection">
                    <div className="loginBox">
                        <input type="text" name="id" placeholder="아이디" className="loginBox_idInput" value={inputId} onChange={handleInputId} onKeyPress={(e) => { if (e.key === 'Enter') { login(() => inputId, inputPwd, true); } }} />
                        <input type="password" name="pwd" id="pwd" placeholder="비밀번호" className="loginBox_pwInput" value={inputPwd} onChange={handleInputPwd} onKeyPress={(e) => { if (e.key === 'Enter') { login(inputId, inputPwd, true); } }} />
                    </div>
                    <button className="loginBtn" onClick={() => login(inputId, inputPwd, true)}>로그인</button>

                    <span className="login_etc_wrap">
                        <label htmlFor="autoLogin" className="login_etc_autologinTxt">
                            <input type="checkbox" name="" id="autoLogin" value="" className="login_etc_autologinInput" onChange={(e) => setAutoLogin(e.target.checked)} />&nbsp;
                            자동 로그인
                        </label>&nbsp;&nbsp;
                        <span className="login_etc_txtDiv">|</span>&nbsp;&nbsp;
                        <span className="login_etc_idpwSrch" onClick={() => history.push("/app/findsession")}>비밀번호 찾기</span>&nbsp;&nbsp;
                        <span className="login_etc_txtDiv">|</span>&nbsp;&nbsp;
                        <span className="login_etc_join" style={{ 'cursor': 'pointer' }} onClick={() => history.push('/app/FUL/Terms')}>회원가입</span>
                    </span>
                </div>
                <div className="socialLogin_box">
                    <div>소셜로그인</div>
                    <span id='kakaoIdLogin' onClick={() => kakaoLogin()}><img src={require("../../../../images/kakaolink_btn_small.png").default} /></span>
                    <span id='naverIdLogin' onClick={() => getNaverToken()} />
                    {/* <GoogleLogin login={login} /> */}
                    <GoogleLogin
                        clientId={'1078113688876-m00v1b62ie7r29eiscp7lecj95rlsmuo.apps.googleusercontent.com'}
                        render={renderProps => (
                            <span onClick={renderProps.onClick} className="socialLogin_google">
                                <img src={require("../../../../images/google_logo.png").default} />
                            </span>
                        )}
                        onSuccess={(res) => {
                            const loginOption = {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    "OPEN_API_YN": "Y",
                                    "TYPE": "google",
                                    "VALUE": {
                                        "id": res.profileObj.googleId,
                                        "name": res.profileObj.name,
                                        "email": res.profileObj.email
                                    }
                                })
                            };
                            fetch(home_url + "/api/slpportal/socialLogin", loginOption)
                                .then(function (response) { return response.json(); })
                                .then(function (data) {
                                    login(data.DATA.ID, data.DATA.PW, true);
                                })
                        }
                        }
                        onFailure={(err) => {
                            console.log(err);
                        }}
                    >
                    </GoogleLogin >
                </div>
            </div>

            {/* 로그인 상태 ------------------------------------------------------------------------------------------------- */}
            <div className="loginSection_wrap login_on" id='' style={{ display: loginStatus ? '' : 'none' }}>
                <div className="loginSection">
                    <div className="user_infoBox">
                        <table>
                            <tr>
                                <td className="user_nameWidCtrl">
                                    <span className="user_name userName_wrap" name='user_name' id=''>{localStorage.getItem('USER_NAME_LOC')}</span>
                                </td>
                                <td>
                                    <span className="login_etc_txtDiv">|</span>
                                    <span className="user_infochange" style={{ 'cursor': 'pointer' }} onClick={() => {
                                        history.push({ pathname: '/app/FUL/MyPage/' });
                                    }}>마이페이지</span>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td className="user_corpWidCtrl">
                                    <span className="user_corp">{localStorage.getItem("COMPANY_NAME")}</span>
                                </td>
                                <td>
                                    <span className="login_etc_txtDiv">|</span>
                                    <span onClick={() => history.push('/app/FUL/Note')} style={{ 'cursor': 'pointer' }}>
                                        <img src={require("../../../../images/mail.png").default} className="login_mailIcon" />&nbsp;
                                    </span>
                                    <span className="user_alarmWrap_count">{NoteNum}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <ul>
                    <li className="user_alarmWrap03">
                        <button className="logoutBtn02" onClick={logout}>로그아웃</button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default PortalSession;