import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { home_url } from '../../../js/globalProperties';
import {
  Card,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography
} from '@material-ui/core';
import cookies from 'react-cookies';
const MyPage = (props) => {
  const history = useHistory();
  //사용자 데이터 조회
  const [User, setUser] = useState({});
  const [IRS_NO, setIRS_NO] = useState('');
  const [isAvailable_IRS_NO, setIsAvailable_IRS_NO] = useState(false);
 
  const [Event,setEvent] = useState([]);
  const [Social_Code , setSocial_Code] = useState(false);

  const getUser = () => {
    if(localStorage.getItem('SOCIAL_CODE') == ''){
      setSocial_Code(true);  
    }
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
        body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID') })
      };
      fetch(home_url + '/api/SMS020010SVC/getUser', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          setUser(data['DATA']);
          console.log("USER : ", data['DATA'][0]);
          setUser(data['DATA'][0]);
          setIRS_NO(data['DATA'][0].IRS_NO);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const getEventHistory = () =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
        body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID') })
      };
      fetch(home_url + '/api/BRD110010SVC/getEventHistory', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) { if (data.DATA.length != 0) {
          setEvent(data.DATA);
        } else{
          setEvent(null)
        }
    });
}
  useEffect(() => {
    getUser();
    getEventHistory();
  }, []);

  const cancelEvent = (event_no, branch_code, seq_no) =>{
  
    if(!window.confirm("예약을 취소하시겠습니까?")) {
            return false;
    }else{
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
          body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID') , EVENT_NO :event_no , BRANCH_CODE : branch_code , SEQ_NO : seq_no  })
        };
        fetch(home_url + '/api/BRD110010SVC/cancelEvent', requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) { if (data != null) {
          alert("취소가 완료되었습니다.")
          getEventHistory();
          } 
      });
    }
}

  const checkIRSNO = () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'IRS_NO': IRS_NO, 'OPEN_API_YN': 'Y' })
      };
      fetch(home_url + '/api/slpportal/checkIrsNo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.TYPE !== 1) {
            alert(data.MSG);
            return false;
          } else {
            alert("요청가능한 사업자등록번호입니다.");
            setIsAvailable_IRS_NO(true);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  //기업등록 요청
  const registIRS = (USER_ID, IRS_NO) => {
    try {
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'IRS_NO': IRS_NO, 'OPEN_API_YN': 'Y' })
      };
      fetch(home_url + '/api/slpportal/checkIrsNo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.TYPE !== 1) { alert(data.MSG); return false; }
        });
    } catch (error) {
      console.log(error);
    }

    //기가입 여부 확인
    let alreadyRegisted = false;

    try {
      let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify({ 'USER_ID': USER_ID, 'OPEN_API_YN': 'Y' })
      };
      fetch(home_url + '/api/SMS020010SVC/getUserCompany', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          console.log("기업등록 승인 여부 확인 : ", data['MSG']);
          if (data['DATA'].length == 1) alreadyRegisted = true;

          if (alreadyRegisted) joinCompanyAfterReset(USER_ID, IRS_NO);
          else joinCompany(USER_ID, IRS_NO);
        });
    } catch (error) {
      console.log(error);
    }

    //기가입자일 경우 기업정보 초기화
    const joinCompanyAfterReset = (USER_ID, IRS_NO) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
          body: JSON.stringify({ 'USER_ID': USER_ID, 'OPEN_API_YN': 'Y' })
        };
        fetch(home_url + '/api/SMS020010SVC/deleteUserCompany', requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            console.log("기업정보 삭제 : ", data['MSG']);
            joinCompany(USER_ID, IRS_NO);
          });
      } catch (error) {
        console.log(error);
      }
    }

    //기업등록 요청
    const joinCompany = (USER_ID, IRS_NO) => {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
          body: JSON.stringify({ 'USER_ID': USER_ID, 'IRS_NO': IRS_NO, 'OPEN_API_YN': 'Y' })
        };
        fetch(home_url + '/api/SMS020010SVC/joinCompany', requestOptions)
          .then(function (response) { return response.json(); })
          .then(function (data) {
            console.log('기업등록 요청 : ', data['MSG']);
            alert(data['MSG']);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  //사용자 정보 변경
  const updateUser = () => {
    let userInfo = User;
    console.log(userInfo);

    let reg = null;
    reg = /^[가-힣]{2,4}$/;
    if (!reg.test(userInfo.USER_NAME_LOC)) { alert("이름(한글)은 한글 2-4자입니다."); return false; }

    // reg = /^[a-zA-Z]{2,20}\s[a-zA-Z]{2,20}$/;
    // if (!reg.test(userInfo.USER_NAME_ENG)) { alert("이름(영문)은 영문 2-20자입니다."); return false; }

    reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    if (!reg.test(userInfo.EMAIL)) { alert("이메일 형식을 확인해주세요."); return false; }

    // reg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    // if (!reg.test(userInfo.TEL_NO)) { alert("전화번호를 확인해주세요."); return false; }
    // if (!reg.test(userInfo.MOBILE_NO)) { alert("휴대폰번호를 확인해주세요."); return false; }
    // if (!reg.test(userInfo.FAX_NO)) { alert("팩스번호를 확인해주세요."); return false; }

    if (!window.confirm("수정하시겠습니까?")) return false;
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
        body: JSON.stringify(userInfo)
      };

      //혹시 이 기능이 동작 안하면 , 'OPEN_API_YN':'Y' 를 추가해야함 
      fetch(home_url + '/api/SMS020010SVC/updateUser', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          console.log('회원정보 수정 : ', data['MSG']);
          alert(data['MSG']);
          localStorage.setItem("USER_NAME_LOC",userInfo.USER_NAME_LOC);
          history.push('/');
        });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Typography className="listTable_tt">
        마이페이지
      </Typography>
      <Card style={{ width: "100%" }}>
        <table className="srchTable">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "70%" }} />
          </colgroup>
          <tr>
            <th>아이디</th>
            <td>{User.USER_ID}</td>
          </tr>

          <tr>
            <th>이름(한글)</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                placeholder="한글 이름을 입력해주세요."
                value={User.USER_NAME_LOC}
                onChange={(e) => setUser((prevState) => ({ ...prevState, "USER_NAME_LOC": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>

          <tr>
            <th>이름(영문)</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={User.USER_NAME_ENG}
                placeholder="영문 이름을 입력해주세요."
                onChange={(e) => setUser((prevState) => ({ ...prevState, "USER_NAME_ENG": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>

          <tr>
            <th>이메일</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={User.EMAIL}
                placeholder="이메일을 입력해주세요."
                onChange={(e) => setUser((prevState) => ({ ...prevState, "EMAIL": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>

          <tr>
            <th>휴대폰 번호</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={User.MOBILE_NO}
                placeholder="휴대폰번호를 입력해주세요."
                onChange={(e) => setUser((prevState) => ({ ...prevState, "MOBILE_NO": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>

          <tr>
            <th>전화 번호</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={User.TEL_NO}
                placeholder="전화번호를 입력해주세요."
                onChange={(e) => setUser((prevState) => ({ ...prevState, "TEL_NO": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>

          <tr>
            <th>FAX</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={User.FAX_NO}
                placeholder="FAX번호를 입력해주세요."
                onChange={(e) => setUser((prevState) => ({ ...prevState, "FAX_NO": e.target.value }))}
                onKeyPress={
                  (event) => {
                    if (event.key === 'Enter') {
                      { updateUser(); }
                    }
                  }}
              />
            </td>
          </tr>
        </table>
        <Box style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button
            variant="contained"
            className="ButtonStyle03"
            onClick={() => getUser()}
          >
            초기화
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            className="ButtonStyle02"
            onClick={() => updateUser()}
          >
            저장
          </Button>
          &nbsp;&nbsp;
          {Social_Code === true ? <Button
            variant="contained"
            className="ButtonStyle02"
            onClick = {() => history.push('/app/FUL/PLT000101_12')}
          >
            비밀번호 변경
          </Button> : '' }
        </Box>
      </Card>
      <br />
      <Typography className="listTable_tt">
        기업 정보
      </Typography>
      <Card style={{ width: "100%" }}>
        <table className="srchTable">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "65%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <tr>
            <th>사업자등록번호</th>
            <td>
              <TextField className="bbsInputSize"
                fullWidth
                type="string"
                value={IRS_NO}
                placeholder="사업자등록번호를 입력해주세요."
                onChange={(event) => {
                  setIRS_NO(event.target.value);
                  setIsAvailable_IRS_NO(false);
                }}
                onKeyPress={
                  (e) => {
                    if (e.key === 'Enter') {
                      { registIRS(User.USER_ID, IRS_NO); }
                    }
                  }}
              />
            </td>
            <td>
              <Button onClick={() => checkIRSNO()}>
                기업 등록여부 확인
              </Button>
            </td>
          </tr>
          <tr>
            <th>기업코드</th>
            <td>{User.COMPANY_CODE === '' ? '-' : User.COMPANY_CODE}</td>
            <td></td>
          </tr>
          <tr>
            <th>기업명</th>
            <td>{User.COMPANY_NAME === '' ? '-' : User.COMPANY_NAME}</td>
            <td></td>
          </tr>
          <tr>
            <th>기업등록 상태</th>
            <td>{User.COMPANY_STATUS}</td>
            <td></td>
          </tr>
        </table>
        <Box style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button
            variant="contained"
            className="ButtonStyle02"
            onClick={() => {
              if (isAvailable_IRS_NO) {
                registIRS(User.USER_ID, IRS_NO);
              } else {
                alert('기업등록 여부를 확인해주세요!');
              }
            }}
          >
            기업등록 신청
          </Button>
        </Box>
      </Card>
      <br />
      <Typography className="listTable_tt">
        이벤트 예약 내역
      </Typography>
      <Card style={{ width: "100%" }}>
        <table className="srchTable">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <tr>
            <th>이벤트명</th>
            <th>장소</th>
            <th>날짜</th>
            <th>시간</th>
            <th></th>
          </tr>
          {Event === null ? 
            <tr><td colSpan = "5" style = {{textAlign : "center" , color : "#5c5c5c"}}>예약 내역이 없습니다.</td> </tr>:
           
            Event&&Event.map((row,index)=>{
                        return (
                        <>
                         <tr key ={row.EVENT_NO}> 
                             <td>{row.EVENT_NAME}</td> 
                             <td>{row.BRANCH_NAME} </td>
                             <td>{row.EVENT_DATE} </td>
                             <td>{row.START_TIME}~{row.END_TIME} </td>
                             <td><Button className={ row.T === 'Y' ? 'cancelEventButton01' : 'cancelEventButton02'}  onClick={()=>{cancelEvent(row.EVENT_NO , row.BRANCH_CODE , row.SEQ_NO)}} >예약취소</Button></td>
                         </tr>
                        </>
                        )
                      })}
        </table>
        </Card>
    </>
  )
}
export default MyPage
