import { useEffect, useState } from 'react';
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';
import { filter } from 'lodash';
import moment from 'moment';
// material
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Checkbox,
} from '@material-ui/core';
// components
import SearchNotFound from '../CommonBoard/SearchNotFound';
import { CommonMoreMenu, CommonHead } from '../CommonBoard/BoardList/';
import CommonToolbar from './NoteToolbar';
import Page from './../CommonBoard/Page';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'No', label: 'No.', alignRight: false },
    { id: 'NTT_CN', label: '내용', alignRight: false },
    { id: 'USER_NAME_LOC', label: '보낸사람', alignRight: false },
    { id: 'ADD_DATE', label: '작성일자', alignRight: false },
];

const TABLE_HEAD_FOR_SENDLIST = [
    { id: 'No', label: 'No.', alignRight: false },
    { id: 'NTT_CN', label: '내용', alignRight: false },
    { id: 'USER_NAME_LOC', label: '받는사람', alignRight: false },
    { id: 'ADD_DATE', label: '작성일자', alignRight: false },
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.NTT_CN.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

function NoteList(props) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('ADD_DATE',);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [List, setList] = useState([]);

    const Parameters = {
        getList: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': props.noteMode },
        getListMyself: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': props.noteMode },
        sendList: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': props.noteMode },
        noteBox: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': props.noteMode },
        getSpamList: { 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': props.noteMode }
    }

    const Titles = {
        getList: "받은쪽지함",
        getListMyself: "내게쓴쪽지함",
        sendList: "보낸쪽지함",
        noteBox: "쪽지보관함",
        getSpamList: "스팸쪽지함"
    }

    const getList = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify(Parameters[props.noteMode])
        };

        let requestUrl = '/api/BRD120010SVC/get';
        if (props.noteMode === 'sendList') requestUrl = '/api/BRD110010SVC/get';

        fetch(home_url + requestUrl, requestOptions)
            .then((response) => { return response.json(); })
            .then((data) => {
                let items = new Array();
                items = data.DATA;
                if (items != null) setList(items);
            });
    }

    useEffect(() => {
        getList();
    }, [props]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = List.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - List.length) : 0;

    const filteredTotalboards = applySortFilter(List, getComparator(order, orderBy), filterName);

    const isTotalboardNotFound = filteredTotalboards.length === 0;

    const today = moment().format('YYYY-MM-DD');

    return (
        <div>
            <Page title="Totalboard | Minimal-UI">
                <Card>
                    <Typography className="listTable_tt">
                        {Titles[props.noteMode]}
                    </Typography>
                    {/* <Scrollbar> */}
                    <TableContainer >
                        <Table className="listTable NoteTable_head">
                            <CommonHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={props.noteMode === 'sendList' ? TABLE_HEAD_FOR_SENDLIST : TABLE_HEAD}
                                rowCount={List.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {List && filteredTotalboards
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = selected.indexOf(row.NTT_ID) !== -1;
                                        return (
                                            <>
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell scope="row" align="center"> {index + page * rowsPerPage + 1} </TableCell>
                                                    <TableCell scope="row" padding="none" className={row.READ_YN === 'Y' || props.noteMode === 'getListMyself' || props.noteMode === 'sendList' ? "NoteTable_tdWidth" : "NoteTable_Nosee"}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography
                                                                variant="subtitle2" noWrap
                                                                onClick={() => {
                                                                    props.setBack(props.noteMode);
                                                                    props.setNoteMode('readNote');
                                                                    props.setNote(row);
                                                                }}
                                                            >
                                                                {row.NTT_CN}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    {/* <TableCell align="center" style={{ display : props.noteMode === 'sendList' ? 'none' : ''}}>{row.USER_NAME_LOC}</TableCell> */}
                                                    {/* <TableCell align="center">{row.USER_NAME_LOC.split(',').length > 1 ? row.USER_NAME_LOC.split(',')[0]+'외'+row.USER_NAME_LOC.split(',').length-1+'명':row.USER_NAME_LOC}</TableCell> */}
                                                    <TableCell align="center">
                                                        {
                                                            row.USER_NAME_LOC.split(',').length > 1 ?
                                                                row.USER_NAME_LOC.split(',')[0].split(' ')[0] + " 외 " + (row.USER_NAME_LOC.split(',').length - 1).toString() + "명" :
                                                                row.USER_NAME_LOC.split(',')[0].split(' ')[0]
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {/* {today === row.ADD_DATE.substring(0, 10) ? row.ADD_DATE.substring(10, 16) : row.ADD_DATE.substring(0, 10)} */}
                                                        {row.ADD_DATE}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )} */}
                            </TableBody>
                            {isTotalboardNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    {/* </Scrollbar> */}
                    <TablePagination
                        rowsPerPageOptions={[15, 30, 45]}
                        component="div"
                        count={List.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="페이지 당 쪽지 수 : "
                    />
                    <CommonToolbar
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    />
                </Card>
                {/* </Container> */}
            </Page>
        </div>
    );
}

export default NoteList;