import { takeEvery, takeLatest }    from "redux-saga/effects";
import { articleActions }           from "../slice/articleSlice";
import { boardActions }             from "../slice/boardSlice";
import { commentActions }           from "../slice/commentSlice";

// 하나로TNS 기능추가
import { brdbd001Actions }           from "../slice/brdbd001Slice";
import { brdbd003Actions }           from "../slice/brdbd003Slice";
import { brdbd009Actions }           from "../slice/brdbd009Slice";
import { loginActions }              from "../slice/loginSlice";


// 데이터 연동을 위한 함수 로딩 (기능별로 구성해놔야 할 듯 )
import {
  registerArticleAsync,
  getArticleAsync,
  fetchArticleAsync,
  updateArticleAsync,
  deleteArticleAsync, }             from "./articleSaga";
import { getBoardAsync }            from "./boardSaga";
import {
  registerCommentAsync,
  getCommentsAsync,
  deleteCommentAsync,}              from "./commentSaga";


// 하나로TNS 기능추가--------------------------------------------------
import {
  registerBrdbd001Async,
  getBrdbd001Async,
  updateBrdbd001Async,
  deleteBrdbd001Async, }             from "./brdbd001Saga";
import {
  registerBrdbd003Async,
  registerBrdbd003REPLYAsync,
  getBrdbd003Async,
  getBrdbd003DetailAsync,
  fetchBrdbd003Async,
  updateBrdbd003Async,
  deleteBrdbd003Async, }             from "./brdbd003Saga";
import { 
  registerBrdbd009Async,
  getBrdbd009Async,
  fetchBrdbd009Async,
  updateBrdbd009Async,
  deleteBrdbd009Async, }             from "./brdbd009Saga";
// ----------------------------------------------------------------

import {
  getUserInfoAsync,}                      from "./loginSaga";
//------------------------------------------------------login------


// 데이터 연동을 위한 인터페이스(위 기능과 쌍으로 구성)
const {
  registerArticle,
  getArticle,
  fetchArticle,
  updateArticle,
  deleteArticle,
} = articleActions;
const { getBoard } = boardActions;
const { registerComment, 
        getComments, 
        deleteComment } = commentActions;

// 하나로TNS 기능추가--------------------------------------------------
const {
  registerBrdbd001,
  getBrdbd001,
  fetchBrdbd001,
  updateBrdbd001,
  deleteBrdbd001,
} = brdbd001Actions;

const {
  registerBrdbd003,
  registerBrdbd003REPLY,
  getBrdbd003,
  getBrdbd003Detail,
  fetchBrdbd003,
  updateBrdbd003,
  deleteBrdbd003,
} = brdbd003Actions;

const {
  registerBrdbd009,
  getBrdbd009,
  fetchBrdbd009,
  updateBrdbd009,
  deleteBrdbd009,
} = brdbd009Actions;
// --------------------------------------------------Brdbd001-------



const {
  getUserInfo,
} = loginActions;
// --------------------------------------------------login----------

// 각 호출이 있을 때마다, 기능에 해당하는 데이터 연동함수를 호출해줌         
export default function* rootWatcher() {
  yield takeLatest(registerArticle.type, registerArticleAsync);
  yield takeEvery(getArticle.type, getArticleAsync);
  yield takeEvery(getBoard.type, getBoardAsync);
  yield takeEvery(fetchArticle.type, fetchArticleAsync);
  yield takeLatest(updateArticle.type, updateArticleAsync);
  yield takeLatest(deleteArticle.type, deleteArticleAsync);
  yield takeLatest(registerComment.type, registerCommentAsync);
  yield takeEvery(getComments.type, getCommentsAsync);
  yield takeLatest(deleteComment.type, deleteCommentAsync);

// 하나로TNS 기능추가--------------------------------------------------
  yield takeLatest(registerBrdbd001.type, registerBrdbd001Async);
  yield takeEvery(getBrdbd001.type, getBrdbd001Async);
  yield takeLatest(updateBrdbd001.type, updateBrdbd001Async);
  yield takeLatest(deleteBrdbd001.type, deleteBrdbd001Async);

  yield takeLatest(registerBrdbd003.type, registerBrdbd003Async);
  yield takeLatest(registerBrdbd003REPLY.type, registerBrdbd003REPLYAsync);
  yield takeEvery(getBrdbd003.type, getBrdbd003Async);
  yield takeEvery(getBrdbd003Detail.type, getBrdbd003DetailAsync);
  yield takeEvery(fetchBrdbd003.type, fetchBrdbd003Async);
  yield takeLatest(updateBrdbd003.type, updateBrdbd003Async);
  yield takeLatest(deleteBrdbd003.type, deleteBrdbd003Async);

  yield takeLatest(registerBrdbd009.type, registerBrdbd009Async);
  yield takeEvery(getBrdbd009.type, getBrdbd009Async);
  yield takeEvery(fetchBrdbd009.type, fetchBrdbd009Async);
  yield takeLatest(updateBrdbd009.type, updateBrdbd009Async);
  yield takeLatest(deleteBrdbd009.type, deleteBrdbd009Async);
// ----------------------------------------------------------------
  yield takeEvery(getUserInfo.type, getUserInfoAsync);
}
