import { Button } from '@material-ui/core';
import { useState, useEffect } from "react";
import cookies from 'react-cookies';
import { home_url } from '../../../js/globalProperties';
function NoteAdress(props) {
    const [RecentUsers, setRecentUsers] = useState([]);
    const [PrimaryUsers, setPrimaryUsers] = useState([]);
    const [SameCompanyUsers, setSameCompanyUsers] = useState([]);
    const [SendTargetUsers, setSendTargetUsers] = useState([]);
    const [SelectedUsersPutIn, setSelectedUsersPutIn] = useState([]);
    const [SelectedUsersPutOut, setSelectedUsersPutOut] = useState([]);

    const [ListMode, setListMode] = useState('recent');

    const getRecentUsers = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID') })
        };
        fetch(home_url + '/api/BRD110010SVC/getRecentUsers', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data != null) {
                    setRecentUsers(data.DATA);
                }
            });
    }

    const getPrimaryUsers = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID') })
        };
        fetch(home_url + '/api/BRD110010SVC/getPrimaryUsers', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data != null) {
                    setPrimaryUsers(data.DATA);
                }
            });
    }

    const getSameCompanyUsers = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ USER_ID: localStorage.getItem('USER_ID') })
        };
        fetch(home_url + '/api/BRD110010SVC/getSameCompanyUsers', requestOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
                if (data != null) {
                    setSameCompanyUsers(data.DATA);
                }
            });
    }

    const PutInUsers = () => {
        let users = SelectedUsersPutIn;
        let Recent = RecentUsers;
        let Primary = PrimaryUsers;
        let SameCompany = SameCompanyUsers;
        let Select = SendTargetUsers;

        for (let user in users) {
            if (ListMode === 'recent') {
                Select.push(Recent.filter(row => row.USER_ID === users[user])[0]);
                // Recent = (Recent.filter(row => row.USER_ID !== users[user]));
            }
            if (ListMode === 'primary') {
                Select.push(Primary.filter(row => row.USER_ID === users[user])[0]);
                // Primary = (Primary.filter(row => row.USER_ID !== users[user]));
            }
            if (ListMode === 'samecompany') {
                Select.push(SameCompany.filter(row => row.USER_ID === users[user])[0]);
                // SameCompany = (SameCompany.filter(row => row.USER_ID !== users[user]));
            }
        }
        if (ListMode === 'recent') {
            setRecentUsers(Recent);
        }
        if (ListMode === 'primary') {
            setPrimaryUsers(Primary);
        }
        if (ListMode === 'samecompany') {
            setSameCompanyUsers(SameCompany);
        }

        console.log(Select);
        console.log(typeof (Select));

        Select = [...new Set(Select.map(JSON.stringify))].map(JSON.parse);

        console.log(Select);

        setSendTargetUsers(Select);
        setSelectedUsersPutOut([]);
        setSelectedUsersPutIn([]);
    }
    const PutOutUsers = () => {
        let users = SelectedUsersPutOut;
        let Recent = RecentUsers;
        let Primary = PrimaryUsers;
        let SameCompany = SameCompanyUsers;
        let Select = SendTargetUsers;

        for (let user in users) {
            if (ListMode === 'recent') {
                // Recent.push(Select.filter(row => row.USER_ID === users[user])[0]);
                Select = (Select.filter(row => row.USER_ID !== users[user]));
            }
            if (ListMode === 'primary') {
                // Primary.push(Select.filter(row => row.USER_ID === users[user])[0]);
                Select = (Select.filter(row => row.USER_ID !== users[user]));
            }
            if (ListMode === 'samecompany') {
                //     SameCompany.push(Select.filter(row => row.USER_ID === users[user])[0]);
                Select = (Select.filter(row => row.USER_ID !== users[user]));
            }
        }
        if (ListMode === 'recent') {
            setRecentUsers(Recent);
        }
        if (ListMode === 'primary') {
            setPrimaryUsers(Primary);
        }
        if (ListMode === 'samecompany') {
            setSameCompanyUsers(SameCompany);
        }

        setSendTargetUsers(Select);
        setSelectedUsersPutOut([]);
        setSelectedUsersPutIn([]);
    }

    useEffect(() => {
        getRecentUsers();
        getPrimaryUsers();
        getSameCompanyUsers();
    }, []);

    return (
        <>
            {/* 쪽지 시작 */}
            <div className="adresSectionWrap">
                <div className="adresTTWrap">쪽지 주소록</div>
                <div className="adresMenuWrap">
                    <ul className="adresMenu">
                        <li>
                            <span
                                className="adresSectionOpen01"
                                onClick={() => { setListMode('recent'); }}
                            >
                                최근 사용한 주소
                            </span>
                            &nbsp;
                            <span className="adresSect_num">{RecentUsers && RecentUsers.length}</span>
                        </li>
                        <li>
                            <span
                                className="adresSectionOpen02"
                                onClick={() => { setListMode('primary'); }}
                            >
                                중요주소록
                            </span>
                            &nbsp;
                            <span className="adresSect_num">{PrimaryUsers && PrimaryUsers.length}</span>
                        </li>
                        <li style={{ display: localStorage.getItem('COMPANY_CODE') === 'TEMP' ? 'none' : '' }}>
                            <span
                                className="adresSectionOpen03"
                                onClick={() => { setListMode('samecompany'); }}
                            >
                                같은회사 주소
                            </span>
                            &nbsp;
                            <span className="adresSect_num">{SameCompanyUsers && SameCompanyUsers.length}</span>
                        </li>
                    </ul>
                </div>
                <div className="adresSelectWrap01">
                    <div className="adresSection01" style={{ display: ListMode === 'recent' ? '' : 'none' }}>
                        <span className="adresSectionTT">최근 사용한 주소</span>
                        <select
                            name="category_name01"
                            multiple size="10000"
                            className="adresDBlebtn01"
                            id="right"
                            onChange={(e) => setSelectedUsersPutIn(Array.from(e.target.selectedOptions, option => option.value))}
                        >
                            {RecentUsers && RecentUsers.map((row, index) => {
                                return (
                                    <>
                                        <option key={index} value={row.USER_ID} onDoubleClick={() => { PutInUsers() }}>{row.USER_NAME_LOC} ({row.USER_ID})</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>

                    <div className="adresSection02" style={{ display: ListMode === 'primary' ? '' : 'none' }}>
                        <span className="adresSectionTT">중요 주소록</span>
                        <select
                            name="category_name02"
                            multiple size="10000"
                            className="adresDBlebtn01"
                            id="right"
                            onChange={(e) => setSelectedUsersPutIn(Array.from(e.target.selectedOptions, option => option.value))}
                        >
                            {PrimaryUsers && PrimaryUsers.map((row, index) => {
                                return (
                                    <>
                                        <option key={index} value={row.USER_ID} onDoubleClick={() => { PutInUsers() }}>{row.USER_NAME_LOC} ({row.USER_ID})</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>

                    <div className="adresSection03" style={{ display: ListMode === 'samecompany' ? '' : 'none' }}>
                        <span className="adresSectionTT">같은 회사 주소</span>
                        <select
                            name="category_name03"
                            multiple size="10000"
                            className="adresDBlebtn01"
                            id="right"
                            onChange={(e) => setSelectedUsersPutIn(Array.from(e.target.selectedOptions, option => option.value))}
                        >
                            {SameCompanyUsers && SameCompanyUsers.map((row, index) => {
                                return (
                                    <>
                                        <option key={index} value={row.USER_ID} onDoubleClick={() => { PutInUsers() }}>{row.USER_NAME_LOC} ({row.USER_ID})</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>
                    <input type="button" value=">>" className="adresbtn adres_Rbtn" id="right" onClick={() => PutInUsers()}></input>
                    <br />
                    <input type="button" value="<<" className="adresbtn adres_Lbtn" id="left" onClick={() => PutOutUsers()}></input>
                </div>
                <div className="adresSelectWrap02">

                </div>
                <div className="adresSelectWrap03">
                    <div className="adresSection04">
                        <span className="adresSectionTT">받는 사람</span>
                        <select
                            name="category_selected"
                            multiple size="10000"
                            className="adresDBlebtn04" id="left"
                            onChange={(e) => setSelectedUsersPutOut(Array.from(e.target.selectedOptions, option => option.value))}
                        >
                            {SendTargetUsers && SendTargetUsers.map((row, index) => {
                                return (
                                    <>
                                        <option key={index} value={row.USER_ID} onDoubleClick={() => { PutOutUsers() }}>{row.USER_NAME_LOC} ({row.USER_ID})</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="adresBtnWrap">
                <span className="adresBtnWrapTxt">Shift 또는 Ctrl키를 누르시면 여러개를 한번에 선택하여 추가할 수 있습니다.</span>
                <span className="adresBtnSection">

                    <Button
                        className="ButtonStyle02"
                        variant="contained"
                        onClick={() => props.setRECEIVE_USER_ID(() => {
                            let Str = '';

                            for (let users in SendTargetUsers) {
                                Str += SendTargetUsers[users].USER_ID;
                                if (SendTargetUsers.length > parseInt(users) + 1) Str += ',';
                            }

                            props.setAdress(false);
                            return Str;
                        })}>
                        확인
                    </Button>
                    <Button
                        className="ButtonStyle03 adresBtn_Lmargin"
                        variant="contained"
                        onClick={() => props.setAdress(false)}>
                        취소
                    </Button>
                </span>
            </div>
            {/* 쪽지 끝 */}
        </>
    )
}
export default NoteAdress;