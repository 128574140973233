import React from "react";
// import ReactPlayer from "react-player";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT030101(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          AGV
          </Typography>
          <div className="common_totalWrap04">
            <div className="common_ttWrap03">
            <span className="common_ttIcon"></span>         
                              AGV 시스템
            </div>
            <video controls autoPlay="autoplay" muted
              src="/assets/mock-images/products_img2/AGV_introduce.mp4"
              width="750px"
              height="450px"
            ></video>
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT030101;