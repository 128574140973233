import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT030101(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          스마트팩토리 솔루션
          </Typography>
          <div className="common_totalWrap02">
            <a href="https://www.uplus.co.kr/biz/bzma/RetrieveSubMainNew.hpi?bnnrClssCd=BSF&mid=100385" target="_blank">
              <img src={require("../../../images/uplus_smart_factory.png").default} className="" />
            </a>
            <div className="common_ttWrap02">
                              ※ 상기 이미지 클릭 시, LGU+ 스마트팩토리솔루션에 대하여 상세 확인 가능합니다.
            </div> 
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT030101;