import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { brdbd003Actions } from "../../../slice/brdbd003Slice";
import RegisterOrEdit from "./BoardForm/RegisterOrEdit";
// import { CrossEditorA } from "../../CrossEditorA.js";
// ort CrossEditorForm from "./BoardForm/CrossEditorForm";
import {
  Box,
  Button,
} from '@material-ui/core';
import { DEXT5Editor } from "../../DEXT5Editor";
import { home_url } from '../../../js/globalProperties';
import cookies from 'react-cookies';

function BoardRegister(props) {

  console.log("Rprops : ", props);

  const dispatch = useDispatch();
  const history = useHistory();


  var items = new Array();
  const onGoTotalBoard = useCallback(() => history.push('/app/commonboard'), [history]);
  const moveCommonBoard = (bbs_data) => {
    // console.log('bbs_data : ', bbs_data);
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'], state: { 'bbs_id': bbs_data['BBS_ID'] } });
  };
  const goBack = () => { history.goBack(); };

  useEffect(() => {
    //컴포넌트가 마운트 되었을 때, 실행되는 영역
    // dispatch(brdbd003Actions.getBrdbd003(props.match.params.bbs_id));
    // const unblock = history.block('목록 페이지로 이동하시겠습니까?');

    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_ID", "value": props.location.state.BBS.NTT_ID }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "BBS_ID", "value": props.location.state.BBS.BBS_ID }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_NO", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_SJ", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_CN", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ANSWER_AT", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "PARNTSCTT_NO", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ANSWER_LC", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "SORT_ORDR", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "RDCNT", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "USE_AT", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCE_BGNDE", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCE_ENDDE", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCR_ID", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCR_NM", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "PASSWORD", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ATCH_FILE_ID", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE1", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE2", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE3", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE4", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE5", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE6", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE7", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE8", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE9", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE10", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "NOTICE_AT", "value": "N" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "SJ_BOLD_AT", "value": "N" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "SECRET_AT", "value": "N" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "BLOG_ID", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ADD_USER_ID", "value": localStorage.getItem('USER_ID') }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ADD_USER_NAME", "value": localStorage.getItem('USER_NAME_LOC') }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "CAT_NAME", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "REPLY_AT", "value": "Y" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "COMMENT_AT", "value": "Y" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "CAL_ID", "value": "" }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "START_TIME", "value": new Date().toISOString() }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "END_TIME", "value": new Date().toISOString() }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "ALLDAY_YN", "value": "N" }));
    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock(); 
    };
  }, [history]);

  const { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, FILE1, FILE2, FILE3, FILE4, FILE5, FILE6, FILE7, FILE8, FILE9, FILE10, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, CAT_NAME, REPLY_AT, COMMENT_AT, CAL_ID, START_TIME, END_TIME, ALLDAY_YN } = useSelector(
    (state) => ({
      NTT_ID: state.brdbd003Reducers.NTT_ID,
      BBS_ID: state.brdbd003Reducers.BBS_ID,
      NTT_NO: state.brdbd003Reducers.NTT_NO,
      NTT_SJ: state.brdbd003Reducers.NTT_SJ,
      NTT_CN: window.DEXT5 && window.DEXT5.GetBodyValue(),
      ANSWER_AT: state.brdbd003Reducers.ANSWER_AT,
      PARNTSCTT_NO: state.brdbd003Reducers.PARNTSCTT_NO,
      ANSWER_LC: state.brdbd003Reducers.ANSWER_LC,
      SORT_ORDR: state.brdbd003Reducers.SORT_ORDR,
      RDCNT: state.brdbd003Reducers.RDCNT,
      USE_AT: state.brdbd003Reducers.USE_AT,
      NTCE_BGNDE: state.brdbd003Reducers.NTCE_BGNDE,
      NTCE_ENDDE: state.brdbd003Reducers.NTCE_ENDDE,
      NTCR_ID: state.brdbd003Reducers.NTCR_ID,
      NTCR_NM: state.brdbd003Reducers.NTCR_NM,
      PASSWORD: state.brdbd003Reducers.PASSWORD,
      ATCH_FILE_ID: state.brdbd003Reducers.ATCH_FILE_ID,
      FILE1: state.brdbd003Reducers.FILE1,
      FILE2: state.brdbd003Reducers.FILE2,
      FILE3: state.brdbd003Reducers.FILE3,
      FILE4: state.brdbd003Reducers.FILE4,
      FILE5: state.brdbd003Reducers.FILE5,
      FILE6: state.brdbd003Reducers.FILE6,
      FILE7: state.brdbd003Reducers.FILE7,
      FILE8: state.brdbd003Reducers.FILE8,
      FILE9: state.brdbd003Reducers.FILE9,
      FILE10: state.brdbd003Reducers.FILE10,
      NOTICE_AT: state.brdbd003Reducers.NOTICE_AT,
      SJ_BOLD_AT: state.brdbd003Reducers.SJ_BOLD_AT,
      SECRET_AT: state.brdbd003Reducers.SECRET_AT,
      BLOG_ID: state.brdbd003Reducers.BLOG_ID,
      REMARKS: state.brdbd003Reducers.REMARKS,
      STATUS: state.brdbd003Reducers.STATUS,
      TIME_ZONE: state.brdbd003Reducers.TIME_ZONE,
      ADD_DATE: state.brdbd003Reducers.ADD_DATE,
      ADD_USER_ID: state.brdbd003Reducers.ADD_USER_ID,
      ADD_USER_NAME: state.brdbd003Reducers.ADD_USER_NAME,
      CHANGE_DATE: state.brdbd003Reducers.CHANGE_DATE,
      CHANGE_USER_ID: state.brdbd003Reducers.CHANGE_USER_ID,
      CHANGE_USER_NAME: state.brdbd003Reducers.CHANGE_USER_NAME,
      CAT_NAME: state.brdbd003Reducers.CAT_NAME,
      REPLY_AT: state.brdbd003Reducers.REPLY_AT,
      COMMENT_AT: state.brdbd003Reducers.COMMENT_AT,
      CAL_ID: state.brdbd003Reducers.CAL_ID,
      START_TIME: state.brdbd003Reducers.START_TIME,
      END_TIME: state.brdbd003Reducers.END_TIME,
      ALLDAY_YN: state.brdbd003Reducers.ALLDAY_YN,
    }),
    shallowEqual
  );

  const [IsForUpdate, setIsForUpdate] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get("isForEdit") === "true") {
      dispatch(brdbd003Actions.fetchBrdbd003(props.match.params.brdbd003Id));
      setIsForUpdate(true);
    }
  }, [BBS_ID]);

  const onSubmitBrdbd003 = (event) => {
    event.preventDefault();

    if (NTT_SJ === "" || NTT_SJ === null || NTT_SJ === undefined) {
      alert("제목을 작성하십시오.");
      return false;
    }

    if (NTT_CN === "" || NTT_CN === null || NTT_CN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    if (SECRET_AT === '' || SECRET_AT === null || SECRET_AT === undefined) {
      alert("비밀글 여부를 선택하십시오.")
      return false;
    }

    if (NOTICE_AT === '' || NOTICE_AT === null || NOTICE_AT === undefined) {
      alert("공지사항 여부를 선택하십시오.")
      return false;
    }

    if (SJ_BOLD_AT === '' || SJ_BOLD_AT === null || SJ_BOLD_AT === undefined) {
      alert("제목을 진하게 할지 여부를 선택하십시오.")
      return false;
    }

    if (!window.confirm('저장하시겠습니까?')) return false;

    const brdbd003 = {
      NTT_ID: NTT_ID,
      BBS_ID: BBS_ID,
      NTT_NO: NTT_NO,
      NTT_SJ: NTT_SJ,
      NTT_CN: window.DEXT5 && window.DEXT5.GetBodyValue(),
      ANSWER_AT: ANSWER_AT,
      PARNTSCTT_NO: PARNTSCTT_NO,
      ANSWER_LC: ANSWER_LC,
      SORT_ORDR: SORT_ORDR,
      RDCNT: RDCNT,
      USE_AT: USE_AT,
      NTCE_BGNDE: NTCE_BGNDE,
      NTCE_ENDDE: NTCE_ENDDE,
      NTCR_ID: NTCR_ID,
      NTCR_NM: NTCR_NM,
      PASSWORD: PASSWORD,
      ATCH_FILE_ID: ATCH_FILE_ID,
      FILE: "",
      FILE1: FILE1,
      FILE2: FILE2,
      FILE3: FILE3,
      FILE4: FILE4,
      FILE5: FILE5,
      FILE6: FILE6,
      FILE7: FILE7,
      FILE8: FILE8,
      FILE9: FILE9,
      FILE10: FILE10,
      NOTICE_AT: NOTICE_AT,
      SJ_BOLD_AT: SJ_BOLD_AT,
      SECRET_AT: SECRET_AT,
      BLOG_ID: BLOG_ID,
      REMARKS: REMARKS,
      STATUS: STATUS,
      TIME_ZONE: TIME_ZONE,
      ADD_DATE: ADD_DATE,
      ADD_USER_ID: ADD_USER_ID,
      ADD_USER_NAME: ADD_USER_NAME,
      CHANGE_DATE: CHANGE_DATE,
      CHANGE_USER_ID: CHANGE_USER_ID,
      CHANGE_USER_NAME: CHANGE_USER_NAME,
      CAT_NAME: CAT_NAME,
      REPLY_AT: REPLY_AT,
      COMMENT_AT: COMMENT_AT,
      CAL_ID: CAL_ID,
      START_TIME: START_TIME,
      END_TIME: END_TIME,
      ALLDAY_YN: ALLDAY_YN,
    };
    dispatch(brdbd003Actions.registerBrdbd003(brdbd003));
    history.push({ pathname: '/app/board/' + brdbd003['BBS_ID'] });
  };

  const onSubmitBrdbd003Reply = (event) => {
    event.preventDefault();

    console.log("DEXT5 ", window.DEXT5.GetBodyValue());
    let NTT_CN = window.DEXT5.GetBodyValue();

    if (NTT_CN === "" || NTT_CN === null || NTT_CN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    if (SECRET_AT === '' || SECRET_AT === null || SECRET_AT === undefined) {
      alert("비밀글 여부를 선택하십시오.")
      return false;
    }

    if (!window.confirm('저장하시겠습니까?')) return false;

    const brdbd003 = {
      NTT_ID: props.location.state.NTT_ID,
      BBS_ID: props.location.state.BBS_ID,
      NTT_NO: props.location.state.NTT_NO,
      NTT_SJ: props.location.state.NTT_SJ,
      NTT_CN: window.DEXT5 && window.DEXT5.GetBodyValue(),
      ANSWER_AT: ANSWER_AT,
      PARNTSCTT_NO: props.location.state.PARNTSCTT_NO,
      ANSWER_LC: props.location.state.ANSWER_LC,
      SORT_ORDR: props.location.state.SORT_ORDR,
      RDCNT: RDCNT,
      USE_AT: USE_AT,
      NTCE_BGNDE: NTCE_BGNDE,
      NTCE_ENDDE: NTCE_ENDDE,
      NTCR_ID: NTCR_ID,
      NTCR_NM: NTCR_NM,
      PASSWORD: PASSWORD,
      ATCH_FILE_ID: ATCH_FILE_ID,
      FILE: "",
      FILE1: FILE1,
      FILE2: FILE2,
      FILE3: FILE3,
      FILE4: FILE4,
      FILE5: FILE5,
      FILE6: FILE6,
      FILE7: FILE7,
      FILE8: FILE8,
      FILE9: FILE9,
      FILE10: FILE10,
      NOTICE_AT: NOTICE_AT,
      SJ_BOLD_AT: SJ_BOLD_AT,
      SECRET_AT: SECRET_AT,
      BLOG_ID: BLOG_ID,
      REMARKS: REMARKS,
      STATUS: STATUS,
      TIME_ZONE: TIME_ZONE,
      ADD_DATE: ADD_DATE,
      ADD_USER_ID: ADD_USER_ID,
      ADD_USER_NAME: ADD_USER_NAME,
      CHANGE_DATE: CHANGE_DATE,
      CHANGE_USER_ID: CHANGE_USER_ID,
      CHANGE_USER_NAME: CHANGE_USER_NAME,
      CAT_NAME: CAT_NAME,
      REPLY_AT: REPLY_AT,
      COMMENT_AT: COMMENT_AT,
      CAL_ID: CAL_ID,
      START_TIME: START_TIME,
      END_TIME: END_TIME,
      ALLDAY_YN: ALLDAY_YN,
    }
    dispatch(brdbd003Actions.registerBrdbd003REPLY(brdbd003));

    //질문게시판에 답변 작성시 알림쪽지 전송
    if (props.location.state.BBS_ID === "20210714090706000058") {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
        body: JSON.stringify({
          USER_ID: localStorage.getItem('USER_ID'),
          NTT_CN: '[' + props.location.state.BBS.BBS_NAME + ']' + props.location.state.NTT_SJ + ' 게시글에 대한 답변이 등록되었습니다.',
          RECEIVE_USER_ID: [props.location.state.ADD_USER_ID]
        })
      };
      fetch(home_url + '/api/BRD110010SVC/save', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          if (data.DATA !== 1) {
            alert(data.MSG);
            return false;
          }
          history.push({ pathname: '/app/board/' + brdbd003['BBS_ID'] });
        })
    } else history.push({ pathname: '/app/board/' + brdbd003['BBS_ID'] });
  }

  const onRegisterChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name == 'FILE1' || name == 'FILE2' || name == 'FILE3'
      || name == 'FILE4' || name == 'FILE5' || name == 'FILE6'
      || name == 'FILE7' || name == 'FILE8' || name == 'FILE9' || name == 'FILE10'
    ) {
      dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: event.target.files[0] }));
    } else {
      dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: value }));
    }
  };

  const onRegisterTimeChange = (name, value) => {
    console.log(name, " : ", value);
    dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: value }));
  }
  return (
    <>
      <RegisterOrEdit
        DEXT5={DEXT5Editor}
        BBS={props.location.state.BBS}
        NTT_ID={props.location.state.NTT_ID ? props.location.state.NTT_ID : NTT_ID}
        BBS_ID={props.location.state.BBS_ID ? props.location.state.BBS_ID : BBS_ID}
        NTT_NO={NTT_NO}
        NTT_SJ={props.location.state.NTT_SJ ? props.location.state.NTT_SJ : NTT_SJ}
        NTT_CN={NTT_CN}
        ANSWER_AT={ANSWER_AT}
        PARNTSCTT_NO={PARNTSCTT_NO}
        ANSWER_LC={props.location.state.ANSWER_LC ? props.location.state.ANSWER_LC : 0}
        SORT_ORDR={props.location.state.SORT_ORDR ? props.location.state.SORT_ORDR : SORT_ORDR}
        RDCNT={RDCNT}
        USE_AT={USE_AT}
        NTCE_BGNDE={NTCE_BGNDE}
        NTCE_ENDDE={NTCE_ENDDE}
        NTCR_ID={NTCR_ID}
        NTCR_NM={NTCR_NM}
        PASSWORD={PASSWORD}
        ATCH_FILE_ID={ATCH_FILE_ID}
        NOTICE_AT={NOTICE_AT}
        SJ_BOLD_AT={SJ_BOLD_AT}
        SECRET_AT={SECRET_AT}
        BLOG_ID={BLOG_ID}
        REMARKS={REMARKS}
        STATUS={STATUS}
        TIME_ZONE={TIME_ZONE}
        ADD_DATE={ADD_DATE}
        ADD_USER_ID={ADD_USER_ID}
        ADD_USER_NAME={ADD_USER_NAME}
        CHANGE_DATE={CHANGE_DATE}
        CHANGE_USER_ID={CHANGE_USER_ID}
        CHANGE_USER_NAME={CHANGE_USER_NAME}
        REPLY={props.location.state.REPLY}
        CAT_NAME={CAT_NAME}
        REPLY_AT={REPLY_AT}
        COMMENT_AT={COMMENT_AT}
        CAL_ID={CAL_ID}
        START_TIME={START_TIME}
        END_TIME={END_TIME}
        ALLDAY_YN={ALLDAY_YN}

        handleRegisterChange={onRegisterChange}
        handleSubmit={onSubmitBrdbd003}
        handleTotalBoard={onGoTotalBoard}
        handleGoBack={goBack}
        updateRequest={IsForUpdate}
        handleRegisterTimeChange={onRegisterTimeChange}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button onClick={() => goBack()}
          className="ButtonStyle03"
          variant="contained">
          이전페이지
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => moveCommonBoard({ BBS_ID })}
          className="ButtonStyle03"
          variant="contained">
          목록으로
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          onClick={(event) => { props.location.state.REPLY ? onSubmitBrdbd003Reply(event) : onSubmitBrdbd003(event) }}
          // color="primary" 
          className="ButtonStyle02"
          variant="contained">
          저장
        </Button>

      </Box>
    </>
  );
}

export default BoardRegister;