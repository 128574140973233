import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt">
          
          </Typography> */}
          <div className="pop_totalWrap">
            <div className="pop_ttWrap">
              <span className="pop_ttIcon"></span>         
                              수요실태조사
            </div>
          <Divider/>
          <img src={require("../../../images/main_graph_banner_02.png").default} />
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT000101_01;