import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
	const history = useHistory();
	if(rest.path){
		// console.log('세션체크 props  : ', rest, rest['path'], localStorage.getItem('USER_AUTH'));
		// 관리자만 사용할 수 있는 메뉴 지정(차후 DB에서 권한정보를 조회해서 활용하도록 변경할 것)
		if(rest['path'].indexOf('/app/totalboard') > -1 ){
			if(localStorage.getItem('USER_AUTH') != 'A'){
				alert('관리자만 사용 가능한 기능입니다.');
				return (<Route {...rest} render={props => <Redirect to="/" /> } /> )
			}
		}
	if(localStorage.getItem('token')){
		return (<Route {...rest} render={props => <Component {...props} />}/>);
	} else{
		if( rest.location.pathname.indexOf('20210714090736000056') > -1
		||   rest.location.pathname.indexOf('20210714090715000057') > -1
		||   rest.location.pathname.indexOf('20210721060749000083') > -1
		||   rest.location.pathname.indexOf('20210721060703000080') > -1
		||   rest.location.pathname.indexOf('20210721060722000081') > -1
		||   rest.location.pathname.indexOf('20210714090756000066') > -1
		||   rest.location.pathname.indexOf('20210820010819000108') > -1
		){
			return (
				<Route {...rest} render={props => <Component {...props} />} /> 
			);
		}else{
			alert('로그인 후 이용 가능합니다.');
			// if(rest.location.pathname=="/app/FUL/PLT000101_05"){
			// 	history.push('/');
			// }
			// if(rest.location.pathname=="/app/FUL/Note"){
			// 	history.push('/');
			// }
			return <Redirect to="/" />;
		}
		
	}
	}
}

export default PrivateRoute;