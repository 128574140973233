import React, { useCallback } from "react";
// import { Button, Input } from "antd";
import { Link, } from "react-router-dom";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import { home_url } from '../../../../js/globalProperties';
import cookies from "react-cookies";
import { saveAs } from 'file-saver';

const states = [
  {
    value: 'Y',
    label: '사용'
  },
  {
    value: 'N',
    label: '미사용'
  }
];

const bbs_type_cd = [
  {
    value: '1',
    label: '게시판형 1'
  },
  {
    value: '2',
    label: '게시판형 2'
  },
  {
    value: '3',
    label: '갤러리형 1'
  },
  {
    value: '4',
    label: '갤러리형 2'
  }
];

const tmplat_cd = [
  {
    value: '1',
    label: '블루'
  },
  {
    value: '2',
    label: '레드'
  },
  {
    value: '3',
    label: '그린'
  },
  {
    value: '4',
    label: '그레이'
  }
];

function DetailOrEdit(props) {
  console.log(props);

  const getfileSize = (x) => {
    var s = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    var e = Math.floor(Math.log(x) / Math.log(1024));
    return (x / Math.pow(1024, e)).toFixed(2) + " " + s[e];
  };

  const convertTime = (t) => {
    let date = new Date(t);
    let year = date.getFullYear();
    let month = 1 + date.getMonth();
    month = month >= 10 ? month : "0" + month;
    let day = date.getDate();
    day = day >= 10 ? day : "0" + day;
    let hour = date.getHours();
    hour = hour >= 10 ? hour : "0" + hour;
    let minute = date.getMinutes();
    minute = minute >= 10 ? minute : "0" + minute;
    if (props.ALLDAY_YN === 'Y')
      return String(year) + '년 ' + String(month) + '월 ' + String(day) + '일 ';
    else
      return String(year) + '년 ' + String(month) + '월 ' + String(day) + '일 ' + String(hour) + '시 ' + String(minute) + '분';
  }

  const getDownload = (FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM) => {
    let requestOptions = {
      method: 'POST',
      headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({
        FILE_STRE_COURS: FILE_STRE_COURS,
        STRE_FILE_NM: STRE_FILE_NM
      })
    }

    fetch(home_url + "/api/BRD030010SVC/getDownload", requestOptions)
      .then(response => response.blob())
      .then(blob => saveAs(blob, ORIGNL_FILE_NM))
  }

  return (
    <>
      <form onSubmit={props.handleSubmit}
        // autoComplete="off"
        // noValidate
        encType="multipart/form-data"
        {...props}>
        <Typography className="listTable_tt">
          {props.BBS == undefined ? "게시물" : props.BBS.BBS_NAME} - 상세보기
        </Typography>
        {/* <CardHeader
            title={props}
            subheader={props.BBS.BBS_ITNRCN}
          /> */}
        <Card style={{ width: "100%" }}>
          <table className="srchTable">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tr>
              <th>제목</th>
              <td colspan="3">
                <span style={{ display: props.CAT_NAME === "" ? 'none' : '' }}>[{props.CAT_NAME}]&nbsp;&nbsp;</span>{props.NTT_SJ}
              </td>
            </tr>
            <tr>
              <th>작성자</th>
              <td colspan="1">
                {props.NTCR_NM}
              </td>
              <th>작성일</th>
              <td colspan="1">
                {props.ADD_DATE}
              </td>
            </tr>
            <tr style={{ display: props.BBS.TMPLAT_ID === "2" ? '' : 'none' }}>
              <th>시작시간</th>
              <td calSpan="1">
                {convertTime(props.START_TIME)}
              </td>
              <th>종료시간</th>
              <td calSpan="1">
                {convertTime(props.END_TIME)}
              </td>
            </tr>
            <tr>
              <td colspan="4" minRows="30">
                <div className="editor-preview">
                  <div className="@@" dangerouslySetInnerHTML={{ __html: props.NTT_CN }} style={{ padding: "20px", minHeight: '200px' }}></div>
                </div>
              </td>
            </tr>
            <tr style={{ display: props.FILES.length === 0 ? 'none' : '' }}>
              <th>
                첨부파일
              </th>
              <td colspan="3">
                <table>
                  <tbody>
                    {
                      props.FILES.map((row, index) => {
                        const { FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN } = row;
                        // return <Typography variant="subtitle2" noWrap>{index}. {ORIGNL_FILE_NM} </Typography>;
                        return (
                          <tr key={index}>
                            <td
                              style={{ cursor: 'pointer', borderBottom: '0px', padding: '0px', paddingLeft: '5px', textDecoration: 'underline' }}
                              onClick={() => getDownload(FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM)}
                            >
                              <img src={require("../../../../images/file.png").default} width="13px" />
                              &nbsp;
                              {ORIGNL_FILE_NM}
                              &nbsp;
                              - {getfileSize(FILE_SIZE)}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>

              </td>
            </tr>
          </table>
        </Card>
      </form>
    </>
  );
}

export default DetailOrEdit;
