import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgProduct } from '../../../../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
'업체명 입력 1',
'업체명 입력 2',
'업체명 입력 3',
'업체명 입력 4',
'업체명 입력 5',
'업체명 입력 6',
'업체명 입력 7',
'업체명 입력 8',
'업체명 입력 9',
'업체명 입력 10',
'업체명 입력 11',
'업체명 입력 12',
'업체명 입력 13',
'업체명 입력 14',
'업체명 입력 15',
'업체명 입력 16',
'업체명 입력 17',
'업체명 입력 18',
'업체명 입력 19',
'업체명 입력 20',
'업체명 입력 21',
'업체명 입력 22',
'업체명 입력 23',
'업체명 입력 24'
];
const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

// ----------------------------------------------------------------------

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    cover: mockImgProduct(setIndex),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale: setIndex % 3 ? null : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', ''])
  };
});

export default products;
