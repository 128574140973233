import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010301(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          사업내용
          </Typography>
          <div className="common_totalWrap02">
            <img src={require("../../../images/strategy_img.png").default} className="" />
          </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT010301;