import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from "react";
// material
import { Box, Card, Link, Typography, Stack } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Label from '../PortalBoard/Label';
import { mockImgBoard } from '../../../utils/mockImages';
import { home_url } from '../../../js/globalProperties';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

PortalCard.propTypes = {
  cardType: PropTypes.string
};

export default function PortalCard({ boardList }) {

  const history = useHistory();

  // console.log("portalcard card : ",boardList)

  const {NTT_ID, BBS_ID, NTT_SJ, ADD_DATE, ATCH_FILE_ID, CHANGE_DATE } = boardList;
  
  const handleBoardRowClick = (bbs_data) => {

    // if (localStorage.getItem('user_id') === null ){
    //   alert("로그인 후 이용가능 합니다.");
    //   history.push('/');
    //   return false;
    // } else {
      // alert('bbs_data : ', bbs_data, bbs_data['NTT_ID'],  {'bbs_id': { 'ntt_id': '19', 'bbs_id': bbs_data['BBS_ID'] } } );
      history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'] + '/' + bbs_data['NTT_ID'], state: bbs_data});
    // }
  };

  const [files, setFiles] = useState([]);
  var resultFiles = [];

  const callApi = () => {
    const requestFileOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({'ATCH_FILE_ID':ATCH_FILE_ID, "OPEN_API_YN":"Y"})
    };

    if(ATCH_FILE_ID != undefined && ATCH_FILE_ID != ""){
      fetch(home_url+'/api/BRD070010SVC/getSelectFileList', requestFileOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        var arry = [];

        resultFiles = data['DATA'];

        for(var n in resultFiles){
          arry.push(resultFiles[n]);
        }
        // console.log("portalcard file : ",resultFiles);

        setFiles(arry);
      });
    }
  }

  const FILELIST = [...Array(0)].map((_, index) => ({}));

  for (var n in files) {
    FILELIST.push(files[n]);
  } 

  useEffect(() => {
    callApi();
  },[]);

  if(files.length>0) {
    return(
      <Card>
      {/* 사진표시영 */}
      <Box sx={{ pt: '100%', position: 'relative' }} className="glyThumnail">
        {/* <ProductImgStyle alt='예제' src={mockImgBoard(getRandom(1,28))} /> */}
        {/* <ProductImgStyle alt='예제' src={require("../../../images/no_image.png").default } /> */}
        {/* <ProductImgStyle alt={name} src={cover} /> */}

        {
          // 아래 row별 변수를 정확하게 매칭안해주면 오류가 발생될 수 있으니, 꼭 참고 할 것.. 웹에서 원인을 찾기 어려움
          
          files.map((row, index) => {
            const {FILE_SIZE, CREAT_DT, ATCH_FILE_ID, FILE_STRE_COURS, STRE_FILE_NM, ORIGNL_FILE_NM, FILE_EXTSN, FILE_SN} = row;
            var filepath = FILE_STRE_COURS.substring(FILE_STRE_COURS.indexOf('BBS\\'), FILE_STRE_COURS.length) ;
            filepath = filepath.replace('\\', '/');
            // console.log('filepath : ', filepath);
            const filename = home_url+"/api/"+filepath+"/"+STRE_FILE_NM;
            // console.log('filename : ', filename);
            if(filename !== null && filename !== ""){
              return (
                <ProductImgStyle key={index} alt={ORIGNL_FILE_NM} src={filename} />
              );
            } else {
              return (
                <ProductImgStyle key={index} alt="이미지없음" src={require("../../../images/no_image.png").default} />
              );
            }
          })
        }
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}  className="thumbnail_ttWrap"
       onClick={() => handleBoardRowClick({NTT_ID, BBS_ID })}

      >
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            {'sale' && (
              <Label className="thumbnail_newIcon"
                // variant="filled"
                // color={('sale' === 'sale' && 'error') || 'info'}
                // sx={{
                //   zIndex: 9,
                //   top: 16,
                //   right: 16,
                //   position: 'absolute',
                //   textTransform: 'uppercase'
                // }}
              >
                N
              </Label>
            )}
          <Typography variant="subtitle2" noWrap className="thumbnail_name">
            {NTT_SJ}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1" className="thumbnail_date">
            <Typography
              component="span"
              variant="body1"
              // sx={{
              //   color: 'text.disabled',
              //   textDecoration: 'line-through'
              // }}
            >
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
              {ADD_DATE}
            {/* {fCurrency(price)} */}
          </Typography>
        </Stack>
      </Stack>
    </Card>
    );
  } else {
    return(
      <Card>
      {/* 사진표시영 */}
      <Box sx={{ pt: '100%', position: 'relative' }} className="glyThumnail">
        <ProductImgStyle alt='예제' src={require("../../../images/no_image.png").default } />
        {/* <ProductImgStyle alt={name} src={cover} /> */}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}  className="thumbnail_ttWrap"
       onClick={() => handleBoardRowClick({NTT_ID, BBS_ID })}

      >
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            {'sale' && (
              <Label className="thumbnail_newIcon"
                // variant="filled"
                // color={('sale' === 'sale' && 'error') || 'info'}
                // sx={{
                //   zIndex: 9,
                //   top: 16,
                //   right: 16,
                //   position: 'absolute',
                //   textTransform: 'uppercase'
                // }}
              >
                N
              </Label>
            )}
          <Typography variant="subtitle2" noWrap className="thumbnail_name">
            {NTT_SJ}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between" className="thumbnail_date_wrap">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1" className="thumbnail_date">
            <Typography
              component="span"
              variant="body1"
              // sx={{
              //   color: 'text.disabled',
              //   textDecoration: 'line-through'
              // }}
            >
              {/* {priceSale && fCurrency(priceSale)} */}
            </Typography>
              {ADD_DATE}
            {/* {fCurrency(price)} */}
          </Typography>
        </Stack>
      </Stack>
    </Card>
    );
  }
}