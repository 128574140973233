import {
    TermsOfservice,
    PrivacyPolicy,
} from './../../PortalCustomerService/PortalTermsText';
import {
    Button,
    Box,
    Card,
    Typography,
    Divider
  } from '@material-ui/core';
import { useLocation } from 'react-router';

const Terms = () => {
    const location = useLocation();
    console.log(location.pathname);
    if (location.pathname === '/app/FUL/TermsOfservice') {
        return (
            <>
            <Typography className="listTable_tt">
            이용약관
            </Typography>
            <div className="common_totalWrap05 termsNprivacy">
                <TermsOfservice />
            </div>
            </>
        )
    } else {
        return (
            <>
            <Typography className="listTable_tt">
            개인정보처리방침
            </Typography>
            <div className="common_totalWrap05 termsNprivacy">
                <PrivacyPolicy />
            </div>
            </>
        )
    }
}
export default Terms;