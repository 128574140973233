import React, { useCallback, useState, useEffect } from "react";
// import { Button, Input } from "antd";
import { Link, } from "react-router-dom";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import cookies from "react-cookies"
import { home_url } from "../../../../js/globalProperties";

const states = [
  {
    value: 'Y',
    label: '사용'
  },
  {
    value: 'N',
    label: '미사용'
  }
];

const auth = [
  {
    value: 'U',
    label: '일반 사용자'
  },
  {
    value: 'M',
    label: '기업 관리자'
  },
  {
    value: 'A',
    label: '전체 관리자'
  }
]

const bbs_type_cd = [
  {
    value: '1',
    label: 'Type 1'
  },
  {
    value: '2',
    label: 'Type 2'
  },
  {
    value: '3',
    label: 'Type 3'
  },
  {
    value: '4',
    label: 'Type 4'
  }
];

const tmplat_cd = [
  {
    value: '1',
    label: '게시판 목록형'
  },
  {
    value: '2',
    label: '게시판 달력형'
  },
  {
    value: '3',
    label: '게시판 앨범형'
  },
  {
    value: '4',
    label: '게시판 앨범+설명형'
  }
];

function RegisterOrEdit(props) {

  console.log(props);

  const [CAT_NAME, setCAT_NAME] = useState("");
  const [CATS, setCATS] = useState();

  const getCATS = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'BBS_ID': props.BBS_ID, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD140010SVC/get', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.DATA) setCATS(data.DATA);
      })
  }

  useEffect(() => {
    getCATS();
  }, [props]);

  const registCategory = () => {
    if (CAT_NAME === '') {
      alert('카테고리 이름을 확인해주세요.');
      return false;
    }

    for (let cat in CATS) {
      if (CATS[cat].CAT_NAME === CAT_NAME) {
        alert('이미 존재하는 카테고리입니다.');
        return false;
      }
    }

    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify({ 'BBS_ID': props.BBS_ID, 'CAT_NAME': CAT_NAME })
    };
    fetch(home_url + '/api/BRD140010SVC/save', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        alert(data.MSG);
        getCATS();
      })
  }

  return (
    <div>
      <form onSubmit={props.handleSubmit}
        // autoComplete="off"
        // noValidate
        {...props}>
        <Card>
          <Typography className="listTable_tt">
            게시판등록
          </Typography>
          <Divider />
          <table className="srchTable">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tr>
              <th>게시판 이름</th>
              <td colSpan="3">
                <TextField className="bbsInputSize"
                  fullWidth
                  // helperText="게시판 이름을 입력하세요"
                  // label="게시판 이름"
                  name="BBS_NAME"
                  onChange={(event) => props.handleRegisterChange(event)}
                  type="string"
                  required
                  value={props.BBS_NAME}
                  variant="outlined"
                />
              </td>
            </tr>
            <tr>
              <th>게시판 설명</th>
              <td colSpan="3">
                <TextField className="bbsInputSize"
                  fullWidth
                  // helperText="게시판의 용도 및 활용방법을 기재해주세요."
                  // label="게시판 설명"
                  name="BBS_INTRCN"
                  onChange={(event) => props.handleRegisterChange(event)}
                  type="string"
                  required
                  value={props.BBS_INTRCN}
                  variant="outlined"
                />
              </td>
            </tr>
            <tr>
              <th>게시판 스타일</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="게시판 스타일"
                  name="TMPLAT_ID"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.TMPLAT_ID}
                  variant="outlined"
                >
                  {tmplat_cd.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td>
              {/* <th>게시판 종류</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="게시판 종류"
                  name="BBS_TY_CODE"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.BBS_TY_CODE}
                  variant="outlined"
                >
                  {bbs_type_cd.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td> */}
              <th>글쓰기 권한</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  name="WRITE_AUTH"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.WRITE_AUTH}
                  variant="outlined"
                >
                  {auth.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td>
            </tr>
            <tr>
              <th>파일 첨부기능</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="파일 첨부기능"
                  name="FILE_ATCH_POSBL_AT"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.FILE_ATCH_POSBL_AT}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td>
              <th>파일 첨부가능 갯수</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="파일 첨부사이즈"
                  name="ATCH_POSBL_FILE_NUMBER"
                  onChange={(event) => props.handleRegisterChange(event)}
                  type="number"
                  value={props.ATCH_POSBL_FILE_NUMBER}
                  variant="outlined"
                />
              </td>
            </tr>
            {/* <tr>
              <th>답변 기능 추가</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="답변 기능 추가"
                  name="REPLY_POSBL_AT"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.REPLY_POSBL_AT}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td>
            </tr> */}
            <tr>
              <th>사용여부</th>
              <td>
                <TextField className="bbsInputSize"
                  fullWidth
                  // label="사용여부"
                  name="USE_AT"
                  onChange={(event) => props.handleRegisterChange(event)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.USE_AT}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </td>
              <td colSpan='2' style={{fontWeight:'bold'}}>* 미사용 체크시 게시판은 삭제됩니다.</td>
            </tr>
            <tr style={{ display: props.BBS_ID ? '' : 'none' }}>
              <th colSpan="1">카테고리</th>
              <td colSpan="3">
                <table className="srchTable">
                  <colgroup>
                    <col style={{ width: "31%" }} />
                    <col style={{ width: "35%" }} />
                    <col style={{ width: "31%" }} />
                    <col style={{ width: "3%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colSpan="1" style={{ textAlign: "center" }}>카테고리</th>
                      <th colSpan="1" style={{ textAlign: "center" }}>생성자</th>
                      <th colSpan="1" style={{ textAlign: "center" }}>생성일자</th>
                      <th colSpan="1" style={{ textAlign: "center" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {CATS && CATS.map((row, index) => (
                      <tr key={index}>
                        <td colSpan="1" style={{ textAlign: "center" }}>{row.CAT_NAME}</td>
                        <td colSpan="1" style={{ textAlign: "center" }}>{row.ADD_USER_NAME}</td>
                        <td colSpan="1" style={{ textAlign: "center" }}>{row.ADD_DATE}</td>
                        <td colSpan="1" style={{ textAlign: "center" }}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              if (window.confirm(row.CAT_NAME + ' 카테고리를 삭제하시겠습니까?') === false) {
                                return false;
                              }
                              let requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
                                body: JSON.stringify({ 'CAT_ID': row.CAT_ID, 'USE_YN': 'N' })
                              };
                              fetch(home_url + '/api/BRD140010SVC/delete', requestOptions)
                                .then(function (response) { return response.json(); })
                                .then(function (data) {
                                  console.log(data);
                                  if (data.TYPE === 1) alert('삭제되었습니다.');
                                  getCATS();
                                })
                            }}
                          >X</Button>
                        </td>
                      </tr>
                    ))}
                    {CATS && CATS.length === 0 &&
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>추가된 카테고리가 없습니다.</td>
                      </tr>}
                  </tbody>
                </table>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: 25,
                    p: 0,
                    marginTop: 1,
                    marginBottom: 1
                  }}
                >
                  <TextField
                    className="bbsInputSize"
                    fullWidth
                    type="string"
                    variant="outlined"
                    onChange={(e) => { setCAT_NAME(e.target.value) }}
                    onKeyPress={
                      (e) => {
                        if (e.key === 'Enter') { registCategory() }
                      }}
                  />
                  <Button
                    variant="outlined"
                    sx={{ height: 30, marginLeft: 1, marginRight: 0.5 }}
                    onClick={() => registCategory()}
                  >추가</Button>
                </Box>
              </td>
            </tr>
          </table>

        </Card>

      </form>
    </div>
  );
}

export default RegisterOrEdit;
