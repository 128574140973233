import { put } from "redux-saga/effects";
import Axios from "axios";
import { brdbd009Actions } from "../slice/brdbd009Slice";
import history from "../utils/history";
import { home_url } from '../js/globalProperties';
import cookies from "react-cookies"


//댓글 등록
export function* registerBrdbd009Async(action) {
  const data = action.payload;
  // data.append('OPEN_API_YN', 'Y');

  const response = yield Axios.post(
    home_url+'/api/BRD090010SVC/putInsertCmt', {"BRD090010F1" :data}, {headers: { 'Content-Type': 'application/json','AJAX':true, 'X-CSRF-TOKEN':localStorage.getItem('X-CSRF-TOKEN')}})
  .then(() => {
    window.location.reload();
});

  alert("등록되었습니다.");
}

//댓글 불러오기
export function* getBrdbd009Async(action) {
  if(action.payload.BBS_ID != ""){
    try {
      const data = action.payload;
      // data.append('OPEN_API_YN', 'Y');

      const responseForBoard = yield Axios.post(home_url+'/api/BRD090010SVC/getSelectCmt', data, {'OPEN_API_YN':'Y'});
      const boardData = responseForBoard.data;
    yield put(brdbd009Actions.getBrdbd009Async(boardData.DATA));
    } catch (e) {
      yield put(brdbd009Actions.getBrdbd009FailedAsync(e.message));
    }
  }else return false;
}

export function* fetchBrdbd009Async(action) {
  const data = action.payload;
  // data.append('OPEN_API_YN', 'Y');

  const response = yield Axios.post(home_url+'/api/BRD090010SVC/getSelectCmt', data, {headers: { 'Content-Type': 'application/json','AJAX':true, 'X-CSRF-TOKEN':localStorage.getItem('X-CSRF-TOKEN')}})
  yield put(brdbd009Actions.getBrdbd009Async(response.data));
}

//댓글 수정
export function* updateBrdbd009Async(action) {
  const data = action.payload;
  // data.append('OPEN_API_YN', 'Y');

  const response = yield Axios.post(home_url+'/api/BRD090010SVC/putUpdateCmt', data, {headers: { 'Content-Type': 'application/json','AJAX':true, 'X-CSRF-TOKEN':localStorage.getItem('X-CSRF-TOKEN')}})
  .then(() => {
    window.location.reload();
});
  alert("수정되었습니다.");
}

//댓글 삭제
export function* deleteBrdbd009Async(action) {
  const data = action.payload;
  // data.append('OPEN_API_YN', 'Y');

  const response = yield Axios.post(home_url+'/api/BRD090010SVC/putDeleteCmt', {"BRD090010F1" :data}, {headers: { 'Content-Type': 'application/json','AJAX':true, 'X-CSRF-TOKEN':localStorage.getItem('X-CSRF-TOKEN')}})
  .then(() => {
    window.location.reload();
});
  alert("삭제되었습니다.");
}
