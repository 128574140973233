import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          
          <img src={require("../../../images/bigdata_img01.jpg").default} />
          <div className="bigdataPage_absolWrap01"></div>
              <div className="bigdataTxt_Wrap03">
                                    물류 산업단지의 운송현황 데이터를<br/> 통계적으로 분석하여<br/> 시각화한 결과를 확인할 수 있는<br/> 실시간 대시보드입니다.                
              </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT000101_01;