import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          포워딩(해외수출입물류) 요청기능 적용사례
          </Typography>
          이메일 등으로 관리되던 해외수출입물류관리를 시스템을 통해 관리함으로써 물동량 및 물류비 통계가 용이해지고 수출입 물류의 흐름을 손쉽게 관리할 수 있으며, 화주기업에게 해당 정보를 손쉽게 제공 가능
          <Divider/>
          <video autoPlay muted loop src={require("../../../images/samplevideo.mp4").default} style={{"width":"1000px", "height":"520px"}}/>
        </Card>
      </Page>
    </>
  );
}

export default PLT000101_01;