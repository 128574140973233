import { put } from "redux-saga/effects";
import Axios from "axios";
import { brdbd003Actions } from "../slice/brdbd003Slice";
import history from "../utils/history";
import { home_url } from '../js/globalProperties';
import cookies from 'react-cookies'

export function* registerBrdbd003Async(action) {
  const data = action.payload;

  var bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key != "FILE") {
      bodyFormData.append(key, action.payload[key]);
    }
  }

  for (var i = 0; i < 10; i++) {
    var file_data = action.payload['FILE' + (i + 1)];
    if (file_data != "" & file_data != undefined) {
      bodyFormData.append('FILE', file_data);
    }
  }
  //반월시화 대나무숲
  if (data.BBS_ID === '20210714090704000071') bodyFormData.append('ANONYMOUS_AT', 'Y');


  const requestOptions = {
    method: 'POST',
    headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
    processData: false,
    body: bodyFormData,

  };
  const response = yield Axios.post(home_url + `/api/BRD030010SVC/putInsertBbsDetail`, bodyFormData, requestOptions);

}

export function* registerBrdbd003REPLYAsync(action) {
  const data = action.payload;

  var bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key != "FILE") {
      bodyFormData.append(key, action.payload[key]);
    }
  }

  for (var i = 0; i < 10; i++) {
    var file_data = action.payload['FILE' + (i + 1)];
    if (file_data != "" & file_data != undefined) {
      bodyFormData.append('FILE', file_data);
    }
  }

  bodyFormData.append('OPEN_API_YN', 'Y');

  //반월시화 대나무숲
  if (data.BBS_ID === '20210714090704000071') bodyFormData.append('ANONYMOUS_AT', 'Y');

  let requestOptions = {
    method: 'POST',
    headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
    processData: false,
    body: bodyFormData,
  };
  const response = yield Axios.post(home_url + `/api/BRD030010SVC/putInsertBbsRe`, bodyFormData, requestOptions);
}

export function* getBrdbd003Async(action) {
  try {
    const data = action.payload;
    const responseForBoard = yield Axios.post(home_url + '/api/BRD030010SVC/getSelectBbsList', data);
    const boardData = responseForBoard.data;
    yield put(brdbd003Actions.getBrdbd003Async(boardData.DATA));
  } catch (e) {
    yield put(brdbd003Actions.getBrdbd003FailedAsync(e.message));
  }
}

export function* getBrdbd003DetailAsync(action) {
  try {
    const data = action.payload;
    const responseForBoard = yield Axios.post(home_url + '/api/BRD030010SVC/getSelectBbsDetailList', data);
    const boardData = responseForBoard.data;
    yield put(brdbd003Actions.getBrdbd003Async(boardData.DATA));
  } catch (e) {
    yield put(brdbd003Actions.getBrdbd003FailedAsync(e.message));
  }
}

export function* fetchBrdbd003Async(action) {
  const bbs_id = action.payload;

  const response = yield Axios.get(`http://localhost:4000/Brdbd003/${bbs_id}`);

  yield put(brdbd003Actions.getBrdbd003Async(response.data));
}

export function* updateBrdbd003Async(action) {
  const data = action.payload;

  var bodyFormData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key != "FILE") {
      bodyFormData.append(key, action.payload[key]);
    }
  }

  for (var i = 0; i < 10; i++) {
    var file_data = action.payload['FILE' + (i + 1)];
    if (file_data != "" & file_data != undefined) {
      bodyFormData.append('FILE', file_data);
    }
  }

  bodyFormData.append('OPEN_API_YN', 'Y');
  const requestOptions = {
    method: 'POST',
    headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
  };

  yield Axios.post(home_url + `/api/BRD030010SVC/putUpdateBbs`, bodyFormData, requestOptions);
}

export function* deleteBrdbd003Async(action) {
  const data = action.payload;
  console.log("data : ", data);

  const requestOptions = {
    method: 'POST',
    headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
  };

  const response = yield Axios.post(home_url + `/api/BRD030010SVC/putDeleteBbs`, { "BRD030010F1": data }, requestOptions)
}