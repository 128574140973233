import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { brdbd003Actions } from "../../../slice/brdbd003Slice";
import RegisterOrEdit from "./BoardForm/RegisterOrEdit";
import cookies from "react-cookies"

import {
  Box,
  Button,
} from '@material-ui/core';
import { home_url } from '../../../js/globalProperties';
import { DEXT5Editor } from "../../DEXT5Editor";


function BoardUpdate(props) {

  const dispatch = useDispatch();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [BBS, setBBS] = useState([]);

  var items = new Array();
  var resultFiles = [];

  const bbsCallApi = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'BBS_ID': props.location.state.BBS_ID, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD010010SVC/selectBbs', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        items = data['DATA'];
        console.log('데이터 조회결과 : ', items);
        // items 
        setBBS(items[0]);
      });
  }
  const onGoTotalBoard = useCallback(() => history.push('/app/commonboard'), [history]);
  const goBack = () => { history.goBack(); };
  const goHome = () => { history.push('/'); };


  const moveCommonBoard = (bbs_data) => {
    // console.log('bbs_data : ', bbs_data);
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'], state: { 'bbs_id': bbs_data['BBS_ID'] } });
  };
  const callApi = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'NTT_ID': String(props.location.state.NTT_ID), 'BBS_ID': props.location.state.BBS_ID, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD030010SVC/getSelectBbsDetailList', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        if (data.TYPE !== 1) {
          alert(data.MSG);
          history.push('/app/board/' + props.location.state.BBS_ID);
          return false;
        }
        items = data['DATA'];
        console.log(items[0].NTT_CN);
        window.DEXT5 && window.DEXT5.SetBodyValue(data.DATA.NTT_CN);
        if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
          if ((items[0] == undefined ? "" : items[0].ADD_USER_ID) != localStorage.getItem('USER_ID')) {
            alert('직접 작성한 게시물만 수정 가능합니다.');   // 관리자가 게시글으 수정할 경우 기존 작성자와 정보가 다른 경우 등록되지 않도록 처리할 필요가 있을 듯(jjn)
            moveCommonBoard({ 'BBS_ID': items[0].BBS_ID });
            return false;
          }
        }

        // 소스 자동화 필요함 
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_ID", "value": items[0] == undefined ? "" : items[0].NTT_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "BBS_ID", "value": items[0] == undefined ? "" : items[0].BBS_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_NO", "value": items[0] == undefined ? "" : items[0].NTT_NO }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_SJ", "value": items[0] == undefined ? "" : items[0].NTT_SJ }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTT_CN", "value": items[0] == undefined ? "" : items[0].NTT_CN }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ANSWER_AT", "value": items[0] == undefined ? "" : items[0].ANSWER_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "PARNTSCTT_NO", "value": items[0] == undefined ? "" : items[0].PARNTSCTT_NO }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ANSWER_LC", "value": items[0] == undefined ? "" : items[0].ANSWER_LC }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "SORT_ORDR", "value": items[0] == undefined ? "" : items[0].SORT_ORDR }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "RDCNT", "value": items[0] == undefined ? "" : items[0].RDCNT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "USE_AT", "value": items[0] == undefined ? "" : items[0].USE_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCE_BGNDE", "value": items[0] == undefined ? "" : items[0].NTCE_BGNDE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCE_ENDDE", "value": items[0] == undefined ? "" : items[0].NTCE_ENDDE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCR_ID", "value": items[0] == undefined ? "" : items[0].NTCR_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NTCR_NM", "value": items[0] == undefined ? "" : items[0].NTCR_NM }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "PASSWORD", "value": items[0] == undefined ? "" : items[0].PASSWORD }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ATCH_FILE_ID", "value": items[0] == undefined ? "" : items[0].ATCH_FILE_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE", "value": items[0] == undefined ? "" : items[0].FILE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE1", "value": items[0] == undefined ? "" : items[0].FILE1 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE2", "value": items[0] == undefined ? "" : items[0].FILE2 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE3", "value": items[0] == undefined ? "" : items[0].FILE3 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE4", "value": items[0] == undefined ? "" : items[0].FILE4 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE5", "value": items[0] == undefined ? "" : items[0].FILE5 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE6", "value": items[0] == undefined ? "" : items[0].FILE6 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE7", "value": items[0] == undefined ? "" : items[0].FILE7 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE8", "value": items[0] == undefined ? "" : items[0].FILE8 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE9", "value": items[0] == undefined ? "" : items[0].FILE9 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "FILE10", "value": items[0] == undefined ? "" : items[0].FILE10 }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "NOTICE_AT", "value": items[0] == undefined ? "" : items[0].NOTICE_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "SJ_BOLD_AT", "value": items[0] == undefined ? "" : items[0].SJ_BOLD_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "SECRET_AT", "value": items[0] == undefined ? "" : items[0].SECRET_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "BLOG_ID", "value": items[0] == undefined ? "" : items[0].BLOG_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "REMARKS", "value": items[0] == undefined ? "" : items[0].REMARKS }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "STATUS", "value": items[0] == undefined ? "" : items[0].STATUS }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "TIME_ZONE", "value": items[0] == undefined ? "" : items[0].TIME_ZONE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ADD_DATE", "value": items[0] == undefined ? "" : items[0].ADD_DATE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ADD_USER_ID", "value": items[0] == undefined ? "" : items[0].ADD_USER_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ADD_USER_NAME", "value": items[0] == undefined ? "" : items[0].ADD_USER_NAME }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "CHANGE_DATE", "value": items[0] == undefined ? "" : items[0].CHANGE_DATE }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "CHANGE_USER_ID", "value": items[0] == undefined ? "" : items[0].CHANGE_USER_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "CHANGE_USER_NAME", "value": items[0] == undefined ? "" : items[0].CHANGE_USER_NAME }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "CAT_NAME", "value": items[0] == undefined ? "" : items[0].CAT_NAME }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "REPLY_AT", "value": items[0] == undefined ? "" : items[0].REPLY_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "COMMENT_AT", "value": items[0] == undefined ? "" : items[0].COMMENT_AT }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "CAL_ID", "value": items[0] == undefined ? "" : items[0].CAL_ID }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "START_TIME", "value": items[0] == undefined ? new Date() : items[0].START_TIME }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "END_TIME", "value": items[0] == undefined ? new Date() : items[0].END_TIME }));
        dispatch(brdbd003Actions.changeRegisterInput({ "name": "ALLDAY_YN", "value": items[0] == undefined ? "N" : items[0].ALLDAY_YN }));

        // console.log('데이터 조회결과 : ', items);
        const atch_file_id = items[0] == undefined ? "" : items[0].ATCH_FILE_ID;
        const requestFileOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'ATCH_FILE_ID': atch_file_id, 'OPEN_API_YN': 'Y' })
        };
        // console.log('arry: ', atch_file_id);
        if (atch_file_id != undefined && atch_file_id != "") {

          fetch(home_url + '/api/BRD070010SVC/getSelectFileList', requestFileOptions)
            .then(function (response) { return response.json(); })
            .then(function (data) {
              var arry = [];

              resultFiles = data['DATA'];

              for (var n in resultFiles) {
                arry.push(resultFiles[n]);
              }

              setFiles(arry);
            });
        }
      });
  }

  const { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, FILE, FILE1, FILE2, FILE3, FILE4, FILE5, FILE6, FILE7, FILE8, FILE9, FILE10, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, CAT_NAME, REPLY_AT, COMMENT_AT, CAL_ID, START_TIME, END_TIME, ALLDAY_YN } = useSelector(
    (state) => ({
      NTT_ID: state.brdbd003Reducers.NTT_ID,
      BBS_ID: state.brdbd003Reducers.BBS_ID,
      NTT_NO: state.brdbd003Reducers.NTT_NO,
      NTT_SJ: state.brdbd003Reducers.NTT_SJ,
      NTT_CN: window.DEXT5 && window.DEXT5.GetBodyValue(),
      ANSWER_AT: state.brdbd003Reducers.ANSWER_AT,
      PARNTSCTT_NO: state.brdbd003Reducers.PARNTSCTT_NO,
      ANSWER_LC: state.brdbd003Reducers.ANSWER_LC,
      SORT_ORDR: state.brdbd003Reducers.SORT_ORDR,
      RDCNT: state.brdbd003Reducers.RDCNT,
      USE_AT: state.brdbd003Reducers.USE_AT,
      NTCE_BGNDE: state.brdbd003Reducers.NTCE_BGNDE,
      NTCE_ENDDE: state.brdbd003Reducers.NTCE_ENDDE,
      NTCR_ID: state.brdbd003Reducers.NTCR_ID,
      NTCR_NM: state.brdbd003Reducers.NTCR_NM,
      PASSWORD: state.brdbd003Reducers.PASSWORD,
      ATCH_FILE_ID: state.brdbd003Reducers.ATCH_FILE_ID,
      FILE: state.brdbd003Reducers.FILE,
      FILE1: state.brdbd003Reducers.FILE1,
      FILE2: state.brdbd003Reducers.FILE2,
      FILE3: state.brdbd003Reducers.FILE3,
      FILE4: state.brdbd003Reducers.FILE4,
      FILE5: state.brdbd003Reducers.FILE5,
      FILE6: state.brdbd003Reducers.FILE6,
      FILE7: state.brdbd003Reducers.FILE7,
      FILE8: state.brdbd003Reducers.FILE8,
      FILE9: state.brdbd003Reducers.FILE9,
      FILE10: state.brdbd003Reducers.FILE10,
      NOTICE_AT: state.brdbd003Reducers.NOTICE_AT,
      SJ_BOLD_AT: state.brdbd003Reducers.SJ_BOLD_AT,
      SECRET_AT: state.brdbd003Reducers.SECRET_AT,
      BLOG_ID: state.brdbd003Reducers.BLOG_ID,
      REMARKS: state.brdbd003Reducers.REMARKS,
      STATUS: state.brdbd003Reducers.STATUS,
      TIME_ZONE: state.brdbd003Reducers.TIME_ZONE,
      ADD_DATE: state.brdbd003Reducers.ADD_DATE,
      ADD_USER_ID: state.brdbd003Reducers.ADD_USER_ID,
      ADD_USER_NAME: state.brdbd003Reducers.ADD_USER_NAME,
      CHANGE_DATE: state.brdbd003Reducers.CHANGE_DATE,
      CHANGE_USER_ID: state.brdbd003Reducers.CHANGE_USER_ID,
      CHANGE_USER_NAME: state.brdbd003Reducers.CHANGE_USER_NAME,
      CAT_NAME: state.brdbd003Reducers.CAT_NAME,
      REPLY_AT: state.brdbd003Reducers.REPLY_AT,
      COMMENT_AT: state.brdbd003Reducers.COMMENT_AT,
      CAL_ID: state.brdbd003Reducers.CAL_ID,
      START_TIME: state.brdbd003Reducers.START_TIME,
      END_TIME: state.brdbd003Reducers.END_TIME,
      ALLDAY_YN: state.brdbd003Reducers.ALLDAY_YN,
    }),
    shallowEqual
  );

  const [IsForUpdate, setIsForUpdate] = useState(false);
  //
  useEffect(() => {
    bbsCallApi();
    callApi();
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.get("isForEdit") === "true") {
      dispatch(brdbd003Actions.fetchBrdbd003(props.match.params.brdbd003Id));
      setIsForUpdate(true);
    }
  }, [history]);

  const onSubmitBrdbd003 = (event) => {
    event.preventDefault();
    let NTT_CN = window.DEXT5.GetBodyValue();
    console.log("NTT_CN : ", NTT_CN);

    if (NTT_SJ === "" || NTT_SJ === null || NTT_SJ === undefined) {
      alert("제목을 작성하십시오.");
      return false;
    }

    if (NTT_CN === "" || NTT_CN === null || NTT_CN === undefined) {
      alert("내용을 작성하십시오.");
      return false;
    }

    const brdbd003 = {
      NTT_ID: NTT_ID,
      BBS_ID: BBS_ID,
      NTT_NO: NTT_NO,
      NTT_SJ: NTT_SJ,
      NTT_CN: window.DEXT5 && window.DEXT5.GetBodyValue(),
      ANSWER_AT: ANSWER_AT,
      PARNTSCTT_NO: PARNTSCTT_NO,
      ANSWER_LC: ANSWER_LC,
      SORT_ORDR: SORT_ORDR,
      RDCNT: RDCNT,
      USE_AT: USE_AT,
      NTCE_BGNDE: NTCE_BGNDE,
      NTCE_ENDDE: NTCE_ENDDE,
      NTCR_ID: NTCR_ID,
      NTCR_NM: NTCR_NM,
      PASSWORD: PASSWORD,
      ATCH_FILE_ID: ATCH_FILE_ID,
      FILE: FILE,
      FILE1: FILE1,
      FILE2: FILE2,
      FILE3: FILE3,
      FILE4: FILE4,
      FILE5: FILE5,
      FILE6: FILE6,
      FILE7: FILE7,
      FILE8: FILE8,
      FILE9: FILE9,
      FILE10: FILE10,
      NOTICE_AT: NOTICE_AT,
      SJ_BOLD_AT: SJ_BOLD_AT,
      SECRET_AT: SECRET_AT,
      BLOG_ID: BLOG_ID,
      REMARKS: REMARKS,
      STATUS: STATUS,
      TIME_ZONE: TIME_ZONE,
      ADD_DATE: ADD_DATE,
      ADD_USER_ID: ADD_USER_ID,
      ADD_USER_NAME: ADD_USER_NAME,
      CHANGE_DATE: CHANGE_DATE,
      CHANGE_USER_ID: CHANGE_USER_ID,
      CHANGE_USER_NAME: CHANGE_USER_NAME,
      CAT_NAME: CAT_NAME,
      REPLY_AT: REPLY_AT,
      COMMENT_AT: COMMENT_AT,
      CAL_ID: CAL_ID,
      START_TIME: START_TIME,
      END_TIME: END_TIME,
      ALLDAY_YN: ALLDAY_YN,
    };

    console.log("brdbd003 : ", brdbd003);

    dispatch(brdbd003Actions.updateBrdbd003(brdbd003));
    alert("저장되었습니다.");
    // window.location.reload();
    moveCommonBoard({ BBS_ID });
  };


  const removeFile = (fileInfo) => {
    // NTT_ID :NTT_ID,
    //   BBS_ID:BBS_ID,
    const dataSet = {
      "BRD070010F1": {
        'NTT_ID': String(fileInfo.NTT_ID)
        , 'BBS_ID': fileInfo.BBS_ID
        , 'ATCH_FILE_ID': fileInfo.ATCH_FILE_ID
        , 'FILE_SN': fileInfo.FILE_SN
        , 'OPEN_API_YN': 'Y'
      }
    };

    const requestFileOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
      body: JSON.stringify(dataSet)
    };

    fetch(home_url + '/api/BRD070010SVC/putDeleteAtchFile', requestFileOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        alert('삭제가 완료 되었습니다. ');
        callApi();
      })
      .catch((error) => alert('삭제에 실패했습니다'));
  }

  const onRegisterChange = (event) => {
    if (!event.editor) {
      event.preventDefault();
      const { name, value } = event.target;
      if (name == 'FILE1' || name == 'FILE2' || name == 'FILE3'
        || name == 'FILE4' || name == 'FILE5' || name == 'FILE6'
        || name == 'FILE7' || name == 'FILE8' || name == 'FILE9' || name == 'FILE10'
      ) {
        dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: event.target.files[0] }));
      } else {
        dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: value }));
      }
    } else {
      dispatch(brdbd003Actions.changeRegisterInput({ name: event.editor.name, value: event.editor.getData() }));
    }
  };

  const FILELIST = [...Array(0)].map((_, index) => ({}));

  for (var n in files) {
    console.log('fileItems[n] : ', files[n]);
    FILELIST.push(files[n]);
  }

  const onRegisterTimeChange = (name, value) => {
    console.log(name, " : ", value);
    dispatch(brdbd003Actions.changeRegisterInput({ name: name, value: value }));
  }

  return (
    <>
      <RegisterOrEdit
        DEXT5={DEXT5Editor}
        BBS={BBS}
        FILES={FILELIST}
        NTT_ID={props.location.state.NTT_ID ? props.location.state.NTT_ID : NTT_ID}
        BBS_ID={props.location.state.BBS_ID ? props.location.state.BBS_ID : BBS_ID}
        NTT_NO={NTT_NO}
        NTT_SJ={props.location.state.NTT_SJ ? props.location.state.NTT_SJ : NTT_SJ}
        NTT_CN={NTT_CN}
        ANSWER_AT={ANSWER_AT}
        PARNTSCTT_NO={PARNTSCTT_NO}
        ANSWER_LC={props.location.state.ANSWER_LC ? props.location.state.ANSWER_LC : 0}
        SORT_ORDR={props.location.state.SORT_ORDR ? props.location.state.SORT_ORDR : SORT_ORDR}
        RDCNT={RDCNT}
        USE_AT={USE_AT}
        NTCE_BGNDE={NTCE_BGNDE}
        NTCE_ENDDE={NTCE_ENDDE}
        NTCR_ID={NTCR_ID}
        NTCR_NM={NTCR_NM}
        PASSWORD={PASSWORD}
        ATCH_FILE_ID={ATCH_FILE_ID}
        FILE={FILE}
        NOTICE_AT={NOTICE_AT}
        SJ_BOLD_AT={SJ_BOLD_AT}
        SECRET_AT={SECRET_AT}
        BLOG_ID={BLOG_ID}
        REMARKS={REMARKS}
        STATUS={STATUS}
        TIME_ZONE={TIME_ZONE}
        ADD_DATE={ADD_DATE}
        ADD_USER_ID={ADD_USER_ID}
        ADD_USER_NAME={ADD_USER_NAME}
        CHANGE_DATE={CHANGE_DATE}
        CHANGE_USER_ID={CHANGE_USER_ID}
        CHANGE_USER_NAME={CHANGE_USER_NAME}
        CAT_NAME={CAT_NAME}
        REPLY={props.location.state.REPLY}
        REPLY_AT={REPLY_AT}
        COMMENT_AT={COMMENT_AT}
        CAL_ID={CAL_ID}
        START_TIME={START_TIME}
        END_TIME={END_TIME}
        ALLDAY_YN={ALLDAY_YN}

        handleRegisterChange={onRegisterChange}
        handleSubmit={onSubmitBrdbd003}
        handleTotalBoard={onGoTotalBoard}
        handleGoBack={goBack}
        handleRemoveFile={removeFile}
        updateRequest={IsForUpdate}
        handleRegisterTimeChange={onRegisterTimeChange}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button onClick={goBack}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          이전페이지
        </Button>
        &nbsp;&nbsp;
        <Button onClick={() => moveCommonBoard({ BBS_ID })}
          // color="inherit"
          className="ButtonStyle03"
          variant="contained">
          목록으로
        </Button>
        &nbsp;&nbsp;
        <Button type="primary" onClick={onSubmitBrdbd003}
          // color="primary"
          className="ButtonStyle02"
          variant="contained">
          저장
        </Button>

      </Box>
    </>
  );
}

export default BoardUpdate;