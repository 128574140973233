import React, { useEffect, useState, useCallback } from "react";
import moment from 'moment';
import { home_url } from '../../../js/globalProperties';

function PortalMainDash() {
  const [orderMainInfo, setOrderMainInfo] = useState({ "COUNT": "-" });
  const [billingInfo, setBillingInfo] = useState({ "COUNT": "-" });
  const [transCount, setTransCount] = useState({ "ALL_COUNT": "-" });
  const thisYear = moment().format('YYYY'); //올 해
  const thisMonth = moment().format('YYYYMM'); //이번 달
  // const thisMonth = '202107'; //이번 달
  const today = moment().format('YYYYMMDD'); //오늘
  var items = new Array();

  //현재 발주나 정산은 일 별 데이터를 확보할 수 없으므로 이번달 기준으로 데이터를 뽑아넣었습니다.
  //또한 발주나 정산은 현재 회사코드를 입력하게 되어있습니다. 주의바랍니다. ( 2021 - 07 - 28 )

  //배차통계
  const getTransCount = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'DATE': thisMonth, "DATE_TYPE": "M", "OPEN_API_YN": "Y" })
      };
      await fetch(home_url + '/api/slpportal/getAllTransInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          items = data['DATA']['MAIN'];
          setTransCount(items);
        });
    } catch (error) {
      console.log(error);
    }


  }

  //발주통계
  const getOrderMainInfo = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'YEAR': thisYear, "COMPANY_CODE": '00071', "OPEN_API_YN": "Y" })
      };
      await fetch(home_url + '/api/OMS020010SVC/getOrderMainInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          items = data['DATA'];
        });
    } catch (error) {
      console.log(error);
    }

    for (let i in items[0]) {
      if (items[0][i] == thisMonth) {
        setOrderMainInfo({ "COUNT": items[0][i + "_COUNT"] });
      }
    }
  }

  //정산통계
  const getBillingInfo = () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "COMPANY_CODE": "00071", "DATE": "202107", "DATE_TYPE": "M", "OPEN_API_YN": "Y" })
      };
      fetch(home_url + '/api/BMS010010SVC/getBillingInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
          items = data['DATA'];
          console.log("data : ", data)
          console.log("items : ", items);
        });

    } catch (error) {
      console.log("error : ", error);
    }
    //  setBillingInfo({"COUNT" : items['MAIN'].AR_COUNT + items['MAIN'].AP_COUNT});
  }

  useEffect(() => {
    getTransCount({ "DATE_TYPE": "M" })
  }, []);

  return (
    <>
      <div className="logistics_data_wrap" id="logisticsData_logout_on">
        <div className="logistics_dash_div">
          <div className="logistics_dash_tt">발주등록</div>
          <div className="logistics_dash_data">{orderMainInfo.COUNT}<span> 건</span></div>
        </div>
        <div className="logistics_dash_div lgsDashDiv_margin">
          <div className="logistics_dash_tt">배차완료</div>
          <div className="logistics_dash_data">{transCount.COMPLETE_COUNT}<span> 건</span></div>
        </div>
        <div className="logistics_dash_div lgsDashDiv_margin">
          <div className="logistics_dash_tt">정산완료</div>
          <div className="logistics_dash_data">{billingInfo.COUNT}<span> 건</span></div>
        </div>
        <div className="logistics_AD_wrap">
          <video controls autoplay="autoplay" muted
              src="https://myslp.kr/resources/info/smart.mp4?autoplay=1" 
              width="683" 
              height="385" 
            ></video>
        </div>
      </div>
    </>
  )
}

export default PortalMainDash;