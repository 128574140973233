import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT000101_01(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          
          <img src={require("../../../images/bigdata_img02.jpg").default} />
          <div className="bigdataPage_absolWrap02"></div>
              <div className="bigdataTxt_Wrap03">
                                      산업단지에 입주된 업체의 현황과<br/> 설물조사 자료를 통계적으로 분석하여<br/> 시각화한 결과를 확인할 수 있는 <br/>대시보드입니다.                
              </div>
        </Card>
      </Page>
    </>
  );
}

export default PLT000101_01;