import React, { useEffect } from "react";
// import PortalHead from "./Sections/PortalHead";
// import PortalFootter from "./Sections/PortalFootter";
import UserList from "./user/User";

function UserBoard() {
  return (
    <div> 
      {/* <PortalHead/> */}
        <UserList/>
      {/* <PortalFootter/> */}
    </div>
  );
}
export default UserBoard;