import { Bar, Doughnut } from 'react-chartjs-2';
import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';

const WeekSales = (props) => {
  console.log("WeekSales : ", props.DATA);
  const theme = useTheme();
  const [DATA, setDATA] = useState({ datasets: [{ data: [] }], labels: [] });
  const [TOOLTIP, setTOOLTIP] = useState({})
  let tooltipArr = [];

  useEffect(() => {
    let data = {
      labels: [],
      datasets: [{
        label: 'My First Dataset',
        data: [],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
      }]
    };
    for (let i in props.DATA) {
      data.labels.push(props.DATA[i].S_FUNC_NAME);
      let arr = data.datasets[0].data;
      // arr.push(Number((props.DATA[i].RATIO).substring(0, (props.DATA[i].RATIO).length - 1)));
      arr.push(props.DATA[i].COUNT);
      console.log('arr:', arr)
      data.datasets[0].data = arr;
      let tooltip = {
        S_FUNC_NAME: props.DATA[i].S_FUNC_NAME,
        RATIO: props.DATA[i].RATIO,
        AMT: props.DATA[i].AMT,
        COUNT: props.DATA[i].COUNT
      };
      tooltipArr[props.DATA[i].S_FUNC_NAME] = tooltip;
    }
    setDATA(data);
    console.log(DATA.datasets[0].data.length);
    setTOOLTIP(tooltipArr)
  }, [props]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    // scales: {
    //   xAxes: [
    //     {
    //       barThickness: 12,
    //       maxBarThickness: 10,
    //       barPercentage: 0.5,
    //       categoryPercentage: 0.5,
    //       ticks: {
    //         fontColor: theme.palette.text.secondary
    //       },
    //       gridLines: {
    //         display: false,
    //         drawBorder: false
    //       }
    //     }
    //   ],
    //   yAxes: [
    //     {
    //       ticks: {
    //         fontColor: theme.palette.text.secondary,
    //         beginAtZero: true,
    //         min: 0
    //       },
    //       gridLines: {
    //         borderDash: [2],
    //         borderDashOffset: [2],
    //         color: theme.palette.divider,
    //         drawBorder: false,
    //         zeroLineBorderDash: [2],
    //         zeroLineBorderDashOffset: [2],
    //         zeroLineColor: theme.palette.divider
    //       }
    //     }
    //   ]
    // },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            let label = [];
            label.push('모듈 : ' + tooltipItem.label);
            label.push('거래 : ' + TOOLTIP[tooltipItem.label].COUNT + '건');
            label.push('금액 : ' + TOOLTIP[tooltipItem.label].AMT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원');
            label.push('비율 : ' + TOOLTIP[tooltipItem.label].RATIO);
            return label;
          }
        }
      }
    }
  };

  return (
    <Card {...props}>
      <CardHeader
        title={props.TITLE}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative',
            textAlign: 'center'
          }}
          
        >
          {DATA.datasets[0].data.length === 0 ?
            <span style={{ fontSize: "14px", padding: "8px 0px", lineHeight: "16px", fontWeight: 'bold', lineHeight:'250px' }}>조회된 내역이 없습니다.</span> :
            <Doughnut
              data={DATA}
              options={options}
            />
          }
        </Box>
      </CardContent>
      <Divider />
    </Card >
  );
};

export default WeekSales;
