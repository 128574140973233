import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

  function LatestOrders(props) {
    console.log('LatestOrders : ', props.LIST);
    return (
      <Card {...props}>
        <CardHeader title="거래처별 매출/매입 현황" />
        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table className="listTable">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    No.
                  </TableCell>
                  <TableCell align="center">
                    거래처명
                  </TableCell>
                  <TableCell align="center">
                    거래비율(%)
                  </TableCell>
                  <TableCell align="center">
                    공급가
                  </TableCell>
                  <TableCell align="center">
                    매출/매입
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.BBS.map((bbs, index) => (
                  <TableRow
                    hover
                    key={bbs.BBS_ID}
                  >
                    <TableCell align="center">
                      {index+1}
                    </TableCell>
                    <TableCell align="center" style={{"cursor":"pointer"}}>
                      {bbs.CUSTOMER_NAME}
                    </TableCell>
                    <TableCell align="right">
                      {bbs.RATIO}
                    </TableCell>
                    <TableCell align="right">
                      {bbs.AMT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                    </TableCell>
                    <TableCell align="center">
                      {bbs.ARAP_TYPE}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          {/* <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            발주관리 화면으로 이동
          </Button> */}
        </Box>
      </Card>
    );
  }

export default LatestOrders;
