import { createSlice } from "@reduxjs/toolkit";

export const articleSlice = createSlice({
  name: "article",
  initialState: {
    id: 0,
    title: "",
    content: "",
    views: 0,
    date: Date.now(),
    editDate: "",
  },
  reducers: {
    registerArticle: (state, { payload: article }) => {
      console.log("게시글 등록 액션 호출 -- registerArticle"); // saga에서 감시용
    },
    getArticle: (state, { payload: id }) => {
      console.log("게시글 조회 액션 호출 -- getArticle"); // saga에서 감시용
    },
    getArticleAsync: (state, { payload: article }) => {
      console.log("saga에서 put 액션 호출 -- getArticleAsync");
      return {
        ...state,
        id: article.id,
        title: article.title,
        content: article.content,
        date: article.date,
        editDate: article.editDate,
        views: article.views,
      };
    },
    fetchArticle: (state, { payload: id }) => {
      console.log("게시글 조회 액션 호출 -- fetchArticle"); // saga에서 감시용
    },
    updateArticle: (state, { payload: article }) => {
      console.log("게시글 수정 액션 호출 -- updateArticle"); // saga에서 감시용
    },
    deleteArticle: (state, { payload: id }) => {
      console.log("게시글 삭제 액션 호출 -- deleteArticle"); // saga 에서 감시용
    },
    changeRegisterInput: (state, { payload }) => {
      console.log('payload.name : '+payload.name);
      switch (payload.name) {
        case "bbs_nm":
          return {
            ...state,
            bbs_nm: payload.value,
          };

        case "bbs_intrcn":
          return {
            ...state,
            bbs_intrcn: payload.value,
          };
        case "bbs_ty_code":
          return {
            ...state,
            bbs_ty_code: payload.value,
          };
        case "reply_posbl_at":
          return {
            ...state,
            reply_posbl_at: payload.value,
          };
        case "file_atch_posbl_at":
          return {
            ...state,
            file_atch_posbl_at: payload.value,
          };
        case "atch_posbl_file_number":
          return {
            ...state,
            atch_posbl_file_number: payload.value,
          };
         case "atch_posbl_file_size":
          return {
            ...state,
            atch_posbl_file_size: payload.value,
          };
         case "use_at":
          return {
            ...state,
            use_at: payload.value,
          };
          
        default:
          break;
      }
    },
  },
});

export const articleReducers = articleSlice.reducer;
export const articleActions = articleSlice.actions;
