import { createSlice } from "@reduxjs/toolkit";

export const brdbd003Slice = createSlice({
  name: "brdbd003",
  initialState: {
    NTT_ID: '',
    BBS_ID: '',
    NTT_NO: '',
    NTT_SJ: '',
    NTT_CN: '',
    ANSWER_AT: '',
    PARNTSCTT_NO: '',
    ANSWER_LC: '',
    SORT_ORDR: '',
    RDCNT: '',
    USE_AT: '',
    NTCE_BGNDE: '',
    NTCE_ENDDE: '',
    NTCR_ID: '',
    NTCR_NM: '',
    PASSWORD: '',
    ATCH_FILE_ID: '',
    FILE: '',
    FILE1: '',
    FILE2: '',
    FILE3: '',
    FILE4: '',
    FILE5: '',
    FILE6: '',
    FILE7: '',
    FILE8: '',
    FILE9: '',
    FILE10: '',
    NOTICE_AT: '',
    SJ_BOLD_AT: '',
    SECRET_AT: '',
    BLOG_ID: '',
    REMARKS: '',
    STATUS: '',
    TIME_ZONE: '',
    ADD_DATE: '',
    ADD_USER_ID: '',
    ADD_USER_NAME: '',
    CHANGE_DATE: '',
    CHANGE_USER_ID: '',
    CHANGE_USER_NAME: '',
    COMMENT_CO: '',
    OPEN_API_YN: 'Y',
    CAT_NAME: '',
    REPLY_AT: '',
    COMMENT_AT: '',
    CAL_ID: '',
    START_TIME: '',
    END_TIME: '',
    ALLDAY_YN: '',
  },
  reducers: {
    registerBrdbd003: (state, { payload: brdbd003 }) => {
    },
    registerBrdbd003REPLY: (state, { payload: brdbd003 }) => {
    },
    getBrdbd003: (state, { payload: id }) => {
    },
    getBrdbd003Async: (state, { payload: brdbd003 }) => {
      return {
        ...state,
        brdbd003: brdbd003,
        isSuccess: true,
        isLoading: false,
      };
    },

    getBrdbd003Detail: (state, { payload: id }) => {
    },
    getBrdbd003DetailAsync: (state, { payload: brdbd003 }) => {
      return {
        ...state,
        brdbd003: brdbd003,
        isSuccess: true,
        isLoading: false,
      };
    },
    getBrdbd003FailedAsync: (state, { payload: error }) => {
      return {
        ...state,
        isLoading: false,
        error: error,
      };
    },
    fetchBrdbd003: (state, { payload: id }) => {
    },
    updateBrdbd003: (state, { payload: brdbd003 }) => {
    },
    deleteBrdbd003: (state, { payload: id }) => {
    },
    changeRegisterInput: (state, { payload }) => {
      // console.log('payload.name : ' + payload.name);
      switch (payload.name) {
        case "NTT_ID": return { ...state, NTT_ID: payload.value, };
        case "BBS_ID": return { ...state, BBS_ID: payload.value, };
        case "NTT_NO": return { ...state, NTT_NO: payload.value, };
        case "NTT_SJ": return { ...state, NTT_SJ: payload.value, };
        case "NTT_CN": return { ...state, NTT_CN: payload.value, };
        case "ANSWER_AT": return { ...state, ANSWER_AT: payload.value, };
        case "PARNTSCTT_NO": return { ...state, PARNTSCTT_NO: payload.value, };
        case "ANSWER_LC": return { ...state, ANSWER_LC: payload.value, };
        case "SORT_ORDR": return { ...state, SORT_ORDR: payload.value, };
        case "RDCNT": return { ...state, RDCNT: payload.value, };
        case "USE_AT": return { ...state, USE_AT: payload.value, };
        case "NTCE_BGNDE": return { ...state, NTCE_BGNDE: payload.value, };
        case "NTCE_ENDDE": return { ...state, NTCE_ENDDE: payload.value, };
        case "NTCR_ID": return { ...state, NTCR_ID: payload.value, };
        case "NTCR_NM": return { ...state, NTCR_NM: payload.value, };
        case "PASSWORD": return { ...state, PASSWORD: payload.value, };
        case "ATCH_FILE_ID": return { ...state, ATCH_FILE_ID: payload.value, };
        case "FILE1": return { ...state, FILE1: payload.value, };
        case "FILE2": return { ...state, FILE2: payload.value, };
        case "FILE3": return { ...state, FILE3: payload.value, };
        case "FILE4": return { ...state, FILE4: payload.value, };
        case "FILE5": return { ...state, FILE5: payload.value, };
        case "FILE6": return { ...state, FILE6: payload.value, };
        case "FILE7": return { ...state, FILE7: payload.value, };
        case "FILE8": return { ...state, FILE8: payload.value, };
        case "FILE9": return { ...state, FILE9: payload.value, };
        case "FILE10": return { ...state, FILE10: payload.value, };
        case "NOTICE_AT": return { ...state, NOTICE_AT: payload.value, };
        case "SJ_BOLD_AT": return { ...state, SJ_BOLD_AT: payload.value, };
        case "SECRET_AT": return { ...state, SECRET_AT: payload.value, };
        case "BLOG_ID": return { ...state, BLOG_ID: payload.value, };
        case "REMARKS": return { ...state, REMARKS: payload.value, };
        case "STATUS": return { ...state, STATUS: payload.value, };
        case "TIME_ZONE": return { ...state, TIME_ZONE: payload.value, };
        case "ADD_DATE": return { ...state, ADD_DATE: payload.value, };
        case "ADD_USER_ID": return { ...state, ADD_USER_ID: payload.value, };
        case "ADD_USER_NAME": return { ...state, ADD_USER_NAME: payload.value, };
        case "CHANGE_DATE": return { ...state, CHANGE_DATE: payload.value, };
        case "CHANGE_USER_ID": return { ...state, CHANGE_USER_ID: payload.value, };
        case "CHANGE_USER_NAME": return { ...state, CHANGE_USER_NAME: payload.value, };
        case "OPEN_API_YN": return { ...state, OPEN_API_YN: payload.value, };
        case "CAT_NAME": return { ...state, CAT_NAME: payload.value, };
        case "REPLY_AT": return { ...state, REPLY_AT: payload.value, };
        case "COMMENT_AT": return { ...state, COMMENT_AT: payload.value, };
        case "CAL_ID": return { ...state, CAL_ID: payload.value, };
        case "START_TIME": return { ...state, START_TIME: payload.value, };
        case "END_TIME": return { ...state, END_TIME: payload.value, };
        case "ALLDAY_YN": return { ...state, ALLDAY_YN: payload.value, };
        default:
          break;
      }
    },
  },
});

export const brdbd003Reducers = brdbd003Slice.reducer;
export const brdbd003Actions = brdbd003Slice.actions;
