import { filter } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Box,
  Table,
  Stack,
  Avatar,
  CardHeader,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  Divider,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components

import Page from '../Page';
import SearchNotFound from '../SearchNotFound';
import { brdbd003Actions } from "../../../../slice/brdbd003Slice";
import { CommonMoreMenu, CommonToolbar, CommonHead } from './';
const TABLE_HEAD = [
  { id: 'No', label: 'No.', alignRight: false },
  // { id: 'CAT_NAME', label: '카테고리', alignRight: false },
  { id: 'NTT_SJ', label: '제목', alignRight: false },
  { id: 'NTCR_NM', label: '작성자', alignRight: false },
  { id: 'ADD_DATE', label: '작성일자', alignRight: false }, //임시로 일자 컬럼을 넣어둠
  { id: 'RDCNT', label: '조회수', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('query : ', query, array);
  if (query) {
    return filter(array, (_user) => _user.NTT_SJ.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CommonList(props) {
  const [BBS_ID, setBbsId] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('ADD_DATE',);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const dispatch = useDispatch();
  const history = useHistory();
  const AUTH = ['U', 'D', 'M', 'A'];

  const callApi = async (bbs) => {
    //    setBbsId(props.BBS.BBS_ID);
    dispatch(brdbd003Actions.getBrdbd003({ 'BBS_ID': bbs.BBS_ID, 'OPEN_API_YN': 'Y' }));
    dispatch(brdbd003Actions.changeRegisterInput({ "name": "BBS_ID", "value": bbs.BBS_ID }));

  }

  useEffect(() => {
    //dispatch(brdbd003Actions.getBrdbd003({ 'BBS_ID': props.BBS.BBS_ID, 'OPEN_API_YN':'Y' }));

    callApi(props.BBS);
    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock(); 
    };
  }, [BBS_ID, props.BBS]);

  const { brdbd003, isLoading, isSuccess, error } = useSelector((state) => ({
    brdbd003: state.brdbd003Reducers.brdbd003,
    isLoading: state.brdbd003Reducers.isLoading,
    isSuccess: state.brdbd003Reducers.isSuccess,
    error: state.brdbd003Reducers.error,
  }));
  const BRDLIST = [...Array(0)].map((_, index) => ({}));
  const Notice = [...Array(0)].map((_, index) => ({}));


  for (var n in brdbd003) {
    BRDLIST.push(brdbd003[n]);
    if (brdbd003[n].NOTICE_AT === 'Y') {
      Notice.push(brdbd003[n]);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = BRDLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);

  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleBoardRowClick = (bbs_data) => {
    if (bbs_data.SECRET_AT === 'Y') {
      if (localStorage.getItem("USER_AUTH") !== 'A' && bbs_data.ADD_USER_ID !== localStorage.getItem('USER_ID') && bbs_data.add_user_id !== localStorage.getItem('USER_ID')) {
        alert('비밀글은 작성자와 관리자만 열람할 수 있습니다.');
        return false;
      }
    }
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'] + '/' + bbs_data['NTT_ID'], state: bbs_data });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRegisterPage = (bbs_data) => {
    history.push({ pathname: '/app/board/register', state: bbs_data });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - BRDLIST.length) : 0;

  const filteredTotalboards = applySortFilter(BRDLIST, getComparator(order, orderBy), filterName);

  const isTotalboardNotFound = filteredTotalboards.length === 0;

  const handleOnClick = useCallback(() => history.push('/app/board/register'), [history]);

  const BBS = props.BBS;

  const today = moment().format('YYYY-MM-DD');

  const childList = (parents_id, CHILD, add_user_id) => {
    return (
      <>
        {CHILD && CHILD.map((reply, index) => {
          let { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, COMMENT_CO } = reply;
          let isItemSelected = selected.indexOf(NTT_SJ) !== -1;
          if (parents_id === PARNTSCTT_NO) {
            return (
              <>
                <TableRow
                  hover
                  key={index}
                  tabIndex={-1}
                  role="checkbox"
                  selected={isItemSelected}
                  aria-checked={isItemSelected}
                >
                  <TableCell scope="row" align="center"></TableCell>
                  {/* <TableCell scope="row" align="center">-</TableCell> */}
                  <TableCell scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="subtitle2" noWrap style={{ "marginLeft": 20 * ANSWER_LC + "px" }} style={{ "overflow": "hidden" }} style={{ "text-overflow": "ellipsis" }}>
                        <span
                          style={{ 'fontWeight': SJ_BOLD_AT === 'Y' ? 'bold' : 'normal', color: SECRET_AT === 'Y' ? 'darkgray' : '', "cursor": "pointer" }}
                          onClick={() => handleBoardRowClick({ BBS, NTT_ID, BBS_ID, SECRET_AT, ADD_USER_ID, add_user_id })}>
                          <img
                            src={require("./../../../../images/lockIcon.png").default}
                            width="15px"
                            style={{ display: SECRET_AT === 'Y' ? '' : 'none', verticalAlign: 'text-top' }}
                          />
                          ㄴRE: {NTT_SJ}
                        </span>&nbsp;&nbsp;{COMMENT_CO !== 0 ? "[" + COMMENT_CO + "]" : null}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">{NTCR_NM}</TableCell>
                  <TableCell align="center">
                    {today === ADD_DATE.substring(0, 10) ? ADD_DATE.substring(10, 16) : ADD_DATE.substring(0, 10)}
                  </TableCell>
                  <TableCell align="center">{RDCNT}</TableCell>
                </TableRow>
                {childList(NTT_ID, CHILD, add_user_id)}
              </>
            )
          }
        })}
      </>
    )
  };

  return (
    <div>
      <Page title="Totalboard | Minimal-UI">
        {/* <Container className="Container_layout"> */}
        <Card>
          <Typography className="listTable_tt">
            {props.BBS.BBS_NAME}
          </Typography>
          {/* <Scrollbar> */}
          <TableContainer >
            <Table className="listTable">
              <CommonHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={BRDLIST.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              // onBoardRowClick={handleBoardRowClick}
              />
              <TableBody>
                {Notice.map((row, index) => {
                  const { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, COMMENT_CO, CHILD, CAT_NAME } = row;
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      style={{ backgroundColor: '#f8f9fb' }}
                    >
                      {/* <TableCell scope="row" align="center">-</TableCell> */}
                      <TableCell scope="row" align="center" style={{ color: 'red', fontWeight: 'bold' }}>공지</TableCell>
                      <TableCell scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {/* <span style={{ display: CAT_NAME === "" ? 'none' : '', fontWeight: 'bold', color: 'gray' }}>[{CAT_NAME}]&nbsp;&nbsp;</span> */}
                            <span
                              style={{ 'fontWeight': SJ_BOLD_AT === 'Y' ? 'bold' : 'normal', "cursor": "pointer" }}
                              onClick={() => handleBoardRowClick({ BBS, NTT_ID, BBS_ID, SECRET_AT })}>
                              {NTT_SJ}
                            </span>
                            &nbsp;&nbsp;{COMMENT_CO !== 0 ? "[" + COMMENT_CO + "]" : null}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="center">{NTCR_NM}</TableCell>
                      <TableCell align="center">
                        {today === ADD_DATE.substring(0, 10) ? ADD_DATE.substring(10, 16) : ADD_DATE.substring(0, 10)}
                      </TableCell>
                      <TableCell align="center">{RDCNT}</TableCell>
                    </TableRow>
                  )
                })}

                {filteredTotalboards
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const { NTT_ID, BBS_ID, NTT_NO, NTT_SJ, NTT_CN, ANSWER_AT, PARNTSCTT_NO, ANSWER_LC, SORT_ORDR, RDCNT, USE_AT, NTCE_BGNDE, NTCE_ENDDE, NTCR_ID, NTCR_NM, PASSWORD, ATCH_FILE_ID, NOTICE_AT, SJ_BOLD_AT, SECRET_AT, BLOG_ID, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, COMMENT_CO, CHILD, CAT_NAME, RN } = row;
                    const isItemSelected = selected.indexOf(NTT_SJ) !== -1;

                    return (
                      <>
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell scope="row" align="center"> {index + page * rowsPerPage + 1} </TableCell> */}
                          {/* <TableCell scope="row" align="center">{BRDLIST.length - page * rowsPerPage - index}</TableCell> */}
                          <TableCell scope="row" align="center">{NTT_ID}</TableCell>
                          {/* <TableCell scope="row" align="center" style={{color:'blue',fontWeight:'bold'}}>{CAT_NAME? CAT_NAME:'-'}</TableCell> */}
                          <TableCell scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                <span style={{ display: CAT_NAME === "" ? 'none' : '', fontWeight: 'bold', color: '#0065ce' }}>{CAT_NAME}&nbsp;&nbsp;</span>
                                <span
                                  style={{ 'fontWeight': SJ_BOLD_AT === 'Y' ? 'bold' : 'normal', color: SECRET_AT === 'Y' ? 'darkgray' : '', "cursor": "pointer" }}
                                  onClick={() => handleBoardRowClick({ BBS, NTT_ID, BBS_ID, SECRET_AT, ADD_USER_ID })}>
                                  <img
                                    src={require("./../../../../images/lockIcon.png").default}
                                    width="15px"
                                    style={{ display: SECRET_AT === 'Y' ? '' : 'none', verticalAlign: 'text-top' }}
                                  />
                                  {NTT_SJ}
                                </span>
                                &nbsp;&nbsp;{COMMENT_CO !== 0 ? "[" + COMMENT_CO + "]" : null}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">{NTCR_NM}</TableCell>
                          <TableCell align="center">
                            {today === ADD_DATE.substring(0, 10) ? ADD_DATE.substring(10, 16) : ADD_DATE.substring(0, 10)}
                          </TableCell>
                          <TableCell align="center">{RDCNT}</TableCell>
                        </TableRow>
                        {childList(NTT_ID, CHILD, ADD_USER_ID)}
                      </>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
              {isTotalboardNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* </Scrollbar> */}

          <TablePagination
            rowsPerPageOptions={[15, 30, 45]}
            component="div"
            count={BRDLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="페이지 당 게시물 수 : "
          />
          <CommonToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button type="primary" className="ButtonStyle02"
              color="primary"
              variant="contained"
              style={{ display: AUTH.indexOf(localStorage.getItem('USER_AUTH')) >= AUTH.indexOf(props.BBS.WRITE_AUTH) ? '' : 'none' }}
              onClick={() => handleRegisterPage({ BBS })}>
              글쓰기
            </Button>
          </Box>
        </Card>
        {/* </Container> */}
      </Page>
    </div>
  );
}
