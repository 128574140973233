import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { brdbd009Actions } from "../../../slice/brdbd009Slice";
import { useLocation } from 'react-router';
// material
import {
  Card,
  Table,
  Button,
  TextField,
  Box,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
// components
// ----------------------------------------------------------------------
function BoardComment(props) {
  const dispatch = useDispatch();
  const BBS_ID = props.BBS_ID;
  const NTT_ID = props.NTT_ID;
  const location = useLocation();

  useEffect(() => {
    dispatch(brdbd009Actions.getBrdbd009({ 'BBS_ID': BBS_ID, 'NTT_ID': NTT_ID, "OPEN_API_YN": "Y" }));
  }, [dispatch, location]);

  const { brdbd009, isLoading, isSuccess, error } = useSelector((state) => ({
    brdbd009: state.brdbd009Reducers.brdbd009,
    isLoading: state.brdbd009Reducers.isLoading,
    isSuccess: state.brdbd009Reducers.isSuccess,
    error: state.brdbd009Reducers.error,
  }));

  //-------------------------------------------------------------------------------
  //Insert Comment
  var answerRegistText = '';

  const answerRegist = (event) => {
    event.preventDefault();
    // setAnswerRegistText(event.target.value);
    answerRegistText = event.target.value;
  }

  const onRegisterBrdbd009 = (data) => {
    if (answerRegistText.length > 0) {
      let WRTER_ID = localStorage.getItem('user_id');

      if (data.ANSWER_NO) { //대댓글일 경우
        if (!data.ANSWER_LC)
          data.ANSWER_LC = 0;
        dispatch(brdbd009Actions.registerBrdbd009({ "BBS_ID": BBS_ID, "NTT_ID": NTT_ID, "WRTER_ID": WRTER_ID, "ANSWER": answerRegistText, "ANSWER_LC": data.ANSWER_LC, "ANSWER_NO": data.ANSWER_NO, "ANSWER_AT": "Y" }));
      }
      else //댓글일 경우
        dispatch(brdbd009Actions.registerBrdbd009({ "BBS_ID": BBS_ID, "NTT_ID": NTT_ID, "WRTER_ID": WRTER_ID, "ANSWER": answerRegistText }));

      answerRegistText = '';
    }
    else {
      alert("댓글을 작성해주세요!");
    }
  }
  //-------------------------------------------------------------------------------

  //Update Comment
  var answerUpdateText = '';
  const [updateNo, setUpdateNo] = useState(0);
  const answerUpdate = (event) => {
    event.preventDefault();
    // setAnswerUpdateText(event.target.value);
    answerUpdateText = event.target.value;
  }

  const onCommentUpdate = (answerNo, wrterId) => {
    //console.log('WRTER_ID : ', wrterId, localStorage.getItem('USER_AUTH'), localStorage.getItem('USER_ID'));
    if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
      if (wrterId != localStorage.getItem('USER_ID')) {
        alert('직접 작성한 게시물만 삭제 가능합니다.');   // 관리자 및 직접 작성한 글만 삭제 가능함 
        return false;
      }
    }

    setUpdateNo(answerNo);
  }
  const onUpdateBrdbd009 = (data) => {
    if (answerUpdateText.length > 0) {
      data.ANSWER = answerUpdateText;
      dispatch(brdbd009Actions.updateBrdbd009(data));
      setUpdateNo(0);
    }
    else
      alert("댓글을 작성해주세요!");
    return false;
  }
  //-------------------------------------------------------------------------------

  //Delete Comment
  const onDeleteBrdbd009 = (data) => {
    // console.log('삭제 : ', data, data['WRTER_ID'], localStorage.getItem('USER_AUTH'), localStorage.getItem('USER_ID') );

    if (localStorage.getItem('USER_AUTH') != 'A') { // 관리자가 아닌 경우 작성자가 맞는지 확인함 
      if (data['WRTER_ID'] != localStorage.getItem('USER_ID')) {
        alert('직접 작성한 게시물만 삭제 가능합니다.');   // 관리자 및 직접 작성한 글만 삭제 가능함 
        return false;
      }
    }

    if (window.confirm("삭제하시겠습니까?"))
      dispatch(brdbd009Actions.deleteBrdbd009(data));
    else
      return false;
  }
  //-------------------------------------------------------------------------------;

  //Nested Comment Toggle
  const [openInputDiv, setOpenInputdiv] = useState(0);
  //-------------------------------------------------------------------------------;

  //Comments List
  const CommentsList = ({ NTT_ID, BBS_ID, ANSWER_NO, WRTER_ID, WRTER_NM, ANSWER, ADD_DATE, CHANGE_DATE, ANSWER_LC, PARNTSCTT_NO }) => {
    return (
      <>
        <Box style={{ background: PARNTSCTT_NO !== 0 ? '#f8f9fb' : '' }}>
          {/* <Box> */}
          <div style={{ marginLeft: (2 * ANSWER_LC) + '%' }}>
            <div className="comment_TotalWrap" >
              <div className="recomment_icon" style={{ display: PARNTSCTT_NO !== 0 ? '' : 'none' }} >ㄴ</div>
              <div className="comment_contentsWrap" style={{ width: PARNTSCTT_NO !== 0 ? '780px' : '795px' }}>
                <div className="commenterNm">
                  {WRTER_NM}
                </div>
                <div style={{ display: updateNo !== ANSWER_NO ? '' : 'none' }}>{ANSWER}</div>
                <div style={{ display: updateNo === ANSWER_NO ? '' : 'none' }}>
                  <TextField
                    fullWidth
                    onChange={answerUpdate}
                    placeholder={ANSWER}
                    onKeyPress={
                      (e) => {
                        if (e.key === 'Enter') {
                          { onUpdateBrdbd009({ BBS_ID, NTT_ID, ANSWER_NO }) }
                        }
                      }} />
                </div>
                <div className="commenterDD">{CHANGE_DATE === '' ? ADD_DATE.substring(0, 16) : CHANGE_DATE.substring(0, 16)}</div>
                <div className="comment_BtnWrap">
                  <Button onClick={() => { openInputDiv !== ANSWER_NO ? setOpenInputdiv(ANSWER_NO) : setOpenInputdiv(0) }} className="commentBtn float_L commentBtn_LmarginX">댓글쓰기</Button>
                  <Button style={{ display: updateNo !== ANSWER_NO && localStorage.getItem('USER_ID') === WRTER_ID ? '' : 'none' }} onClick={() => onCommentUpdate(ANSWER_NO, WRTER_ID)} className="commentBtn  float_R">수정</Button>
                  <Button style={{ display: updateNo === ANSWER_NO ? '' : 'none' }} onClick={() => onUpdateBrdbd009({ BBS_ID, NTT_ID, ANSWER_NO })} className="commentBtn  float_R">완료</Button>
                  <Button style={{ display: updateNo !== ANSWER_NO && localStorage.getItem('USER_ID') === WRTER_ID || localStorage.getItem('USER_AUTH') === 'A' ? '' : 'none' }} onClick={() => onDeleteBrdbd009({ BBS_ID, NTT_ID, ANSWER_NO, WRTER_ID })} className="commentBtn  float_R">삭제</Button>
                  <Button style={{ display: updateNo === ANSWER_NO ? '' : 'none' }} onClick={() => setUpdateNo(0)} className="commentBtn  float_R">취소</Button>
                </div>
              </div>
            </div>
          </div>
          <Box className="recomment_Wrap" style={{ display: openInputDiv === ANSWER_NO ? '' : 'none' }}>
            <Table className="recomment_InputWrap">
              <colgroup>
                <col style={{ width: "90%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      type="string"
                      required
                      variant="outlined"
                      size="normal"
                      label={localStorage.getItem("user_id")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={answerRegist}
                      placeholder="답글을 입력하세요."
                      onKeyPress={
                        (e) => {
                          if (e.key === 'Enter') {
                            { onRegisterBrdbd009({ ANSWER_NO, ANSWER_LC }) }
                          }
                        }} />
                  </TableCell>
                  <TableCell style={{ paddingLeft: "0px" }}>
                    <Button className="commentBtn03" onClick={() => { onRegisterBrdbd009({ ANSWER_NO, ANSWER_LC }) }}>완료</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

        </Box>
      </>
    )

  }

  //Nested Comment List
  const NestedCommentList = ({ parents_id }) => {
    return (
      <>
        {brdbd009 && brdbd009.map((comments) => {
          const { NTT_ID, BBS_ID, ANSWER_NO, WRTER_ID, WRTER_NM, ANSWER, USE_AT, PASSWORD, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, ANSWER_LC, PARNTSCTT_NO } = comments;
          if (PARNTSCTT_NO === parents_id) {
            return (
              <>
                <CommentsList
                  NTT_ID={NTT_ID}
                  BBS_ID={BBS_ID}
                  ANSWER_NO={ANSWER_NO}
                  ANSWER={ANSWER}
                  ADD_DATE={ADD_DATE}
                  CHANGE_DATE={CHANGE_DATE}
                  WRTER_ID={WRTER_ID}
                  WRTER_NM={WRTER_NM}
                  ANSWER_LC={ANSWER_LC}
                  PARNTSCTT_NO={PARNTSCTT_NO}
                />
                <NestedCommentList
                  parents_id={ANSWER_NO}
                />
              </>
            );
          }
        })}
      </>
    )
  }

  //Input Comment
  const InputComment = () => {
    return (
      <Box>
        <Table>
          <colgroup>
            <col style={{ width: "90%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  placeholder={localStorage.getItem('USER_ID') ? '댓글을 입력하세요.' : '로그인 후 이용 가능합니다.'}
                  disabled={localStorage.getItem('USER_ID') ? false : true}
                  type="string"
                  required
                  variant="outlined"
                  onChange={answerRegist}
                  label={localStorage.getItem("user_id")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyPress={
                    (e) => {
                      if (e.key === 'Enter') {
                        { onRegisterBrdbd009({ "ANSWER_LC": null, "ANSWER_NO": null }) }
                      }
                    }}
                />
              </TableCell>
              <TableCell style={{ paddingLeft: "0px" }}>
                <Button className="commentBtn02" onClick={() => onRegisterBrdbd009({ "ANSWER_LC": null, "ANSWER_NO": null })}>등록</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    )
  }
  //-------------------------------------------------------------------------------;
  return (
    <>
      <InputComment />
      {brdbd009 && brdbd009.map((comments) => {
        const { NTT_ID, BBS_ID, ANSWER_NO, WRTER_ID, WRTER_NM, ANSWER, USE_AT, PASSWORD, REMARKS, STATUS, TIME_ZONE, ADD_DATE, ADD_USER_ID, ADD_USER_NAME, CHANGE_DATE, CHANGE_USER_ID, CHANGE_USER_NAME, ANSWER_LC, PARNTSCTT_NO } = comments;
        //Depth가 0인 것부터 출력
        if (PARNTSCTT_NO === 0) {
          return (
            <>
              <CommentsList
                NTT_ID={NTT_ID}
                BBS_ID={BBS_ID}
                ANSWER_NO={ANSWER_NO}
                ANSWER={ANSWER}
                ADD_DATE={ADD_DATE}
                CHANGE_DATE={CHANGE_DATE}
                WRTER_ID={WRTER_ID}
                WRTER_NM={WRTER_NM}
                PARNTSCTT_NO={PARNTSCTT_NO}
              />
              <NestedCommentList
                parents_id={ANSWER_NO}
              />
            </>
          )
        }
      })}
    </>
  )
}
export default BoardComment;
