import { combineReducers } from "redux";
// import { articleReducers } from "./articleSlice";
import { articleReducers } from "./articleSlice";
import { boardReducers } from "./boardSlice";
import { commentReducers } from "./commentSlice";
 
import { brdbd001Reducers } from "./brdbd001Slice";
import { brdbd003Reducers } from "./brdbd003Slice";
import { brdbd009Reducers } from "./brdbd009Slice";
import { loginReducers} from  "./loginSlice";


const rootReducer = combineReducers({
  articleReducers,
  boardReducers,
  commentReducers,
  brdbd001Reducers,
  brdbd003Reducers,
  brdbd009Reducers,
  loginReducers,
});

export default rootReducer;
