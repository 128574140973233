import { put } from "redux-saga/effects";
import Axios from "axios";
import { brdbd001Actions } from "../slice/brdbd001Slice";
import history from "../utils/history";
import { home_url } from '../js/globalProperties';
import cookies from 'react-cookies';


export function* registerBrdbd001Async(action) {
  const data = action.payload;
  data.append('OPEN_API_YN', 'Y');
  const response = yield Axios.post(home_url + `/api/BRD010010SVC/insertBbs`, data)
    .then(() => {
      alert("저장되었습니다.");
      history.push({ pathname: '/app/totalboard/' });
      history.go(0);
    });
}

export function* getBrdbd001Async(action) {
  try {
    const data = action.payload;
    const requestOptions = {
      headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
    };
    const responseForBoard = yield Axios.post(home_url + `/api/BRD010010SVC/selectBbs`, requestOptions);
    const boardData = responseForBoard.data;

    yield put(brdbd001Actions.getBrdbd001Async(boardData.DATA));
  } catch (e) {
    yield put(brdbd001Actions.getBrdbd001FailedAsync(e.message));
  }
}

export function* updateBrdbd001Async(action) {
  const data = action.payload;
  const requestOptions = {
    method: 'POST',
    headers: { 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
  };
  yield Axios.post(home_url + '/api/BRD010010SVC/updateBbs', data, requestOptions)
    .then(() => {
      alert("수정되었습니다.");
      history.push({ pathname: '/app/totalboard/' });
      history.go(0);
    });
}

export function* deleteBrdbd001Async(action) {
  const bbs_id = action.payload;

  const response = yield Axios.post(home_url + `/api/BRD010010SVC/putDelete`, { "BRD010010F1": bbs_id })
    .then(() => {
      alert("삭제되었습니다.");
      history.push({ pathname: '/app/totalboard/' });
      history.go(0);
    });
}
