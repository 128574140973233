import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory } from 'react-router-dom';
// material
import {
  Box,
  Button,
  Container, 
  Stack, 
  Typography,
  Divider,
  Pagination,
  TablePagination,
  } from '@material-ui/core';

// components
import Page from '../Page';
import {
  CommonGallerySort,
  CommonGalleryList,
  CommonGalleyToolbar
} from './';

import { brdbd003Actions } from "../../../../slice/brdbd003Slice";
//
// import PRODUCTS from '../data/products';

// ----------------------------------------------------------------------
function PotalBoard(props){
  const history = useHistory();
  const dispatch = useDispatch();
  const [BBS_ID, setBbsId] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const BBS = props.BBS;
  const AUTH = ['U', 'D', 'M', 'A'];

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    dispatch(brdbd003Actions.getBrdbd003({'BBS_ID':props.BBS.BBS_ID, 'OPEN_API_YN':'Y' }));
  }, [BBS_ID ,props.BBS]);
  
  const { brdbd003, isLoading, isSuccess, error } = useSelector((state) => ({
    brdbd003: state.brdbd003Reducers.brdbd003,
    isLoading: state.brdbd003Reducers.isLoading,
    isSuccess: state.brdbd003Reducers.isSuccess,
    error: state.brdbd003Reducers.error,
  }));
  
  const products = [];
  
  for (var n in brdbd003) {
    products.push(brdbd003[n]);
  }

  const handleRegisterPage = (bbs_data) => {
    history.push({ pathname: '/app/board/register', state: bbs_data });
  };
  
  return (
    <Page title="Dashboard: Products | Minimal-UI">
        <Typography className="listTable_tt">
          {props.BBS.BBS_NAME}
        </Typography>
        <Divider/>
        <div className=" position_rel">
        <br/>
        <CommonGalleryList BBS={props.BBS} products={products} />
        <CommonGalleyToolbar className=""/>
          <Pagination className="glyPagePosition"
            pageSize={page}
            itemsCount={products.length}
            currentPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
          </div>
      {/* </Container> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
        <Button type="primary" className="ButtonStyle02"
          color="primary"
          variant="contained"
          style={{ display: AUTH.indexOf(localStorage.getItem('USER_AUTH')) >= AUTH.indexOf(props.BBS.WRITE_AUTH) ? '' : 'none' }}
          onClick={() => handleRegisterPage({BBS})}>
          글쓰기
        </Button>
        </Box>


    </Page>
  );
}


export default PotalBoard;