import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const CommonGalleyToolbar = (props) => (
      <Card>
        <CardContent className="tableSrchInput_Wrap">
            <TextField className="tableSrchInput"
              // fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                      <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                )
              }}
              placeholder="Search product"
              variant="outlined"
            />
        </CardContent>
      </Card>
);

export default CommonGalleyToolbar;
