import { useState,useEffect } from "react";
import { home_url } from '../../../js/globalProperties';
import cookies from "react-cookies"

function NoteLeft(props) {
    const [COUNT, setCOUNT] = useState(0);
    const [TRADECOUNT, setTRADECOUNT] = useState(0);

    const getCount = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AJAX': true, 'X-CSRF-TOKEN': localStorage.getItem('X-CSRF-TOKEN') },
            body: JSON.stringify({ 'USER_ID': localStorage.getItem('USER_ID'), 'REQUEST': 'getList', 'READ_YN': 'N' })
        };
        fetch(home_url + '/api/BRD120010SVC/getCount', requestOptions)
            .then((response) => { return response.json(); })
            .then((data) => {
                setCOUNT(data.DATA.NOTE_CNT);
                setTRADECOUNT(data.DATA.TRADENOTE_CNT);
            });
    }

    useEffect(() => {
        getCount();
    },[props.noteMode])
    return (
        <>
            <span className="NoteleftmenuTT">쪽지함</span>
            <ul className="Noteleftmenu">
                <li className="paddingR">
                    <button className="Noteleftmenu_btn01" onClick={() => props.setNoteMode('writeNote')}>쪽지쓰기</button>
                    <button className="Noteleftmenu_btn02" onClick={() => props.setNoteMode('writeNoteMyself')}>내게쓰기</button>
                </li>
                <li onClick={() => props.setNoteMode('getList')}>
                    받은쪽지함
                    &nbsp;
                    <span className="user_alarmWrap_count">{COUNT}</span>
                    <span className="NoteleftmenuSpan">＞</span>
                </li>

                <li onClick={() => props.setNoteMode('getListMyself')}>내게쓴쪽지함<span className="NoteleftmenuSpan">＞</span></li>

                <li onClick={() => props.setNoteMode('sendList')}>보낸쪽지함<span className="NoteleftmenuSpan">＞</span></li>

                <li onClick={() => props.setNoteMode('noteBox')}>쪽지보관함<span className="NoteleftmenuSpan">＞</span></li>

                <li onClick={() => props.setNoteMode('getSpamList')}>스팸쪽지함<span className="NoteleftmenuSpan">＞</span></li>

                <li onClick={() => props.setNoteMode('getIgnoreList')}>차단목록<span className="NoteleftmenuSpan">＞</span></li>

                <li onClick={() => props.setNoteMode('tradeGetList')}>
                    중고거래 받은쪽지함
                    &nbsp;
                    <span className="user_alarmWrap_count">{TRADECOUNT}</span>
                    <span className="NoteleftmenuSpan">＞</span>
                </li>

                <li onClick={() => props.setNoteMode('tradeSendList')}>중고거래 보낸쪽지함<span className="NoteleftmenuSpan">＞</span></li>
            </ul>
        </>
    );
}
export default NoteLeft;