import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010401(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          <Typography className="listTable_tt">
          구축사례
          </Typography>
          <Divider/>
          <img src={require("./images/PLT/010401.PNG").default} style={{"width":"800px"}}/>
        </Card>
      </Page>
    </>
  );
}

export default PLT010401;