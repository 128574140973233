import React, { useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";

import CommonList from "./BoardList/CommonList";
import CommonGallery from "./BoardList/CommonGallery"; // 이민호 작업대상 CommonGallery 파일을 준비해야함  
import CommonGallery2 from "./BoardList/CommonGallery2";

import { home_url } from '../../../js/globalProperties';
import CommonCalander from "./BoardList/CommonCalander";


function BoardMain(props) {
  var items = new Array();
  var bss_type = "";
  const history = useHistory();
  const [TMPLAT_ID, setTMPLAT_ID] = useState([]);
  const [BBS_ID, setBBS_ID] = useState({});
  const [BBS, setBBS] = useState([]);
  const [BBS_NAME, setBBS_NAME] = useState([]);
  const [BBS_TY_CODE, setBBS_TY_CODE] = useState([]);
  const location = useLocation();

  const dispatch = useDispatch();

  const callApi = (bbs_id) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'BBS_ID': bbs_id, 'OPEN_API_YN': 'Y' })
    };
    fetch(home_url + '/api/BRD010010SVC/selectBbs', requestOptions)
      .then(function (response) { return response.json(); })
      .then(function (data) {
        items = data['DATA'];
        // items 
        setBBS(items[0]);
        setTMPLAT_ID(items[0].TMPLAT_ID);
        setBBS_ID(items[0].BBS_ID);
        setBBS_NAME(items[0].BBS_NAME);
        setBBS_TY_CODE(items[0].BBS_TY_CODE);

      });
  }
  useEffect(() => {
    callApi(props.match.params.bbs_id);
    return () => {
      //컴포넌트가 언마운트 될 때, 실행되는 영역 
      // unblock(); 
    };
    // }, [props.match.params.bbs_id]);
  }, [location]);

  if (BBS.length == 0) {
    console.log('게시판 정보가 없는 상황에서 목록화면을 호출하는 현상이 발생됨.');
    return '';
  }

  switch (TMPLAT_ID) {


    case '1':
      return (
        <div>
          <CommonList
            BBS={BBS}
          />
        </div>
      );
    case '2':
      return (
        <div className="cccccc">
          <CommonCalander 
            BBS={BBS} />
        </div>
      );
    case '3':
      return (
        <div>
          <CommonGallery
            BBS={BBS} />
        </div>
      );
    case '4':
      return (
        <div>
          <CommonGallery2
            BBS={BBS} />
        </div>
      );
    default:
      return (
        <div>
          {/* <CommonList
            BBS_ID={BBS_ID}
            BBS_NAME={BBS_NAME}/> */}
          <CommonList
            BBS={BBS}
          />
        </div>
      );
  }
}
export default BoardMain;